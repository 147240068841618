import { BaseClass } from './__base';
import { Strings } from '../../utils/strings';
import { Lists } from '../../utils/lists';
import { Objects } from '../../utils/objects';
import { UUID } from '../types';
import { immerable } from 'immer';
import {
	ALL_STREAMS,
	AVAILABLE_STREAMS,
	BUSINESS_STREAM_FLAG,
	INTERNATIONAL_STREAM,
	OCR_STREAM,
} from '../../utils/constants';
import { featureFlagsSelector } from '../../state/state';
import { getRecoil, setRecoil } from '../../state/recoilNexus';
import Client from '../client';
import { defaultSubjectAtom } from '../../state/session';
import { Numbers } from '../../utils/numbers';

export enum Roles {
	Teacher = 'TEACHER',
	Trial = 'TRIAL',
	RestrictedAccess = 'TEACH_CAMBRIDGE_RESTRICTED_ACCESS',
	Assessor = 'ASSESSOR',
	ExamsOfficer = 'EXAMS_OFFICER',
	ViewOnly = 'TEACH_CAMBRIDGE_ADMIN_VIEWER'
}

export type UserRole =
	| Roles.Assessor
	| Roles.Teacher
	| Roles.Trial
	| Roles.RestrictedAccess
	| Roles.ExamsOfficer
	| Roles.ViewOnly


export type UserRoles = {
	isTeacher: boolean,
	isTrial: boolean,
	isRestrictedAccess: boolean,
	isAssessor: boolean,
	isExamsOfficer: boolean,
	isViewOnly: boolean,
}

export type SourceSystem = {
	systemId: string,
	orgId: string
}

export type TrialAccessData = {
	id: string;
	date: number;
}

export class Organization extends BaseClass {
	public bpid: string;
	public name: string;
	public productGroup: string[];
	public roles: UserRole[];
	public sourceSystems: SourceSystem[];

	constructor(data: any = {}) {
		super(data);
		this.bpid = Strings.default(data.bpid);
		this.name = Strings.default(data.name);
		this.productGroup = Lists.default(data.productGroup);
		this.roles = Lists.default<UserRole>(data.roles);
		this.sourceSystems = Lists.default<SourceSystem>(data.sourceSystems);
	}

	public getOrgId(): string {
		return Strings.default(Objects.default(this.sourceSystems[0]).orgId, this.bpid);
	}
}

export class Preferences extends BaseClass {
	// Tour steps already seen by the user
	public tour: string[];
	// Default subject configured by the user
	public defaultSubject: UUID;

	constructor(data: any = {}) {
		super(data);
		this.tour = Lists.default(data.tour);
		this.defaultSubject = Strings.default(data.defaultSubject);
	}
}

export class UserQualification extends BaseClass {
	// Qualification group mapping uuid
	public qualification: UUID;
	// Subject mapping uuid
	public subject: UUID;
	// Lists of subject's assessments
	public assessments: UUID[];
	// Lists of chosen units
	public units: UUID[];
	// Class size given by the user when registering
	public class_size: number;
	// Whether the user has started to teach the qualification
	public teaching: boolean;
	// Date when the qualification was chosen by the user
	public created: Date; // Date string

	constructor(data: any = {}) {
		super(data);
		this.qualification = Strings.default(data.qualification);
		this.assessments = Lists.default(data.assessments);
		this.units = Lists.default(data.units);
		this.created = new Date(data.created);
		this.teaching = Boolean(data.teaching);
		this.class_size = Numbers.default(data.class_size);
		this.subject = Strings.default(data.subject);

	}
}

export class UserDetails extends BaseClass {
	// Date when the registration on TeachCambridge was created
	public created: Date; // Date string
	// Job title selected by the user when registering
	public jobTitle: string;
	// Date when the user was last logged in
	public lastLogin: Date; // Date string
	// How many times the user logged in
	public loginCount: number;
	public registrationInfo?: any;
	// Date when the user updated his profile
	public updated: Date; // Date string
	// Details about preferences (tour, defaultSubject)
	public preferences: Preferences;
	// SubjectsComponents chosen by the user
	public qualification: UserQualification[] = [];
	// Organization code
	public org: string;
	// Organization name
	public orgName: string;
	// Flag that indicates if user needs to confirm profile
	public reconfirm: boolean;

	public notifications?: any;

	constructor(data: any = {}) {
		super(data);
		this.created = new Date(data.created); // Date string
		this.jobTitle = Strings.default(data.job_title);
		this.lastLogin = new Date(data.last_login); // Date string
		this.loginCount = data.login_count;
		this.updated = new Date(data.updated); // Date string
		this.preferences = new Preferences(Objects.default(data.preferences));
		this.org = Strings.default(data.org);
		this.orgName = Strings.default(data.org_name);
		this.reconfirm = Boolean(data.reconfirm);

		Lists.default(data.qualification).forEach(q => {
			this.qualification.push(new UserQualification(q));
		});

		if (data.notifications) {
			this.notifications = Objects.default(data.notifications);
		}
		if (data.registrationInfo) {
			this.registrationInfo = Objects.default(data.registrationInfo);
		}
	}

}

export class UserSession extends BaseClass {
	[immerable] = true;
	public client_id: string;
	// User id from DB
	public accountId: UUID;
	// Token used for downloading documents
	public downloadToken: UUID;
	// User email
	public email: string;
	// Env on which application is running
	public env: string;
	// User family name
	public familyName: string;
	// User given name
	public givenName: string;
	// User name
	public name: string;
	// Selected organization
	public lastSelectedOrg: string;
	// Lists of available organizations
	public organizations: Organization[] = [];
	//  Business stream
	public selectedBusinessStream: string;
	public businessStream: string;
	// Auth token
	public token: string;

	// Details about preferences (subjects, tour, etc.)
	public preferences: UserDetails | null = null;
	// Token used for preview mode
	public preview?: boolean;
	// Error returned by server
	public error?: string;
	// URL returned by server (login URL)
	public redirectURL?: string;

	public trial: TrialAccessData | null = null;

	constructor(data: any = {}) {
		super(data);
		this.client_id = Strings.default(data.client_id);
		this.token = Strings.default(data.token);
		this.downloadToken = Strings.default(data.download_token);
		this.name = Strings.default(data.name);
		this.givenName = Strings.default(data.given_name);
		this.familyName = Strings.default(data.family_name);
		this.email = Strings.default(data.email);
		this.selectedBusinessStream = Strings.default(data.selectedBusinessStream);
		this.lastSelectedOrg = Strings.default(data.lastSelectedOrg);
		this.accountId = Strings.default(data.accountId);
		this.env = Strings.default(data.env);
		this.businessStream = Strings.default(data.businessStream);

		if (data.preferences) {
			this.preferences = new UserDetails(data.preferences);
		}

		if (data.error) {
			this.error = data.error;
		}
		if (data.redirectURL) {
			this.redirectURL = data.redirectURL;
		}
		if (data.preview) {
			this.preview = !!data.preview;
		}

		Lists.default(data.orgs).forEach(org => {
			this.organizations.push(new Organization(org));
		});

		if (data.trialAccess) {
			this.trial = data.trialAccess;
		}
	}

	public clone(overrides: any = {}): any {
		return new UserSession({...JSON.parse(JSON.stringify(this.__data)), ...overrides});
	}

	public getDefaultSubject(): string {
		return Objects.default(Objects.default(this.preferences).preferences).defaultSubject;
	}

	public availableStreams(): string[] {
		const flags = getRecoil(featureFlagsSelector);
		if (!Lists.default<any>(flags).includes(BUSINESS_STREAM_FLAG)) {
			return [OCR_STREAM];
		}
		if (this.businessStream === ALL_STREAMS) {
			return [OCR_STREAM, INTERNATIONAL_STREAM];
		}
		const streams = Array.from(new Set(this.organizations.map(o => Lists.default<string>(o.productGroup)).flat())).filter(o => AVAILABLE_STREAMS.includes(o));
		streams.sort((a, b) => a.localeCompare(b));
		return streams;
	}

	public getOrganization(bpId: string): Organization | undefined {
		return this.organizations.find(org => org.bpid === bpId);
	}

	public getRoles(): UserRoles {
		const roles: UserRoles = {
			isTeacher: false,
			isTrial: false,
			isRestrictedAccess: false,
			isAssessor: false,
			isExamsOfficer: false,
			isViewOnly: false,
		};

		const selectedOrg = this.organizations.find(org => org.bpid === this.lastSelectedOrg);
		if (!selectedOrg) {
			return roles;
		}

		if (selectedOrg.bpid === 'TRIAL') {
			roles.isTrial = true;
		}
		if (!selectedOrg.roles.includes(Roles.Teacher)) {
			if (selectedOrg.roles.includes(Roles.RestrictedAccess)) {
				roles.isRestrictedAccess = true;
			}
			if (selectedOrg.roles.includes(Roles.ExamsOfficer)) {
				roles.isExamsOfficer = true;
			}
			if (selectedOrg.roles.includes(Roles.Assessor)) {
				roles.isAssessor = true;
			}
		} else {
			roles.isTeacher = true;
		}

		return roles;
	}

	public hasFullAccess(): boolean {
		return !this.isTrial() && (this.isTeacher() || this.isAssessor() || this.isExamsOfficer());
	}

	public isTeacher(): boolean {
		return this.getRoles().isTeacher;
	}

	public isTrial(): boolean {
		return this.getRoles().isTrial;
	}

	public isRestrictedAccess(): boolean {
		return this.getRoles().isRestrictedAccess;
	}

	public isAssessor(): boolean {
		return this.getRoles().isAssessor;
	}

	public isExamsOfficer(): boolean {
		return this.getRoles().isExamsOfficer;
	}

	public isViewOnly(): boolean {
		return this.getRoles().isViewOnly;
	}

	//display mode = full -> display org code with name
	//display mode = short -> display only org name
	public getOrgName(options: 'short' | 'long' = 'long'): string {
		let org = this.organizations.find(o => o.bpid === this.lastSelectedOrg);
		let orgObj = Objects.default(org);
		let orgCode = Strings.default(Objects.default(Lists.default<SourceSystem>(orgObj.sourceSystems).find((s) => s.systemId === 'LegacyCustomerNum')).orgId, orgObj.bpid);
		let orgName = orgCode + ' - ' + orgObj.name;
		if (options === 'short') {
			orgName = orgObj.name;
		}
		return orgName;
	}

	public getAccessType(): string {
		if (this.isTrial() || this.isRestrictedAccess()) {
			return 'Limited';
		}
		return 'Full';
	}

	public getQualifications(): UserQualification[] {
		return Lists.default<UserQualification>(Objects.default(this.preferences).qualification);
	}

	public isFirstTimeUser(): boolean {
		return this.getQualifications().length === 0;
	}

	public getJobTitle(): string {
		return Objects.default(this.preferences).jobTitle;
	}

	public getPreferences(): Preferences {
		return Objects.default(Objects.default(this.preferences).preferences);
	}

	public getRegistrationInfo(): any {
		return Objects.default(Objects.default(this.preferences).registrationInfo);
	}

	public shouldUpdatePreferences(): boolean {
		// if the roles has changed force user to reconfirm preferences (to update job title)
		return this.reconfirmPreferences();
	}

	public async setDefaultSubject(subjectId: string) {
		const prefs = this.getPreferences();
		setRecoil(defaultSubjectAtom, subjectId);
		await Client.UpdatePreferences({...prefs, defaultSubject: Strings.default(subjectId)});
	}

	public reconfirmPreferences(): boolean {
		return Boolean(Objects.default(this.preferences).reconfirm);
	}
}