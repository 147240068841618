import React, {useEffect} from 'react';
import {Browser} from '../../utils/browser';
import {ROUTES} from '../../utils/routes';
import {Strings} from '../../utils/strings';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import Client from '../../tw/client';
import {ConfirmationModalProps, modalAtom} from './ModalDialog';
import Card from '../Card/Card';
import {sharedItem} from '../../state/state';
import {sessionAtom} from '../../state/session';

const SharedItemModal = () => {
	const [item, setItem] = useRecoilState(sharedItem);
	const session = useRecoilValue(sessionAtom);
	const setValue = useSetRecoilState(modalAtom);
	const {pageId, pageSlug} = Browser.getParams(ROUTES);

	useEffect(() => {
		if (!session || Strings.default(pageId) !== 'item') {
			return;
		}
		if (Strings.default(pageId) === 'item' && Strings.default(pageSlug)) {
			Client.getItem(pageSlug).then((res: any) => {
				if (!res) {
					return null;
				}
				const tmp = res.getObject ? res.getObject(false) : res;
				tmp.shared = true;
				setItem(tmp);
			});
		}
	}, [session]);

	const doNothing = () => {
		return
	}

	useEffect(() => {
		if (!item) {
			return;
		}
		const modalObj: ConfirmationModalProps = {
			title: 'Shared item',
			confirmText: '',
			hasCancel: true,
			noFooter: true,
			message: <Card item={item} key={item.getId()} showCheckbox={false} style={{width: '100%'}}/>,
			onConfirm: doNothing,
			onCancel: () => {
				setItem(null);
			},
		};
		setValue(modalObj);

	}, [item]);

	return (
		 <></>
	);
};

export default SharedItemModal;