export enum TrialMessages {
	FirstParagraph = `Your trial account is now set up and you’re ready to go.`,
	SecondParagraph = `Trial access is designed to give you a taste of the Teach Cambridge experience and enables you to see the types of support available to teachers. If you enjoy the experience and are considering teaching our qualifications, contact our customer development team on 02476 856072 or `,
	ThirdParagraph = `With full access you get the complete experience and access to all our support and resources.`,
	FourthParagraph = `Your trial account is active for two weeks. Let us know your thoughts via the survey at the end of your trial period.`,
	SurveyTitle = `Teach Cambridge trial survey`,
	SurveyFirstParagraph = `Your trial account has now expired.`,
	SurveySecondParagraph = `We hope you enjoyed the experience and can see all the support we have to offer you. Please complete our short survey and let us know your thoughts. Our customer development team will be in touch to discuss how they can help you switch to us and get full access to Teach Cambridge.`,
	SurveyThirdParagraph = `Alternatively, you can contact our `,
	SurveyCustomerLink = `Customer Support Centre`,
	SurveyQuestion1 = `How was your experience with Teach Cambridge?`,
	SurveyQuestion2 = `Would you say finding resources on Teach Cambridge is:`,
	SurveyQuestion3 = `Was there anything you couldn’t find or felt was missing?`,
	SurveyQuestion4 = `How could we improve Teach Cambridge?`,
	SurveyQuestion5 = `Why did you want to try trial access of Teach Cambridge?`,
	SurveyQuestion6 = `Did the experience of using Teach Cambridge persuade you to offer OCR qualifications in your centre?`,
	SurveyQuestion7 = `Are you interested in switching to OCR?`,
	SurveyQuestionExtra7 = `For which qualifications?`,
	SurveyQuestion8 = `Have you spoken with a member of the Customer Support Team during the trial?`,
	SurveyQuestion9 = `Can we offer you any further support?`,
	SurveyQuestionExtra9 = `How can we help you?`,
	SurveyFinalNoSupport = `Thank you for trying out Teach Cambridge!`,
	SurveyFinalSupport1 = `Thank you for completing the survey.`,
	SurveyFinalSupport2 = `We'll be in touch with you shortly.`,
	SurveyCompletedLine1 = `You have already completed this survey.`,
	SurveyCompletedLine2 = `If you responded to say that we could offer you further support, we'll be in touch with you shortly.`,
	SurveyCompletedLine3 = `If you would like to speak to someone immediately, you can contact the customer development team on 02476 856072 or you can email`,
	TrialEmail = `customerdevelopment@cambridge.org`
}