import React from 'react';
import styled from '@emotion/styled';
import ActivityType from './ActivityType';
import Series from './Series';
import DateRange from './DateRange';
import Button from '@mui/material/Button';
import { useSetRecoilState } from 'recoil';
import { examDateFilter } from '../../../../state/keyDates';

const Wrapper = styled.div`
    display: flex;

    .filter-group {
        margin-right: 32px;
        padding-right: 32px;
        border-right: 1px solid var(--color-grey);

        &:last-of-type {
            margin-right: 0;
        }
    }
`;

const StyledButton = styled(Button)`
    margin-top: 16px;
    margin-left: 16px;
    height: 30px;
`;

const Filter = () => {
	const setFilter = useSetRecoilState(examDateFilter);

	return (
		<Wrapper data-testid='key-date-filters'>
			<DateRange />
			<ActivityType />
			<Series />
			<StyledButton data-testid='clear-filter-btn' size='small' disableRipple onClick={() => setFilter({sort: 'asc'})}>Clear
				filters</StyledButton>
		</Wrapper>
	);
};

export default Filter;