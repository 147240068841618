import React, { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { usefulLinkSelector } from '../../../state/usefulLink';
import Group from '../../Card/Group';
import WidgetHeader from '../commons/WidgetHeader';
import { WidgetTitles } from '../commons/widgetTitles';
import { WidgetContext } from '../widget';
import { UsefulLink } from '../../../tw/models/UsefulLink';

const UsefulLinks = () => {
	const usefulLinks = useRecoilValue(usefulLinkSelector);
	const context = useContext(WidgetContext);
	const [filteredUsefulLinks, setFilteredUsefulLinks] = useState<UsefulLink[]>([]);

	useEffect(() => {
		// filter available links to show only selected links for the current page
		if (context && usefulLinks.length && context.widget.links.length) {
			setFilteredUsefulLinks(usefulLinks.filter((link) => context.widget.links.includes(link.getId())));
		}
	}, [context, usefulLinks]);

	return (
		<div data-testid="useful-links-widget">
			<WidgetHeader>
				<h2> {WidgetTitles.UsefulLinks}</h2>
			</WidgetHeader>
			<Group items={filteredUsefulLinks}/>
		</div>
	);
};

export default UsefulLinks;