export const Browser = {
	// This method is replaced by the useNavigate() hook in the App.tsx
	// and is used to navigate to a new page without requiring the use of the hook which will
	// unnecessarily refresh the component
	navigate: (_path: string, _args?: any) => {
		console.warn('navigator not replaced');
	},

	queryParams: (): { [key: string]: string } => {
		const params = new URLSearchParams(document.location.search);
		const result: { [key: string]: string } = {};
		// @ts-ignore
		for (const [key, value] of params.entries()) {
			result[key] = decodeURIComponent(value);
		}
		return result;
	},

	queryParam: (key: string): string | undefined => {
		return Browser.queryParams()[key];
	},

	hasQueryParam: (key: string): boolean => {
		return !!Browser.queryParam(key);
	},

	getParams: (routes: any, path: string = ''): any => {
		path = path || document.location.pathname;
		const pathParts = path.split('/');

		const matchRoute = (route: any): any => {
			const parts = (route.path || '').split('/');
			let matched = true
			const result: any = {pathname: path}

			for (let i = 0; i < parts.length; i++) {
				if (parts[i].startsWith(':') && pathParts[i]) {
					result[parts[i].substring(1)] = pathParts[i]
					continue
				}

				if (parts[i] !== pathParts[i] && parts[i] !== '*') {
					matched = false
					break
				}
			}

			if (matched && parts.length !== pathParts.length && parts[parts.length - 1] !== '*') {
				matched = false
			}
			if (matched) {
				if (route.children && route.children.length > 0) {
					for (let i = 0; i < route.children.length; i++) {
						const matchedRoute = matchRoute(route.children[i])
						if (matchedRoute) {
							return matchedRoute
						}
					}
				}
				return result
			}

			return null
		}

		const res = matchRoute({children: routes, path: '/*'} as any)
		return res || {pathname: path}
	},

	setQueryParam: (key: string | object, value?: string) => {
		const params = Browser.queryParams();
		let replacements = {};
		if (typeof key === 'object') {
			replacements = key;
		} else {
			replacements[key] = value;
		}
		let newParams: string[] = [];
		for (const keyR in replacements) {
			newParams.push(encodeURIComponent(keyR) + '=' + encodeURIComponent(replacements[keyR]));
		}
		for (const param in params) {
			if (typeof replacements[param] === 'undefined') {
				newParams.push(param + '=' + params[param]);
			}
		}

		Browser.navigate(`${document.location.pathname}?${newParams.join('&')}`);
	}
};
