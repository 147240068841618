import {CMSObject, DisplayMode} from "./__CMSObject";
import {UUID} from "../types";
import {Strings} from "../../utils/strings";
import {Lists} from "../../utils/lists";
import {Numbers} from "../../utils/numbers";
import {fi} from "../../utils/helpers";
import {ChildAssessment} from "./ChildAssessment";
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";
import {Component} from "./Component";

export class Assessment extends CMSObject {
	public code: string;
	public modular: boolean;
	public name: string;
	public qualification: UUID;
	public specification_group: UUID[];
	public status: boolean;
	public subject: UUID[];
	public version: number;

	public units: ChildAssessment[] = [];
	public components: Component[] = [];

	constructor(item: any = {}) {
		super(item);
		this.code = Strings.default(item.code);
		this.modular = Boolean(item.modular);
		this.name = Strings.default(item.name);
		this.qualification = item.qualification;
		this.specification_group = Lists.default<UUID>(item.specification_group);
		this.status = Boolean(item.status);
		this.subject = Lists.default(item.subject);
		this.version = Numbers.default(item.version, 1);

		const existing = getRecoil(references(this.getId()));
		if (existing) {
			return existing as Assessment;
		}
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		switch (options) {
			case DisplayMode.SHORT:
				return this.code;
			case DisplayMode.FULL:
				return `${this.code} - ${this.name}`;
			case DisplayMode.DETAILED:
				return `${this.code} - ${this.name} (${fi(this.modular, 'Modular', 'Linear')})`;
		}
	}

	public resolveUnits(units: ChildAssessment[]): void {
		units.forEach(unit => {
			if (unit.assessment.includes(this.getId())){
				if (!this.units.filter(u => u.getId() === unit.getId()).length){
					this.units.push(unit);
				}
			}
		});
		this.units = Lists.sort(Array.from(new Set(this.units)), 'code');
	}

	public resolveComponents(components: Component[]): void {
		components.forEach(component => {
            if (component.assessment === this.getId()) {
				component.title = component.displayLabel();
                this.components.push(component);
            }
        });
		this.components = Lists.sort(Array.from(new Set(this.components)), 'id');
	}
}
