import {Launch} from '@mui/icons-material';
import React, {useMemo} from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {sessionAtom} from '../../state/session';
import styled from '@emotion/styled';
import {device} from '../../utils/constants';
import {useRecoilValue} from 'recoil';
import {goToMyCambridge} from '../../utils/helpers';

const TextElem = styled.div`
    @media ${device.tablet} {
        padding-bottom: 8px;
    }
`;
const AccountEditDetails = () => {
	const user = useRecoilValue(sessionAtom);

	const getDetailsText = useMemo(() => {
		if (!user) return null;
		if (user.isExamsOfficer() && !user.isTeacher()) {
			return (
				<div data-testid="exams-officer-info">
					In order to <span className="text-semiBold">edit your personal information, add users </span>{' '}
					or <span className="text-semiBold">create accounts</span>, go to your {' '}
					<span className="text-semiBold"> My Cambridge </span> account.
				</div>
			);
		}
		return (
			<div data-testid="users-info">
				In order to <span className="text-semiBold">edit your personal information</span>{' '}
				go to your <span className="text-semiBold">My Cambridge</span> account.
			</div>
		);
	}, [user]);

	return (
		<div className="flex-column-space-between full-height">
			<div>
				<div className="text-semiBold text-20 mb-24 pl-24"> Edit details</div>
				<TextElem className="pl-24 pr-24"> {getDetailsText}</TextElem>
			</div>
			<div>
				<Divider/>
				<div className=" flex-row-end pt-16 pr-24">
					<Button data-testid="my-cambridge-link"
							size="small" color="primary" variant="outlined" startIcon={<Launch/>}
							onClick={goToMyCambridge}>
						Go to My Cambridge account
					</Button>
				</div>
			</div>
		</div>
	);
};

export default AccountEditDetails;