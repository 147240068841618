import React from 'react';
import Header from './Header';
import Content from './Content';
import styled from '@emotion/styled';

const SecondaryContentLayout = styled.div`
    display: grid;
    grid-template-areas:
    'header'
    'main';
    grid-area: secondaryContent;
    height: auto;
    min-height: 100vh;
    //overflow-y: auto;
    position: relative;
    grid-template-rows:70px 1fr;
`;

const SecondaryContent = ({children}: any) => {
	return (
		<SecondaryContentLayout data-testid="secondary-content" id="secondary-content">
			<Header/>
			<Content>
				{children}
			</Content>
		</SecondaryContentLayout>
	);
};

export default SecondaryContent;