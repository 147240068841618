import React, {Suspense, useEffect} from 'react';
import {FormMessages} from './TrainingComponents/messages';
import styled from '@emotion/styled';
import {TrainingFormComponents} from './TrainingComponents/trainingFormConfig';
import {atom, useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {sessionAtom} from '../../../state/session';
import {Objects} from '../../../utils/objects';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {Messages} from '../../../utils/messages';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import AdditionalInfo from './TrainingComponents/AdditionalInfo';
import Client from '../../../tw/client';
import {AlertSeverity, snackbarStateAtom} from '../../Snackbar/SnackbarComponent';
import PageLoader from '../../Loader/PageLoader';
import {Dates} from '../../../utils/dates';
import {Strings} from '../../../utils/strings';

const FormElem = styled.div`
    padding: 16px 0;
`;

const FormTitleElem = styled.div`
    font-size: 18px;
    font-family: var(--font-semi-bold);
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--color-monochrome);

    &::after {
        border-top: 1px solid var(--color-border-light);
        content: ' ';
        flex: 1;
        margin-left: 16px;
    }
`;

const FormContentElem = styled.div`
    input, textarea {
        background-color: var(--color-white);
        border-radius: 6px;
    }
`;

export const selectedTraining = atom<string | null>({
	key: 'selectedTraining',
	default: null,
});

const phoneRegExp = /^[0-9+\-()\[\]{}#*\s]{6,}$/;
const validationSchema = yup.object({
	phone: yup.string()
		.matches(phoneRegExp, Messages.PhoneNumberValidation),
	centre_address: yup.string()
		.required(Messages.MandatoryField),
	training: yup.string()
		.required(Messages.MandatoryField),
	date_first: yup.date()
		.required(Messages.MandatoryField)
		.typeError(Messages.DateValidation)
		.min(Dates.getMidnight(), Messages.FutureDateValidation),
	date_second: yup.date().optional()
		.typeError(Messages.DateValidation)
		.min(Dates.getMidnight(), Messages.FutureDateValidation),
	date_third: yup.date().optional()
		.typeError(Messages.DateValidation)
		.min(Dates.getMidnight(), Messages.FutureDateValidation),
	venue: yup.string(),
	start_time: yup.string()
		.nullable(),
	delegates_number: yup.number()
		.typeError(Messages.NumberTypeValidation)
		.positive(Messages.PositiveIntegerValidation)
		.integer(Messages.PositiveIntegerValidation),
	other: yup.string(),
	code: yup.string(),
});

const TrainingForm = () => {
	const userSession = useRecoilValue(sessionAtom);
	const setSnackbarState = useSetRecoilState(snackbarStateAtom);
	const [selectedTrainingEvent, setSelectedTraining] = useRecoilState(selectedTraining);

	let emptyProperties = {
		name: `${Strings.default(Objects.default(userSession).givenName)} ${Strings.default(Objects.default(userSession).familyName)}`,
		phone: '',
		email: Objects.default(userSession).email || '',
		centre_address: '',
		centre_name: '',
		centre_number: '',
		training: null,
		date_first: '',
		date_second: '',
		date_third: '',
		venue: 'Online',
		start_time: '',
		delegates_number: '',
		other: '',
		code: '',
	};

	const formik = useFormik({
		initialValues: emptyProperties,
		validationSchema: validationSchema,
		onSubmit: async (values, actions) => {
			try {
				await Client.submitTrainingForm(values).then(() => {
					setSelectedTraining(values.training);
					actions.resetForm();
					setSnackbarState({
						message: FormMessages.SubmittedSuccess,
						severity: AlertSeverity.Success,
					});
				});
			} catch (err) {
				setSnackbarState({
					message: FormMessages.Error,
					severity: AlertSeverity.Error,
				});
			}
		},
	});

	useEffect(() => {
		if (!userSession) return;
		formik.setFieldValue('centre_number', Strings.default(userSession.lastSelectedOrg));
		formik.setFieldValue('centre_name', Strings.default(userSession.getOrgName('short')));
	}, [userSession]);

	if (selectedTrainingEvent) return null;
	if (!userSession) return null;

	const onChange = (value, field) => {
		formik.setFieldValue(field, value);
	};
	const renderComponent = (comp) => {
		return <comp.component formik={formik} onChange={onChange}/>;
	};

	return (
		<Suspense fallback={<PageLoader/>}>
			<div className="text-16 text-regular mb-16">{FormMessages.InfoNote}</div>
			<form
				autoComplete="off"
				onSubmit={(e) => {
					e.preventDefault();
					formik.handleSubmit();
					formik.validateForm().then((resp) => {
						if (!Object.keys(resp).length) return;
						setSnackbarState({
							message: `${FormMessages.Error}. ${Messages.FormsErrorMessage}`,
							severity: AlertSeverity.Error,
						});
					});
				}}
				data-testid="training-form"
				noValidate
			>
				{TrainingFormComponents.map((comp, idx) => (
					<FormElem key={comp.label}>
						<FormTitleElem>
							{comp.label}
						</FormTitleElem>
						<FormContentElem>
							{renderComponent(comp)}
						</FormContentElem>
					</FormElem>
				))}
				<Divider/>
				<FormContentElem>
					<AdditionalInfo formik={formik} onChange={onChange}/>
				</FormContentElem>
				<div className="flex-row-end mt-16">
					<Button size="small" variant="outlined" onClick={() => formik.resetForm()} data-testid="reset-btn">
						Reset
					</Button>
					<Button size="small" variant="contained" type="submit" data-testid="submit-btn">
						Send
					</Button>
				</div>
			</form>
		</Suspense>
	);
};

export default TrainingForm;