import React, {useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from '@emotion/styled';
import {atom, useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {HomepageResourcesConfig} from './config';
import {DetailsProps, getTabContent, ResourceProps} from './utils';
import WidgetHeader from '../commons/WidgetHeader';
import {sessionAtom} from '../../../state/session';
import ResourceTab from "./ResourceTab";

export const homepageDetailsAtom = atom<DetailsProps | null>({
	key: 'homepageDetailsAtom',
	default: null,
});
export const homepageResourceAtom = atom<ResourceProps | null>({
	key: 'homepageResourceAtom',
	default: null,
});

export const homepageTab = atom<number>({
	key: 'homepageTabAtom',
	default: 0,
});

const HomepageResourcesElem = styled.div`
    background-color: var(--color-white);
    border: solid 1px var(--color-border-light);
    border-radius: 6px;
`;

const HomepageResources = () => {
	const user = useRecoilValue(sessionAtom);
	const [value, setValue] = useRecoilState(homepageTab);
	const setHomepageResource = useSetRecoilState(homepageResourceAtom);
	const handleChange = (e, newVal) => {
		e.preventDefault();
		setValue(newVal);
	};

	useEffect(() => {
		setHomepageResource(getTabContent(value));
	}, [value]);

	const isTabDisabled = (idx) => {
		if (!user) return false;
		return idx == 2 && user.isTrial();

	};
	return (
		<div data-testid="resources-widget">
			<WidgetHeader>
				<h2>Resources</h2>
			</WidgetHeader>
			<HomepageResourcesElem>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					{HomepageResourcesConfig.map((h, idx) => (
						<Tab label={h.label} key={h.label} disabled={isTabDisabled(idx)} data-testid={h.label}/>
					))}
				</Tabs>
				<div className="pall-16">
					<ResourceTab/>
				</div>
			</HomepageResourcesElem>
		</div>

	);
};

export default HomepageResources;