import React, {useMemo} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {documentSelector} from '../../../state/documents';
import {homepageDetailsAtom, homepageResourceAtom} from './HomepageResources';
import {Browser} from '../../../utils/browser';
import {fi} from '../../../utils/helpers';
import Group from '../../Card/Group';
import ViewAllBtn from '../commons/ViewAllBtn';
import NoContentComponent from './NoContentComponent';
import {UnitSelector} from '../../../state/product';
import {HomepageMessages} from './messages';
import {Objects} from '../../../utils/objects';
import styled from '@emotion/styled';

const WrapperElem = styled.div`
    .download-buttons {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin-right: 0
    }
`
const ResourceTab = () => {
	const documents = useRecoilValue(documentSelector);
	const homepageResourceTab = useRecoilValue(homepageResourceAtom);
	const setHomepageDetails = useSetRecoilState(homepageDetailsAtom);
	const unit = useRecoilValue(UnitSelector);

	const items = useMemo(() => {
		if (!homepageResourceTab) return [];
		return homepageResourceTab.items().byUnitList(unit.map(u => u.value));
	}, [documents, homepageResourceTab, localStorage.getItem('new_updated'), unit]);

	const displayedItems = useMemo(() => {
		return items.length > 4 ? items.slice(0, 4) : items;
	}, [items]);

	const showDownload = useMemo(() => {
		let dwd = items.filter(i => i.downloadable())
		return  Objects.default(homepageResourceTab).title === HomepageMessages.NewAndUpdatedPageTitle && dwd.length <= 4
	},[items])

	const displayBtn = useMemo(() => {
		return items.length > 4;
	}, [items]);

	const viewAll = () => {
		if (!homepageResourceTab) return;
		setHomepageDetails({title: homepageResourceTab.title, items: homepageResourceTab.items, dwdFileName: homepageResourceTab.dwdFileName});
		Browser.navigate(`${document.location.pathname}${homepageResourceTab.route}`);
	};

	if (!homepageResourceTab) return null;

	return (
		fi(items.length,
			<WrapperElem data-testid='resource-tab'>
				<Group items={displayedItems} hideActions={!showDownload} label={homepageResourceTab.dwdFileName}/>
				{fi(displayBtn,
					<div className='flex-row-end'>
						<ViewAllBtn viewAll={viewAll} nrOfItems={items.length} />
					</div>)}
			</WrapperElem>,
			<NoContentComponent message={homepageResourceTab.message} />)
	);
};

export default ResourceTab;