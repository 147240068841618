import {atom, selector} from 'recoil';
import Client from '../tw/client';
import {Types, UUID} from '../tw/types';
import {sessionAtom} from './session';
import {Lists} from '../utils/lists';
import {references} from './state';
import {Objects} from '../utils/objects';
import {Qualification} from '../tw/models/Qualification';
import {Browser} from '../utils/browser';
import {ChildAssessment} from '../tw/models/ChildAssessment';
import {CTUnit} from '../tw/models/CTUnit';
import {SELECTED_SUBJECT, SELECTED_UNIT} from '../utils/constants';
import {Subject} from '../tw/models/Subject';
import {UserQualification} from '../tw/models/Session';
import {Strings} from '../utils/strings';
import {getRecoil} from './recoilNexus';

// Stores product tree with all subject mappings from CMS
export const productTreeSelector = selector<Qualification[] | null>({
	key: 'productTreeSelector',
	get: async ({get}) => {
		const user = get(sessionAtom);
		if (!user) return [];
		return await Client.getProductTree();
	},
	// set: () => {},
});

// Stores selected subject UUID
const selectedSubjectAtom = atom<UUID | null>({
	key: 'selectedSubjectAtom',
	default: null,
});

// Stores selected unit UUID
const selectedUnitAtom = atom<any[]>({
	key: 'selectedUnitAtom',
	default: [{label: 'All units', value: 'all'}],
});

export const enableSubjectFilter = atom<boolean>({
	key: 'enableSubjectFilter',
	default: false,
});

export const enableUnitFilter = atom<boolean>({
	key: 'enableUnitFilter',
	default: true,
});

export const SubjectSelector = selector<Subject | null>({
	key: 'SubjectSelector',
	get: async ({get}) => {
		const session = get(sessionAtom);
		if (!session) return null;

		const defaultSubject = Strings.default(Objects.default(Lists.default(Objects.default(session.preferences).qualification)[0]).subject);
		let selectedSubjectId = get(selectedSubjectAtom);
		if (!selectedSubjectId) {
			selectedSubjectId = Strings.default(Browser.queryParam('subject'), Strings.default(localStorage.getItem(SELECTED_SUBJECT)));
			if (!selectedSubjectId) {
				selectedSubjectId = Strings.default(session.getDefaultSubject(), defaultSubject);
				if (!selectedSubjectId) {
					return null;
				}
			}
		}
		if (Lists.default<UserQualification>(Objects.default(session.preferences).qualification)
			.filter(q => q.subject === selectedSubjectId).length === 0) {
			selectedSubjectId = Strings.default(session.getDefaultSubject(), defaultSubject);
		}

		get(productTreeSelector); // make sure product data is loaded
		const subject = get(references(selectedSubjectId));
		if (subject) {
			return subject as Subject;
		}
		return null;
	},
	set: ({set, get}, value: any) => {
		if (value && typeof value === 'object' && value.getId) {
			value = value.getId();
		}
		// set product in selectedSubjectAtom
		set(selectedSubjectAtom, value);

		// set subjectId to localstorage
		localStorage.setItem(SELECTED_SUBJECT, value);

		const subjectFilterEnabled = get(enableSubjectFilter);
		if (subjectFilterEnabled) {
			Browser.setQueryParam({subject: value, unit: 'all'});
		}
	},
});

export const getUnitCodesForURL = (units) => {
	let code: string[] = [];
	if (units.length > 0) {
		units.forEach(v => {
			if (v.value === 'all') {
				code.push('all');
			}else{
				const unit = getRecoil(references(v.value));
				if (unit) {
					if (unit.getType() === Types.CHILD_ASSESSMENT) {
						code.push((unit as ChildAssessment).code);
					} else {
						code.push((unit as CTUnit).alias);
					}
				}
			}
		});
	}
	return code
}
export const UnitSelector = selector<any | null>({
	key: 'unitSelector',
	get: async ({get}) => {
		const unit = get(selectedUnitAtom);
		const subject = get(SubjectSelector);
		if (!subject || !unit || !unit.length) {
			return [{label: 'All units', value: 'all'}];
		}
		return unit;
	},
	set: ({set, get}, units: any[]) => {
		let code = getUnitCodesForURL(units)
		set(selectedUnitAtom, units);

		localStorage.setItem(SELECTED_UNIT, JSON.stringify(code));
		const subjectFilterEnabled = get(enableSubjectFilter);
		if (subjectFilterEnabled) {
			Browser.setQueryParam('unit', code.join(','));
		}
	},
});
