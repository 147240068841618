import React from 'react';
import { components } from 'react-select';
import {fi} from "../../../utils/helpers";

const CheckboxOption = (props) => {
	return (
		<components.Option {...props} data-testid="checkbox-option-test-id" className="bold-option">
			<>
                <span style={{color: `var(${fi(props.isSelected, '--color-blue', '--color-monochrome')}`}} className={
					`
					${!props.isSelected ? 'checkbox-img-unchecked' : 'checkbox-img-checked'}
					`
				}>
                </span>
				<span style={{color: `var(${fi(props.isSelected, '--color-blue', '--color-monochrome')}`}}
					  className="checkbox-option-text">
					{props.data.label}
				</span>
			</>
		</components.Option>
	);
};

export default CheckboxOption;
