import {atom, atomFamily} from 'recoil';

export const trialSurveyStart = atom<boolean>({
	key: 'trialSurveyStartAtom',
	default: false,
});

export const trialSurveyStep = atom<number>({
	key: 'trialSurveyStepAtom',
	default: 0,
});

export const skippedQuestions = atom<any[]>({
	key: 'skippedQuestionsAtom',
	default: [],
})

export const trialSurveyData = atomFamily<any, any>({
	key: 'trialSurveyDate',
	default: null,
});

export const trialSurveyStepData = atom<any>({
	key: 'trialSurveyStepData',
	default: null,
});
export const trialSurveyExtraStepData = atom<any>({
	key: 'trialSurveyExtraStepData',
	default: null,
});

//used to store question 9 answer for displaying final message
export const trialSurveySupport = atom<any>({
	key: 'trialSurveySupportAtom',
	default: null,
});

export const trialSurveyCompleted = atom<boolean>({
	key: 'trialSurveyCompletedAtom',
	default: false,
});