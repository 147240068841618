import React, {useContext} from 'react';
import {WidgetContext} from '../widget';
import {WidgetType} from '../../../tw/models/Page';
import TrainingFormWrapper from './TrainingFormWrapper';

const Forms = () => {
	const context = useContext(WidgetContext);

	const renderForm = () => {
		if (context.widget.formType === WidgetType.TrainingForm) {
			return <TrainingFormWrapper/>;
		}
		return null;
	};
	return (
		renderForm()
	);
};

export default Forms;