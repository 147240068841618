import {atom, DefaultValue, selector} from 'recoil';
import {UserSession} from '../tw/models/Session';
import {Browser} from '../utils/browser';
import Client from '../tw/client';
import {Strings} from '../utils/strings';
import {PREVIEW_TOKEN, USER_TOUR} from '../utils/constants';
import {Objects} from '../utils/objects';
import GA, {MeasurementConfig} from '../tw/models/GA';
import {Lists} from "../utils/lists";

export const sessionAtom = atom<UserSession | null>({
	key: 'sessionAtom',
	default: null,
});

export const tourAtom = atom<string[]>({
	key: 'tourAtom',
	default: [],
});

export const defaultSubjectAtom = atom<string>({
	key: 'defaultSubjectAtom',
    default: '',
})

export const sessionSelector = selector<UserSession | null>({
	key: 'sessionSelector',
	get: async ({get}) => {
		const storedSession = get(sessionAtom);
		if (storedSession) {
			return storedSession;
		}

		let session: UserSession | null = null;

		// check if it's a preview mode session
		if (Browser.hasQueryParam(PREVIEW_TOKEN)||Client.getPreviewToken()) {
			let previewToken = Client.getPreviewToken();
			if (!previewToken) {
				previewToken = Strings.default(Browser.queryParam(PREVIEW_TOKEN));
			}
			if (previewToken) {
				Client.setPreviewToken(previewToken);
			}
		}

		// with these 2 query params present it means we just returned from SAP CDC after logging in
		if (Browser.hasQueryParam('code') && Browser.hasQueryParam('state')) {
			try {
				const trialSetup = JSON.parse(Strings.default(sessionStorage.getItem('trialAccessSetup'), '{}'));
				let role = Strings.default(Objects.default(trialSetup).jobTitle, '');
				session = await Client.login(role);
				localStorage.removeItem(USER_TOUR);
			} catch (e) {
				console.error(e);
			}
			GA.LoginEvent();
			return session;
		}

		// restoring session from storage
		const storedToken = Client.getAuthToken();
		if (storedToken) {
			Client.setAuthToken(storedToken);
		}

		try {
			session = await Client.getAccountDetails();
		} catch (error) {
			console.log(error);
		}

		return session;
	},
	set: ({set}, value) => {
		if (value instanceof DefaultValue) {
			set(sessionAtom, null);
			set(tourAtom, []);
			return;
		}
		if (value) {
			Client.setAuthToken(value.token);
			set(sessionAtom, value);
			set(tourAtom, Lists.default(value.getPreferences().tour));
			set(defaultSubjectAtom, value.getDefaultSubject());
			GA.measurementId = MeasurementConfig[value.env];
		}
	},
});

export const hasRestrictedAccess = selector<boolean>({
	key: 'hasRestrictedAccessSelector',
	get: ({get}) => {
		const user = get(sessionAtom);
		if (!user) {
			return false;
		}
		return user.isRestrictedAccess() || user.isTrial();
	},
});
export const logOutOfSSO = (clientID: string): string => {
	let url = 'sso.cambridge.org/logout';
	let redirectParam = `?redirect_uri=${window.location.origin}&client_id=${clientID}`;
	if (['localhost', 'dev.'].find(d => window.location.hostname.includes(d))) {
		url = 'proxy.dev.internal.' + url;
	} else if (['test.', 'pp1.'].find(d => window.location.hostname.includes(d))) {
		url = 'proxy.qa.internal.' + url;
	}
	return 'https://' + url + redirectParam;
};