import {useCallback, useMemo, useRef} from 'react';
import {cacheBuster, selectedPageAtom} from './state';
import {setRecoil} from './recoilNexus';
import {useRecoilValue} from 'recoil';
import {Page} from '../tw/models/Page';
import {buildTree, filterTree, findTreeNode, ITreeItem} from '../components/TreeMenu/utils';
import {DocumentsWrapper} from '../tw/models/DocumentsWrapper';
import {SubjectUpdate} from '../tw/models/SubjectUpdate';
import {Subject} from '../tw/models/Subject';
import {Objects} from '../utils/objects';
import {Lists} from '../utils/lists';
import {fi, walkTreeUp} from '../utils/helpers';
import {Browser} from '../utils/browser';

type TParam = {
	pageId: string,
	pageSlug: string
	pathname: string;
}

export class MenuState {

	private static instance: MenuState;

	// ----- Exposed menu state -----

	// Should the menu be visible at all. Hidden on my account page for example.
	public menuVisible: boolean = true;
	// The state indicating whether the secondary menu is expanded or collapsed. Expaned by default
	public secondaryMenuVisible: boolean = true;

	// Current selected root page
	private _rootPage: ITreeItem | null = null;
	// Current selected page from the child menu of the root page. If any
	private _selectedPage: ITreeItem | null = null;

	// Entire page tree
	private _tree: ITreeItem[] = [];
	// Filtered page tree by subject
	private _filteredTree: ITreeItem[] = [];
	// Submenu tree belonging to the selected root page. If any
	private _subTree: ITreeItem[] = [];

	// ----- Data set by other components or server -----

	// Just to keep track of setting data
	private dataSet: number = 0;

	// All server pages cache.
	private _pages: Page[] = [];
	// Filtered pages by the selected subject.
	private filteredPages: Page[] = [];

	// Current route parameters
	private params: TParam = {pageId: '', pageSlug: '', pathname: '/'};

	// All server documents for the selected subject.
	private documents: DocumentsWrapper = [] as any;

	// All server subject updates for the selected subject.
	private subjectUpdates: SubjectUpdate[] = [];

	// Selected subject
	private selectedSubject: Subject | null = null;

	constructor() {
		if (MenuState.instance) {
			return MenuState.instance;
		}

		MenuState.instance = this;
	}

	get rootPage(): ITreeItem | null {
		return this._rootPage;
	}

	get selectedPage(): ITreeItem | null {
		return this._selectedPage;
	}

	get filteredTree(): ITreeItem[] {
		return this._filteredTree;
	}

	get subTree(): ITreeItem[] {
		return this._subTree;
	}

	get pages(): Page[] {
		return this._pages;
	}

	public toggleVisible = (val: boolean) => {
		this.menuVisible = val;
		this.refreshState();
	};

	public toggleVisibleMenu = (val: boolean) => {
		this.secondaryMenuVisible = val;
		this.refreshState();
	}

	public setPages = (pages: Array<Page>) => {
		this._pages = pages.map(page => new Page(JSON.parse(JSON.stringify(page.__data))));
		this._tree = buildTree(pages);

		this.filterMenu();
		this.determineState();
	};

	public setParams = (params: TParam | null) => {
		const oldPageId = Objects.default(this.params).pageId;
		const newPageId = Objects.default(params).pageId;
		this.params = Objects.default(params, {pathname: '/'});

		if (this.params.pathname === '/' || (newPageId && newPageId !== oldPageId)) {
			this.determineState();
		}
	};

	public setData = (data: any) => {
		this.documents = data.documents;
		this.subjectUpdates = data.subjectUpdates;
		this.selectedSubject = data.selectedSubject;
		this.dataSet++;

		this.filterMenu();
		this.determineState();

	};

	public setPage = (page: ITreeItem | string) => {
		let target: ITreeItem = page as ITreeItem;
		if (typeof page ==='string') {
			target = findTreeNode(this._tree, page);
			if (!target) {
                Browser.navigate('/');
				return;
            }
		}
		if (['Key documents', 'My favourites', 'New or updated resources'].includes(target.object.title)) {
			Browser.navigate(`${document.location.pathname}${document.location.search}`);
		} else {
			Browser.navigate(`${target.object.getURL()}${document.location.search}`);
		}
	};

	public toggleMenu = (page: ITreeItem, val?: boolean) => {
		const p = findTreeNode(this._subTree, page);
		if (p) {
			p.opened = fi(typeof val === 'boolean', val, !p.opened);
		}
		this.refreshState();
	};

	private setSelectedPage(page: ITreeItem) {
		if (this._selectedPage) {
			const old = findTreeNode(this._subTree, this._selectedPage.id);
			if (old) {
				old.selected = false;
			}
		}

		this._selectedPage = page;
		const current = findTreeNode(this._subTree, this._selectedPage);
		if (current) {
			current.selected = true;
		}

		setRecoil(selectedPageAtom, {
			index: this._selectedPage.index,
			id: this._selectedPage.id,
			order: this._selectedPage.order,
			children: [],
			object: new Page(JSON.parse(JSON.stringify(this._selectedPage.object.__data))),
		});
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}, 10)
	}

	private filterMenu = () => {
		this.filteredPages = this.pages.filter(p => p.hasSubject(this.selectedSubject));
		this._filteredTree = buildTree(this.filteredPages);
		filterTree(this._filteredTree, this.documents, this.subjectUpdates);
	};

	private determineState = () => {
		const params = Objects.default(this.params);
		const isPageRoute = params.pathname === '/' || params.pageId;

		if (this.dataSet === 0 || this.pages.length === 0 || !isPageRoute) {
			return;
		}

		// if there is no params or there is an item shared
		if (!params.pageId || params.pageId === 'item') {
			this.goToHomeRoot();
			return;
		}

		// Get the page referenced in the params both from the unfiltered menu and filtered menu
		let [targetPage, filteredTargetPage] = this.getPage(params.pageId);

		// If it doesn't exist anywhere, then it's a wrong id or unpublished page or just crap so
		// we redirect home
		if (!targetPage && !filteredTargetPage) {
			this.goToHomeRoot();
			return;
		}

		// If the page doesn't exist in the filter menu but exists in the unfiltered,
		// it means it may have been filtered out so we go to the first parent in the filtered menu
		// we can find
		if (!filteredTargetPage) {
			const parent = this.getNextAvailableParent(targetPage as ITreeItem);

			// If none of the parents exist in the filtered menu, then we go to the home page
			if (!parent) {
				this.goToLandingPage();
				return;
			}

			filteredTargetPage = parent;
		}

		const root = this.getRootPageForPage(filteredTargetPage!);
		if (!this._rootPage || (root && (root.id !== this._rootPage.id))) {
			this._rootPage = root;
		}
		this._subTree = Lists.default(root?.children);
		findTreeNode(this._subTree, (node) => {
			node.selected = false;
			return false;
		});

		this.setSelectedPage(filteredTargetPage!);

		const parents = this.getPageParents(filteredTargetPage!);
		parents.forEach(p => this.toggleMenu(p, true));

		this.refreshState();
	};

	// getPage searches for the given page in the unfiltered and filtered (by subject) menu
	private getPage(page: ITreeItem | string): [ITreeItem | null, ITreeItem | null] {
		const unfiltered = findTreeNode(this._tree, page);
		const filtered = findTreeNode(this._filteredTree, page);
		return [unfiltered, filtered];
	}

	private getPageParents(page: ITreeItem): ITreeItem[] {
		const parents: ITreeItem[] = [];
		walkTreeUp(page as ITreeItem, (node) => {
			parents.push(node);
		});
		return parents.slice(1);
	}

	private getNextAvailableParent = (page: ITreeItem): ITreeItem | null => {
		const parents = this.getPageParents(page);
		for (let i = 0; i < parents.length - 1; i++) {
			// we need to check for the page in the subtree, so it won't go all the way up
			// to the root
			const filteredParent = findTreeNode(this._filteredTree, parents[i]);
			if (filteredParent) {
				return filteredParent;
			}
		}
		return null;
	};

	// Given any page in the tree, return the root page for that page.
	private getRootPageForPage = (page: ITreeItem): ITreeItem | null => {
		let parent: ITreeItem | null = null;
		walkTreeUp(page as ITreeItem, (node) => {
			parent = node;
		});
		return parent;
	};

	private goToHomeRoot() {
		if (this.params && this.params.pageId === 'teaching-resource') {
			this.refreshState();
			return;
		}
		const homePage = findTreeNode(this._filteredTree, (node) => node.object.isHomePage);
		// no page is set, should go to home page
		if (homePage) {
			this.setRootPage(homePage);
		} else {
			this.setRootPage(this._filteredTree[0]);
		}
		this.refreshState();
	}

	private goToLandingPage() {
		const subjectHomePage = this._subTree.find(i => i.object.landingPage());
		if (!subjectHomePage) {
			this.goToHomeRoot();
			return;
		}
		this.setSelectedPage(subjectHomePage!);
		this.refreshState();
	}

	public goToLandingForTour() {
		const landingForTour = findTreeNode(this._filteredTree,i => i.object.userTourPage());

		if (!landingForTour) {
			this.goToHomeRoot();
			return;
		}
		this.setPage(landingForTour)
		// this.setSelectedPage(landingForTour!);
		this.refreshState();
	}

	// setRootPage selects the root page and sets the child menu and selects the first child page on it
	public setRootPage = (rootPage: ITreeItem | string) => {
		if (typeof rootPage ==='string') {
			rootPage = findTreeNode(this._filteredTree, rootPage)
		}
		const futureSubtree: ITreeItem[] = Lists.default(Objects.default(rootPage).children);
		let targetPage: ITreeItem = rootPage as ITreeItem;

		if (futureSubtree.length) {

			// If we have a submenu, set the selected page marked as subjectHome or first page in list.
			const selectedPage = findTreeNode(futureSubtree, (node) => node.object.landingPage());
			if (selectedPage) {
				targetPage = selectedPage;
			} else {
				targetPage = futureSubtree[0];
			}
		}
		Browser.navigate(`${targetPage.object.getURL()}${document.location.search}`);
	};

	private refreshState = () => {
		setRecoil(cacheBuster('menuState'), (val) => val + 1);
	};
}

export const useMenu = () => {
	const cache = useRecoilValue(cacheBuster('menuState'));
	const state = useRef<MenuState>(new MenuState());

	const toggleMenu = useCallback((page: ITreeItem, val?: boolean) => state.current.toggleMenu(page, val), [cache, state]);
	const goToLandingForTour = useCallback(() => state.current.goToLandingForTour(), [cache, state]);

	return useMemo(() => ({
		state: state.current,
		toggleVisible: state.current.toggleVisible,
		toggleVisibleMenu: state.current.toggleVisibleMenu,
		toggleMenu,
		setPage: state.current.setPage,
		setRootPage: state.current.setRootPage,
		goToLandingForTour,
	}), [cache]);
};