import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {logOutOfSSO, sessionAtom, tourAtom} from '../../state/session';
import {Browser} from '../../utils/browser';
import {
	ACCOUNT_LABEL,
	device,
	ROUTE_MY_ACCOUNT,
	SELECTED_SUBJECT,
	SELECTED_UNIT,
	USER_TOUR,
} from '../../utils/constants';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import {useMediaQuery} from '@mui/material';
import {fi, goToMyCambridge} from '../../utils/helpers';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, {useMemo} from 'react';
import styled from '@emotion/styled';
import {bindPopover, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks';
import Client from '../../tw/client';
import {showTourAtom} from '../../state/state';

const AccountBtnElem = styled(Button)`
    color: var(--color-black);

    &:hover {
        background-color: var(--color-white);
    }
`;
const AccountOptionsElem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    color: #212121;

    hr {
        width: 100%;
    }
`;

const UserDetailsElem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 8px 16px 8px 16px;
    line-height: 1.5;

    label {
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
    }

    span {
        color: var(--color-monochrome);
    }
`;
const Footer = styled.div`
    padding: 16px;
    display: flex;
    align-self: center;
    width: 100%;

    button {
        width: 100%;
        line-height: 16px;
    }
`;

const ButtonElem = styled(Button)`
    font-size: 16px;
    font-family: var(--font-regular);
    width: 100%;
    padding: 9px 16px;
    justify-content: left;
    line-height: 20px;
`;

const AccountMenu = () => {
	const mobile = useMediaQuery(device.mobile);
	const popupState = usePopupState({variant: 'popover', popupId: 'account-dropdown'});
	const setShowAtom = useSetRecoilState(showTourAtom);
	const resetTourSteps = useResetRecoilState(tourAtom);
	const userSession = useRecoilValue(sessionAtom);

	const shouldDisableAction = useMemo(() => {
		if (!userSession) return true;
		return userSession.getQualifications().length === 0 || userSession.shouldUpdatePreferences();
	}, [userSession]);

	if (!userSession) return null;
	const onMyAccount = () => {
		Browser.navigate(ROUTE_MY_ACCOUNT);
		popupState.close();
	};

	const onTakeTour = () => {
		setShowAtom((val) => val + 1);
		localStorage.removeItem(USER_TOUR);
		resetTourSteps();
		popupState.close();
	};

	const onRedirect = () => {
		goToMyCambridge();
		popupState.close();
	};

	const onLogout = () => {
		const clientId = userSession.client_id;
		Client.logout().then(() => {
			window.location.assign(logOutOfSSO(clientId));
			localStorage.removeItem(SELECTED_SUBJECT);
			localStorage.removeItem(SELECTED_UNIT);
		}).catch();
	};

	return (
		<div>
			<AccountBtnElem className="text-16 text-bold pl-8 flex-row-center" {...bindTrigger(popupState)}
							startIcon={<Avatar/>} data-testid="account-area"
							endIcon={fi(popupState.isOpen, <ExpandLess fontSize="small"/>, <ExpandMore
								fontSize="small"/>)}>
				{fi(!mobile, 'My Account')}
			</AccountBtnElem>
			<Popover {...bindPopover(popupState)}
					 anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
					 transformOrigin={{vertical: 'top', horizontal: 'left'}}
					 data-testid="account-menu"
			>
				<AccountOptionsElem>
					<UserDetailsElem>
						<label data-testid="user-name">{`${userSession.givenName} ${userSession.familyName}`}</label>
						<span data-testid="job-title">{userSession.preferences?.jobTitle}</span>
					</UserDetailsElem>
					<Divider/>
					<ButtonElem data-testid="your-account-btn" size="small" onClick={onMyAccount}
								disabled={shouldDisableAction} color="secondary">
						{ACCOUNT_LABEL}
					</ButtonElem>
					<ButtonElem data-testid="take-tour-btn" size="small" onClick={onTakeTour}
								disabled={shouldDisableAction} color="secondary">
						Take a tour
					</ButtonElem>
					<ButtonElem data-testid="my-cambridge-btn" size="small" onClick={onRedirect} color="secondary">
						Go to My Cambridge
					</ButtonElem>
					<Divider/>
					<Footer>
						<Button variant="outlined" color="primary" size="small" data-testid="logout-btn"
								onClick={() => onLogout()}>
							Sign out
						</Button>
					</Footer>
				</AccountOptionsElem>
			</Popover>
		</div>
	);
};

export default AccountMenu;