import React from 'react';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosOutlined from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlined from '@mui/icons-material/ArrowForwardIosOutlined';
import {atom, useRecoilState} from 'recoil';
import {device} from '../../../utils/constants';

const CarouselWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
	
	@media ${device.mobile} {
       margin-top: 8px;
    }
`;

const CarouselElem = styled.div`
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    /* use this property to hide the scrollbar on firefox */
    scrollbar-width: none;
	&::-webkit-scrollbar{
        display: none;
    }

	@media ${device.tablet} {
        width: 80vw;
    }
`;

const CarouselItemElem = styled('div')<{ isSelected: boolean }>`
    border-radius: 6px;
    height: 40px;
    width: 75px;
    max-width: 75px;
    background-color: var(--color-border-light);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-blue);
    font-size: 16px;
    font-family: var(--font-regular);
    padding: 8px;
    margin: 0 16px;
    cursor: pointer;
    flex-basis: 20%;
    flex-shrink: 0;
    padding: 1rem;

    ${props => props.isSelected &&
            `background-color: var(--color-blue); 
           color: var(--color-white);
         `
    };
    @media ${device.tablet} {
        margin: 0 10px;
    }
`;

export const selectedYearAtom = atom<string>({
	key: 'selectedYearAtom',
	default: '',
});

export const selectedMonthAtom = atom<string>({
	key: 'selectedMonthAtom',
	default: '',
});

export enum CarouselItemsType {
	Year = 'year',
	Month = 'month'
}

const CarouselItems = ({items, type}: { items: any[], type: CarouselItemsType }) => {
	const [selectedYear, setSelectedYear] = useRecoilState(selectedYearAtom);
	const [selectedMonth, setSelectedMonth] = useRecoilState(selectedMonthAtom);
	const selectPrevious = () => {
		if (type === CarouselItemsType.Year) {
			const selectedYearIdx = items.indexOf(selectedYear);
			if (selectedYearIdx !== 0) {
				setSelectedYear(items[selectedYearIdx - 1]);
			}
			let carousel = document.querySelector('.carousel-year');
			let item = document.querySelector('.item-year');
			carousel!.scrollLeft -= item!.clientWidth;
			return;
		}
		const selectedMonthIdx = items.indexOf(selectedMonth);
		if (selectedMonthIdx !== 0) {
			setSelectedMonth(items[selectedMonthIdx - 1]);
		}
		let carousel = document.querySelector('.carousel-month');
		let item = document.querySelector('.item-month');
		carousel!.scrollLeft -= item!.clientWidth;
	};

	const selectNext = () => {
		if (type === CarouselItemsType.Year) {
			const selectedYearIdx = items.indexOf(selectedYear);
			if (selectedYearIdx !== items.length - 1) {
				setSelectedYear(items[selectedYearIdx + 1]);
			}
			let carousel = document.querySelector('.carousel-year');
			let item = document.querySelector('.item-year');
			carousel!.scrollLeft += item!.clientWidth;
			return;
		}
		const selectedMonthIdx = items.indexOf(selectedMonth);
		if (selectedMonthIdx !== items.length - 1) {
			setSelectedMonth(items[selectedMonthIdx + 1]);
		}
		let carousel = document.querySelector('.carousel-month');
		let item = document.querySelector('.item-month');
		carousel!.scrollLeft += item!.clientWidth;
	};

	const selectItem = (item) => {
		if (type === CarouselItemsType.Year) {
			setSelectedYear(item);
			return;
		}
		setSelectedMonth(item);
	};

	const isElementSelected = (item) => {
		if (type === CarouselItemsType.Year) {
			return item === selectedYear;
		}
		return item === selectedMonth;
	};

	return (
		<CarouselWrapper>
			<IconButton size="small" onClick={selectPrevious} data-testid='previous-btn' id={`left-${type}`}>
				<ArrowBackIosOutlined fontSize="small"/>
			</IconButton>
			<CarouselElem className={`carousel-${type}`}>
				{items.map((item, idx) => (
					<CarouselItemElem onClick={() => selectItem(item)} key={item} isSelected={isElementSelected(item)}
									  data-testid={`${item}-id`} className={`item-${type}`}>
						{item}
					</CarouselItemElem>
				))}
			</CarouselElem>

			<IconButton size="small" onClick={selectNext} data-testid='next-btn' id={`right-${type}`}>
				<ArrowForwardIosOutlined fontSize="small"/>
			</IconButton>
		</CarouselWrapper>
	);
};

export default CarouselItems;