import {Strings} from '../utils/strings';
import {Preferences, UserSession} from './models/Session';
import axios, {AxiosRequestConfig} from 'axios';
import {AUTH_TOKEN, PREVIEW_TOKEN} from '../utils/constants';
import {fi} from '../utils/helpers';
import {InstanceOf} from './models';
import {transaction} from '../state/recoilNexus';
import {references} from '../state/state';
import {Lists} from '../utils/lists';
import {Qualification} from './models/Qualification';
import {Notification} from './models/Notification';
import {Page} from './models/Page';
import {IDistinctResult, IQueryResult, QueryContentRequest, Sort, SubjectUpdateViewState, Types, UUID} from './types';
import {ChildAssessment} from './models/ChildAssessment';
import {Assessment} from './models/Assessment';
import {QualificationSize} from './models/QualificationSize';
import {CTUnit} from './models/CTUnit';
import {Pathway} from './models/Pathway';
import {CMSObject} from './models/__CMSObject';
import {Objects} from '../utils/objects';
import {SubjectUpdate} from './models/SubjectUpdate';
import {Numbers} from '../utils/numbers';
import {Component} from './models/Component';
import {EventsAir} from './models/EventsAir';
import {ZendeskArticle} from './models/ZendeskArticle';

enum Method {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
}

class Client {
	constructor() {
		axios.defaults.baseURL = (process.env.REACT_APP_API_URL || `//api.${document.location.host}`) + `/api/v1.0`;
		axios.defaults.withCredentials = true;
	}

	public getAuthToken(): string {
		return Strings.default(localStorage.getItem(AUTH_TOKEN));
	}

	public setAuthToken(token: string): void {
		localStorage.setItem(AUTH_TOKEN, token);
		axios.defaults.headers.common['Authorization'] = token;
	}

	public deleteAuthToken(): void {
		localStorage.removeItem(AUTH_TOKEN);
		delete (axios.defaults.headers.common['Authorization']);
	}

	public getPreviewToken(): string {
		return Strings.default(sessionStorage.getItem(PREVIEW_TOKEN));
	}

	public setPreviewToken(previewToken: string): void {
		sessionStorage.setItem(PREVIEW_TOKEN, previewToken);
		axios.defaults.headers.common[PREVIEW_TOKEN] = previewToken;
	}

	public async getAccountDetails(): Promise<UserSession> {
		return this.call<UserSession>(Method.GET, '/accountInfo').then(response => new UserSession(response));
	}

	public async login(trialSetup: string = ''): Promise<UserSession> {
		return this.call(Method.GET, `/login${document.location.search}${fi(trialSetup, `&trial=${trialSetup}`, '')}`).then(response => new UserSession(response));
	}


	public async toggleFavorite(uuid: UUID): Promise<void> {
		return this.call(Method.GET, `/toggleFavorite/${uuid}`);
	}

	public async rate(uuid: UUID, item_version: number, rating: number, feedback: string): Promise<any> {
		return this.call<any>(Method.POST, '/rateItem', {item_version, uuid, feedback, rating});
	}

	public downloadURL(uuid: UUID, forceDownload: boolean, downloadToken: UUID = ''): string {
		return `${axios.defaults.baseURL}/${fi(forceDownload, 'download', 'stream')}/${uuid}${fi(downloadToken, `?token=${downloadToken}&ts=${(new Date()).getTime()}`, '')}`;
	}

	public async getSignedURL(uuid: UUID, forceDownload: boolean, downloadToken: UUID = ''): Promise<string> {
		return this.call<string>(Method.GET, `${this.downloadURL(uuid, forceDownload, downloadToken)}`).then((res) => {
			return res;
		});
	}

	public async download(uuid: UUID): Promise<void> {
		window.location.href = await this.getSignedURL(uuid, true);
	}

	public async downloadZip(uuids: UUID[], name: string): Promise<any> {
		return axios.post('/downloadAll', {uuids, name}, {responseType: 'arraybuffer'}).then(response => {
			const blob = new Blob([response.data], {type: response.headers['content-type']});
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = response.headers['content-disposition'].split('=')[1];
			link.click();
		});
	}

	public async getProductTree(): Promise<Qualification[]> {
		return this.call<any>(Method.GET, '/productDataTree').then((res) => {
			res = Objects.default(res)
			const result: Qualification[] = [];
			const childAssessments: ChildAssessment[] = [];
			const components: Component[] = [];
			const ctUnits: CTUnit[] = [];
			const assessments: Assessment[] = [];
			const qualificationSize: QualificationSize[] = [];
			const pathways: Pathway[] = [];
			transaction(({set}) => {
				for (let id in Objects.default(res.references)) {
					const ref = InstanceOf(res.references[id]);
					switch (ref.getType()) {
						case Types.CHILD_ASSESSMENT:
							childAssessments.push(ref);
							break;
						case Types.COMPONENT:
							components.push(ref);
							break;
						case Types.CT_UNIT:
							ctUnits.push(ref);
							break;
						case Types.ASSESSMENT:
							assessments.push(ref);
							break;
						case Types.QUALIFICATION_SIZE:
							qualificationSize.push(ref);
							break;
						case Types.PATHWAY:
							pathways.push(ref);
							break;
					}
					set(references(id), ref);
				}
				assessments.forEach(assessment => {
					assessment.resolveUnits(childAssessments);
					assessment.resolveComponents(components);
					set(references(assessment.getId()), assessment);
				});
				qualificationSize.forEach(qs => {
					qs.resolveUnits(ctUnits, pathways);
					set(references(qs.getId()), qs);
				});

				Lists.default(res.qualifications).forEach((qualification) => {
					const tmp: Qualification = InstanceOf(qualification);
					set(references(tmp.getId()), tmp);
					tmp.subjects.forEach(subject => {
						set(references(subject.getId()), subject);
					});
					result.push(tmp);
				});
			});
			return result;
		});
	}

	public async getPages(): Promise<Page[]> {
		return this.call<IQueryResult<any>>(Method.GET, '/pages').then((res) => {
			return this.toClassResults(res).results;
		});
	}

	public static searchTimer: any;
	public static searchCancelToken: any = null;

	public async search(query: string, contentTypes: string[],
						examYears: number[], series: string[],
						assessments: string[], units: string[],
						components: string[], sort: string, order: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			clearTimeout(Client.searchTimer);
			if (Client.searchCancelToken) {
				Client.searchCancelToken.cancel('test cancellation');
				Client.searchCancelToken = null;
			}
			Client.searchTimer = setTimeout(() => {
				const source = axios.CancelToken.source();
				const cancelToken = source.token;
				Client.searchCancelToken = source;

				this.call(Method.POST, `/search`, {
					query,
					content_type: contentTypes,
					exam_year: examYears,
					series,
					assessments,
					units,
					components,
					sort,
					order
				}, {cancelToken}).then((res) => {
					resolve(res);
				}).catch((e) => {
					reject(e);
				}).finally(() => {
					Client.searchCancelToken = null;
				});
			}, 500);
		});
	}

	public async getDocuments(subjectID: UUID): Promise<CMSObject[]> {
		return this.call<IDistinctResult>(Method.GET, `/documents?subject=${subjectID}`).then((res) => {
			return this.toClassResults(res).results;
		});
	}
	public async getDocumentsByUUIDS(uuids: UUID[]): Promise<CMSObject[]> {
		return this.call<IDistinctResult>(Method.POST, `/documents`,{"uuids":uuids}).then((res) => {
			return this.toClassResults(res).results;
		});
	}

	public async getSubjectInfo(subjectID: UUID): Promise<SubjectUpdate[]> {
		return this.call<IDistinctResult>(Method.GET, `/subjectInfo?subject=${subjectID}`).then((res) => {
			return this.toClassResults(res).results;
		});
	}

	public async getGlobalNotifications(): Promise<Notification[]> {
		return this.call<any>(Method.GET, '/globalNotifications').then((res) => {
			return this.toClassResults(res).results;
		});
	}

	public async getFAQArticles(subjectCodes: string[]): Promise<ZendeskArticle[]> {
		return this.call<any>(Method.GET, `/articles?subjects=${subjectCodes}`)
			.then(res => Lists.default(res).map(r => new ZendeskArticle(r)));
	}

	public async getEvents(codes: string[]): Promise<EventsAir[]> {
		return this.call<any[]>(Method.GET, `/events?codes=${codes}`).then(res => {
			const result: EventsAir[] = [];
			transaction(({set}) => {
				res.forEach((e: any) => {
					const refs = Objects.default(e.references);
					for (let id in refs) {
						const ref = InstanceOf(refs[id]);
						set(references(id), ref);
					}
					const obj = new EventsAir(e);
					result.push(obj);
				});
			});
			return result;
		});
	}

	public async query<T>(request: QueryContentRequest): Promise<IQueryResult<T>> {
		const req = {...request};
		req.start = Numbers.default(req.start);
		req.limit = Numbers.default(req.limit, -1);
		req.sort = Strings.default(req.sort, 'asc') as Sort;
		req.order = Strings.default(req.order);
		let data = await this.call<IQueryResult<T>>(Method.POST, `/query`, req);
		if (data) {
			data = this.toClassResults(data);
		}
		return data;
	}

	public async markAsSeen(uuid: UUID): Promise<void> {
		return this.call<any>(Method.GET, `/markAsSeen/${uuid}`).catch();
	}

	public async markAsVisitated(subject: SubjectUpdateViewState): Promise<void> {
		return this.call<any>(Method.POST, `/subjectupdateview`, subject).catch();
	}


	public async getFeatureFlags(): Promise<string[]> {
		return this.call<any>(Method.GET, '/featureFlags').catch();
	}

	public async logout(): Promise<any> {
		return this.call<any>(Method.GET, '/logout').then(() => {
			localStorage.removeItem('token');
		}).catch();
	}

	public getIconSrc(iconID: UUID, downloadToken): string {
		return `${axios.defaults.baseURL}/iconDownload/${iconID}?token=${downloadToken}`;
	}

	public async saveUserPreferences(data): Promise<UserSession> {
		return this.call<UserSession>(Method.POST, '/userPreferences', data);
	}

	public async switchOrganization(org: string): Promise<UserSession> {
		return this.call<UserSession>(Method.GET, `/switchOrganization/${org}`);
	}

	public async submitTrainingForm(data: any): Promise<any> {
		return this.call<any>(Method.POST, `/submitTrainingForm`, data);
	}

	public async downloadKeyDates(ids: UUID[], downloadToken: UUID, path: string): Promise<any> {
		window.location.href = `${axios.defaults.baseURL}/${path}?ids=${ids.join(',')}${fi(downloadToken, `&token=${downloadToken}&ts=${new Date().getTime()}`, '')}`;
	}

	public async UpdatePreferences(data: Preferences): Promise<any> {
		if (data.__data) {
			delete data.__data;
		}
		return this.call<any>(Method.PUT, '/preferences', data);
	}

	public async addFeedback(data: any[]): Promise<any> {
		return this.call<any>(Method.POST, '/feedback', data);
	}

	public async addTrialFeedback(code: string, data: any[]): Promise<any> {
		return this.call<any>(Method.POST, `/trialFeedback?code=${code}`, data);
	}

	public async checkTrialSurvey(code: string): Promise<any> {
		return this.call<any>(Method.GET, `/checkTrialAccess?registerCode=${code}`);
	}

	public async getItem(uuid: UUID): Promise<CMSObject | null> {
		return this.call<any>(Method.GET, `/sharedItem/${uuid}`).then(res => {
			let item: CMSObject | null = null;
			if (res) {
				transaction(({set}) => {
					item = InstanceOf(res);
					if (!item) return;
					set(references(item.getId()), item);
					if (res.__references) {
						const refs = Objects.default(res.__references);
						for (let id in refs) {
							const ref = InstanceOf(refs[id]);
							set(references(id), ref);
						}
					}
				});
			}
			return item;
		});
	}

	private toClassResults(data: IQueryResult<any> | IDistinctResult): any {
		if (data) {
			data.references = Objects.default(data.references);
			// replace reference objects with classes
			transaction(({set}) => {
				for (let id in data.references) {
					const ref = InstanceOf(data.references[id]);
					data.references[id] = ref;
					set(references(id), ref);
				}
			});
			transaction(({set}) => {
				data.results = Lists.default<any>(data.results).map((item) => {
					const obj = InstanceOf(item);
					set(references(obj.getId()), obj);
					return obj;
				});
			});
		}
		return data;
	}

	private async call<T>(method: Method, endpoint: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
		try {
			// @ts-ignore
			const response = await axios[method](endpoint, data, config);
			return response?.data;
		} catch (e: any) {
			if (e?.response?.data) {
				throw new Error(e.response.data.error);
			} else if (e?.message) {
				throw new Error(e.message);
			}
			throw new Error(e);
		}
	}
}

export default new Client();