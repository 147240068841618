import React, {useState} from 'react';
import {Messages} from '../../utils/messages';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import {COOKIE_NAME, COOKIE_VERSION} from '../../utils/constants';
import {setCookie} from '../../utils/helpers';
import {atom} from 'recoil';
import {setRecoil} from '../../state/recoilNexus';

export const consentCookieAtom = atom<boolean>({
	key: 'consentCookieAtom',
	default: document.cookie.includes(COOKIE_NAME + '=' + COOKIE_VERSION),
});

const cookiesLink = 'https://www.cambridge.org/legal/cookies';

const CookiesElem = styled.div`
    background-color: var(--color-backdrop-darker);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    padding: 16px 24px;

    .text, a {
        color: var(--color-white);
        font-size: 16px;
        font-family: var(--font-regular);
    }

    a {
        margin-right: 16px;
        cursor: pointer
    }
`;

const CookieComponent = () => {
	const isConsentNeeded = () => {
		return !document.cookie.includes(COOKIE_NAME + '=' + COOKIE_VERSION);
	};

	const [showBanner, setShowBanner] = useState<boolean>(isConsentNeeded());
	const acceptCookies = () => {
		setCookie(COOKIE_NAME, COOKIE_VERSION, 90, '/');
		setShowBanner(false);
		setRecoil(consentCookieAtom, true);
	};

	if (!showBanner) return null;

	return (
		<CookiesElem className="cookies-component">
			<div className="text"> {Messages.CookiesConsent}</div>
			<div>
				<a href={cookiesLink} target="_blank" rel="noreferrer"> Learn more </a>
				<Button size="small" variant="contained" color="primary" onClick={acceptCookies}
						data-testid="accept-cookies-btn">
					Accept
				</Button>
			</div>
		</CookiesElem>
	);
};

export default CookieComponent;