import React from "react";
import DateField from "../../../FormComponents/Date/DateField";
import {Strings} from "../../../../utils/strings";
import {fi} from "../../../../utils/helpers";
import FormGroup from "@mui/material/FormGroup";
import styled from "@emotion/styled";
import RadioButtonsGroup from "../../../FormComponents/Radio/RadioButtonsGroup";
import InputField from "../../../FormComponents/Input/InputField";
import {device} from '../../../../utils/constants';

const DeliveryDateElem = styled.div`
    margin-top: 16px;

    .MuiFormGroup-root {
        .MuiFormControl-root {
            width: 25%;

            .MuiTextField-root {
                width: 100%;
            }
        }
    }


    @media ${device.tablet} {
        .MuiFormGroup-root {
            flex-direction: column;

            .MuiFormControl-root {
                width: 100%;
            }
        }
    }
`

const InputsElem = styled.div`
    width: 50%;
    @media ${device.tablet} {
        width: 100%;
    }
`
const deliveryDateOptions = [
    {label: "Online", value: "Online"},
    {label: "Centre", value: "Centre"}
]
const DeliveryDate = ({formik, onChange}: { formik: any, onChange: (...props) => void }) => {

    const onChangeDate = (e, field) => {
        if (e.toString()==="Invalid Date") {
            onChange("", field)
            return
        }
        let formattedDate = `${e.getDate().toString().padStart(2, '0')} ${e.toLocaleString('default', {month: 'long'})} ${e.getFullYear()}`
        onChange(formattedDate, field)
    }

    const onDateChange = (field: string): (evt) => void => {
        return (evt) => {
            onChangeDate(evt, field)
        }
    }

    return (
        <DeliveryDateElem>
            <FormGroup>
                <DateField id="date_first"
                           data-testid="date_first"
                           label="First choice *"
                           value={fi(formik.values.date_first !== "", new Date(formik.values.date_first), null)}
                           onChange={onDateChange("date_first")}
                           touched={Boolean(formik.touched.date_first)}
                           showError={Boolean(formik.touched.date_first && formik.errors.date_first)}
                           errorMessage={Strings.default(formik.errors.date_first)}
                           format={'dd/MM/yyyy'}
                           disabledPast={true}/>
                <DateField id="date_second"
                           data-testid="date_second"
                           label="Second choice"
                           value={fi(formik.values.date_second !== "", new Date(formik.values.date_second), null)}
                           onChange={onDateChange("date_second")}
                           touched={Boolean(formik.touched.date_second)}
                           showError={Boolean(formik.touched.date_second && formik.errors.date_second)}
                           errorMessage={Strings.default(formik.errors.date_second)}
                           format={'dd/MM/yyyy'}
                           disabledPast={true}/>
                <DateField id="date_third"
                           data-testid="date_third"
                           label="Third choice"
                           value={fi(formik.values.date_third !== "", new Date(formik.values.date_third), null)}
                           onChange={onDateChange("date_third")}
                           touched={Boolean(formik.touched.date_third)}
                           showError={Boolean(formik.touched.date_third && formik.errors.date_third)}
                           errorMessage={Strings.default(formik.errors.date_third)}
                           format={'dd/MM/yyyy'}
                           disabledPast={true}/>
            </FormGroup>
            <RadioButtonsGroup field={{options: deliveryDateOptions, name: "venue", label: "Venue for delivery"}}
                               onChange={onChange} value={formik.values.venue} styling="flex-row-start"/>

            <InputsElem>
                <InputField
                    id="start_time"
                    value={formik.values.start_time}
                    onChange={(event) => onChange(event.target.value, 'start_time')}
                    showError={Boolean(formik.touched.start_time) && Boolean(formik.errors.start_time)}
                    errorMessage={Strings.default(formik.errors.start_time)}
                    touched={Strings.default(!!formik.touched.start_time)}
                    field={{name: "start_time", label: "Preferred start time"}}
                />
                <InputField
                    id="delegates_number"
                    value={formik.values.delegates_number}
                    onChange={(event) => onChange(event.target.value, 'delegates_number')}
                    showError={Boolean(formik.touched.delegates_number) && Boolean(formik.errors.delegates_number)}
                    errorMessage={Strings.default(formik.errors.delegates_number)}
                    touched={Strings.default(!!formik.touched.delegates_number)}
                    field={{name: "delegates_number", label: "Estimated number of delegates to attend"}}
                />
            </InputsElem>
        </DeliveryDateElem>

    )
}

export default DeliveryDate