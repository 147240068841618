import {CMSObject, DisplayMode} from "./__CMSObject";
import {UUID} from "../types";
import {Strings} from "../../utils/strings";
import {Lists} from "../../utils/lists";

export class ContentGroup extends CMSObject {
	public name: string;
	public description: string;
	public content_types: UUID[];

	constructor(obj: any = {}) {
		super(obj);
        this.name = Strings.default(obj.name);
        this.description = Strings.default(obj.description);
        this.content_types = Lists.default(obj.content_types);
	}

	public displayLabel(_options: DisplayMode = DisplayMode.SHORT): string {
		return this.name;
	}
}