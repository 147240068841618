import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { SearchFilterAtom, searchHits, showSearchResults } from '../../utils';
import Button from '@mui/material/Button';
import React, { useMemo } from 'react';
import Client from '../../../../../tw/client';
import { SubjectSelector } from '../../../../../state/product';
import styled from '@emotion/styled';
import { device } from '../../../../../utils/constants';

const Wrapper = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    button {
        width: max-content;
        padding: 0;

        :first-of-type {
            margin-right: 8px;
        }
    }
	
	@media ${device.mobile} {
        margin-top: 8px;
		button {
			width: 100%;
		}
	}
`

const ClearFilters = () => {
	const resetFilter = useResetRecoilState(SearchFilterAtom);
	const setSearchHits = useSetRecoilState(searchHits);
	const setShowResults = useSetRecoilState(showSearchResults);
	const subject = useRecoilValue(SubjectSelector);
	const filter = useRecoilValue(SearchFilterAtom);

	const weHaveFilters = useMemo(() => {
		return filter.query.length > 0 || filter.series.length > 0 || filter.years.length > 0 || filter.contentTypes.length > 0;
	}, [filter.query, filter.series, filter.years, filter.contentTypes]);

	const assessments = useMemo(() => {
		if (!subject) return [];
		if (subject.isTechnicals()) {
			return subject.getQualificationSizes();
		}
		return subject.getAssessmentCodes();
	}, [subject]);


	const clearFilters = () => {
		resetFilter();
		Client.search('', [], [], [], assessments, [], [], filter.sort, filter.order).then(r => {
			setSearchHits(r.hits.hits);
			setShowResults(true);
		});
	};

	return (
		<Wrapper className="clear-filter">
			<Button disabled={!weHaveFilters} size="small" onClick={clearFilters}
					data-testid="clear-filters-btn">Clear filters</Button>
		</Wrapper>
	)
}

export default ClearFilters;