import GA from '../../../tw/models/GA';
import {Strings} from '../../../utils/strings';
import {fi} from '../../../utils/helpers';
import {Types} from '../../../tw/types';

export const searchForLink = (elemId, item) => {
	const elem = document.getElementById(elemId);
	if (elem) {
		const isNotificationModel = item.getType() === Types.NOTIFICATIONS
		const links = elem.getElementsByTagName('a');
		for (let i = 0; i < links.length; i++) {
			const href = links[i].getAttribute('href');
			links[i].onclick = function() {
				GA.LinkEvent({
					event_category: fi(isNotificationModel, 'Notification Link',
						fi(links[i].target === '_blank', 'Outbound Link', 'Internal Link')),
					event_label: item.displayLabel(),
					link_name: Strings.default(href),
				});
			};
		}
	}
};