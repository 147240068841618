import CheckBox from '@mui/icons-material/CheckBox';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckboxComp from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import React, {ForwardedRef, useEffect, useMemo, useRef, useState} from 'react';
import Select, {components as simpleSelectComponents} from 'react-select';
import WindowedSelect, {components as windowedComponents} from 'react-windowed-select';
import CheckboxOption from './CheckboxOption';
import {Lists} from '../../../utils/lists';
import {fi} from '../../../utils/helpers';
import {Strings} from '../../../utils/strings';
import {atomFamily, useRecoilValue} from 'recoil';
import {useMediaQuery} from '@mui/material';
import {device} from '../../../utils/constants';
import {Objects} from '../../../utils/objects';
import styled from '@emotion/styled';

export interface ISelectValue {
	value: any;
	label: string;
	object: any;
	options?: ISelectValue[];
}

export interface ISelect {
	label?: string;
	id: string;
	name?: string;
	ariaLabel?: string;
	value: any;
	onChange: (...props: any) => any;
	showError?: boolean;
	errorMessage?: string;
	values: ISelectValue[];
	checkbox?: boolean;
	dataTestId?: string;
	required?: boolean;
	loading?: boolean;
	readonly?: boolean;
	render?: (val: any) => string;
	showSelectAll?: boolean;
	selectAllValue?: boolean;
	onChangeSelectAll?: (selectValue: boolean, id: string) => any;
	placeholder?: string;
	portal?: any;
	isClearable?: boolean;
	styles?: any;
	component?: any;
	hideSelectedOptions?: boolean;
	notNative?: boolean;
}

const CustomList = React.forwardRef((props: any, ref: ForwardedRef<any>) => {
	const hasOptions = Lists.default<ISelectValue>(props.options).find(i => !!i.options);
	if (hasOptions) {
		return <Select ref={ref} {...props} id={`${props.id}-control`}/>;
	}
	return <WindowedSelect ref={ref} {...props} id={`${props.id}-control`}/>;
});

const MultiValueLabel = props => {
	let tmp = JSON.parse(JSON.stringify(props));
	let selectedValues = tmp.selectProps.value;
	let lastValue = selectedValues[selectedValues.length - 1];
	if (selectedValues.length > 1 && tmp.data.label !== lastValue.label) {
		tmp.children = tmp.children + ',';
	}
	const hasOptions = Lists.default<ISelectValue>(props.options).find(i => !!i.options);
	if (hasOptions) {
		return <windowedComponents.MultiValueLabel {...tmp} />;
	}
	return <simpleSelectComponents.MultiValueLabel {...tmp} />;
};

const SelectAllOption = props => {
	let name;
	if (props.name === 'units') name = 'allUnits';
	return (
		<div data-testid={`select-all-${name}`} className="flex-row-center">
			<CheckBox
				aria-label={`select-all-${name}`}
				id={`select-all-${name}`}
				name={`select-all-${name}`}
				checked={props.selectAllValue}
				value={props.selectAllValue}
				onChange={(event) => {
					props.onChangeSelectAll && props.onChangeSelectAll(event.target.checked, name);
				}}
				color={'primary'}
				size="medium"
				disableRipple
				disabled={props.readonly}
				{...props}
			/>
			<FormLabel htmlFor={`select-all-${name}`}>
				Select all
			</FormLabel>
		</div>
	);
};

interface ICustomStyles {
	showError: boolean;
	alignRight: boolean;
	alignTop: boolean;
}

const option = (provided) => {
	return {
		...provided,
		backgroundColor: 'transparent',
		fontSize: 16,
		'&:hover': {
			backgroundColor: 'var(--color-selected)',
		},
		'&.bold-option': {
			padding: 0,
			display: 'flex',
			alignItems: 'flex-start',
			fontFamily: 'var(--font-semi-bold)',
			cursor: 'pointer',
			':active': {
				backgroundColor: 'transparent',
			},
			'& .checkbox-option-text': {
				paddingTop: 9,
			},
		},
	};
};

const customStyles = (props: ICustomStyles) => {
	return {
		menuPortal: base => ({...base, zIndex: 9999, fontFamily: 'var(--font-regular)'}),
		multiValue: (provided, {isDisabled}) => {
			return {
				...provided,
				backgroundColor: 'transparent',
				fontSize: 19,
				fontFamily: 'var(--font-regular)',
				paddingLeft: '0',
				display: 'contents',
				'& .css-12jo7m5': {
					paddingLeft: '0',
				},
				'& div': {
					color: fi(isDisabled, 'var(--color-border)', 'var(--color-black)'),
				},
				'& div:first-of-type': {
					overflow: 'visible',
				},
			};
		},
		multiValueRemove: (provided) => {
			return {...provided, display: 'none'};
		},
		singleValue: (provided) => {
			return {...provided, lineHeight: 'normal'};
		},
		control: (provided) => {
			return {
				...provided,
				borderColor: fi(props.showError, 'var(--color-red)', 'var(--color-border)'),
				boxShadow: 'none',
				fontFamily: 'var(--font-regular)',
				'&:hover': {
					borderColor: 'var(--color-border)',
				},
				backgroundColor: 'white',
			};
		},
		option,
	};
};

// eslint-disable-next-line
const tagStyles = () => {
	return {
		multiValue: (provided, {isDisabled}) => {
			return {
				...provided,
				height: '23px',
				margin: '0 8px 0 0',
				borderRadius: '11.5px',
				border: 'solid 1px var(--color-grey)',
				backgroundColor: 'var(--color-white)',
				'& .css-12jo7m5': {
					padding: '3px 8px',
					fontFamily: 'var(--font-bolder)',
					fontSize: 14,
					fontWeight: 'bold',
					fontStretch: 'normal',
					fontStyle: 'normal',
					lineHeight: '1.21',
					letterSpacing: 'normal',
					textAlign: 'left',
				},
				'& .css-eyboaj': {
					'svg': {
						width: 17,
						height: 17,
					},
				},
				'& div': {
					color: fi(isDisabled, 'var(--color-border)', 'var(--color-black)'),
				},
			};
		},
		multiValueRemove: (provided) => {
			return {
				...provided,
				borderRadius: '0',
				borderTopRightRadius: '11.5px',
				borderBottomRightRadius: '11.5px',
				':hover': {
					backgroundColor: 'var(--color-grey)',
					color: 'var(--color-black)',
				},


			};
		},
		control: (provided) => {
			return {
				...provided,
				borderColor: 'var(--color-border)',
				boxShadow: 'none',
				minHeight: 44,
				'&:hover': {
					borderColor: 'inherit',
				},
				backgroundColor: 'white',
			};
		},
		option,
	};
};

const SelectNative = styled.select`
    min-height: 38px;
    height: 42px;
    border: solid 1px var(--color-border-light);
    border-radius: 4px;
    color: var(--color-black);
    background-color: var(--color-white);
`;

const SelectComponent = (props: ISelect) => {
	const [alignRight, setAlignRight] = useState(false);
	const [alignTop, setAlignTop] = useState(false);
	const mobileOrTablet = useMediaQuery(device.tablet);

	const menuObserver: any = useRef({});
	const selectRef: any = useRef();

	const onMenuOpen = () => {
		const observeOnscreen = (entries: IntersectionObserverEntry[], _observer: IntersectionObserver): void => {
			const {boundingClientRect, intersectionRect} = entries[0];
			const isOffscreenLeft = boundingClientRect.width < intersectionRect.width;
			setAlignRight(!isOffscreenLeft);
			const isOffscreenBottom = boundingClientRect.height < intersectionRect.height;
			setAlignTop(!isOffscreenBottom);
		};

		setTimeout(() => {
			const menuList = selectRef.current.menuListRef;
			menuObserver.current = new IntersectionObserver(observeOnscreen);
			if (!menuList) return;
			menuObserver.current.observe(menuList);
		}, 1);
	};

	const onMenuClose = () => {
		setAlignRight(false);
		if (menuObserver.current?.disconnect) {
			menuObserver.current.disconnect();
		}
	};

	const handleChange = (newValue) => {
		props.onChange(newValue, props.id);
	};

	const handleMobileSelectChange = (e, id) => {
		if (props.checkbox) {
			let selectedOptions = e.target.selectedOptions;
			let newValues: any[] = [];
			for (let i = 0; i < selectedOptions.length; i += 1) {
				if (selectedOptions[i].label !== '' && selectedOptions[i].value !== '') {
					newValues.push({label: selectedOptions[i].label, value: selectedOptions[i].value});
				}
			}
			props.onChange([...newValues], id);
		} else {
			let newValue = props.values.find(v => v.value == e.target.value);
			props.onChange(newValue, id);
		}
	};

	const handleValue = useMemo(() => {
		if (props.checkbox) {
			return props.value;
		}
		// simple selection
		return Lists.default<ISelectValue>(props.values).find((opt) => opt.value === props.value) || null;
		// eslint-disable-next-line
	}, [props.value, props.values]);

	const handleMobileValue = useMemo(() => {
		if (props.checkbox) {
			return Lists.default(props.value).map((i: any) => i.value);
		}
		// simple selection
		return Objects.default(props.values.find((option) => option.value === props.value)).value || '';
	}, [props.value, props.values]);

	return (
		<FormControl variant="standard" fullWidth={true} data-testid="select-component">
			<div className="flex-row-space-between" id={props.dataTestId}>
				{props.label && <FormLabel htmlFor={props.id} required={Boolean(props.required)}>
					{props.label}
				</FormLabel>}
				{fi(props.showSelectAll,
					<div>
						<CheckboxComp
							aria-label={`select-all-${props.id}`}
							id={`select-all-${props.id}`}
							name={`select-all-${props.id}`}
							checked={props.selectAllValue}
							value={props.selectAllValue}
							onChange={(event) => {
								props.onChangeSelectAll && props.onChangeSelectAll(event.target.checked, props.id);
							}}
							color={'primary'}
							size="medium"
							disableRipple
							disabled={props.readonly}
						/>
						<FormLabel htmlFor={`select-all-${props.id}`}>
							Select all
						</FormLabel>
					</div>)}
			</div>
			{fi(mobileOrTablet && !props.notNative,
				<SelectNative
					multiple={!!props.checkbox}
					value={handleMobileValue}
					onChange={e => handleMobileSelectChange(e, props.id)}
					data-testid={'mobile-select-' + props.name}
					disabled={props.readonly || props.selectAllValue}
					id={props.id}
				>
					<option disabled></option>
					{Lists.default<ISelectValue>(props.values).map(option => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</SelectNative>,
				<CustomList
					classNamePrefix={props.id}
					ref={selectRef}
					menuPortalTarget={props.portal}
					styles={{
						...customStyles({
							showError: Boolean(props.showError),
							alignRight,
							alignTop,
						}), ...props.styles,
					}}
					required={Boolean(props.required)}
					IconComponent={ExpandMore}
					aria-label={Strings.default(props.ariaLabel, props.id)}
					components={fi(props.component, {...props.component}, props.checkbox && {
						Option: CheckboxOption,
						MultiValueLabel,
					})}
					title={props.name}
					variant="outlined"
					id={props.id}
					placeholder={props.placeholder ? props.placeholder : ''}
					name={Strings.default(props.name, props.id)}
					value={handleValue}
					onChange={handleChange}
					error={props.showError}
					isLoading={props.loading || false}
					isMulti={!!props.checkbox}
					data-testid={Strings.default(props.dataTestId)}
					options={props.values}
					menuPlacement="auto"
					windowThreshold={20}
					hideSelectedOptions={fi(typeof props.hideSelectedOptions !== 'undefined', props.hideSelectedOptions, !props.checkbox)}
					closeMenuOnSelect={!props.checkbox}
					isClearable={props.isClearable}
					onMenuOpen={onMenuOpen}
					onMenuClose={onMenuClose}
					isDisabled={props.readonly || props.selectAllValue}
					formatOptionLabel={(optionLabel) => {
						if (props.render) {
							return props.render(optionLabel);
						}
						return Strings.default(optionLabel.label);
					}}
				/>)}
			{fi(props.showError, <FormHelperText>{props.errorMessage}</FormHelperText>)}
		</FormControl>
	);
};

export default SelectComponent;
