import React, { useContext, useMemo } from 'react';
import WidgetHeader from '../commons/WidgetHeader';
import { WidgetContext } from '../widget';
import { Lists } from '../../../utils/lists';
import Group from '../../Card/Group';
import { Objects } from '../../../utils/objects';
import { Page } from '../../../tw/models/Page';
import { useMenu } from '../../../state/menu';

const ChildPages = () => {
	const context = useContext(WidgetContext);
	const {state: {selectedPage}} = useMenu();

	const widgetProps = useMemo(() => {
		if (!context) {
			return null;
		}
		return context.widget;
	}, [context]);

	const childPages: Page[] = useMemo(() => {
		if (!selectedPage) {
			return [];
		}
		return Lists.default(selectedPage.children).map((child: any) => Objects.default(child.object) as Page);
	}, [selectedPage]);

	return (
		<div data-testid='child-page-widget'>
			<WidgetHeader>
				<h2>{widgetProps.title}</h2>
				<div>{widgetProps.description}</div>
			</WidgetHeader>
			<Group items={childPages}/>
		</div>
	)
}

export default ChildPages