import React, {useContext, useMemo, useState} from 'react';
import {UUID} from '../../../tw/types';
import {useRecoilValue} from 'recoil';
import {SubjectSelector, UnitSelector} from '../../../state/product';
import {documentSelector} from '../../../state/documents';
import {Lists} from '../../../utils/lists';
import Group from '../../Card/Group';
import {WidgetContext} from '../widget';
import {getRecoil} from '../../../state/recoilNexus';
import {references} from '../../../state/state';
import {ContentType as ContentTypeCls} from '../../../tw/models/ContentType';
import {fi} from '../../../utils/helpers';
import WidgetHeader from '../commons/WidgetHeader';
import {groupByTag, NamedGroup, sortCourseItems} from '../helpers';
import ViewAllBtn from '../commons/ViewAllBtn';
import AccordionComponent from '../commons/AccordionComponent';
import {Unit} from '../../../tw/models/Subject';
import {DisplayMode} from '../../../tw/models/__CMSObject';
import {DocumentsWrapper} from '../../../tw/models/DocumentsWrapper';
import {qualificationSizeAtom} from '../SizePathway/SizeComponent';
import {pathwayAtom} from '../SizePathway/PathwayComponent';
import {UnitTypes} from '../../../tw/models/QualificationSize';
import {selectedMonthAtom, selectedYearAtom} from '../commons/CarouselItems';
import {WidgetType} from '../../../tw/models/Page';
import styled from '@emotion/styled';
import {Objects} from '../../../utils/objects';
import NoContent from '../../commons/NoContent';

interface ContentTypeWidget {
	contentType: UUID;
	byUnits: boolean;
	showDescription: boolean;
	showTitle: boolean;
	showDescending?: boolean;
	sortDescending?: boolean;
	subgroups: Array<string>;
}

const SubgroupWrapperElem = styled.div`
    &.unit-subgroup {
        margin-top: unset
    }

    &.unit-subgroup ~ .unit-subgroup {
        margin-top: 32px;
    }
`;
const ContentType = () => {
	const context = useContext(WidgetContext);
	const widget = context.widget as ContentTypeWidget;

	const subject = useRecoilValue(SubjectSelector);
	const unit = useRecoilValue(UnitSelector);
	const documents = useRecoilValue(documentSelector);
	const contentType = getRecoil(references(widget.contentType)) as ContentTypeCls;
	const qualificationSize = useRecoilValue(qualificationSizeAtom);
	const pathway = useRecoilValue(pathwayAtom);
	const selectedYear = useRecoilValue(selectedYearAtom);
	const selectedMonth = useRecoilValue(selectedMonthAtom);

	const [expanded, setExpanded] = useState(false);

	const filterContentWidget = useMemo(() => {
		return context.filteredWidgets.find(w => w.type === WidgetType.FilterContent);
	}, [context]);

	const allUnits = useMemo(() => {
		return Objects.default(Lists.default(unit)[0]).value === 'all';
	}, [unit]);

	const filteredDocuments = useMemo(() => {
		const unitIds = unit.map(u => u.value);
		let filtered = Lists.sort(documents.byContentType(widget.contentType).byUnitList(unitIds).byQualificationSize(qualificationSize).byPathway(pathway), 'title') as DocumentsWrapper;

		// check if there are online courses and sort them by 'essential' label
		if (filtered.byTag('#online_course').length > 0) {
			filtered = sortCourseItems(filtered) as DocumentsWrapper;
		}

		let tmp: DocumentsWrapper = filtered as DocumentsWrapper;
		if (filterContentWidget) {
			if (filterContentWidget.byYear && filterContentWidget.bySeries) {
				tmp = filtered.byYearFilter(selectedYear).byMonthFilter(selectedMonth);
			} else if (filterContentWidget.byYear && !filterContentWidget.bySeries) {
				tmp = filtered.byYearFilter(selectedYear);
			} else if (!filterContentWidget.byYear && filterContentWidget.bySeries) {
				tmp = filtered.byMonthFilter(selectedMonth);
			}
		}

		tmp.sortByTag('#sort');
		if (widget.showDescending || widget.sortDescending) {
			return tmp.sortByTag('#sort', false, true).sortDescending();
		}
		return tmp;
	}, [documents, unit, context, qualificationSize, pathway, selectedYear, selectedMonth]);

	const noYearFilter = useMemo(() => {
		return !context.page.config.filter(w => w.type === WidgetType.FilterContent).length;
	}, [context]);

	const sections = useMemo(() => {
		let result = groupByTag(contentType, filteredDocuments, Lists.default<string>(widget.subgroups));
		// the widget is grouped by units
		if (widget.byUnits) {
			//get units or components for selected subject and sort them alphabetically by unit/component
			let units: any[];
			if (allUnits) {
				units = Lists.default<Unit>(subject?.userUnits());
			} else {
				units = unit; //selected units
			}

			result.forEach(g => {
				const subgroups: NamedGroup[] = [];
				units.forEach(unitt => {
					let unitId = allUnits ? unitt.getId() : unitt.value;
					const unitItems = g.items.byUnit(unitId);
					if (unitItems.length) {
						let unitObj = allUnits ? unitt : getRecoil(references(unitId));
						if(unitObj){
							subgroups.push({
								items: unitItems,
								title: unitObj.displayLabel(DisplayMode.FULL),
								description: '',
								tag: '',
								order: 0,
							});
						}
					}
				});

				g.subgroups = Lists.sort(subgroups, 'title', Boolean(widget.showDescending));
			});
		}

		// technicals items with SizePathway widget
		if (qualificationSize) {
			const technicals: NamedGroup[] = [];
			let mandatoryItems: DocumentsWrapper = new DocumentsWrapper();
			let optionalItems: DocumentsWrapper = new DocumentsWrapper();
			let unitTypes: UnitTypes;

			// pathway is selected
			if (pathway) {
				unitTypes = qualificationSize.unitsType(pathway);
			} else {
				unitTypes = qualificationSize.unitsType(null);
			}

			mandatoryItems = filteredDocuments.byUnitList(unitTypes.mandatoryUnits);
			optionalItems = filteredDocuments.byUnitList(unitTypes.optionalUnits);

			if (mandatoryItems.length) {
				technicals.push(
					{
						title: 'Mandatory units',
						items: mandatoryItems,
						description: '',
						tag: '',
						order: 0,
					});
			}
			if (optionalItems.length) {
				technicals.push(
					{
						title: 'Optional units',
						items: optionalItems,
						description: '',
						tag: '',
						order: 0,
					});
			}

			result = technicals;
		}

		return result;
	}, [filteredDocuments, contentType]);

	if (!filteredDocuments || !filteredDocuments.length) {
		return <NoContent/>;
	}

	const viewAll = () => {
		setExpanded(!expanded);
	};
	return (
		<>
			{sections.map((group, idx) => (
				<div data-testid={`content-type-widget-${idx}`} key={group.title}>
					{fi(group.items.length, <WidgetHeader>
						{fi(pathway, <h2 className="mb-16" data-testid='pathway-title'>{pathway?.displayLabel()}</h2>)}
						{fi(pathway || qualificationSize,
							<span className="text-semiBold text-18">{group.title}</span>,
							<h2 data-testid="group-title">{group.title}</h2>)}
						{fi(widget.showDescription, <div>{group.description}</div>)}
					</WidgetHeader>)}

					{fi(widget.byUnits && allUnits && noYearFilter,
						<AccordionComponent list={group.subgroups} showViewAllButton={true}/>,
						fi(widget.byUnits && subject?.isModular(),
							(Lists.default<NamedGroup>(group.subgroups).map((sg, i) => (
								<SubgroupWrapperElem className="unit-subgroup" key={sg.title}
													 data-testid={`unit-subgroup-${i}`}>
									<div className="mb-16">{sg.title}</div>
									<Group itemsOriginalLength={sg.items.length}
										   allItems={sg.items}
										   items={fi(expanded, sg.items, sg.items.slice(0, 4))}
										   label={sg.title}/>
									<ViewAllBtn viewAll={viewAll} expanded={expanded}
												nrOfItems={sg.items.length}/>
								</SubgroupWrapperElem>
							))),
							<Group
								itemsOriginalLength={group.items.length}
								allItems={group.items}
								items={fi(expanded, group.items, group.items.slice(0, 4))} label={group.title}/>,
						))
					}
					{fi(!widget.byUnits,
						<ViewAllBtn viewAll={viewAll} expanded={expanded}
									nrOfItems={group.items.length}/>)}
				</div>
			))}
		</>
	);
};

export default ContentType;