import {Browser} from '../../../../utils/browser';
import {ROUTES} from '../../../../utils/routes';
import Button from '@mui/material/Button';
import ArrowBackIosOutlined from '@mui/icons-material/ArrowBackIosOutlined';
import React from 'react';

const FAQDetailsFooter = () => {
	const params = Browser.getParams(ROUTES);

	const goBack = () => {
		Browser.navigate(`/${params['pageId']}/${params['pageSlug']}${document.location.search}`);
	};
	return (
		<Button variant="text" color="primary" startIcon={<ArrowBackIosOutlined/>} size="small"
				onClick={goBack} data-testid='faq-back-btn'>
			Frequently asked questions
		</Button>
	);
};

export default FAQDetailsFooter