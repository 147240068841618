import {resetRecoil} from "../state/recoilNexus";
import {sessionAtom, sessionSelector} from "../state/session";

export const Process = {
	ENV: process.env.NODE_ENV,

	cleanup: () => {
		resetRecoil(sessionSelector);
		resetRecoil(sessionAtom);
	},
};
