import {ExamDateType, ExamTypeFilter, examDateFilter, examDateLoader} from '../../../../state/keyDates';
import {useRecoilState, useRecoilValue} from 'recoil';
import {bindMenu, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks';
import React, {useEffect, useMemo} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import {Strings} from '../../../../utils/strings';
import {fi} from '../../../../utils/helpers';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import { device } from '../../../../utils/constants';
import { useMediaQuery } from '@mui/material';

export type KeyTypeFilterProps = {
	keyFilter: string,
	title: string;
	filter: (keyDateType: ExamDateType) => string;
	sort?: (a: string, b: string) => number;
	set: (filter: ExamTypeFilter, value: string) => ExamTypeFilter;
}

const StyledMenu = styled(Menu)`
    max-height: 228px;
	
	@media ${device.mobile} {
		z-index: 9999;
    }
`

const KeyTypeFilterComponent = (props: KeyTypeFilterProps) => {
	// get key dates and timetables from server
	const keyDates = useRecoilValue(examDateLoader);
	const [filter, setFilter] = useRecoilState(examDateFilter);
	const popupState = usePopupState({variant: 'popover', popupId: props.title});
	const tablet = useMediaQuery(device.tablet);

	const list = useMemo(() => {
		const listt = keyDates.map(props.filter).filter(a => a);
		const res = Array.from(new Set(listt)).sort(props.sort);
		res.unshift('All');
		return res;
	}, [keyDates]);

	useEffect(() => {
		if (popupState.anchorEl) {
			popupState.close();
		}
	}, [filter]);

	const onClick = (_evt, value) => {
		setFilter((filter) => props.set(filter, value));
	};

	return (
		<div className={`filter-group ${tablet ? 'flex-row-start' : ''}`} key={props.title} data-testid='filter-group'>
			<p className={tablet ? 'mr-8' : ''}>{props.title}:</p>
			<Button {...bindTrigger(popupState)} disableRipple className={tablet ? 'pall-0' : ''}>
				{Strings.default(filter[props.keyFilter], 'All')}
				{fi(popupState.isOpen, <ExpandLessIcon fontSize='small' />, <ExpandMoreIcon fontSize='small' />)}
			</Button>
			<StyledMenu {...bindMenu(popupState)}
				  anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
				  transformOrigin={{vertical: 'top', horizontal: 'left'}}
			>
				{list.map((value, idx) => (
					<MenuItem data-testid={`item-${idx}`} key={value} value={value} onClick={(evt) => onClick(evt, value)}>{value}</MenuItem>
				))}
			</StyledMenu>
		</div>
	);
};

export default KeyTypeFilterComponent;
