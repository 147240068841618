import {MediaItemFile} from './__MediaLibrary';
import {UUID} from '../types';
import {Strings} from '../../utils/strings';
import React, {ReactElement} from 'react';
import {CMSFile} from './File';
import {ReactComponent as ImageIconSmall} from '../../assets/images/card/image-small.svg';
import {ReactComponent as ImageIcon} from '../../assets/images/card/image-normal.svg';
import {fi} from '../../utils/helpers';
import Client from '../client';
import {disableButton} from '../../components/Card/CardActions/Buttons/utils';
import {Messages} from '../../utils/messages';

export class Image extends MediaItemFile {
	public static searchable: boolean = true;
	public static markAsSeen = true;
	public alt: string;
	public content_type: UUID;

	constructor(item: any = {}) {
		super(item);
		this.alt = Strings.default(item.alt);
		this.content_type = Strings.default(item.content_type);
	}

	public getIcon(small: boolean = false): ReactElement | null {
		const fileInfo = this.fileInfo();
		if (!fileInfo) {
			return null;
		}
		if (CMSFile.Extensions['Image'].includes(fileInfo.extension)) {
			const defaultAction = this.defaultAction();
			const Icon = fi(small, ImageIconSmall, ImageIcon);
			return <Icon onClick={defaultAction.actionHandler}
						 title={fi(disableButton(this), Messages.FeatureNotAvailable, defaultAction.label)}/>;
		}
		return null;
	}

	public async preview(): Promise<string> {
		let url: string = '';
		url = await Client.getSignedURL(this.getId(), false);
		this.markAsSeen();
		this.addToLocalStorage();
		return url;
	}

	public hasPreview(): boolean {
		return true;
	}

	public downloadable(): boolean {
		return true;
	}
}