import React, {Suspense, useEffect, useMemo, useState} from 'react';
import {useRecoilState, useRecoilValue, useResetRecoilState} from 'recoil';
import {
	groupingAtom,
	GroupingRule,
	SearchCurrentPage,
	SearchFilterAtom,
	SearchResultSelector,
	showSearchResults,
} from './utils';
import Group from '../../Card/Group';
import styled from '@emotion/styled';
import NoContent from '../../commons/NoContent';
import {fi} from '../../../utils/helpers';
import TablePagination from '@mui/material/TablePagination';
import {useHotkeys} from 'react-hotkeys-hook';
import {Messages} from '../../../utils/messages';
import {Objects} from '../../../utils/objects';
import {getRecoil} from '../../../state/recoilNexus';
import {references} from '../../../state/state';
import {Lists} from '../../../utils/lists';
import {DocumentsWrapper} from '../../../tw/models/DocumentsWrapper';
import {LibraryItem} from '../../../tw/models/__MediaLibrary';
import Loader from '../../Loader/Loader';
import {selectedItems} from '../../widgets/helpers';
import {sessionAtom} from '../../../state/session';
import {computeFilesSize} from '../../widgets/commons/utils';
import DownloadResources from './components/DownloadResources';
import { useMediaQuery } from '@mui/material';
import { device } from '../../../utils/constants';

const Wrapper = styled.div`
    padding-top: 24px;

    h3 {
        font-size: 24px;
        font-family: var(--font-semi-bold);
        padding-bottom: 16px;
    }
`;

const CustomTablePagination = styled(TablePagination)`
    width: 100%;

    > div {
        padding: 0 15px 0 0 !important;
    }
	
	@media ${device.mobile} {
		padding: 0 !important;
		
		.MuiTablePagination-toolbar {
			display: flex;
			justify-content: space-between;
            padding: 0 !important;
		
			.MuiTablePagination-displayedRows {
				flex: none;
			}
		}
	}
`;

const Table = styled.table`
    width: 100%;
    margin-top: 8px;

    td.MuiTablePagination-root {
        border-bottom: none;
    }

    .MuiTablePagination-displayedRows {
        flex: 1;
        text-align: right;
    }

    .MuiTablePagination-spacer {
        display: none;
    }
`;

const SearchResults = () => {
	const searchResults = useRecoilValue(SearchResultSelector);
	const showResults = useRecoilValue(showSearchResults);
	const filter = useRecoilValue(SearchFilterAtom);
	const [items, setItems] = useState<DocumentsWrapper | null>(null);
	const [page, setPage] = useRecoilState(SearchCurrentPage);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const grouping = useRecoilValue(groupingAtom);

	const [selectedIds, setSelectedIds] = useRecoilState(selectedItems);
	const session = useRecoilValue(sessionAtom);
	const [checkboxIndeterminateState, setCheckboxIndeterminateState] = useState<boolean>(false);
	const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
	const resetSelected = useResetRecoilState(selectedItems);
	const mobileOrTablet = useMediaQuery(device.mobile);

	const message = useMemo(() => {
		if (!showResults) {
			return Messages.NoSearchResults;
		}
		return '';
	}, [showResults]);

	useEffect(() => {
		if (grouping !== 'none') {
			setRowsPerPage(-1);
			setPage(0);
		} else {
			setRowsPerPage(20);
		}
	}, [grouping]);

	const searchItems = useMemo(() => {
		return searchResults.from(searchResults);
	}, [searchResults]);


	useEffect(() => {
		let limit = page * rowsPerPage + rowsPerPage;
		let slice = searchItems;
		if (limit !== -1) {
			slice = searchItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) as DocumentsWrapper;
		}
		setItems(slice as DocumentsWrapper);
	}, [searchItems, page, rowsPerPage]);

	const handleChangePage = (
		_event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setPage(newPage);
		const slice = searchItems.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage) as DocumentsWrapper;
		setItems(slice);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const newRowsPerPage = parseInt(event.target.value, 10);
		setRowsPerPage(newRowsPerPage);
		setPage(0);
		let slice: any[];
		if (newRowsPerPage === -1) {
			slice = searchItems;
		} else {
			slice = searchItems.slice(0, newRowsPerPage);
		}

		setItems(slice as DocumentsWrapper);
	};

	useHotkeys('ArrowRight,PageDown,ArrowDown', (evt) => {
		evt.preventDefault();
		const newPage = page + 1;
		if (rowsPerPage * newPage < searchItems.length) {
			setPage(newPage);
			const slice = searchItems.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage);
			setItems(slice as DocumentsWrapper);
		}
	});

	useHotkeys('ArrowLeft,PageUp,ArrowUp', (evt) => {
		evt.preventDefault();
		const newPage = page - 1;
		if (page > 0) {
			setPage(newPage);
			const slice = searchItems.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage);
			setItems(slice as DocumentsWrapper);
		}
	});

	useHotkeys('Home', (evt) => {
		evt.preventDefault();
		setPage(0);
		const slice = searchItems.slice(0, rowsPerPage);
		setItems(slice as DocumentsWrapper);
	});

	useHotkeys('End', (evt) => {
		evt.preventDefault();
		const newPage = Math.floor(searchItems.length / rowsPerPage);
		setPage(newPage);
		const slice = searchItems.slice(newPage, newPage * rowsPerPage + rowsPerPage);
		setItems(slice as DocumentsWrapper);
	});

	const groups = useMemo(() => {
		const wrapper: DocumentsWrapper = Lists.default(searchItems) as DocumentsWrapper;
		let result: any[] = [];
		switch (grouping) {
			case GroupingRule.None:
				return [{label: '', items: items}];
			case GroupingRule.ContentType:
				const unique = Array.from(new Set(wrapper.map(item => item['content_type'])));
				unique.forEach((ct) => {
					const contentTypeObject = Objects.default(getRecoil(references(ct)));
					const label = contentTypeObject.displayLabel();
					if (searchItems.length) {
						result.push({
							items: wrapper.byContentType(ct),
							title: fi(label.includes('#'), label.substring(0, label.indexOf('#')), label),
						});
					}
				});
				return Lists.sort(result, 'title');
			case GroupingRule.Series:
				const uniqueSeries = Array.from(new Set(wrapper.map(item => item['series']))).sort((a, b) => a.localeCompare(b));
				const others: any[] = [];
				uniqueSeries.forEach((ct) => {
					let filteredItems = wrapper;
					if (searchItems.length) {
						if (ct !== undefined) {
							result.push({
								items: filteredItems.bySeries(ct),
								title: ct,
							});
						} else {
							others.push({
								items: filteredItems.filter(i => !i['series']),
								title: 'Others',
							});
						}
					}
				});
				if (others.length) {
					result = [...result, ...others];
				}
				return result;
			case GroupingRule.Year:
				const uniqueYears = Array.from(new Set(wrapper.map(item => item['exam_year']))).sort((a, b) => a - b).reverse();
				const otherGroup: any[] = [];
				uniqueYears.forEach((ct) => {
					let filteredItems = wrapper;
					if (searchItems.length) {
						if (ct !== undefined) {
							result.push({
								items: filteredItems.byYears(ct),
								title: ct,
							});
						} else {
							otherGroup.push({
								items: filteredItems.filter(i => !i['exam_year']),
								title: 'Others',
							});
						}
					}
				});
				if (otherGroup.length) {
					result = [...result, ...otherGroup];
				}
				return result;
		}
	}, [grouping, filter.years, filter.contentTypes, filter.years, items]);

	const downloadableItems = useMemo(() => {
		let dwd = searchResults.filter(item => item.downloadable());
		if (session && (session.isTrial() || session.isRestrictedAccess())) {
			// keep only the items that are publicly available
			dwd = dwd.filter(i => (i as LibraryItem).isPubliclyAvailable());
		}
		return dwd;
	}, [searchResults]);
	const handleSelectAll = (event) => {
		setCheckboxValue(event.target.checked);
		if (event.target.checked) {
			setCheckboxIndeterminateState(false);
			const allItems = downloadableItems.map(i => i.getId());
			setSelectedIds(allItems);
		} else {
			setSelectedIds([]);
		}
	};

	useEffect(() => {
		if (selectedIds.length > 0 && (selectedIds.length < downloadableItems.length)) {
			setCheckboxIndeterminateState(true);
			setCheckboxValue(false);
		}
		if (!selectedIds.length) {
			setCheckboxIndeterminateState(false);
			setCheckboxValue(false);
		}
		if (selectedIds.length === downloadableItems.length) {
			setCheckboxIndeterminateState(false);
			setCheckboxValue(true);
		}
	}, [selectedIds, downloadableItems]);

	useEffect(() => {
		reset();
	},[searchResults])
	const reset = () => {
		setCheckboxValue(false);
		resetSelected();
	};
	const fileSize = useMemo(() => {
		return computeFilesSize(selectedIds, downloadableItems);
	}, [selectedIds, downloadableItems]);

	return (
		<Wrapper>
			{fi(searchResults.length > 0,
				<>
					<div className="flex-row-start align-center pb-16">
						<span className="text-24 text-semiBold">Results ({searchResults.length})</span>
						<Suspense
							fallback={<div className="ml-16 flex-row-end"><Loader
								size={16}/> &nbsp; Downloading
								...
							</div>}>
							<DownloadResources selectAll={handleSelectAll} fileSize={fileSize} checkbox={checkboxValue}
											   indeterminate={checkboxIndeterminateState} selected={selectedIds}
											   dwdItems={downloadableItems} reset={reset}/>
						</Suspense>

					</div>
					{fi(grouping === GroupingRule.None,
						<Group items={items} hideActions={true} selectState={true}/>,
						<>
							{groups?.map((g, i) => (
								<div key={`groups-${g.title}`} className="mb-32 mt-8">
									<p className="text-semiBold text-18 mb-8">{g.title}</p>
									<Group items={g.items} hideActions={true} selectState={true}/>
								</div>
							))}

						</>,
					)}
					<Table style={{width: '100%'}}>
						<tbody>
						<tr>
							<CustomTablePagination
								showFirstButton={true}
								showLastButton={true}
								rowsPerPageOptions={!mobileOrTablet ? [20, 40, {label: 'All', value: -1}] : []}
								count={searchResults.length}
								page={page}
								rowsPerPage={rowsPerPage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								onPageChange={handleChangePage}
								SelectProps={{
									disabled: grouping !== 'none',
								}}
								labelRowsPerPage={'Results per page:'}/>
						</tr>
						</tbody>
					</Table>
				</>, <NoContent label={message}/>)}
		</Wrapper>);
};

export default SearchResults;