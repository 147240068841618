import {RegistrationLinks} from '../utils';
import {fi} from '../../../utils/helpers';
import {SIGN_UP_URL} from '../../../utils/constants';
import React from 'react';

const FinalMessage = ({assessor}: { assessor: boolean }) => {
	return (
		<>
			<div className="mb-16 text-16 text-regular">
				By completing your profile you are agreeing to our {' '}
				<a href={RegistrationLinks.termsAndConditions} target="_blank" rel="noreferrer">
					terms and conditions
				</a>.
			</div>
			{/*user is NOT assessor*/}
			{fi(assessor, <div className="mb-16 text-16 text-regular">
				Please <a href={SIGN_UP_URL} target="_blank" rel="noreferrer">sign up</a> if you want to
				receive updates on our latest news, events and resources.
			</div>)}
		</>);
};

export default FinalMessage;