import CookieComponent from '../components/Cookies/CookieComponent';
import HelpGuidePanel from '../components/HelpGuide/HelpGuidePanel';
import HelpGuideContent from '../components/HelpGuide/HelpGuideContent';
import BackToTopButton from '../components/commons/BackToTopButton';
import SnackbarComponent from '../components/Snackbar/SnackbarComponent';
import TrialIntroModal from '../components/ModalDialogs/TrialIntroModal';
import VideoPlayer from '../components/Card/CardActions/VideoPlayer';
import ModalDialog from '../components/ModalDialogs/ModalDialog';
import FeedbackModal from '../components/ModalDialogs/FeedbackModal';
import ChangeOrgModal from '../components/ModalDialogs/ChangeOrgModal';
import React from 'react';

const OverlayComponents = () => {
	return (
		<>
			<CookieComponent/>
			<HelpGuidePanel/>
			<HelpGuideContent/>
			<BackToTopButton/>
			<SnackbarComponent/>
			<TrialIntroModal/>
			<VideoPlayer/>
			<ModalDialog/>
			<FeedbackModal/>
			<ChangeOrgModal/>
		</>
	);
};

export default OverlayComponents;