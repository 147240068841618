import {EventsAir} from '../../../../tw/models/EventsAir';
import {Dates} from '../../../../utils/dates';
import React from 'react';
import styled from '@emotion/styled';

const PastEventDetailsElem = styled.div`
    .duration > span {
        font-family: var(--font-bold);
    }
`
const PastEventDetails = ({event}: { event: EventsAir }) => {
	return (
		<PastEventDetailsElem data-testid='past-event-details'>
			<div className="text-16 text-semiBold">{event.location} - {event.format}</div>
			<div className="text-14 text-regular"> Originally hosted: {' '}
				<span className="text-semiBold">{Dates.format(event.startDate, false)}</span>
			</div>
			<div className="text-14 text-regular duration" dangerouslySetInnerHTML={{__html: event.getEventDuration()}}/>
		</PastEventDetailsElem>
	);
};

export default PastEventDetails;