import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import 'date-fns';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import styled from '@emotion/styled';

const DatePickerElem = styled(DatePicker)`
    max-height: 36px;

    fieldset {
        border: none;
    }

    .MuiOutlinedInput-input {
        box-sizing: border-box;
    }

    .MuiOutlinedInput-root {
        max-height: 40px
    }
`;

const FormControlElem = styled(FormControl)`
    &.error {
        .MuiOutlinedInput-root {
            border: solid 1px var(--color-red) !important;
        }

        .MuiFormHelperText-root {
            margin-left: 0 !important;
            margin-top: 0 !important;
        }
    }
`;

interface IInput {
	id: string;
	label: string;
	value: Date | null;
	onChange: (...params: any) => any;
	showError: boolean;
	touched: boolean;
	errorMessage: string;
	format: string;
	disabledPast: boolean;
	disabled?: boolean;
}


const DateField = (props: IInput) => {
	const onChange = (evt) => {
		props.onChange(evt);
	};

	return (
		<FormControlElem className={`${props.showError ? 'error' : ''}`} data-testid="date-picker">
			<FormLabel htmlFor={props.id}>{props.label}</FormLabel>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePickerElem
						disablePast={props.disabledPast}
						disabled={props.disabled}
						format={props.format}
						value={props.value}
						onChange={onChange}
					/>
			</LocalizationProvider>
			{props.showError && (
				<FormHelperText error={props.touched} data-testid={'form-error-message'}>
					{props.errorMessage}
				</FormHelperText>
			)}
		</FormControlElem>
	);
};

export default DateField;