import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { feedbackAtom, feedbackTrigger } from '../../state/feedback';
import { device, FEEDBACK_COOKIE_NAME, FEEDBACK_COOKIE_VERSION, RESOURCE_FINDER_FLAG } from '../../utils/constants';
import { sessionAtom } from '../../state/session';
import { Objects } from '../../utils/objects';
import { Link, useLocation } from 'react-router-dom';
import { featureFlagsSelector, sharedItem } from '../../state/state';
import { Lists } from '../../utils/lists';
import { useMenu } from '../../state/menu';
import { WidgetType } from '../../tw/models/Page';
import { findTreeNode } from '../TreeMenu/utils';

const BorderElement = styled.div`
    margin: 0 16px;
    @media ${device.mobile} {
        display: none
    }
`;
const LowerSectionContainer = styled.div`
    display: flex;
    @media ${device.mobile} {
        flex-direction: column
    }
`;
const LowerSectionElem = styled.div`
    font-family: var(--font-regular);
    font-size: 16px;
    line-height: 20px;

    .linkElement {
        color: var(--color-blue);
        text-decoration: underline;
        cursor: pointer
    }
`;
const LowerSection = () => {
	const setFeedbackAtom = useSetRecoilState(feedbackAtom);
	const setFeedbackTrigger = useSetRecoilState(feedbackTrigger);
	const user = useRecoilValue(sessionAtom);
	const featureFlags = useRecoilValue(featureFlagsSelector);
	const sharedItemState = useRecoilValue(sharedItem);
	const {state: {filteredTree}} = useMenu();

	const location = useLocation();
	const shouldShowFeedback = () => {
		if (!user) return false;
		const feedbackCookie = document.cookie.includes(FEEDBACK_COOKIE_NAME + '=' + FEEDBACK_COOKIE_VERSION);
		if (feedbackCookie || sharedItemState) {
			return false;
		}
		return Objects.default(user.preferences).loginCount >= 3;
	};
	useEffect(() => {
		setFeedbackAtom(shouldShowFeedback());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sharedItemState]);

	const resourceFinderPath = useMemo(() => {
		let resourceFinder = findTreeNode(filteredTree, (node) => node.object.config.find(c => c.type === WidgetType.ResourceFinder));
		if (resourceFinder) {
			return resourceFinder.object.getURL();
		}
		return '/';
	}, []);

	if (!user) return null;
	const showFeedback = () => {
		setFeedbackAtom(true);
		setFeedbackTrigger('link click');
	};

	return (
		<LowerSectionContainer>
			<LowerSectionElem>
				Got feedback about this site? <span className="linkElement"
													onClick={showFeedback}
													data-testid="feedback-link">Let us know</span>
			</LowerSectionElem>
			{Lists.default<string>(featureFlags).includes(RESOURCE_FINDER_FLAG) && location.pathname !== resourceFinderPath && (user && !user.preview) && (
				<>
					<BorderElement>|</BorderElement>
					<LowerSectionElem>
						Didn’t find what you were looking for? <Link className="linkElement"
																	 data-testid="resource-finder-link"
																	 to={resourceFinderPath}>Use
						the Resource Finder</Link>
					</LowerSectionElem>
				</>
			)}
		</LowerSectionContainer>
	);
};

export default LowerSection;