import {RegistrationMessages} from '../messages';
import {RegistrationLinks} from '../utils';
import {TrialMessages} from '../../Trial/messages';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import React from 'react';
import styled from '@emotion/styled';

const ExamOfficerMsgElem = styled.div`
    p:not(:first-of-type) {
        font-size: 16px;
        line-height: 20px;
    }

    a {
        color: var(--color-blue);
        text-decoration: underline;
    }
`;
const ExamsOfficerMessage = ({goBack}: { goBack: () => void }) => {
	return (
		<ExamOfficerMsgElem>
			<div className="pl-24 pr-24 pb-16">
				<p className="text-24 text-regular mb-16">{RegistrationMessages.TrialExamOfficerHeader}</p>
				<p className="text-16 text-bold">{RegistrationMessages.TrialExamOfficerSubheaderOne}</p>
				<br/>
				<p>{RegistrationMessages.TrialExamOfficerContentOneStart}&nbsp;
					<a href={RegistrationLinks.customerSupportCenter} target="_blank" rel="noreferrer">
						{TrialMessages.SurveyCustomerLink}
					</a>
					&nbsp;{RegistrationMessages.TrialExamOfficerContentOneEnd} </p>
				<br/>
				<p>{RegistrationMessages.TrialExamOfficerContentTwo}</p>
				<br/>
				<br/>
				<p className="text-16 text-bold">{RegistrationMessages.TrialExamOfficerSubheaderTwo}</p>
				<br/>
				<p>{RegistrationMessages.TrialExamOfficerContentThree}</p>
				<br/>
			</div>
			<Divider className="footer-divider"/>
			<div className="mt-8 flex-row-end pr-24">
				<Button color="primary" variant="text" size="small"
						onClick={goBack} data-testid="back-btn">Back</Button>
			</div>
		</ExamOfficerMsgElem>
	);
};

export default ExamsOfficerMessage;