import React from 'react';
import {Strings} from '../../../../utils/strings';
import FormGroup from '@mui/material/FormGroup';
import InputField from '../../../FormComponents/Input/InputField';

const PersonalInfo = ({formik, onChange}: { formik: any, onChange: (...props) => void }) => {
	return (
		<div className="mt-16" data-testid="personalInfo-section">
			<InputField
				id='name'
				value={formik.values.name}
				onChange={(event) => onChange(event.target.value, 'name')}
				showError={Boolean(formik.touched.name) && Boolean(formik.errors.name)}
				errorMessage={Strings.default(formik.errors.name)}
				touched={Strings.default(!!formik.touched.name)}
				disabled={true}
				field={{name: 'name', label: 'Contact name'}}
			/>
			<FormGroup>
				<InputField
					id="phone"
					value={formik.values.phone}
					onChange={(event) => onChange(event.target.value, 'phone')}
					showError={Boolean(formik.touched.phone) && Boolean(formik.errors.phone)}
					errorMessage={Strings.default(formik.errors.phone)}
					touched={Strings.default(!!formik.touched.phone)}
					field={{name: 'phone', label: 'Phone Number'}}
				/>
				<InputField
					id='email'
					value={formik.values.email}
					onChange={(event) => onChange(event.target.value, 'email')}
					showError={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
					errorMessage={Strings.default(formik.errors.email)}
					touched={Strings.default(!!formik.touched.email)}
					disabled={true}
					field={{name: 'email', label: 'Email'}}
				/>
			</FormGroup>
		</div>
	);
};

export default PersonalInfo;