import {CMSObject} from "./__CMSObject";
import {UUID} from "../types";
import {Lists} from "../../utils/lists";
import {Strings} from "../../utils/strings";

export class Pathway extends CMSObject {
	public id: number;
	public is2012: boolean;
	public name: string;
	public qualification_size: UUID[];

	constructor(item: any = {}) {
		super(item);

		this.id = item.id;
		this.is2012 = item.is2012;
		this.name = Strings.default(item.name);
		this.qualification_size = Lists.default(item.qualification_size);
	}
}
