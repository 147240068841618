import {CMSObject, DisplayMode} from "./__CMSObject";
import {UUID} from "../types";
import {Numbers} from "../../utils/numbers";
import {Strings} from "../../utils/strings";
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";

export class Component extends CMSObject {
	public id: number;
	public type: string;
	public name: string;
	public assessment?: UUID;
	public child_assessment?: UUID;

	public title: string;

	constructor(obj: any = {}) {
		super(obj);

		this.id = Numbers.default(obj.id);
		this.type = Strings.default(obj.type);
		this.name = Strings.default(obj.name);
		this.assessment = obj.assessment;
		this.child_assessment = obj.child_assessment;
		this.title = "";
	}

	public get code(): string {
		return this.displayLabel()
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		const id = this.id.toString().padStart(2, '0')
		let short = `Unit ${id}`;
		let parent: string = Strings.default(this.assessment, this.child_assessment);
		if (parent) {
			const assessment = getRecoil(references(parent));
			if (assessment) {
				short = `${assessment.displayLabel()}/${id}`;
			}
		}
		switch (options) {
			case DisplayMode.DETAILED:
			case DisplayMode.FULL:
				return `${short} - ${this.name}`;
			case DisplayMode.SHORT:
			default:
				return short;
		}
	}
}