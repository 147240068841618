import React, {useEffect, useMemo} from 'react';
import Menu from './Menu';
import styled from '@emotion/styled';
import SubjectFilter from '../components/widgets/SubjectFilter/SubjectFilter';
import {device} from '../utils/constants';
import SharedItemModal from '../components/ModalDialogs/SharedItemModal';
import {useMenu} from '../state/menu';
import {useMediaQuery} from '@mui/material';

const PageLayoutElem = styled('div')<{ showMenu: boolean }>`
    display: grid;
    grid-area: main;
    grid-template-areas:
  'subject subject'
  'nav pageContent';
    grid-template-rows: auto 1fr;
    grid-template-columns: 300px 1fr;
    height: 100%;
    overflow: hidden;

    ${props => !props.showMenu && `grid-template-areas: 'subject' 'pageContent'; 
    		grid-template-columns: 1fr;`} ;

    @media ${device.tablet} {
        grid-template-areas:
  				'subject subject' 
				'pageContent pageContent';
        grid-template-columns: 0 1fr;
    }
`;
const PageLayout = ({children}: any) => {
	const {toggleVisible, state: {subTree, secondaryMenuVisible}} = useMenu();
	const mobile = useMediaQuery(device.mobile);

	useEffect(() => {
		toggleVisible(true);
		return () => {
			toggleVisible(false);
		};
	}, []);

	const showMenu = useMemo(() => {
		if (!subTree.length && !mobile) return false;
		return secondaryMenuVisible;
	}, [secondaryMenuVisible, subTree]);

	return (
		<PageLayoutElem data-testid="page-layout" showMenu={showMenu}>
			<SubjectFilter/>
			<Menu/>
			<SharedItemModal/>
			{children}
		</PageLayoutElem>
	);
};

export default PageLayout;