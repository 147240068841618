import {DefaultAction} from '../../tw/types';
import React, {useMemo} from 'react';
import {CMSObject} from '../../tw/models/__CMSObject';
import {fi} from '../../utils/helpers';
import {Description, Icon, Title} from './Card';
import styled from '@emotion/styled';

const ChildPageContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    width: 49%;
    border: 1px solid;
    border-color: var(--color-grey);
    border-radius: 6px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .04);
    background-color: white;

    > div > p {
        margin-top: 8px !important;
    }

    &.fullWidth {
        width: 100%;

        .title {
            padding-top: 0 !important;
        }
    }

    &:hover {
        > div > h5 {
            text-decoration: underline;
        }

        cursor: pointer;
    }

    @media (max-width: 1200px) {
        width: 100%;
    }
`;
const ChildPageCard = ({item, defaultAction}: {item: any, defaultAction: DefaultAction}) => {
	const cmsInstance = useMemo(() => {
		return item instanceof CMSObject;
	}, [item]);

	return (
		<ChildPageContainer onClick={defaultAction.actionHandler} title={defaultAction.label}
							className={`${!cmsInstance ? 'fullWidth' : ''}`}>
			<Icon>{item.getIcon()}</Icon>
			<Title className='title'>
				<h5>{item.title}</h5>
				{fi(cmsInstance, <Description>{item.description}</Description>)}
			</Title>
		</ChildPageContainer>
	);
};

export default ChildPageCard