import React, {useContext, useMemo} from 'react';
import {WidgetContext} from '../widget';
import {useRecoilValue} from 'recoil';
import {SubjectSelector} from '../../../state/product';
import SizeComponent from './SizeComponent';
import {QualificationSize} from '../../../tw/models/QualificationSize';
import {Lists} from '../../../utils/lists';

const SizePathway = () => {
	const context = useContext(WidgetContext);

	const subject = useRecoilValue(SubjectSelector);

	const filteredSizes: QualificationSize[] = useMemo(() => {
		let sizes: QualificationSize[] = [];
		if (!subject) {
			return sizes;
		}
		sizes = subject.getAssessments().filter(s => s instanceof QualificationSize) as QualificationSize[];
		return Lists.sort(sizes, 'glh');
	}, [subject, context]);

	return (
		<SizeComponent sizes={filteredSizes} />
	);
};

export default SizePathway;