import React, {useEffect, useState} from 'react';
import {fi} from '../../utils/helpers';
import Button from '@mui/material/Button';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {Sort} from '../../tw/types';
import {useRecoilValue} from 'recoil';
import {examDateFilter} from '../../state/keyDates';
import styled from '@emotion/styled';
import { device } from '../../utils/constants';

const sortList = [['asc', 'Ascending'], ['desc', 'Descending']];

const Wrapper = styled.div`
	@media ${device.tablet} {
		display: flex;
		flex-direction: row;
		align-items: center;
		
		& > button {
			margin-left: 8px;
		}
	}
`

const Sorter = ({label, callbackFn}: {label: string, callbackFn: (sortBy: Sort) => void}) => {
	const filter = useRecoilValue(examDateFilter);
	const [state, setState] = useState<number>(0);

	useEffect(() => {
		if (filter?.sort) {
			if (sortList[state][0] !== filter.sort) {
				setState(+Boolean(!state));
			}
		}
	}, [filter]);

	const onClick = () => {
		const newState = +Boolean(!state)
		setState(newState);
		callbackFn(sortList[newState][0] as Sort);
	}

	return (
		<Wrapper data-testid='sorter'>
			<p>{label}</p>
			<Button onClick={onClick} data-testid='sorter-btn'>
				{sortList[state][1]}
				{fi(state, <ArrowDownwardIcon fontSize='small' />, <ArrowUpwardIcon fontSize='small' />)}
			</Button>
		</Wrapper>
	)
}

export default Sorter