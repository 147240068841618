import {CMSObject} from "./__CMSObject";
import {Strings} from "../../utils/strings";
import React, {ReactElement} from "react";
import {ReactComponent as LinkIcon} from "../../assets/images/card/link-normal.svg";
import {DefaultAction} from "../types";
import GA from './GA';

export class UsefulLink extends CMSObject {
	public title: string;
	public link: string;
	public display_text: string;
	public description: string;
	constructor(obj: any = {}) {
		super(obj);

		this.title = Strings.default(obj.title, '');
		this.link = Strings.default(obj.link, '');
		this.display_text = Strings.default(obj.display_text, '');
		this.description = Strings.default(obj.description, '');
	}

	public defaultAction(): DefaultAction {
		return {
			label: 'Go to',
			actionHandler: () => {
				GA.LinkEvent({
					event_category: 'Outbound Link',
					event_label: this.link,
					event_description: Strings.default(this.description),
					link_name: this.display_text,
					uuid: this.getId(),
				})
				window.open(this.link, '_blank');
			},
		}
	}

	public getIcon(_small: boolean = false): ReactElement | null {
		const defaultAction = this.defaultAction();
		return <LinkIcon onClick={defaultAction.actionHandler} title={defaultAction.label}/>
	}

	public hideActions() {
		return true;
	}
}