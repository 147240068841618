import React from 'react';
import Button from '@mui/material/Button';
import Launch from '@mui/icons-material/Launch';
import {SIGN_UP_URL} from '../../utils/constants';

const Marketing = () => {
	const signUp = () => {
		window.open(SIGN_UP_URL, '_blank');
	};

	return (
		<div className="pl-24 pr-24" data-testid="marketing-component">
			<div className="text-semiBold text-20 mb-24"> Update your marketing preferences</div>
			<p>Please sign up if you want to receive updates on our latest news, events and resources.
			</p>
			<div className="flex-row-end pt-16">
				<Button size="small" color="primary" variant="outlined" startIcon={<Launch/>} onClick={signUp}
						data-testid="sign-up-btn">
					Sign up
				</Button>
			</div>
		</div>
	);
};

export default Marketing;