import {Messages} from '../../utils/messages';
import React from 'react';
import {SIGN_UP_URL} from '../../utils/constants';
import styled from '@emotion/styled';
import {sessionAtom} from '../../state/session';
import {useRecoilValue} from 'recoil';
import Divider from '@mui/material/Divider';

const SubjectInfoNoteElem = styled.div`
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 20px;
    font-family: var(--font-regular);

    a {
        text-decoration: underline;
        color: var(--color-blue);

        &:hover {
            cursor: pointer
        }
    }
`;
const SubjectInfoNote = () => {
	const user = useRecoilValue(sessionAtom);
	if (!user) {
		return null;
	}
	if (user.isAssessor() || user.isExamsOfficer() || user.isTrial() || user.isRestrictedAccess()) {
		return null;
	}
	return (
		<>
			<SubjectInfoNoteElem data-testid="subject-info-note">
				<p>{Messages.SubjectNotesLineOne}</p>
				<br/>
				<p>Please <a href={SIGN_UP_URL} target="_blank" rel="noreferrer">sign up</a> and we will contact you
					when
					they have arrived.</p>
			</SubjectInfoNoteElem>
			<Divider/>
		</>

	);
};

export default SubjectInfoNote;