import React from 'react';
import {device} from '../../utils/constants';
import styled from '@emotion/styled';

// map cu ocr si CI
const USEFUL_LINKS = [
	{
		label: 'About',
		redirectTo: 'https://www.ocr.org.uk/about/',
	},
	{
		label: 'Contact us',
		redirectTo: 'https://www.ocr.org.uk/contact-us/',
	},
];

const UsefulLinksElem = styled.div`
    @media ${device.mobile} {
        display: flex;
        flex-direction: row;
		a:first-of-type{
			margin-left: 0
		}
    }
`

const UsefulLinks = () => {
	return (
		<UsefulLinksElem data-testid='website-useful-links'>
			{USEFUL_LINKS.map((item, index) => (
				<a href={item.redirectTo} target="_blank" rel="noreferrer" key={item.label}
				   data-testid={`${item.label}-test-id`} className="text-14 text-semiBold ml-24">
					{item.label}
				</a>
			))}
		</UsefulLinksElem>);
};

export default UsefulLinks;