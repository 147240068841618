import PageLayout from '../layout/PageLayout';
import {
    ROUTE_LANDING,
    ROUTE_MY_ACCOUNT,
    ROUTE_NOT_FOUND, ROUTE_PREFERENCES,
    ROUTE_REGISTRATION,
    ROUTE_SERVER_ERROR,
    ROUTE_SIGNUP_TRIAL_ACCESS,
    ROUTE_TRIAL_SURVEY,
    ROUTE_UNAUTHORIZED,
} from './constants';
import AppLayout from "../layout/AppLayout";
import MyAccount from "../pages/MyAccount";
import LandingPage from "../pages/LandingPage";
import ErrorPageComponent from "../pages/ErrorPageComponent";
import PageContent from "../layout/PageContent";
import TrialAccessPage from "../pages/TrialAccessPage";
import Registration from "../pages/Registration";
import IPNoticePage from "../pages/IPNoticePage";
import HomepageDetailsPage from "../components/widgets/Homepage/HomepageDetailsPage";
import EventDetails from "../components/widgets/Events/EventDetails";
import FAQDetails from "../components/widgets/FAQ/FAQDetails";
import SubjectUpdateDetails from "../components/widgets/SubjectUpdates/SubjectUpdateDetails";
import Survey from '../components/Trial/Survey';
import Preferences from '../components/Preferences/Preferences';


export type AppRoute = {
    path: string;
    component: (props?: any) => JSX.Element | null;
    errorPage?: boolean;
    private?: boolean;
    children?: AppRoute[];
    trialAccess?: boolean;
    title?: string;
}

export const ROUTES: AppRoute[] = [
    {
        path: ROUTE_LANDING,
        component: LandingPage,
        private: false,
    },
    {
        path: "/*",
        component: AppLayout,
        private: false,
        children: [
            {
                path: ROUTE_NOT_FOUND,
                component: ErrorPageComponent,
                errorPage: true,
            },
            {
                path: ROUTE_UNAUTHORIZED,
                component: ErrorPageComponent,
                errorPage: true,
            },
            {
                path: ROUTE_SERVER_ERROR,
                component: ErrorPageComponent,
                errorPage: true,
            },
            {
                path: ROUTE_SIGNUP_TRIAL_ACCESS,
                component: TrialAccessPage,
                trialAccess: true,
                private: false,
                errorPage: false,
            },
            {
                path: ROUTE_TRIAL_SURVEY,
                component: Survey,
                private: false,
                trialAccess: true,
            },
            {
                path: ROUTE_PREFERENCES,
                component: Preferences,
                private: true,
            },
            {
                path: ROUTE_REGISTRATION,
                title: "",
                component: Registration,
                private: true,
            },
            {
                path: ROUTE_MY_ACCOUNT,
                component: MyAccount,
                private: true,
            },
            {
                path: '/*',
                component: PageLayout, // route with 2 columns (menu | content)
                private: true,
                children: [
                    {
                        path: '/:pageId/ip-notice',
                        component: IPNoticePage,
                        private: true,
                    },
                    {
                        path: '/:pageTitle',
                        component: PageContent,
                        private: true,
                    },
                    {
                        path: '/:pageId/:pageSlug',
                        component: PageContent,
                        private: true,
                    },
                    {
                        path: '/:pageId/:pageSlug/newAndUpdated',
                        component: HomepageDetailsPage,
                        private: true,
                    },
                    {
                        path: '/:pageId/:pageSlug/favourites',
                        component: HomepageDetailsPage,
                        private: true,
                    },
                    {
                        path: '/:pageId/:pageSlug/keyDocs',
                        component: HomepageDetailsPage,
                        private: true,
                    },
                    {
                        path: '/:pageId/:pageSlug/event-details/:id',
                        component: EventDetails,
                        private: true,
                    },
                    {
                        path: '/:pageId/:pageSlug/article/:id',
                        component: FAQDetails,
                        private: true,
                    },
                    {
                        path: '/:pageId/:pageSlug/info-details/:id',
                        component: SubjectUpdateDetails,
                        private: true,
                    },
                ],
            },
        ]
    }

];
