import React from 'react';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import {device} from '../../../../utils/constants';

const PriceListElem = styled.div`
    background-color: var(--color-white);
    border: solid 1px var(--color-border-light);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;

    hr {
        width: 100%;
    }
`;

const HeaderElem = styled.div`
    padding: 16px;
`;

const FullDayElem = styled.div`
    display: grid;
    grid-template-areas: 'fullDay fullDayPrice';
    padding: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 300px;
    width: 100%;

    .fullDay {
        grid-area: fullDay;
        border-right: solid 1px var(--color-border-light);
    }

    .fullDayPrice {
        padding-left: 16px;
        grid-area: fullDayPrice;
        display: flex;
        align-items: center;
    }
    @media ${device.tablet} {
        grid-template-columns: 0.7fr 0.3fr;
    }
`;
const HalfDayElem = styled.div`
    display: grid;
    grid-template-areas: 'halfDay halfDayPrice';
    padding: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 300px;
    width: 100%;

    .halfDay {
        grid-area: halfDay;
        border-right: solid 1px var(--color-border-light);
    }

    .halfDayPrice {
        padding-left: 16px;
        grid-area: halfDayPrice;
        display: flex;
        align-items: center;
    }
    @media ${device.tablet} {
        grid-template-columns: 0.7fr 0.3fr;
    }
`;
const WebinarElem = styled.div`
    display: grid;
    grid-template-areas: 'webinar webinarPrice';
    padding: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 300px;
    width: 100%;

    .webinar {
        grid-area: webinar;
        border-right: solid 1px var(--color-border-light);
    }

    .webinarPrice {
        padding-left: 16px;
        grid-area: webinarPrice;
        display: flex;
        align-items: center;
    }

    @media ${device.tablet} {
        grid-template-columns: 0.7fr 0.3fr;
    }

`;
const FooterElem = styled.div`
    padding: 16px;
`;

const BoldTextElem = styled.div`
    font-family: var(--font-semi-bold);
`;
const PriceList = () => {
	return (
		<PriceListElem>
			<HeaderElem>
				<BoldTextElem>In-house course prices September 2023 to July 2024</BoldTextElem>
			</HeaderElem>
			<Divider/>
			<FullDayElem>
				<div className="fullDay">
					<BoldTextElem>Full day cost</BoldTextElem>
					<div>Typically 10.00am-4.00pm</div>
				</div>
				<div className="fullDayPrice">£999</div>
			</FullDayElem>
			<Divider/>
			<HalfDayElem>
				<div className="halfDay">
					<BoldTextElem>Half day cost</BoldTextElem>
					<div>Typically</div>
					<div>10.00am-12.30pm</div>
					<div>1.30pm-4.00pm</div>
				</div>
				<div className="halfDayPrice">£699</div>
			</HalfDayElem>
			<Divider/>
			<WebinarElem>
				<div className="webinar">
					<BoldTextElem>Webinar course (1 to 1.5 hours)</BoldTextElem>
					<div>(online only, although we can also offer these courses in school alongside a longer course)
					</div>
				</div>
				<div className="webinarPrice">£300</div>
			</WebinarElem>
			<Divider/>
			<FooterElem>
				<BoldTextElem>Note</BoldTextElem>
				<div>If you would like us to deliver multiple courses on a single day please tell us in the box below
					and we will confirm the price to you.
				</div>

			</FooterElem>
		</PriceListElem>
	);
};

export default PriceList;