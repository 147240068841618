import React, {useContext, useMemo} from 'react';
import {WidgetContext} from '../widget';
import {useRecoilValue} from 'recoil';
import {Lists} from '../../../utils/lists';
import {subjectUpdateSelector} from '../../../state/subjectUpdate';
import {SubjectUpdate} from '../../../tw/models/SubjectUpdate';
import Group from '../../Card/Group';
import {fi} from '../../../utils/helpers';
import {viewAll} from './utils';
import NoContentComponent from './NoContentComponent';
import {HomepageMessages} from './messages';
import WidgetHeader from '../commons/WidgetHeader';
import ViewAllBtn from '../commons/ViewAllBtn';
import {shouldHideButton} from './Homepage';
import styled from '@emotion/styled';

const NoContentElem = styled.div`
    background-color: var(--color-white);
    border: solid 1px var(--color-border-light);
    border-radius: 6px;
`;
const HomepageSubjectUpdates = () => {
	const context = useContext(WidgetContext);
	const subjectsList = useRecoilValue(subjectUpdateSelector);

	const subjectUpdates = useMemo(() => {
		let list = [...Lists.default<SubjectUpdate>(subjectsList)].filter((s) => s.isUpdate());
		return Lists.sort(list, 'date', true);
	}, [subjectsList]);

	return (
		<div data-testid="subject-updates-widget">
			<WidgetHeader>
				<h2>{HomepageMessages.SubjectUpdatesWidgetLabel}</h2>
			</WidgetHeader>
			{fi(subjectUpdates.length, <Group items={subjectUpdates.slice(0, context.widget.numberOfItems)}/>,
				<NoContentElem>
					<NoContentComponent message={HomepageMessages.NoSubjectUpdates} extra={true}/>
				</NoContentElem>,
			)}
			{!shouldHideButton(context.widget) &&
				<div data-testid="subject-updates-view-all" className="flex-row-end mr-16">
					<ViewAllBtn viewAll={() => viewAll(context.widget)} noIcon={true}
								nrOfItems={subjectUpdates.length}/>
				</div>}
		</div>
	);
};

export default HomepageSubjectUpdates;