import {CMSObject} from './__CMSObject';
import {Lists} from '../../utils/lists';
import {Strings} from '../../utils/strings';
import {getRecoil} from '../../state/recoilNexus';
import {references} from '../../state/state';
import {Assessment} from './Assessment';
import {CTUnit} from './CTUnit';
import {ChildAssessment} from './ChildAssessment';
import {QualificationSize} from './QualificationSize';
import {Component} from './Component';
import {sessionAtom} from '../../state/session';
import {Objects} from '../../utils/objects';
import {Numbers} from '../../utils/numbers';
import {fi} from '../../utils/helpers';

export type Unit = ChildAssessment | CTUnit | Component;
export type MappedAssessment = Assessment | QualificationSize;

export class Subject extends CMSObject {
    public title: string;
    public description: string;
    public assessmentIDs: string[];
    public disabled: boolean;
    public qualificationSizeIDs: string[];
    public qualificationMappingID: string;
    public specificationGroup: string[];

    constructor(item: any = {}) {
        super(item);
        this.assessmentIDs = Lists.default(item.assessment);
        this.qualificationSizeIDs = Lists.default(item.qualification_size);
        this.disabled = Boolean(item.disabled);
        this.description = Strings.default(item.description);
        this.title = Strings.default(item.title);
        this.qualificationMappingID = Strings.default(item.qualification_group_mapping);
        this.specificationGroup = Lists.default(item.specification_group);
    }

    public isModular(): boolean {
        for (let i = 0; i < this.assessmentIDs.length; i++) {
            const assessment = getRecoil(references(this.assessmentIDs[i])) as Assessment;
            if (assessment?.modular) {
                return true;
            }
        }
        return false;
    }

    public isTechnicals(): boolean {
        return Lists.default(this.qualificationSizeIDs).length > 0;
    }

    public getUnits(): Unit[] {
        const units: Unit[] = [];
        for (let i = 0; i < this.assessmentIDs.length; i++) {
            const assessment = getRecoil(references(this.assessmentIDs[i])) as Assessment;
            if (assessment && !assessment.modular) {
                units.push(...assessment.components);
            }
            if (assessment) {
                units.push(...assessment.units);
            }
        }
        for (let i = 0; i < this.qualificationSizeIDs.length; i++) {
            const size = getRecoil(references(this.qualificationSizeIDs[i])) as QualificationSize;
            if (size) {
                units.push(...size.units);
            }
        }
        if (this.isTechnicals()){
           return Array.from(new Set(units)).sort((a, b) => {
                const nameA = (a as CTUnit).alias.toLowerCase().replace("unit", "");
                const nameB = (b as CTUnit).alias.toLowerCase().replace("unit", "");

                const nrA = Numbers.default(nameA);
                const nrB = Numbers.default(nameB);
                return fi(nrA < nrB, -1, fi(nrA > nrB, 1, 0));
            })
        }
        return Array.from(new Set(units));
    }

    public getTitle(): string {
        return this.title;
    }

    public isDisabled(): boolean {
        return this.disabled;
    }

    public getQualificationAndSubject(): string {
        let qualification = getRecoil(references(this.qualificationMappingID));
        if (!qualification) return '';
        return `${qualification.displayLabel()}: ${this.displayLabel()}`;
    }

    // Returns only the units the user chose to see from this subject
	public userUnits(): Unit[] {
        const subjectUnits = this.getUnits();
        const session = getRecoil(sessionAtom);
        if (!session) {
            return subjectUnits;
        }
        const userUnitsIds = Lists.default<string>(Objects.default(session.getQualifications().find(q => q.subject === this.getId())).units);
        if (!userUnitsIds.length) {
            return subjectUnits;
        }
        return Lists.default<Unit>(subjectUnits.filter(unit => userUnitsIds.includes(unit.getId())));
    }

    public getAssessmentCodes(): string[] {
        let assessmentCodes: string[] = [];
        this.assessmentIDs.forEach((id) => {
            const a = getRecoil(references(id)) as Assessment
            if (a) assessmentCodes.push(a.code)
        })
        return assessmentCodes
    }

    public getQualificationSizes(): string[] {
        let qualificationSizeCodes: string[] = [];
        this.qualificationSizeIDs.forEach((id) => {
            const qs = getRecoil(references(id)) as QualificationSize
            if (qs) qualificationSizeCodes.push(qs.code)
        })
        return qualificationSizeCodes
    }

    public getAssessments(): MappedAssessment[] {
        const qualifications: MappedAssessment[] = [];
        this.assessmentIDs.forEach((id) => {
            const assessment = getRecoil(references(id)) as Assessment;
            if (assessment) {
                qualifications.push(assessment);
            }
        });
        this.qualificationSizeIDs.forEach((id) => {
            const size = getRecoil(references(id)) as QualificationSize;
            if (size) {
                qualifications.push(size);
            }
        })
        return qualifications
    }
}