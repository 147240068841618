import React, { useEffect, useMemo, useRef, useState } from 'react';
import { findTreeNode, ITreeItem } from '../../TreeMenu/utils';
import { Page } from '../../../tw/models/Page';
import { fi } from '../../../utils/helpers';
import styled from '@emotion/styled';
import { Browser } from '../../../utils/browser';
import { UUID } from '../../../tw/types';
import { device } from '../../../utils/constants';
import { Lists } from '../../../utils/lists';
import { references } from '../../../state/state';
import { getRecoil } from '../../../state/recoilNexus';
import { useMenu } from '../../../state/menu';

type QuickLinksProps = {
	form_1_title: string;
	form_1_pages: any;
	form_2_title: string;
	form_2_pages: any;
}

const QuickLinksWrapperElem = styled('div')<{ hasTwoBoxes: boolean }>`
    display: grid;
    grid-template-columns: 100%;
    gap: 16px;
    grid-auto-rows: 1fr;
    ${props => props.hasTwoBoxes && `grid-template-columns: 49% 49%`};
    @media ${device.tablet} {
        display: flex;
        flex-direction: column;
    }

`;

const QuickLinkElem = styled.div`
    margin-bottom: 16px;
    line-height: 20px;

    span {
        font-size: 16px;
        font-family: var(--font-bolder);
        color: var(--color-blue);
        cursor: pointer;
    }

    div {
        margin-top: 8px;
        font-size: 16px;
        font-family: var(--font-regular);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 100%;
    }
`;

const QuickLinksContentElem = styled('div')<{ title: string }>`
    background-color: var(--color-white);
    border: solid 1px var(--color-border-light);
    border-radius: 6px;
    padding: 16px;
    flex: 1;
    ${props => !props.title && `margin-top: 38px`};
`;

// It has to be a different component because we need the ref to be assigned to each page description
const Description = ({id, label}: { id: UUID, label: string }) => {
	const textElementRef = useRef<HTMLDivElement>(null);
	const [isOverflowed, setIsOverflowed] = useState(false);
	const compareSize = () => {
		if (textElementRef?.current) {
			setIsOverflowed(textElementRef.current.clientHeight < textElementRef.current.scrollHeight);
		}
	};

	useEffect(() => {
		compareSize();
		window.addEventListener('resize', compareSize);
		return () => {
			window.removeEventListener('resize', compareSize);
		};
	}, []);

	useEffect(() => {
		setTimeout(() => compareSize(), 3);
	}, [textElementRef?.current]);

	return (
		<div className="typography-medium-lato-regular dark-grey-color" id={`description-${id}`}
			 title={fi(isOverflowed, label)}
			 ref={textElementRef} data-testid={`${id}-description`}>
			{label}
		</div>
	);
};

const QuickLinksBox = ({pages, title}: { pages: Page[], title: string }) => {
	const onClick = (page) => {
		Browser.navigate(`${page.getURL()}${document.location.search}`);
	};

	if (!pages.length) {
		return null;
	}

	return (
		<div className="flex-column-center" data-testid='quick-link-box'>
			{fi(title, <div className="text-18 text-latoBold mb-16" data-testid='quick-link-box--title'>{title}</div>)}
			<QuickLinksContentElem title={title}>
				{pages.map((page, idx) => (
					<QuickLinkElem key={page.title}>
						<span onClick={() => onClick(page)} data-testid={`${page.title}-title`}>{page.title}</span>
						<Description label={page.description} id={page.getId()}/>
					</QuickLinkElem>
				))}
			</QuickLinksContentElem>
		</div>

	);
};

const getPagesForBox = (widget: QuickLinksProps, _pages: any[], formNumber: number, pageTree: ITreeItem[]) => {
	let widgetNumber = `form_${formNumber}_pages`;
	let pagesList: Page[] = [];
	widget[widgetNumber].forEach(p => {
		let found = getRecoil(references(p.uuid)) as Page;
		if (found) {
			const node = findTreeNode(pageTree, found.getId())
			if (node) {
				pagesList.push(found);
			}
		}
	});
	return Lists.default<Page>(pagesList).slice(0, 4);
};
const QuickLinks = (props: QuickLinksProps) => {
	const {state: {pages, subTree}} = useMenu();

	const firstFormPages = useMemo(() => {
		return getPagesForBox(props, pages, 1, subTree);
	}, [pages, props]);

	const secondFormPages = useMemo(() => {
		return getPagesForBox(props, pages, 2,subTree);
	}, [pages, props]);

	const displaySecondBox = useMemo(() => {
		return props.form_2_pages.length > 0 && secondFormPages.length > 0;
	}, [props, secondFormPages]);

	const hasTwoBoxes = useMemo(() => {
		return displaySecondBox && firstFormPages.length > 0
	},[displaySecondBox])
	return (
		<QuickLinksWrapperElem hasTwoBoxes={hasTwoBoxes}>
			<QuickLinksBox pages={firstFormPages} title={props.form_1_title}/>
			{fi(displaySecondBox, <QuickLinksBox pages={secondFormPages} title={props.form_2_title}/>)}
		</QuickLinksWrapperElem>
	);
};

export default QuickLinks;