import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { SubjectSelector } from '../../state/product';
import { Lists } from '../../utils/lists';
import { Notification, NotificationType } from '../../tw/models/Notification';
import styled from '@emotion/styled';
import PageNotification from './components/PageNotification';
import { useMenu } from '../../state/menu';

const Wrapper = styled.div`
    margin-bottom: 24px;
`;

const PageNotifications = () => {
	const {state: {selectedPage}} = useMenu();
	const selectedSubject = useRecoilValue(SubjectSelector);

	const filteredNotifications: Notification[] = useMemo<Notification[]>(() => {
		if (!selectedPage || !selectedSubject) {
			return [];
		}
		const notifications = Lists.default(selectedPage.object.notificationsList().filter((n: Notification) => n.matchesSubject(selectedSubject.getId())));
		const notificationsMatrix : {[key:string]:Notification[]} = {}
		notifications.forEach((n:any)=>{
			notificationsMatrix[n.type] = notificationsMatrix[n.type]?[...notificationsMatrix[n.type], n]:[n]
		})
		Object.keys(notificationsMatrix).forEach((key:any)=>{
			notificationsMatrix[key].sort((a,b)=> {
				if (a.getPublishedDate() > b.getPublishedDate() ) return 1;
				if (a.getPublishedDate()  < b.getPublishedDate() ) return -1;
				return 0;
			})
		})
		return Lists.default([...Lists.default(notificationsMatrix[NotificationType.CRITICAL]), ...Lists.default(notificationsMatrix[NotificationType.WARNING]), ...Lists.default(notificationsMatrix[NotificationType.INFO])])
	}, [selectedPage, selectedSubject]);
	if (!selectedPage || !selectedSubject || !filteredNotifications.length) return null;

	return (
		<Wrapper data-testid='page-notifications-wrapper'>
			{filteredNotifications.map((n: Notification) => (
				<PageNotification key={n.getId()} notification={n} />
			))}
		</Wrapper>
	);
};

export default PageNotifications;