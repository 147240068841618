import {BaseClass} from './__base';
import {Numbers} from '../../utils/numbers';
import {Strings} from '../../utils/strings';
import {Lists} from '../../utils/lists';
import {DefaultAction, SeenOptions, Types, UUID} from '../types';
import {Objects} from '../../utils/objects';
import {ClassOf, InstanceOf} from './index';
import {immerable} from 'immer';
import React, {ReactElement} from 'react';
import {fi} from '../../utils/helpers';
import Client from '../client';
import {getRecoil, setRecoil} from '../../state/recoilNexus';
import {refreshItem} from '../../state/documents';
import GA from './GA';
import {sessionAtom} from '../../state/session';
import {references} from '../../state/state';
import {ratingFeedbackAtom} from '../../state/modals';
import {disableButton} from "../../components/Card/CardActions/Buttons/utils";

export enum DisplayMode {
	SHORT,
	FULL,
	DETAILED,
}

export type ContentMatcher = {
	matched: string; //first occurrence of query
	matchesNumber: number; //nr of matches inside doc
}

export class CMSObjectMetadata extends BaseClass {
	// Date when the object was created
	created: Date; // Date string
	// Date when the object was published
	published: Date; // Date string
	// Object version
	version: number;
	// Author of the last change on the object
	author: string;
	// Number of times the object was accesses from the website (viewed or downloaded)
	accessed: number;
	// Date when the object was last modified
	modified?: Date; // Date string
	// Internal tags associated with the object used to easily group/search/identify the object
	tags?: string[];


	constructor(data: any = {}) {
		super(data);

		this.created = new Date(data.created);
		this.published = new Date(data.published);
		this.version = Numbers.default(+data.version);
		this.author = Strings.default(data.author);
		this.accessed = Numbers.default(+data.accessed);
		if (data.modified) {
			this.modified = new Date(data.modified);
		}

		this.tags = Lists.default(data.tags);
	}
}

export class CMSObject extends BaseClass {
	[immerable] = true;
	public __type: Types;
	public __uuid: UUID;
	public __rating: number[];
	public __seen?: SeenOptions;
	public __favorite?: boolean;
	public __meta: CMSObjectMetadata;
	public shared: boolean;
	// used for displaying only share item action on cards
	public onlyShareAction: boolean;
	//used for resource finder - content inside document
	public matcher: ContentMatcher | null;

	constructor(item: any = {}) {
		super(item);
		this.__type = Strings.default(item.__type, Types.UNDEFINED) as Types;
		this.__uuid = Strings.default(item.__uuid);
		this.__rating = Lists.default(item.__rating);
		const meta = Objects.default({...item.__meta});
		this.__meta = new CMSObjectMetadata(meta);
		this.__favorite = Boolean(item.__favorite);
		this.shared = Boolean(item.shared);
		this.onlyShareAction = Boolean(item.onlyShareAction);
		if (item.__seen) {
			this.__seen = Strings.default(item.__seen) as SeenOptions;
		}
		if (item.matcher) {
			this.matcher = item.matcher;
		} else {
			this.matcher = null;
		}
	}

	// Returns the class used to instantiate this object from its type
	public classOf(): any {
		return ClassOf(this.__type);
	}

	public getId(): string {
		return this.__uuid;
	}

	public getVersion(): number {
		return this.__meta.version;
	}

	public getType(): Types {
		return this.__type;
	}

	public clone(overrides: any = {}): any {
		return InstanceOf({...JSON.parse(JSON.stringify(this.__data)), ...overrides});
	}

	public getTags(): string[] {
		return Lists.default(this.__meta.tags);
	}

	// Display label is used to represent the object in the UI.
	// This method returns the label for some basic fields but should be overridden
	// by the inheriting classes.
	// Based on where the item is displayed it can have one of three modes.
	//    DisplayMode.SHORT - Just display the object name (eg J800)
	//    DisplayMode.LONG - Add some extra information to the object name (eg J800 - Sports Science)
	//    DisplayMode.FULL - Add all available information to the object name (eg J800 - Sports Science - modular)
	public displayLabel(_options: DisplayMode = DisplayMode.SHORT): string {
		return Strings.default(this.__data['label'],
			Strings.default(this.__data['title'],
				Strings.default(this.__data['name'],
					Strings.default(this.__data['code'],
						''))));
	}

	public getIcon(_small: boolean = false): ReactElement | null {
		return null;
	}

	public getFlag(): ReactElement[] {
		const res: ReactElement[] = [];
		if (this.__seen) {
			res.push(<span key={this.getId()}>{this.__seen}</span>);
		}
		return res;
	}

	public resetFlag() {
		const tmp = this.cloneUpdated();
		tmp.__seen = undefined;
		setRecoil(refreshItem, tmp);
	}

	public isNewOrUpdated(): boolean {
		return !!this.__seen;
	}

	public toggleFavorite() {
		Client.toggleFavorite(this.getId()).then(() => {
			const tmp = this.cloneUpdated();
			tmp.__favorite = !this.__favorite;
			setRecoil(refreshItem, tmp);
		});
	}

	public rate(rating: number, feedback: string = '') {
		Client.rate(this.getId(), this.__meta.version, rating, feedback).then((ratings: {
			[key: string]: number[]
		}) => {
			const tmp = this.cloneUpdated();
			tmp.__rating = ratings[this.getId()];
			setRecoil(refreshItem, tmp);
			setRecoil(ratingFeedbackAtom, false);
		});
	}

	public markAsSeen() {
		const cls = this.classOf();
		if (cls.markAsSeen) {
			Client.markAsSeen(this.getId()).catch();
		}
		const tmp = this.cloneUpdated();
		tmp.__seen = undefined;
		setRecoil(refreshItem, tmp);
	}

	public isSearchable(): boolean {
		const cls = this.classOf();
		return !!cls.searchable;
	}

	public cloneUpdated(): CMSObject {
		const updatedDocs = getRecoil(refreshItem);
		if (Array.isArray(updatedDocs) && updatedDocs.length) {
			for (let i = 0; i < updatedDocs.length; i++) {
				if (updatedDocs[i].getId() === this.getId()) {
					return this.clone(updatedDocs[i]);
				}
			}
		} else {
			if (typeof updatedDocs === 'object' && updatedDocs instanceof CMSObject) {
				return this.clone(updatedDocs);
			}
		}
		return this.clone();
	}

	public getStatus(): string {
		return fi(this.__meta.version > 1, 'Updated', 'Added');
	}

	public getPublishedDate(): string {
		return Strings.default(this.__meta.published);
	}

	public isFavorite(): boolean {
		return Boolean(this.__favorite);
	}

	public isRateable(): boolean {
		return false;
	}

	public isRated(): boolean {
		return this.__rating.length > 0 && this.__rating[0] !== 0;
	}

	public hasRating(): boolean {
		return this.__rating.length > 0 && this.__rating.filter(r => r === 1).length > 0;
	}

	public getRating(): number[] {
		return this.__rating;
	}

	public hasPreview(): boolean {
		return false;
	}

	public isTechnicals(): boolean {
		return false;
	}

	public getExtraLabel(): string | null {
		return null;
	}

	public addToLocalStorage() {
		const key = 'new_updated';
		let items: any[] = [];
		if (localStorage.getItem(key)) {
			items = JSON.parse(localStorage.getItem(key) as string);
		}
		if (!items.filter(d => d.id === this.getId()).length) {
			items.push({id: this.getId(), accessed: new Date()});
		}
		localStorage.setItem(key, JSON.stringify(items));
	}

	public download() {
		Client.download(this.getId()).then(() => {
			this.markAsSeen();
			const params = this.formGAParams();
			GA.ItemActionEvent('Download', params);
			this.addToLocalStorage();
		})
	}

	public hideActions() {
		return this.shared;
	}

	public defaultAction(): DefaultAction {
		return {
			label: 'Download',
			actionHandler: () => {
				if (disableButton(this)) {
					return;
				}
				this.download();
			},
		};
	}

	public async preview(): Promise<string> {
		const session = getRecoil(sessionAtom);
		if (!session) {
			return '';
		}
		let url: string = '';
		url = Client.downloadURL(this.getId(), false, session.downloadToken);
		this.markAsSeen();
		this.addToLocalStorage();
		return url;
	}

	public isKeyDocument(): boolean {
		return this.getTags().includes('#key_document');
	}

	public downloadable(): boolean {
		return false;
	}

	public isPubliclyAvailable(): boolean {
		return true
	}

	public formGAParams(): any {
		let contentTypeName: string = '';
		if (this['content_type']) {
			const contentType = getRecoil(references(this['content_type']));
			if (contentType) {
				contentTypeName = contentType.displayLabel();
			}
		}
		return {
			event_label: this.displayLabel(),
			event_description: Strings.default(this['description']),
			uuid: this.getId(),
			content_type: contentTypeName,
			specificationcode: Strings.default(Lists.default(this['assessmentCodes']).join(', ')),
			unitcomponent: Strings.default(Lists.default(this['unitCodes']).join(', ')),
			examyear: Strings.default(this['exam_year']),
			series: Strings.capitalize(Strings.default(this['series'])),
		};
	}

}