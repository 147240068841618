import {Strings} from '../../utils/strings';
import {ExamDates} from './__ExamDates';
import {getRecoil} from '../../state/recoilNexus';
import {references} from '../../state/state';
import {fi} from '../../utils/helpers';
import {Dates} from '../../utils/dates';
import React, {ReactElement} from 'react';
import {SubjectSelector} from '../../state/product';
import {Types} from '../types';

export class KeyDate extends ExamDates {
	public type: string;
	public qualifications: string;
	public ab: string;
	public subject: string;
	public description: string;

	constructor(data: any = {}) {
		super(data);

		this.description = Strings.default(data.description);
		this.type = Strings.default(data.type);
		this.qualifications = Strings.default(data.qualifications);
		this.ab = Strings.default(data.ab);
		this.subject = Strings.default(data.subject);
	}

	public getType(): Types {
		return Types.KEY_DATE;
	}

	public getQualifications(): string[] {
		return this.qualifications.replace(/\s*,\s*/g, ",").split(',');
	}

	public getSubjects(): string[] {
		if (!this.subject) return [];
		return this.subject.replace(/\s*,\s*/g, ",").split(',');
	}

	public getTitle(): ReactElement {
		return (
			<div>
				{this.type}
			</div>
		);
	}

	public getDate(): ReactElement {
		return fi(this.updated,
			<div className='mb-8'>Updated - {Dates.format(this.updated)}</div>,
			fi(this.created, <div className='mb-8'>Added - {Dates.format(this.created)}</div>, ''));
	}

	public renderQualification(): ReactElement {
		const subject = getRecoil(SubjectSelector);
		if (!subject) {
			return <></>;
		}
		const qualification = getRecoil(references(subject.qualificationMappingID));
		if (!qualification) {
			return <></>;
		}
		return (
			<div>
				{qualification.displayLabel()}
			</div>
		);
	}
}