import {EventsAir} from '../../../../tw/models/EventsAir';
import {fi} from '../../../../utils/helpers';
import React from 'react';
import TrainingMaterialsSection from './TrainingMaterialsSection';
import PastEventDetails from './PastEventDetails';
import ActiveEventDetails from './ActiveEventDetails';
import styled from '@emotion/styled';

const EventInfoElem = styled.div`
    background-color: var(--color-lighter-blue-background);
    border-left: 7px solid var(--color-blue);
    padding: 16px 24px;
    margin-bottom: 40px;
    margin-top: 32px;

    .greenText {
        color: var(--color-green)
    }

    div {
        margin-bottom: 8px;

        &:first-of-type {
            margin-bottom: 24px;
        }
    }

    .greyText {
        color: var(--color-border)
    }
`;

const EventDescriptionElem = styled.div`
    margin-bottom: 40px;

    p {
        margin-bottom: 8px;
        line-height: 20px;
    }
	
	.event-content{
		color: var(--color-grey-text)
	}
`;

const DetailsContent = ({event}: { event: EventsAir }) => {
	return (
		<>
			<EventInfoElem>
				{fi(event.isPastEvent(), <PastEventDetails event={event}/>, <ActiveEventDetails event={event}/>)}
				<div className="text-14 text-regular greyText">Course reference: {event.code}</div>
			</EventInfoElem>
			<EventDescriptionElem>
				<div className="text-20 text-semiBold mb-8">Event description</div>
				<div className="event-content wysiwyig-content" dangerouslySetInnerHTML={{__html: event.overview}}/>
			</EventDescriptionElem>
			{fi(event.isPastEvent(), <TrainingMaterialsSection event={event}/>)}
		</>
	);
};
export default DetailsContent;