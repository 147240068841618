import React, {useContext, useEffect, useMemo, useRef} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import CarouselItems, {CarouselItemsType, selectedMonthAtom, selectedYearAtom} from '../commons/CarouselItems';
import {SubjectSelector, UnitSelector} from '../../../state/product';
import {documentSelector} from '../../../state/documents';
import {InstanceOf} from '../../../tw/models';
import {capitalize, fi} from '../../../utils/helpers';
import styled from '@emotion/styled';
import {WidgetContext} from '../widget';
import {WidgetType} from '../../../tw/models/Page';
import {device} from '../../../utils/constants';

const FilterContentElem = styled.div`
    .label {
        width: 65px;
    }

    .carousel-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .year-carousel {
        margin-bottom: 16px;
    }

    @media ${device.mobile} {
        .carousel-item {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    }
`;
const YearSeriesFilter = () => {
	const [currentYear, setSelectedYear] = useRecoilState(selectedYearAtom);
	const [currentMonth, setSelectedMonth] = useRecoilState(selectedMonthAtom);
	const currentSubject = useRef<string | undefined>('');

	const documents = useRecoilValue(documentSelector);
	const context = useContext(WidgetContext);
	const subject = useRecoilValue(SubjectSelector);
	useRecoilValue(UnitSelector);

	const contentTypeWidget = useMemo(() => {
		return context.filteredWidgets.find(w => w.type === WidgetType.ContentType);
	}, [context]);

	const filteredDocuments = useMemo(() => {
		if (!contentTypeWidget) return [];
		return documents.byContentType(contentTypeWidget.contentType);
	}, [documents, subject, contentTypeWidget]);

	const years = useMemo(() => {
		if (!filteredDocuments) return [];
		let list = filteredDocuments.map(d => InstanceOf(d))
			.filter(d => d.exam_year)
			.map(e => e.exam_year);
		return Array.from(new Set(list)).sort((a,b) => a-b).reverse();
	}, [filteredDocuments]);

	const months = useMemo(() => {
		if (!filteredDocuments) return [];
		let list = filteredDocuments.map(d => InstanceOf(d))
			.filter(d => d.series)
			.map(e => capitalize(e.series.substring(0, 3)));
		return ['All'].concat(Array.from(new Set(list)).sort((a, b) => a.localeCompare(b)));
	}, [filteredDocuments]);

	useEffect(() => {
		if (currentYear === '' || currentMonth === '' || currentSubject.current !== subject?.getId()) {
			const currentYearr = new Date().getFullYear();
			let initialYear = years.find(y => y === currentYearr);
			setSelectedYear(initialYear || years[0]);
			setSelectedMonth('All');
			currentSubject.current = subject?.getId();
		}
	}, [years]);

	const showYear = useMemo(() => {
		return context.widget.byYear && years.length;
	}, [context, years]);

	const showSeries = useMemo(() => {
		return context.widget.bySeries && months.length > 1;
	}, [context, months]);

	if (!showYear && !showSeries) return null;
	return (
		<FilterContentElem data-testid="year-series-widget">
			{fi(showYear, <div className="carousel-item year-carousel" data-testid="year-filter">
				<span className="text-16 text-regular label"> Year: </span>
				<CarouselItems items={years} type={CarouselItemsType.Year}/>
			</div>)}
			{fi(showSeries, <div className="carousel-item" data-testid="series-filter">
				<span className="text-16 text-regular label"> Series: </span>
				<CarouselItems items={months} type={CarouselItemsType.Month}/>
			</div>)}
		</FilterContentElem>
	);
};

export default YearSeriesFilter;