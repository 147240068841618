import React, { useMemo } from 'react';
import ReactPlayer from 'react-player/lazy';
import Modal from '@mui/material/Modal';
import styled from '@emotion/styled';
import { ReactComponent as ClosePlayerIcon } from '../../../assets/images/card/actions/close-player.svg';
import { atom, useRecoilState } from 'recoil';
import { Types } from '../../../tw/types';

export const playVideoAtom = atom<any>(
	{
		key: 'playVideoAtom',
		default: null,
	},
);

const formatURL = (url: string): string => {
	try {
		const newURL = new URL(url);
		if (newURL.href.includes('youtube.com/watch?')) {
			return `https://www.youtube.com/watch?v=${newURL.searchParams.get('v')}`;
		}
	} catch (e) {
	}
	return url;
};

const CustomModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 7001;
`;

const CustomModalBody = styled.div`
    width: 835px;
    height: 469.7px;

    > div {
        margin-bottom: 8px;
        display: flex;
        justify-content: flex-end;

        svg {
            cursor: pointer;
        }
    }
`;
const VideoPlayer = () => {
	const [videoPlayer, setShowPlayer] = useRecoilState(playVideoAtom);

	const closed = useMemo<boolean>(() => {
		return !videoPlayer;
	}, [videoPlayer]);

	if (!videoPlayer) return null;

	if (videoPlayer.getType() !== Types.VIDEO) {
		return null;
	}

	return (
		<CustomModal data-testid="video-modal" open={!closed} onClose={() => setShowPlayer(null)} closeAfterTransition>
			<CustomModalBody>
				<div>
					<ClosePlayerIcon onClick={() => setShowPlayer(null)}/>
				</div>
				<ReactPlayer
					data-testid="video-player"
					url={formatURL(videoPlayer.link)}
					playing
					controls
					width="100%"
					height="100%"
					light={false}
					config={{
						youtube: {
							playerVars: {
								modestbranding: 1,
								rel: 0,
								fs: 0,
							},
						},
					}}
				></ReactPlayer>
			</CustomModalBody>
		</CustomModal>
	);
};

export default VideoPlayer;