import React, {useMemo} from 'react';
import {ReactComponent as BellIcon} from '../../assets/images/bell.svg';
import {ReactComponent as RedBullet} from '../../assets/images/red-bullet.svg';
import styled from '@emotion/styled';
import {bindPopover, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';

import {selector, useRecoilValue} from 'recoil';
import {sessionAtom} from '../../state/session';
import Client from '../../tw/client';
import {fi} from '../../utils/helpers';
import {Notification} from '../../tw/models/Notification';
import {cacheBuster} from '../../state/state';
import {setRecoil} from '../../state/recoilNexus';
import GlobalNotification from './GlobalNotification';
import {Lists} from '../../utils/lists';

export const globalNotificationsSelector = selector<Notification[] | null>({
	key: 'globalNotificationsSelector',
	get: async ({get}) => {
		let globalNotifications: Notification[] | null = [];
		let user = get(sessionAtom);
		if (!user || !user.getQualifications().length) return globalNotifications;

		get(cacheBuster('globalNotifications'));

		try {
			globalNotifications = await Client.getGlobalNotifications();
			if (!user.hasFullAccess()) {
				return globalNotifications.filter(n => n.availableForRestricted);
			}
		} catch (error) {
			console.log(error);
		}

		return globalNotifications;
	},
});

const NotificationPaperElem = styled(Paper)`
    top: 36px;
    width: 650px;
    height: auto;

    button {
        padding: 0;

        &.Mui-disabled {
            color: var(--color-blue);
            opacity: 0.4
        }

        &:hover {
            background-color: var(--color-white);
        }
    }
`;

const NotificationsContentElem = styled.div`
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
`;

const BellElem = styled('div')<{ animate: boolean }>`
    cursor: pointer;
    display: flex;

    svg:last-of-type {
        margin-left: -6px
    }

    ${props => props.animate && `animation: ring 1.5s ease infinite`}
`;
let seenTimer: any;

const Notifications = () => {
	const notifications = useRecoilValue(globalNotificationsSelector);
	const popupState = usePopupState({variant: 'popover', popupId: 'account-dropdown'});
	const userSession = useRecoilValue(sessionAtom);

	const notificationsList = useMemo(() => {
		return Lists.default<Notification>(notifications);
	}, [notifications]);

	if (!userSession) return null;

	const markAsRead = (evt, ids: string[] = []) => {
		if (evt) {
			evt.preventDefault();
			evt.stopPropagation();
		}
		let notificationIds: string[] = [];
		if (ids.length) {
			notificationIds = ids;
		} else {
			notificationIds = notificationsList.map(n => n.getId());
		}

		Promise.all(notificationIds.map(id => Client.markAsSeen(id))).finally(() => {
			setRecoil(cacheBuster('globalNotifications'), val => val + 1);
		});
	};

	const disableMarkAll = () => {
		return notificationsList.filter(n => n.wasSeen()).length === notificationsList.length;
	};

	const stopEvent = (event) => {
		event.preventDefault();
		event.stopPropagation();
		event.stopImmediatePropagation && event.stopImmediatePropagation();
	};

	const handleBellClick = (event) => {
		stopEvent(event);
		onEventScroll(null);
	};

	const onEventScroll = (e) => {
		if (e === null) {
			const tmp = document.getElementById('notifications-area');
			if (!tmp) {
				setTimeout(() => onEventScroll(null), 100);
				return;
			}
			e = {target: tmp};
		}

		const list = e.target;
		const listBox = list.getBoundingClientRect();
		const items = list.querySelectorAll('.notification-item') || [];
		const seenItems: string[] = [];
		items.forEach(item => {
			const itemBox = item.getBoundingClientRect();
			const itemTop = itemBox.y - listBox.y;
			if (itemTop < 0 || itemTop + itemBox.height - 16 > listBox.height) {
				return;
			}
			if (item.classList.contains('unread')) {
				seenItems.push(item.dataset['notificationid']);
			}
		});

		clearTimeout(seenTimer);
		if (seenItems.length) {
			seenTimer = setTimeout(() => {
				if (document.getElementById('notifications-popover')) {
					// notifications popover is still displayed
					markAsRead(null, seenItems);
				}

			}, 3000);
		}
	};

	const newNotifications = () => {
		let newNot = false;
		notificationsList.forEach(n => {
			if (!n.wasSeen()) newNot = true;
		});

		return newNot;
	};
	const notificationsHeader = 'Notifications (' + notificationsList.length + ')';
	return (
		<div>
			<BellElem animate={newNotifications()} data-testid='notifications-bell' onClick={e => handleBellClick(e)}>
				<BellIcon data-testid='bell-icon' {...bindTrigger(popupState)}/>
				{fi(newNotifications(), <RedBullet/>)}
			</BellElem>
			<Popover {...bindPopover(popupState)}
					 anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
					 transformOrigin={{vertical: 'top', horizontal: 'right'}}
					 data-testid="notifications-popover"
					 id="notifications-popover"
					 className="mt-16"
					 // open={true}
			>
				<NotificationPaperElem className="notifications-popover">
					<div className="flex-row-space-between pall-16">
						<p className="text-16 text-bold">{notificationsHeader}</p>
						{fi(notificationsList.length, <Button variant="text" color="primary" disableRipple
															  onClick={markAsRead}
															  data-testid='mark-all-read-btn'
															  disabled={disableMarkAll()}>
							Mark all as read
						</Button>)}
					</div>
					<Divider/>
					<NotificationsContentElem data-testid='notifications-area' onScroll={(e) => onEventScroll(e)} id="notifications-area">
						{fi(notificationsList.length,
							notificationsList.map((not, index) => (
								<GlobalNotification notification={not} index={index} key={not.title}/>
							)),
							<div className="pall-16"> There are no notifications for you.</div>)}
					</NotificationsContentElem>
				</NotificationPaperElem>
			</Popover>
		</div>
	);
};

export default Notifications;