import {SubjectUpdate} from '../../../../tw/models/SubjectUpdate';
import Button from '@mui/material/Button';
import ArrowBackIosOutlined from '@mui/icons-material/ArrowBackIosOutlined';
import {fi} from '../../../../utils/helpers';
import React from 'react';

const DetailsFooter = ({subjectUpdate, goBack}: { subjectUpdate: SubjectUpdate, goBack: () => void }) => {
	const performAction = () => {
		window.open(subjectUpdate.link, '_blank');
	};

	return (
		<>
			<Button variant="text" color="primary" startIcon={<ArrowBackIosOutlined/>} size="small"
					data-testid="back-btn"
					onClick={goBack}>
				Back
			</Button>
			{
				fi(subjectUpdate.label, <Button variant="contained" color="primary" size="small"
												data-testid="perform-action-btn"
												onClick={performAction}>
					{subjectUpdate.label}
				</Button>)
			}
		</>
	);
};

export default DetailsFooter;