import React from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import {ratingFeedbackAtom} from '../../../state/modals';
import {setRecoil} from '../../../state/recoilNexus';

const Container = styled.div`
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: white;
    width: 100% !important;
    height: 54px;
    padding: 16px;

    button {
        padding-left: 8px;

        &:hover {
            background-color: transparent;
        }
    }
`;

const Clear = styled(ClearIcon)`
    height: 14px !important;
    width: 14px !important;
    fill: var(--color-border) !important;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
`;

const Feedback = ({onClose}: {onClose: (message: string) => void}) => {
	const triggerFeedbackModal = () => {
		setRecoil(ratingFeedbackAtom, true);
		onClose('');
	};
	return (
		<Container data-testid='rating-feedback' className='rating-feedback'>
			<Title>
				<span>How can we improve this resource?</span>
				<Button data-testid='submit-feedback-btn' color='primary' size='small' disableRipple onClick={triggerFeedbackModal}>Send feedback</Button>
			</Title>
			<div>
				<Clear data-testid='clear-icon' onClick={() => onClose('')} />
			</div>
		</Container>
	);
};

export default Feedback;