import styled from '@emotion/styled';
import React from 'react';

const QuestionElem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: var(--font-semi-bold);
    margin-bottom: 16px;
`;
const SurveyQuestion = ({question}: { question: string }) => {
	return (
		<QuestionElem data-testid='survey-question'>
			{question}
		</QuestionElem>
	);
};

export default SurveyQuestion;