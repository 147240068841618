import type {UUID} from '../types';
import type {CMSObject} from './__CMSObject';
import type {Pathway} from './Pathway';
import type {QualificationSize} from './QualificationSize';
import {Lists} from '../../utils/lists';
import {Strings} from '../../utils/strings';
import {capitalize, fi} from '../../utils/helpers';
import {Numbers} from '../../utils/numbers';
import {Objects} from '../../utils/objects';

export class DocumentsWrapper extends Array<CMSObject> {
	constructor() {
		super();
	}

	from(array): DocumentsWrapper {
		const tmp = new DocumentsWrapper();
		for (let i = 0; i < array.length; i++) {
			tmp.push(array[i]);
		}
		return tmp;
	}

	filter(predicate: (value: CMSObject, index: number, array: DocumentsWrapper) => unknown, thisArg?: any): DocumentsWrapper {
		const tmp = new DocumentsWrapper();
		for (let i = 0; i < this.length; i++) {
			if (predicate.call(thisArg, this[i], i, this)) {
				tmp.push(this[i]);
			}
		}
		return tmp;
	}

	// Returns the list of content type uuids of given document list
	contentTypeIds(): UUID[] {
		const tmp: UUID[] = [];
		this.forEach(doc => {
			if (doc['content_type']) {
				tmp.push(doc['content_type']);
			}
		});
		// list of distinct content type ids
		return Array.from(new Set(tmp.filter(id => id)));
	}

	// Returns the list of uuids for the given document list
	ids(): UUID[] {
		return this.map(doc => doc.getId()).filter(id => id);
	}

	topicIds(): UUID[] {
		const tmp: UUID[] = [];
		this.forEach(doc => {
			if (doc['topic']) {
				tmp.push(doc['topic']);
			}
		});
		return Array.from(new Set(tmp.filter(id => id)));
	}

	// Returns the list of assessment uuids for the given document list
	assessmentIds(): UUID[] {
		const tmp: UUID[] = [];
		this.forEach(doc => {
			if (doc['assessment']) {
				tmp.push(doc['assessment']);
			}
		});
		return Array.from(new Set(tmp.filter(id => id)));
	}

	// Returns the filtered document list uuids by the given list items
	byIds(uuids: UUID[]): DocumentsWrapper {
		const tmp = new DocumentsWrapper();
		this.forEach(doc => {
			if ((uuids || []).includes(doc.getId())) {
				tmp.push(doc);
			}
		});
		return tmp;
	}

	// Returns the filtered document list uuids by the given content type ids
	byContentType(...contentTypeId: UUID[]): DocumentsWrapper {
		const tmp = new DocumentsWrapper();
		this.forEach(doc => {
			if ((contentTypeId || []).includes(doc['content_type'])) {
				tmp.push(doc);
			}
		});
		return tmp;
	}

	// Returns the filtered document list uuids by the given unit id
	byUnit(unit: UUID | 'all' = 'all'): DocumentsWrapper {
		if (unit === 'all') {
			return this;
		}
		return this.filter(doc => typeof doc['matchesUnit'] === 'function' && doc['matchesUnit']([unit]));
	}

	byQualificationSize(size: QualificationSize | null): DocumentsWrapper {
		if (!size) {
			return this;
		}
		const sizeId = size.getId();
		return this.filter(doc => doc['qualification_size'].includes(sizeId));
	}

	byPathway(pathway: Pathway | null): DocumentsWrapper {
		if (!pathway) {
			return this;
		}
		const tmp = new DocumentsWrapper();
		this.forEach(doc => {
			if (doc['qualification_size'].filter(sizeId => pathway.qualification_size.includes(sizeId)).length > 0) {
				tmp.push(doc);
			}
		});
		return tmp;
	}


	byTopic(topicId: UUID): DocumentsWrapper {
		const tmp = new DocumentsWrapper();
		this.forEach(doc => {
			if (typeof doc['getTopic'] === 'function' && doc['getTopic']() === topicId) {
				tmp.push(doc);
			}
		});
		return tmp;
	}

	byUnitList(unitIds: UUID[]): DocumentsWrapper {
		let tmp = new DocumentsWrapper();
		unitIds.forEach(unitId => {
			const filtered = this.byUnit(unitId);
			if (filtered.length > 0) {
				filtered.forEach(f => {
					if (!tmp.find(d => d.getId() === f.getId())){
						tmp.push(f);
					}
				});
			}
		});

		return Lists.sort(tmp, 'title') as DocumentsWrapper;
	}

	byYears(...years: string[]): DocumentsWrapper {
		const tmp = new DocumentsWrapper();
		const stringYears = Lists.default<any>(years).map(y => y?.toString());
		this.forEach(doc => {
			if ((stringYears || []).includes(Strings.default(doc['exam_year']))) {
				tmp.push(doc);
			}
		});
		return tmp;
	}

	bySeries(...series: string[]): DocumentsWrapper {
		const tmp = new DocumentsWrapper();
		this.forEach(doc => {
			if ((series || []).includes(doc['series'])) {
				tmp.push(doc);
			}
		});
		return tmp;
	}

	byYearFilter(year: string): DocumentsWrapper {
		if (!year) return this;
		const tmp = new DocumentsWrapper();
		this.forEach(doc => {
			if ((year && doc['exam_year'] && year.toString() === Strings.default(doc['exam_year'])) || !doc['exam_year']) {
				tmp.push(doc);
			}
		});
		return tmp;
	}

	byMonthFilter(series: string): DocumentsWrapper {
		const tmp = new DocumentsWrapper();
		this.forEach(doc => {
			if (series === capitalize(Strings.default(doc['series']).substring(0, 3)) || series === 'All' || !doc['series']) {
				tmp.push(doc);
			}
		});
		return tmp;
	}

	byTag(tag: string): DocumentsWrapper {
		const tmp = new DocumentsWrapper();
		this.forEach(doc => {
			if (doc['__meta'] && doc['__meta']['tags']) {
				const tagValue = Strings.default(doc['__meta']['tags'].find(t => t.toLowerCase().includes(tag))).toLowerCase().replace(tag, '').trim();
				if (!tagValue) {
					return;
				}
				tmp.push(doc);
			}
		});
		return tmp;
	}

	sortByTag(tag: string, asValues: boolean = false, desc: boolean = false): DocumentsWrapper {
		const tmp = this;
		if (this.byTag(tag).length === 0) {
			return tmp;
		}
		tmp.sort((a: any, b: any) => {
			let tagA: string;
			let tagB: string;
			if (asValues) {
				tagA = a;
				tagB = b;
			} else {
				tagA = Strings.default(Lists.default<string>(Objects.default(a['__meta'])['tags']).find(t => t.toLowerCase().includes(tag))).toLowerCase().replace(tag, '').trim();
				tagB = Strings.default(Lists.default<string>(Objects.default(b['__meta'])['tags']).find(t => t.toLowerCase().includes(tag))).toLowerCase().replace(tag, '').trim();
			}
			const floatValA = parseFloat(tagA);
			const floatValB = parseFloat(tagB);
			if (asValues && isNaN(floatValA) && isNaN(floatValB)) {
				return fi(a < b, -1, fi(a > b, 1, 0));
			}
			if (!isNaN(floatValA) && isNaN(floatValB)) {
				return -1;
			}
			if (isNaN(floatValA) && !isNaN(floatValB)) {
				return 1;
			}
			if (!isNaN(floatValA) && !isNaN(floatValB)) {
				if (floatValA < floatValB) {
					return -1;
				} else if (floatValA > floatValB) {
					return 1;
				} else {	// compare the strings from the sort tag
					const substrA = tagA.substr(Numbers.default(Objects.default(floatValA.toString().match(/[a-z ]/i)).index, floatValA.toString().length)).toLowerCase().trim();
					const substrB = tagB.substr(Numbers.default(Objects.default(floatValB.toString().match(/[a-z ]/i)).index, floatValB.toString().length)).toLowerCase().trim();
					if (substrA < substrB) {
						return -1;
					} else if (substrA > substrB) {
						return 1;
					}
				}
			}
			return 0;
		});
		if (desc) {
			tmp.reverse();
		}
		return tmp;
	}

	sortDescending(): DocumentsWrapper {
		const tmp = this;
		tmp.sort((a, b) => {
			// ascending by unit
			const regex = /\((.*)\)/;
			let unitA = a['title'].match(regex);
			let unitB = b['title'].match(regex);
			if (unitA !== null && unitA[1] && unitB !== null && unitB[1]) {
				if (unitA[1].toString().toLowerCase() < unitB[1].toString().toLowerCase()) {
					return -1;
				} else if (unitA[1].toString().toLowerCase() > unitB[1].toString().toLowerCase()) {
					return 1;
				}
			}
			// descending by year
			if (a['exam_year'] && b['exam_year']) {
				if (a['exam_year'] < b['exam_year']) {
					return 1;
				} else if (a['exam_year'] > b['exam_year']) {
					return -1;
				}
			}
			// ascending by month/series
			if (a['series'] < b['series']) {
				return -1;
			} else if (a['series'] > b['series']) {
				return 1;
			}
			// descending by title
			if (a['title'].toString().toLowerCase() < b['title'].toString().toLowerCase()) {
				return 1;
			} else if (a['title'].toString().toLowerCase() > b['title'].toString().toLowerCase()) {
				return -1;
			}
			return 0;
		});
		return tmp;

	}
}