import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';
import {atom, useRecoilValue, useSetRecoilState} from 'recoil';
import {ITour} from './types';
import {fi} from '../../utils/helpers';
import {Strings} from '../../utils/strings';
import {device} from '../../utils/constants';
import {useMediaQuery} from '@mui/material';

interface WrapperProps {
	left: number;
	top: number;
	width: number;
	height: number;
}

const WrapperElem = styled.div<WrapperProps>`
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: ${props => `${props.left}px ${props.width}px auto`};
    grid-template-rows: ${props => `${props.top}px ${props.height}px auto`};
    grid-template-areas: 'top top top'
	'left center right'
	'bottom bottom bottom';
    pointer-events: none
`;
const AreaElem = styled.div`
    background-color: rgba(0, 0, 0, 0.2);
`;
const TopElem = styled(AreaElem)`
    grid-area: top;
`;
const LeftElem = styled(AreaElem)`
    grid-area: left;
`;
const RightElem = styled(AreaElem)`
    grid-area: right;
`;
const BottomElem = styled(AreaElem)`
    grid-area: bottom;
`;
const CenterElem = styled.div`
    grid-area: center;
`;
export const backdropElement = atom<ITour | null>({
	key: 'backdropElementAtom',
	default: null,
});

export const backdropSet = atom<string>({
	key: 'backdropSetAtom',
	default: '',
});

function GetScreenCordinates(obj) {
	let p: any = {};
	p.x = obj.offsetLeft;
	p.y = obj.offsetTop;
	while (obj.offsetParent) {
		p.x = p.x + obj.offsetParent.offsetLeft;
		p.y = p.y + obj.offsetParent.offsetTop;
		if (obj == document.getElementsByTagName('body')[0]) {
			break;
		} else {
			obj = obj.offsetParent;
		}
	}
	return p;
}

let retryTimer;
const Backdrop = () => {
	const backdropTarget = useRecoilValue(backdropElement);
	const setBackdrop = useSetRecoilState(backdropSet);
	const [scrollTop, setScrollTop] = useState(0);
	const [boundingBox, setBoundingBox] = useState<WrapperProps | null>(null);
	const isMobile = useMediaQuery(device.mobile)
	const isTablet = useMediaQuery(device.tablet)
	const getBoundingBox = () => {
		if (!backdropTarget || !Object.keys(backdropTarget).length) {
			setBoundingBox(null);
			return;
		}
		let windowY = window.scrollY;
		let backdropHeight = 0;
		let top = 0;
		let [width, height, x, y] = [0, 0, -1, -1];
		let targets: string[] = backdropTarget.targetElement as string[];
		if (typeof backdropTarget.targetElement === 'string') {
			targets = [backdropTarget.targetElement];
		}
		targets.forEach((t, idx) => {
			const element = document.querySelector(Strings.default(backdropTarget.backdropElement, t));
			if (!element) {
				return;
			}

			let box = element.getBoundingClientRect();
			width += box.width;
			height += box.height;
			let position = GetScreenCordinates(element); //real position
			if (idx === 0) {
				x = position.x;
				y = position.y;
			} else {
				x = Math.min(x, position.x);
				y = Math.min(y, position.y);
			}
		});

		if (x === -1 || y === -1) {
			setBackdrop('');
			retryTimer = setTimeout(getBoundingBox, 100);
			setBoundingBox(null);
			return;
		}
		if (backdropTarget.padding) {
			const pad = backdropTarget.padding;
			x -= pad;
			y -= pad;
			width += pad * 2;
			height += pad * 2;
		}
		setBackdrop(backdropTarget?.id);
		//minimum top value
		let minTop = 137;
		const calculatePosition = () => {
			let topValue = y; //adjusted y
			minTop = 137;

			if (['subject_filter_new', 'select_subjects'].includes(backdropTarget.id)) {
				topValue -= fi(backdropTarget.id === 'select_subjects', 0, Math.min(windowY, 44));
				minTop = 0;
			} else {
				topValue -= windowY;
			}
			if (backdropTarget.id === 'main_navigation' || ((isMobile || isTablet )&& backdropTarget.id === 'left_hand_menu')) {
				minTop = 0;
			}
			backdropHeight = Math.max(0, height - fi(topValue < minTop, minTop - topValue, 0));
			top = Math.max(minTop, topValue);
		};
		calculatePosition();
		if (top + backdropHeight > window.innerHeight) { //if element is outside the screen (bottom) (scroll up)
			let target = (y + height) - window.innerHeight; //scroll to bottom of the element
			if (backdropHeight > window.innerHeight - minTop) {// if element height is bigger than height of the screen
				// scroll to top of element
				target = y - window.innerHeight;
				if (backdropTarget.id === 'manage_subjects' && isMobile) {
					target += 500;
				}
			}

			setTimeout(() => {
				window.scrollTo({
					top: target,
				})
			}, 10);
			calculatePosition();
		} else if (backdropHeight < height) { //element tries to shrink to go under fixed elements on page (scroll down)
			window.scrollTo({
				top: windowY - (height - backdropHeight),
			});
		}

		setBoundingBox({
			width: width,
			height: backdropHeight,
			left: x,
			top: top,
		});
	};

	useEffect(() => {
		getBoundingBox();
	}, [backdropTarget, scrollTop]);

	useEffect(() => {
		const scrollFct = (_e) => {
			setScrollTop(window.scrollY);
		};
		window.addEventListener('scroll', scrollFct);
		return () => {
			window.removeEventListener('scroll', scrollFct);
		};
	}, []);

	if (!boundingBox) return null;

	return (
		<WrapperElem {...boundingBox} data-testid="backdrop-wrapper">
			<TopElem/>
			<LeftElem/>
			<CenterElem id="tour-center"/>
			<RightElem/>
			<BottomElem/>
		</WrapperElem>
	);
};

export default Backdrop;