import {Notification} from '../../../tw/models/Notification';
import {useRecoilValue} from 'recoil';
import {sessionAtom} from '../../../state/session';
import Wysiwyg from '../../widgets/wysiwyg/Wysiwyg';
import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
    border: 1px solid black;
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    padding: 16px;
    margin-bottom: 16px;

    & > div:nth-of-type(2) {
        padding-left: 16px;
    }

    &.notification-critical {
        background-color: var(--color-critical-background);
        border-color: var(--color-red);
    }

    &.notification-warning {
        background-color: var(--color-lighter-orange);
        border-color: var(--color-orange);
    }

    &.notification-info {
        background-color: var(--color-lighter-blue-background);
        border-color: var(--color-blue);
    }
`;

const PageNotification = ({notification}: { notification: Notification }) => {
	const session = useRecoilValue(sessionAtom);

	if (!session) {
		return null;
	}

	// hide notifications that are marked as not visible for restricted access (trial or restricted role)
	if (!session.hasFullAccess() && !notification.availableForRestricted) {
		return null;
	}

	return (
		<Container data-testid="page-notification-container" className={notification.getClass()}>
			<div>{notification.getIcon()}</div>
			<Wysiwyg item={notification} fieldId="description"
					 id={`page-notification--description-${notification.getId()}`}/>
		</Container>
	);
};

export default PageNotification;