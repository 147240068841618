import {CMSObject} from './__CMSObject';
import {Strings} from '../../utils/strings';
import {Lists} from '../../utils/lists';
import {DefaultAction, UUID} from '../types';
import {Numbers} from '../../utils/numbers';
import Client from '../client';
import React, {ReactElement} from 'react';
import {sessionAtom} from '../../state/session';
import {getRecoil} from '../../state/recoilNexus';
import defaultPageIcon from '../../assets/images/defaultPageIcon.svg';
import {Subject} from './Subject';
import {fi} from '../../utils/helpers';
import {ReactComponent as ChildPageIcon} from '../../assets/images/card/child-page-normal.svg';
import {Browser} from '../../utils/browser';
import {DocumentsWrapper} from './DocumentsWrapper';
import {ROUTE_RESOURCE_FINDER} from '../../utils/constants';
import {SearchOutlined} from '@mui/icons-material';
import {references} from '../../state/state';
import {ContentGroup} from './ContentGroup';
import {SubjectSelector} from '../../state/product';
import {Notification} from './Notification';
import GA from './GA';
import {SubjectUpdate} from './SubjectUpdate';
import {Objects} from '../../utils/objects';
import {ReactComponent as DefaultPageIcon} from '../../assets/images/defaultPageIcon.svg';

export enum WidgetType {
	ContentGroup = 'content_group',
	ContentType = 'content_type',
	ContentItems = 'content_items',
	ChildPages = 'child_pages',
	FAQ = 'faq',
	Events = 'events',
	FilterByQualification = 'qualifications',
	FilterContent = 'years',
	EventFilter = 'month_and_year',
	FreeText = 'text',
	UsefulLinks = 'links',
	HomepageResources = 'home_page_resources',
	Homepage = 'home_page',
	SubjectUpdate = 'subject_update',
	GlobalAnnouncements = 'global_announcements',
	KeyDates = 'key_dates',
	SizePathway = 'size_pathway',
	Form = 'form',
	TrainingForm = 'training',
	QuickLinks = 'quick_links',
	ResourceFinder = 'resource_finder',
	MySubject = 'my_subjects'
}

// Widgets that are rendered as cards and are filtered by subject
const cardWidgets = [
	WidgetType.ContentType,
	WidgetType.ContentGroup,
	WidgetType.ContentItems,
	WidgetType.Events,
	WidgetType.SubjectUpdate,
	WidgetType.GlobalAnnouncements,
	WidgetType.FreeText,
];

// Widgets that are not rendered as cards and aren't filtered by subject
const displayableWidgets = [
	WidgetType.FAQ,
	WidgetType.Form,
	WidgetType.KeyDates,
	WidgetType.UsefulLinks,
	WidgetType.QuickLinks,
	WidgetType.ResourceFinder,
	WidgetType.MySubject,
];

export class Widgets extends Array {
	constructor() {
		super();
	}

	// Returns a list of widgets that are available based on filtered documents.
	filterAvailable(documents: DocumentsWrapper, subjectUpdates?: SubjectUpdate[]): Widgets {
		const result = new Widgets();

		// list of document ids
		const documentIds = documents.ids();
		// list of distinct content type ids
		const contentTypeIds = documents.contentTypeIds();
		// list of topic ids
		const topicIds = documents.topicIds();
		// list of assessment ids
		const assessmentIds = documents.assessmentIds();

		this.forEach(widget => {
			switch (widget.type) {
				case WidgetType.ContentGroup:
					let contentTypesList = widget.contentTypes;
					// search for content group uuid in references atom and get content types list
					// in case that a new content type is added to content group
					const contentGroup = getRecoil(references(widget.contentGroup)) as ContentGroup;
					if (contentGroup) {
						contentTypesList = contentGroup.content_types;
					}
					// one of the content types in this content group belongs to one of the documents
					if (contentTypesList.find(contentTypeId => contentTypeIds.includes(contentTypeId))) {
						result.push(widget);
					}
					break;
				case WidgetType.ContentType:
					// at least one document has this content type
					if (contentTypeIds.includes(widget.contentType)) {
						result.push(widget);
					}
					break;
				case WidgetType.ContentItems:
					// at least one document from this widget lists is available
					if (widget.items.find(itemId => documentIds.includes(itemId))) {
						result.push(widget);
					}
					break;
				// case WidgetType.Events:
				// 	// @TODO: find how to filter this
				// case WidgetType.FilterContent:
				// 	break;
				// case WidgetType.EventFilter:
				// 	break;
				case WidgetType.FreeText:
					if (topicIds.includes(widget.topic)) {
						result.push(widget);
					}
					break;
				// case WidgetType.UsefulLinks:
				// 	break;
				// case WidgetType.HomepageResources:
				// 	break;
				// case WidgetType.Homepage:
				// 	break;
				case WidgetType.SubjectUpdate:
					const selectedSubject = getRecoil(SubjectSelector);
					let updates = Lists.default<SubjectUpdate>(subjectUpdates).filter(u => u.isUpdate());
					if (selectedSubject && updates && updates.length) {
						if (updates.filter(su => su.assessment.filter(id => assessmentIds.includes(id))).length) {
							result.push(widget);
						}
					}
					break;
				case WidgetType.GlobalAnnouncements:
					const selectedSubject2 = getRecoil(SubjectSelector);
					let announcements = Lists.default<SubjectUpdate>(subjectUpdates).filter(a => a.isAnnouncement());
					if (selectedSubject2 && announcements && announcements.length) {
						if (announcements.filter(su => su.assessment.filter(id => assessmentIds.includes(id))).length) {
							result.push(widget);
						}
					}
					break;

				// case WidgetType.KeyDates:
				// 	break;
				// case WidgetType.SizePathway:
				// 	break;
				// case WidgetType.Form:
				// 	break;
				// case WidgetType.QuickLinks:
				//     break;
				default:
					// All other widgets are not dependent on the available documents and they should just show
					//  WidgetType.FilterByQualification
					result.push(widget);
			}
		});

		return result;
	}

	cardWidgets(): Widgets {
		const result = new Widgets();
		this.forEach(widget => {
			if (cardWidgets.includes(widget.type)) {
				result.push(widget);
			}
		});
		return result;
	}
}

const tourTags: string[] = ['#user_tour', '#user_tour_1', '#user_tour_2'];

export class Page extends CMSObject {
	public static ResourceFinderPageId = 'finder';

	// Title of the page
	public title: string;
	// Description of the page
	public description: string;
	// Whether the page is the home page or not
	public isHomePage: boolean;
	// Whether the page is the subject home page or not
	public isLandingPage: boolean;
	// Array with configs related to the widgets configured on the page
	public config: Widgets;
	// The icon of the page
	public icon?: UUID;
	// Parent id of the current tree node.
	public parent: UUID | undefined | null;
	public notifications?: Notification[];
	// Order of the current tree node.
	public order: number;
	public qualification?: UUID[];
	public subject?: UUID[];
	public link: string;
	public tags: string[];

	constructor(page: any = {}) {
		super(page);
		this.title = Strings.default(page.title);
		this.description = Strings.default(page.description);
		this.isHomePage = Boolean(page.is_home_page);
		this.isLandingPage = Boolean(page.is_landing_page);
		this.config = Widgets.from(Lists.default<any>(page.config)) as Widgets;

		this.config.forEach(c => {
			c._id = crypto.randomUUID();
		});

		this.parent = page.parent;
		this.order = Numbers.default(page.order);

		if (page.icon) {
			this.icon = Strings.default(page.icon);
		}
		if (page.qualification_group_mapping) {
			this.qualification = Lists.default(page.qualification_group_mapping);
		}
		if (page.qualification_mapping) {
			this.subject = Lists.default(page.qualification_mapping);
		}
		if (page.__notifications) {
			const tmp: Notification[] = [];
			page.__notifications.forEach(notification => {
				tmp.push(new Notification(notification));
			});
			this.notifications = tmp;
		}
		this.link = Strings.default(page.link);
		this.tags = Lists.default(Objects.default(page.__meta).tags);
	}

	public landingPage(): boolean {
		return this.isLandingPage;
	}

	public homePage(): boolean {
		return this.isHomePage;
	}

	public userTourPage(): boolean {
		return this.landingPage() && this.tags.filter(tag => tourTags.includes(tag)).length > 0;
	}

	public isLinkPage(): boolean {
		return this.link !== '';
	}

	public getURL(): string {
		if (this.getId() === Page.ResourceFinderPageId) {
			return ROUTE_RESOURCE_FINDER;
		}
		return '/' + this.getId() + '/' + this.title.replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, ' ').toLowerCase() // replace all special characters | symbols with a space
			.trim().replace(/\s+/g, '-');
	}

	public getPageIcon(): any {
		const session = getRecoil(sessionAtom);
		if (!session) {
			return <DefaultPageIcon/>;
		}
		if (Page.ResourceFinderPageId === this.getId()) {
			return <SearchOutlined fontSize={'small'}/>;
		}

		let imgSrc: string = '';
		if (this.icon) {
			imgSrc = Client.getIconSrc(this.icon, session.downloadToken);
		}
		if (!imgSrc) {
			return <DefaultPageIcon/>;
		} else {
			return <img src={imgSrc} alt="Image" width="24px" height="24px" onError={(e) => {
				e.currentTarget.onerror = null;
				e.currentTarget.src = defaultPageIcon;
			}}/>;

		}
	}

	public hasSubject(subject: UUID | Subject | null): boolean {
		if (!subject) {
			return !this.subject;
		}

		const subjectID = fi(typeof subject === 'string', subject, (subject as Subject).getId());
		return !this.subject || this.subject.includes(subjectID);
	}

	public getIcon(_small: boolean = false): ReactElement | null {
		const defaultAction = this.defaultAction();
		return <ChildPageIcon title={defaultAction.label} onClick={defaultAction.actionHandler}/>;
	}

	public defaultAction(): DefaultAction {
		return {
			label: 'Go to',
			actionHandler: () => {
				GA.LinkEvent({
					event_category: 'Internal Link',
					event_label: this.displayLabel(),
					event_description: this.description,
				});
				const queryParams = Browser.queryParams();
				if (!queryParams) {
					Browser.navigate(this.getURL());
				} else {
					Browser.navigate(`${this.getURL()}?subject=${queryParams['subject']}&unit=${queryParams['unit']}`);
				}
			},
		};
	}

	// checks if there is only one type of widget on the page
	public oneWidget(): boolean {
		return this.config.filter(w => cardWidgets.includes(w.type)).length === 1;
	}

	public oneWidgetWithContent(ids: UUID[], filteredItems: CMSObject[], widgetType: WidgetType): boolean {
		switch (widgetType) {
			case WidgetType.ContentType:
				// check if a content item widget exist on the current page
				const itemsWidget = Lists.default(this.config.filter(c => c.type === WidgetType.ContentItems)).map((c: any) => c.items).flat();
				if (itemsWidget.length) {
					return filteredItems.filter(item => itemsWidget.includes(item.getId())).length === 0;
				} else {
					return filteredItems.filter((item: any) => ids.includes(item.content_type)).length === 0;
				}
			case WidgetType.ContentItems:
				// get all content types for all widgets and filter documents by them
				let contentTypeIds = Lists.default(this.config.filter(c => c.type === WidgetType.ContentType)).map((w: any) => w.contentType);
				const contentTypesGroupIds = Lists.default(this.config.filter(c => c.type === WidgetType.ContentGroup)).map((cg: any) => cg.contentTypes).flat();
				contentTypeIds = [...contentTypesGroupIds];
				return filteredItems.filter((item: any) => contentTypeIds.includes(item.content_type)).length === 0;
		}
		return false;
	}

	public hasContent(documents: DocumentsWrapper, subjectUpdates?: SubjectUpdate[]): boolean {
		const widgets = this.config.filterAvailable(documents, subjectUpdates);
		const hasCardWidget = widgets.cardWidgets().length > 0;
		if (hasCardWidget) {
			return true;
		} else {
			if (this.landingPage()) {
				return true;
			}
			return widgets.find(widget => displayableWidgets.includes(widget.type)) !== undefined;
		}
	}

	public notificationsList(): Notification[] {
		return Lists.default(this.notifications);
	}
}