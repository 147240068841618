import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {Button, Divider} from '@mui/material';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {registrationDataAtom, registrationStepAtom} from '../../pages/Registration';
import {sessionAtom} from '../../state/session';
import {fi} from '../../utils/helpers';
import styled from '@emotion/styled';
import {getRecoil, setRecoil} from '../../state/recoilNexus';
import {saveUserPreferences} from './utils';
import GA from '../../tw/models/GA';
import TrialMessage from './components/TrialMessage';
import FinalMessage from './components/FinalMessage';

const validationSchema = yup.object({
	supportResponse: yup
		.string()
		.nullable(),
});


const FormElem = styled.form`
    a {
        text-decoration: underline;
        color: var(--color-blue);
    }
`;

const CommunicationStep = () => {
	const userSession = useRecoilValue(sessionAtom);
	const setActiveStep = useSetRecoilState(registrationStepAtom);

	const emptyProperties = {
		allowContact: 'yes',
	};

	const formik = useFormik({
		initialValues: emptyProperties,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			if (userSession?.isTrial()) {
				setRecoil(registrationDataAtom('allowContact'), values.allowContact);
			}
			await saveUserPreferences();
			GA.RegistrationEvent();
		},
	});

	useEffect(() => {
		let storedInfo = getRecoil(registrationDataAtom('allowContact'));
		if (storedInfo) {
			formik.setFieldValue('allowContact', storedInfo);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!userSession) return null;
	const onResponseChange = (obj, field) => {
		formik.setFieldValue(field, obj);
	};

	const goBack = () => {
		if (userSession?.isTrial()) {
			setRecoil(registrationDataAtom('allowContact'), formik.values.allowContact);
		}
		setActiveStep(old => old - 1);
	};

	return (
		<FormElem
			autoComplete="off"
			onSubmit={(e) => {
				e.preventDefault();
				formik.handleSubmit();
			}}
			data-testid="communications-form"
			noValidate>
			<div className="ml-56 mr-56">
				{fi(userSession.isTrial(),
					<TrialMessage value={formik.values.allowContact} onChange={onResponseChange}/>,
					<FinalMessage assessor={!userSession.isAssessor()}/>)}
			</div>
			<Divider/>
			<div className="flex-row-end pt-16 pb-16 pl-16 mr-32">
				<Button color="primary" variant="text" size="small"
						onClick={goBack} data-testid="back-btn">
					Back
				</Button>
				<Button color="primary" variant="contained" size="small"
						type="submit" data-testid="complete-btn">
					Complete
				</Button>
			</div>
		</FormElem>
	);
};
export default CommunicationStep;