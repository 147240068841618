import React, { ReactComponentElement } from 'react';
import styled from '@emotion/styled';
import { Messages } from '../utils/messages';
import SearchOff from '@mui/icons-material/SearchOff';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import { device, ROUTE_SIGNUP_TRIAL_ACCESS } from '../utils/constants';
import { ReactComponent as UnauthorizedError } from '../assets/images/pageNotFound.svg';
import Button from '@mui/material/Button';
import { goToMyCambridge } from '../utils/helpers';

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--color-background);
`;

const ErrorContent = styled.div`
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
        height: 80px;
        width: 80px;
        margin-bottom: 24px;
    }

    div {
        padding-right: 8px;
        margin-left: 8px;
    }

    p {
        font-size: 16px;
        line-height: 20px;
        font-family: var(--font-semi-bold);
        text-align: center;
        color: var(--color-monochrome);

        &:first-of-type {
            margin-bottom: 16px;
        }
    }

    @media ${device.mobile} {
        svg {
            height: 70px;
            width: 70px;
        }
    }
`;

type Error = {
	code: number,
	title: string | any,
	content: string | any,
	icon: ReactComponentElement<any>
}

const TitleElem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        font-size: 24px;
        font-family: var(--font-semi-bold);
        color: var(--color-monochrome);
        margin-bottom: 16px;
    }

    .error-info {
        font-size: 16px;
        font-family: var(--font-regular);
    }
`;

const LinkElem = styled.span`
  color: var(--color-blue);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 0;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`

const ContentElem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    p {
        font-size: 16px;
        font-family: var(--font-regular);
    }
`
const error401Title = <TitleElem>
	<p className="title">We are unable to authorise your access to Teach Cambridge because</p>
	<p className="title">you have signed in with an account that does not have the correct Role</p>
	<p className="error-info">401 error log in</p>
</TitleElem>;

const UnauthorizedErrorContent = () => {
	const handleClick = () => {
		window.open("https://www.ocr.org.uk/administration/support-and-tools/my-cambridge/", "_blank", "noopener")
	}

	const trialAccess = () => {
		let url = window.location.origin + ROUTE_SIGNUP_TRIAL_ACCESS;
		window.open(url, "_self")
	}
	return (
		<ContentElem >
			<p>Please <LinkElem data-testid='contact-admin-btn' onClick={handleClick}>contact your Exams Officer/Centre Administrator</LinkElem> for further guidance.</p>
			<p>Thinking about teaching an OCR qualification? <LinkElem data-testid='trial-access-redirect' onClick={trialAccess}>Sign up for trial access</LinkElem></p>
			<div className='mt-24 flex-row-center'>
				<Button variant="contained" size="small" data-testid='go-to-btn' onClick={goToMyCambridge}>
					Go to my Cambridge
				</Button>
			</div>
		</ContentElem>
	);
};
const errorMap: { [key: string]: Error } = {
	'error-unauthorized': {
		code: 401,
		title: error401Title,
		content: <UnauthorizedErrorContent/>,
		icon: <UnauthorizedError color={'disabled'}/>,
	},
	'error-not-found': {
		code: 404,
		title: <p>404 - Not found</p>,
		content: Messages.NotFoundError,
		icon: <SearchOff color={'disabled'}/>,
	},
	'error-unknown': {
		code: 500,
		title: <p>500 - Server error</p>,
		content: Messages.UnknownError,
		icon: <WarningAmberOutlined color={'disabled'}/>,
	},
};

const ErrorPageComponent = () => {
	let error = window.location.pathname;
	error = error.substring(error.lastIndexOf('/') + 1);
	return (
		<Wrapper>
			<ErrorContent>
				{errorMap[error].icon}
				<div>
					{errorMap[error].title}
					{errorMap[error].content}
				</div>
			</ErrorContent>
		</Wrapper>
	);
};

export default ErrorPageComponent;