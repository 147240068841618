import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React, {useMemo} from 'react';
import styled from '@emotion/styled';
import {useRecoilValue} from 'recoil';
import {registrationStepAtom} from '../../pages/Registration';
import {Roles} from '../../tw/models/Session';
import {sessionAtom} from '../../state/session';
import {RegistrationSteps} from './config';
import {fi} from '../../utils/helpers';

const StepperElem = styled(Stepper)`
    background-color: var(--color-white);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;
const RegistrationStepper = () => {
	const userSession = useRecoilValue(sessionAtom);
	const activeStep = useRecoilValue(registrationStepAtom);

	const registrationConfig = useMemo(() => {
		if (!userSession) return [];
		let roles = userSession.getRoles();
		if (roles.isTrial) {
			return RegistrationSteps[Roles.Trial];
		}
		if (roles.isAssessor || roles.isExamsOfficer || roles.isRestrictedAccess) {
			return RegistrationSteps[Roles.ExamsOfficer];
		}
		if (userSession.shouldUpdatePreferences()) {
			return RegistrationSteps['RECONFIRM'];
		}
		return RegistrationSteps[Roles.Teacher];
	}, [userSession]);

	if (!userSession) return null;
	return (
		<>
			{fi(registrationConfig.length > 1, <StepperElem activeStep={activeStep} alternativeLabel>
				{registrationConfig.map(step => (
					<Step key={step.label} data-testid="step">
						<StepLabel data-testid="step-label">{step.label}</StepLabel>
					</Step>
				))}
			</StepperElem>, null)}

			<div className="mt-32" data-testid="step-content">
				{registrationConfig[activeStep].component()}
			</div>
		</>
	);
};

export default RegistrationStepper;