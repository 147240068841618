import React, {useEffect, useMemo, useState} from 'react';
import InputField from '../../FormComponents/Input/InputField';
import {Strings} from '../../../utils/strings';
import styled from '@emotion/styled';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {
	skippedQuestions,
	trialSurveyData,
	trialSurveyExtraStepData,
	trialSurveyStep,
	trialSurveyStepData,
} from '../../../state/trial';

const TextElem = styled.div`
    width: 100%;
`;
const TrialTextField = ({inputId, extra}: { inputId: string, extra?: boolean }) => {
	const [localValue, setLocalValue] = useState<string | null>(null);
	const setStepData = useSetRecoilState(trialSurveyStepData);
	const setExtraStepData = useSetRecoilState(trialSurveyExtraStepData);
	const surveyStep = useRecoilValue(trialSurveyStep);
	const currentStoredData = useRecoilValue(trialSurveyData(surveyStep));
	const [skipped, setSkipped] = useRecoilState(skippedQuestions);

	useEffect(() => {
		setLocalValue(null);
	}, [surveyStep]);
	const onChange = (e) => {
		if (skipped.length) {
			let copy = [...skipped];
			setSkipped(copy.filter(c => c !== surveyStep));
		}
		if (extra) {
			setExtraStepData(e.target.value);
		} else {
			setStepData(e.target.value);
		}
		setLocalValue(Strings.default(e.target.value));
	};

	const value = useMemo(() => {
		if (localValue !== null) {
			return localValue;
		}
		if (currentStoredData ) {
			if (extra) {
				setExtraStepData(currentStoredData.extra);
				return currentStoredData.extra;
			}
			const data = currentStoredData === 'skip' ? '' : currentStoredData
			setStepData(data);
			return data;
		}
		return '';
	}, [currentStoredData, localValue]);

	return (
		<TextElem data-testid='trial-text-field'>
			<InputField
				id={inputId}
				name={inputId}
				isTextArea={true}
				value={value}
				onChange={onChange}
				type={'text'}
				field={{name: inputId, label: 'Answer'}}
			/>
		</TextElem>
	);
};

export default TrialTextField;