import React from 'react';
import styled from '@emotion/styled';
import AccountDetails from '../components/Account/AccountDetails';
import AccountEditDetails from '../components/Account/AccountEditDetails';
import Marketing from '../components/Account/Marketing';
import Button from '@mui/material/Button';
import {useMediaQuery} from '@mui/material';
import {Browser} from '../utils/browser';
import {ArrowBack} from '@mui/icons-material';
import LowerSection from '../components/LowerSection/LowerSection';
import {device} from '../utils/constants';
import {fi} from '../utils/helpers';

const AccountPageElem = styled.div`
    background-color: var(--color-background);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 300px;

    @media ${device.tablet} {
        padding: 16px;
    }

    .first-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media ${device.tablet} {
            flex-direction: column
        }
    }
`;

const BoxElem = styled('div')<{ small: boolean }>`
    width: 100%;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey);
    border-radius: 6px;
    padding: 24px 0 16px 0;
    margin-bottom: 24px;
    box-shadow: 0 4px 6px var(--color-box-shadow);
    //height: 100%;
    ${props => props.small && `width:48%; margin-bottom: 0`}
`;

const AccountPageHeader = 'Account & settings';

const MyAccount = () => {
	const mobileOrTablet = useMediaQuery(device.tablet);

	const goBackToHome = () => {
		Browser.navigate('/');
	};

	return (
		<AccountPageElem>
			<div className="flex-row-start mb-16">
				<Button size="small" onClick={goBackToHome} startIcon={<ArrowBack/>} data-testid="back-btn">
					Back to home
				</Button>
			</div>
			<div className="text-32 text-latoBold mb-16"> {AccountPageHeader}</div>
			<div className="first-section mb-24">
				<BoxElem small={fi(mobileOrTablet, false, true)}>
					<AccountDetails/>
				</BoxElem>
				<BoxElem small={fi(mobileOrTablet, false, true)}>
					<AccountEditDetails/>
				</BoxElem>
			</div>
			<BoxElem small={false}>
				<Marketing/>
			</BoxElem>
			<LowerSection/>
		</AccountPageElem>
	);
};

export default MyAccount;