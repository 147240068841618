import React from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {setRecoil} from '../../state/recoilNexus';
import {registrationDataAtom, registrationStepAtom} from '../../pages/Registration';
import {Messages} from '../../utils/messages';
import {useRecoilState, useRecoilValue} from 'recoil';
import styled from '@emotion/styled';
import {sessionSelector} from '../../state/session';
import {fi} from '../../utils/helpers';
import {RolesLabels} from './utils';
import {Objects} from '../../utils/objects';
import ExamsOfficerMessage from './components/ExamsOfficerMessage';
import TeacherMessage from './components/TeacherMessage';
import TrialIntroForm from './components/TrialIntroForm';

const TrialIntroElem = styled.div`
    background-color: var(--color-white);
    width: 670px;
    border: 1px solid var(--color-grey);
    padding: 24px 0 16px 0;
    box-shadow: 0 0 0 1px var(--color-box-shadow);
    border-radius: 6px;
    margin-bottom: 24px;
`;

const validationSchema = yup.object({
	job: yup
		.string()
		.nullable()
		.required(Messages.MandatoryField),
	teachingOCR: yup.boolean().nullable()
		.when('job', {
			is: (val) => val === RolesLabels.ExamOfficer,
			then: (schema) => schema.nullable(),
			otherwise: (schema) => schema.nullable().required(Messages.MandatoryField),
		}),
});

const TrialIntro = () => {
	const [activeStep, setActiveStep] = useRecoilState(registrationStepAtom);
	const userSession = useRecoilValue(sessionSelector);

	const emptyProperties = {
		job: null,
		teachingOCR: null,
	};

	const formik = useFormik({
		initialValues: emptyProperties,
		validationSchema: validationSchema,
		onSubmit: (values) => {
			setRecoil(registrationDataAtom('job'), values.job);
			setActiveStep(old => old + 1);
		},
	});

	const onChange = (value, field) => {
		if (field === 'job') {
			if (!value) {
				formik.setFieldValue('job', null);
			} else {
				if (value.value === RolesLabels.ExamOfficer) formik.setFieldValue('teachingOCR', null);
				formik.setFieldValue('job', value.value);
			}
		} else if (field === 'teachingOCR') {
			formik.setFieldValue('teachingOCR', value == 'true');
		}
	};

	const goBack = () => {
		setActiveStep(old => old - 1);
	};
	const handleCreateAccount = () => {
		sessionStorage.setItem('trialAccessSetup', JSON.stringify({
			'jobTitle': formik.values.job,
		}));
		let user = Objects.default(userSession);
		window.open(user.redirectURL, '_self');
	};

	const renderContent = () => {
		if (activeStep === 0) {
			return <TrialIntroForm formik={formik} onChange={onChange} createAccount={handleCreateAccount}/>;
		}
		return fi(formik.values.job === RolesLabels.ExamOfficer,
			<ExamsOfficerMessage goBack={goBack}/>,
			<TeacherMessage goBack={goBack}/>);
	};
	return (
		<TrialIntroElem>
			{renderContent()}
		</TrialIntroElem>
	);
};

export default TrialIntro;