import {useRecoilValue} from 'recoil';
import {hasRestrictedAccess} from '../../../state/session';
import React from 'react';
import RatingComponent from './RatingComponent';
import styled from '@emotion/styled';
import AddToFavorite from './AddToFavorite';
import CopyLink from './CopyLink';

const UserActionContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: -16px;

    > div:not([data-testid='rating-feedback']) {
        cursor: pointer;
        border-right: 1px solid var(--color-box-shadow);
        width: 60px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const UserActions = ({item}: { item: any }) => {
	const restrictedAccess = useRecoilValue(hasRestrictedAccess);

	if (item.hideActions()) {
		return null;
	}

	if (item.onlyShareAction) { // for event_material items display only share action
		return (
			<UserActionContainer className='user-actions' data-testid='user-share-action-wrapper'>
				<CopyLink item={item} disabled={restrictedAccess}/>
			</UserActionContainer>
		)
	}

	return (
		<UserActionContainer className='user-actions' data-testid='user-action-wrapper'>
			<AddToFavorite item={item} disabled={restrictedAccess}/>
			<RatingComponent item={item} disabled={restrictedAccess}/>
			<CopyLink item={item} disabled={restrictedAccess}/>
		</UserActionContainer>
	)
};

export default UserActions;