import {Strings} from '../../utils/strings';
import {Lists} from '../../utils/lists';
import {CMSObject, DisplayMode} from './__CMSObject';
import {UUID} from '../types';
import {Numbers} from '../../utils/numbers';

export class ChildAssessment extends CMSObject {
	public code: string;
	public name: string;
	public assessment: UUID[];
	public version: number;

	constructor(item: any = {}) {
		super(item);

		this.code = Strings.default(item.code);
		this.name = Strings.default(item.name);
		this.assessment = Lists.default(item.assessment);
		this.version = Numbers.default(item.version);
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		if (options === DisplayMode.SHORT) {
			return this.code;
		} else {
			return `${this.code} - ${this.name}`;
		}
	}
}
