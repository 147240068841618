import {CMSObject} from "./__CMSObject";
import {Strings} from "../../utils/strings";
import React from "react";
import {ReactComponent as Critical} from "../../assets/images/critical-notification.svg"
import {ReactComponent as Warning} from "../../assets/images/warning-notification.svg"
import {ReactComponent as Informative} from "../../assets/images/informative-notification.svg"
import {Dates} from "../../utils/dates";
import {Lists} from "../../utils/lists";
import {getRecoil} from "../../state/recoilNexus";
import {productTreeSelector} from "../../state/product";
import {Objects} from "../../utils/objects";
import {references} from "../../state/state";
import {Subject} from "./Subject";
import {UUID} from '../types';

export enum NotificationType {
    INFO = "Information",
    CRITICAL = "Critical",
    WARNING = "Warning"
}
export class Notification extends CMSObject {
    public title: string;
    public description: string;
    public closable: boolean;
    public seen: boolean;
    public type: NotificationType;
    public subject: string[]; //subject
    public qualification: string[]; //qualification
    public availableForRestricted: boolean;

    constructor(item: any = {}) {
        super(item);
        this.title = Strings.default(item.title);
        this.description = Strings.default(item.description);
        this.closable = Boolean(item.closable);
        this.seen = Boolean(item.seen);
        this.type = Strings.default(item.type) as NotificationType;
        this.subject = Lists.default(item.qualification_mapping);
        this.qualification = Lists.default(item.qualification_group_mapping);
        this.availableForRestricted = Boolean(item.available_for_restricted);
    }

    public getIcon() {
        switch (this.type) {
            case NotificationType.CRITICAL:
                return <Critical/>;
            case NotificationType.WARNING:
                return <Warning/>;
            case NotificationType.INFO:
                return <Informative/>;
            default:
                return null
        }
    }

    public getPublishedDate() {
        return Dates.local(this.__meta.published, 'long');
    }

    public getNotificationMapping() {
        const productData = getRecoil(productTreeSelector);
        if (!productData) return [];

        if (!this.qualification.length) {
            return ["All subjects"]
        }

        return this.qualification.map(qId => {
            let q = getRecoil(references(qId));
            let qObj = Objects.default(q);
            return Lists.default<Subject>(qObj.subjects).filter((s) => this.subject.includes(s.getId()))
                .map((item) => qObj.displayLabel() + ": " + item.displayLabel());
        }).flat()
    }

    public wasSeen() {
        return this.seen;
    }

    public matchesSubject(subjectId: UUID): boolean {
        return !this.subject.length || this.subject.includes(subjectId);
    }

    public getClass(): string {
        switch (this.type) {
            case NotificationType.CRITICAL:
                return "notification-critical";
            case NotificationType.WARNING:
                return "notification-warning";
            default:
                return "notification-info";
        }
    }
}