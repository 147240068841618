import React, {useContext, useMemo, useState} from 'react';
import {WidgetContext} from '../widget';
import {useRecoilValue} from 'recoil';
import {documentSelector} from '../../../state/documents';
import WidgetHeader from '../commons/WidgetHeader';
import Group from '../../Card/Group';
import {fi} from '../../../utils/helpers';
import ViewAllBtn from '../commons/ViewAllBtn';

const ContentItems = () => {
	const context = useContext(WidgetContext);
	const documents = useRecoilValue(documentSelector);
	const [expanded, setExpanded] = useState(false);

	const filteredDocuments = useMemo(() => {
		return documents.byIds(context.widget.items);
	}, [documents]);


	if (!context || !documents.length) {
		return null;
	}
	const viewAll = () => {
		setExpanded(!expanded)
	}
	return (
		<>
			<WidgetHeader>
				<h2>{context.widget.title}</h2>
			</WidgetHeader>
			<div>
				<Group items={fi(expanded, filteredDocuments, filteredDocuments.slice(0, 4))}
					   allItems={filteredDocuments}
					   label={context.widget.title} itemsOriginalLength={filteredDocuments.length}/>
				<ViewAllBtn viewAll={viewAll}
							expanded={expanded}
							nrOfItems={filteredDocuments.length} />
			</div>
		</>
	);
};

export default ContentItems;