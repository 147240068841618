import React from 'react';
import styled from '@emotion/styled';
import UserActions from './CardActions/UserActions';
import CardActions from './CardActions/CardActions';

const Container = styled.div`
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 -16px;
  padding: 16px 16px 0 16px;

  svg {
    color: var(--color-border);
    height: 18px;
    width: 18px;
    transition: all 200ms linear;

    &:hover {
      fill: var(--color-blue);
    }
  }
  
  // footer has only one child
  &:has(>:last-child:nth-of-type(1)) {
    justify-content: flex-end;
  }
`
const Footer = ({item}: { item: any }) => {

	return (
		<Container className='footer' data-testid='card-footer'>
			<UserActions item={item}/>
			<CardActions item={item}/>
		</Container>
	)
}

export default Footer;