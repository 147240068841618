import {LibraryItem} from './__MediaLibrary';
import {DefaultAction, UUID} from '../types';
import {Strings} from '../../utils/strings';
import React, {ReactElement} from 'react';
import {fi} from '../../utils/helpers';
import {ReactComponent as VideoIconSmall} from '../../assets/images/card/video-small.svg';
import {ReactComponent as VideoIcon} from '../../assets/images/card/video-normal.svg';
import {setRecoil} from '../../state/recoilNexus';
import {playVideoAtom} from '../../components/Card/CardActions/VideoPlayer';
import GA from '../../tw/models/GA';
import {disableButton} from '../../components/Card/CardActions/Buttons/utils';
import {Messages} from '../../utils/messages';

export class Video extends LibraryItem {
	public static searchable: boolean = true;
	public static markAsSeen = true;
	public content_type: UUID;
	public link: string;

	constructor(item: any = {}) {
		super(item);
		this.content_type = item.content_type;
		this.link = Strings.default(item.link);
	}

	public defaultAction(): DefaultAction {
		return {
			label: 'Play',
			actionHandler: () => {
				if (disableButton(this)) {
					return;
				}
				GA.ItemActionEvent('Video', this.formGAParams());
				this.markAsSeen();
				this.addToLocalStorage();
				setRecoil(playVideoAtom, this);
			},
		};
	}

	public getIcon(small: boolean = false): ReactElement | null {
		const defaultAction = this.defaultAction();
		const Icon = fi(small, VideoIconSmall, VideoIcon);
		return <Icon onClick={defaultAction.actionHandler}
					 title={fi(disableButton(this), Messages.FeatureNotAvailable, defaultAction.label)}/>;
	}
}