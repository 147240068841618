import React, {useMemo} from 'react';
import SurveyQuestion from '../commons/SurveyQuestion';
import {trialSurveyData, trialSurveyStep, trialSurveyStepData} from '../../../state/trial';
import {useRecoilValue} from 'recoil';
import {FormType, SurveyConfig} from '../surveyConfig';
import TrialTextField from '../commons/TrialTextField';
import {fi} from '../../../utils/helpers';
import {Objects} from '../../../utils/objects';
import TrialRadioField from '../commons/TrialRadioField';
import Divider from '@mui/material/Divider';
import { Strings } from '../../../utils/strings';

const GenericStep = () => {
	const surveyStep = useRecoilValue(trialSurveyStep);
	const currentValue = useRecoilValue(trialSurveyStepData);
	const storedValue = useRecoilValue(trialSurveyData(surveyStep));

	const question = useMemo(() => {
		return Strings.default(SurveyConfig[surveyStep].question);
	}, [surveyStep]);

	const formType = useMemo(() => {
		return Strings.default(Objects.default(SurveyConfig[surveyStep].form).type);
	}, [surveyStep]);

	const displayExtraQuestion = useMemo(() => {
		let check: string = '';
		if (currentValue) {
			check = currentValue;
		} else if (storedValue) {
			if (storedValue.stepData) {
				check = storedValue.stepData;
			} else {
				check = storedValue;
			}
		}
		return SurveyConfig[surveyStep].extra && check === 'Yes';
	}, [storedValue, currentValue, surveyStep]);

	return (
		<div data-testid='generic-step-wrapper'>
			<SurveyQuestion question={question}/>
			{fi(formType === FormType.Text,
				<TrialTextField inputId={`trial-question-${surveyStep}`}/>,
				<TrialRadioField/>)}
			{fi(displayExtraQuestion,
				<div data-testid='generic-step-extra'>
					<Divider/>
					<br/>
					<SurveyQuestion question={Objects.default(SurveyConfig[surveyStep].extra).question || ''}/>
					<TrialTextField inputId={`trial-extra-${surveyStep}`} extra={true}/>
				</div>)}
		</div>
	);
};

export default GenericStep;