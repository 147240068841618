import {atom, selector} from "recoil";
import GA, {GADimensions} from "../tw/models/GA";

const gaAtom = atom({
	key: 'gaAtom',
	default: {}
})

export const GASelector = selector<GADimensions>({
	key: 'GAState',
	get: ({get}) => {
		return get(gaAtom);
	},
	set: ({set, get}, value) => {
		const state = {...get(gaAtom), ...value}
		set(gaAtom, state);
		GA.initConfig();
		GA.setDimensions(state);
	}
})