import {JobsOptions, RolesLabels, TrueOrFalseOptions} from '../utils';
import SelectComponent from '../../FormComponents/Select/SelectComponent';
import {RegistrationMessages} from '../messages';
import {Strings} from '../../../utils/strings';
import {fi} from '../../../utils/helpers';
import RadioButtonsGroup from '../../FormComponents/Radio/RadioButtonsGroup';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import React from 'react';

const TrialIntroForm = ({formik, onChange, createAccount}: {
	formik: any,
	onChange: (...params) => void,
	createAccount: () => void
}) => {
	const getBtn = () => {
		if (formik.values.job === RolesLabels.ExamOfficer || formik.values.teachingOCR || !formik.values.job) {
			return {
				text: 'Continue',
				onClick: () => formik.handleSubmit(),
			};
		}
		return {
			text: 'Create account',
			onClick: () => createAccount(),
		};
	};
	return (
		<form noValidate
			  data-testid="about-you-form"
			  autoComplete="off"
			  onSubmit={(e) => {
				  e.preventDefault();
				  formik.handleSubmit();
				  formik.validateForm().then(r => {
					  if (!Object.keys(r).length) return;
				  });
			  }}>
			<div className="pl-16 pr-16 pb-16">
				<p className="text-regular text-24 mb-16">About your job</p>
				<SelectComponent
					ariaLabel={'job'}
					id={'job'}
					label={RegistrationMessages.TrialJobLabel}
					name={'job'}
					value={formik.values.job}
					onChange={onChange}
					checkbox={false}
					values={JobsOptions}
					required={true}
					showError={(Boolean(formik.touched.job) && Boolean(formik.errors.job))}
					errorMessage={Strings.default(formik.errors.job)}
					// touched={!!formik.touched.job}
					showSelectAll={false}
					isClearable={true}
				/>
				{fi(formik.values.job && formik.values.job !== RolesLabels.ExamOfficer,
					<RadioButtonsGroup field={{
						options: TrueOrFalseOptions,
						name: 'teachingOCR',
						label: RegistrationMessages.TrialTeachingLabel,
					}} onChange={onChange} value={formik.values.teachingOCR}
									   styling={'flex-row-start'}/>)}
			</div>
			<Divider/>
			<div className="flex-row-end pt-16 pr-16">
				<Button color="primary" variant="contained" size="small"
						onClick={getBtn().onClick}
						disabled={Boolean(Object.keys(formik.errors).length)}
						data-testid="continue-btn">
					{getBtn().text}
				</Button>
			</div>
		</form>
	);
};
export default TrialIntroForm;