import {COOKIE_NAME, COOKIE_VERSION} from '../../utils/constants';

const dimensionMap = {
	'dimension1': 'qualification',
	'dimension2': 'qualificationgroup',
	'dimension3': 'school',
	'dimension4': 'schoolid',
	'dimension5': 'userrole',
	'dimension6': 'accessType',
	'dimension7': 'businessStream',
};

type GAEventCategories =
	| 'View resources'
	| 'User feedback form'
	| 'Update user details'
	| 'Login & Registration'
	| 'Events'
	| 'Internal Link'
	| 'Outbound Link'
	| 'Notification Link'
	| 'Subject Updates'
	| 'Search'
	| 'Trial access survey'
	| 'Tour'
	| 'Changing organization';


type GAEventActions =
	| 'Preview'
	| 'Download'
	| 'Completed'
	| 'Successful Login'
	| 'Successful Registration'
	| 'Book event'
	| 'Past event view'
	| 'Active event view'
	| 'Link Click'
	| 'Subject update view'
	| 'Video'
	| 'Tour closed'
	| 'Tour - never show again'
	| 'Tour started'
	| 'Tour opened'
	| 'Tour completed'
	| 'Org from header'
	| 'Org from account page'
	| string;

export type GADimensions = {
	qualification?: string;
	qualificationgroup?: string;
	school?: string;
	schoolid?: string;
	unit?: string;
	userrole?: string;
	user_id?: string;
	subject?: string;
	accessType?: string;
	businessStream?: string;
}

export enum MeasurementConfig {
	'dev' = 'G-QZFYCE0PBT',
	'test' = 'G-2PVESKB87E',
	'pp1' = 'G-69278C3FED',
	'prd' = 'G-J6N7FJ2384',
}

type GAEvent = {
	event_category: GAEventCategories;
	event_label?: string;
	event_description?: string;
	link_name?: string;
	event_name?: string;
	uuid?: string;
	content_type?: string;
	specificationcode?: string;
	unitcomponent?: string;
	examyear?: string;
	series?: string;
}

type EventPayload = {
	name: GAEventActions;
	params: GAEvent
}

export default class GA {
	public static queue: EventPayload[] = [];

	// public static measurementId: string | null = process.env.REACT_APP_GA_MEASUREMENT_ID || null;
	public static measurementId: string | null = null;
	private static dimensionsSet: boolean = false;

	public static initConfig() {
		if (GA.measurementId && document.cookie.includes(COOKIE_NAME + '=' + COOKIE_VERSION) && !GA.dimensionsSet) {
			gtag('config', GA.measurementId, {
				'send_page_view': false,
				'custom_map': dimensionMap,
			});
		}
	}

	private static valid(params: GADimensions): boolean {
		for (let key in dimensionMap) {
			const dim = dimensionMap[key];
			if (!params[dim]) return false;
		}
		return true;
	}

	public static setDimensions(params: GADimensions) {
		if (GA.measurementId && document.cookie.includes(COOKIE_NAME + '=' + COOKIE_VERSION) && GA.valid(params)) {
			gtag('set', 'user_properties', {...params});
			GA.dimensionsSet = true;
			while (GA.queue.length) {
				const q = GA.queue.shift();
				if (q) {
					GA.callEvent(q.name, q.params);
				}
			}
		}
	}

	public static disablePageViewMeasurement() {
		if (GA.measurementId && document.cookie.includes(COOKIE_NAME + '=' + COOKIE_VERSION) && GA.dimensionsSet) {
			gtag('config', GA.measurementId, {send_page_view: false});
		}
	}

	public static sendPageViewEvent(page_title: string) {
		if (GA.measurementId && document.cookie.includes(COOKIE_NAME + '=' + COOKIE_VERSION) && GA.dimensionsSet) {
			gtag('event', 'page_view', {page_title: page_title});
		}
	}

	public static callEvent(name: GAEventActions, params: GAEvent) {
		if (!GA.dimensionsSet) {
			GA.queue.push({name, params});
			return;
		}
		console.log('**** GA callEvent -', name, params)
		if (GA.measurementId && document.cookie.includes(COOKIE_NAME + '=' + COOKIE_VERSION) && GA.dimensionsSet) {
			gtag('event', name, {...params});
		}
	}

	public static LoginEvent() {
		GA.callEvent('Successful Login', {event_category: 'Login & Registration', event_label: 'Successful Login'});
	}

	public static RegistrationEvent() {
		GA.callEvent('Successful Registration', {
			event_category: 'Login & Registration',
			event_label: 'Successful Registration',
		});
	}

	public static UpdatePreferencesEvent() {
		GA.callEvent('Update user details', {event_category: 'Update user details', event_label: 'Completed'});
	}

	public static TrialSurveyEvent() {
		GA.callEvent('Trial access survey completed', {
			event_category: 'Trial access survey',
			event_label: 'Trial access survey completed',
		});
	}

	public static FeedbackEvent(actionName: GAEventActions) {
		GA.callEvent(actionName, {event_category: 'User feedback form', event_label: actionName});
	}

	public static LinkEvent(params: GAEvent) {
		GA.callEvent('Link Click', params);
	}

	public static EventActionEvent(actionName: GAEventActions, eventName: string) {
		GA.callEvent(actionName, {
			event_category: 'Events',
			event_label: eventName,
		});
	}

	public static ItemActionEvent(actionName: GAEventActions, params: any) {
		GA.callEvent(actionName, {...params, event_category: 'View resources'});
	}

	public static ChangeOrganizationEvent(actionName: GAEventActions) {
		GA.callEvent(actionName, {event_category: 'Changing organization', event_label: actionName});
	}

	public static TourEvent(actionName: GAEventActions) {
		GA.callEvent(actionName, {event_category: 'Tour', event_label: actionName});
	}

	public static SubjectUpdateEvent(params: any) {
		GA.callEvent('Subject update view', {...params, event_category: 'Subject Updates'});
	}

	public static SearchEvent(params: any) {
		GA.callEvent('Search', {...params, event_category: 'Search'});
	}
}
