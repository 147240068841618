import React from 'react';
import styled from '@emotion/styled';
import {Copyright} from '@mui/icons-material';
import SocialMediaComponent from '../components/SocialMedia/SocialMediaComponent';
import {Browser} from '../utils/browser';
import {device} from '../utils/constants';
import {useMediaQuery} from '@mui/material';
import RelatedSites from '../components/commons/RelatedSites';
import UsefulLinks from '../components/commons/UsefulLinks';
import {fi} from '../utils/helpers';

const COPYRIGHT_TEXT = ' Cambridge University Press & Assessment';
const IP_LABEL = 'IP notice: teaching resources';

const FOOTER_LINKS = [
	{
		label: 'Accessibility and Standards',
		redirectTo: 'https://www.cambridgeassessment.org.uk/footer/accessibility-and-standards/index.aspx',
		target: '_blank',
	},
	{
		label: 'Data Protection',
		redirectTo: 'https://www.cambridgeassessment.org.uk/footer/data-protection/index.aspx',
		target: '_blank',
	},
	{
		label: 'Use of Cookies',
		redirectTo: 'https://www.cambridgeassessment.org.uk/footer/use-of-cookies/index.aspx',
		target: '_blank',
	},
	{
		label: 'Statement on Modern Slavery',
		redirectTo: 'https://www.registrarysoffice.admin.cam.ac.uk/governance-and-strategy/anti-slavery-and-anti-trafficking',
		target: '_blank',
	},
	{
		label: 'Terms and Conditions',
		redirectTo: 'https://www.cambridgeassessment.org.uk/footer/terms-and-conditions/index.aspx',
		target: '_blank',
	},
	{
		label: IP_LABEL,
		redirectTo: '/teaching-resource/ip-notice',
		target: '_self',
	},
];

const CustomFooter = styled.footer`
    grid-area: footer;
    display: flex;
    align-items: center;
    z-index: 11;
    font-family: var(--font-semi-bold);
    background-color: var(--color-black);
    padding: 0 24px;
    height: 48px;
    min-height: 48px;
    width: 100%;
    color: var(--color-white);

    div:nth-of-type(2) {
        display: flex;
        flex-direction: row;
        flex: 1;
    }

    @media ${device.tablet} {
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        height: 160px;
        .footer-lower-section {
            display: flex;
            flex-direction: row;
            width: 100%;

            .flex-1 {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
            }
        }
    }
    @media ${device.mobile} {
        height: 235px;
        .footer-lower-section {
            flex-direction: column;
        }
    }
`;

const FooterLinksElem = styled.div`
    span {
        display: flex;
    }

    span:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    span:not(:last-of-type):after {
        background-color: var(--color-white);
        content: "";
        display: block;
        height: 18px;
        margin: 0 8px;
        width: 1px;
    }

    @media ${device.tablet} {
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 16px;

        span {
            line-height: 22px;
        }
    }
`;
const Footer = () => {
	const copyrightText = new Date().getFullYear() + COPYRIGHT_TEXT;
	const mobile = useMediaQuery(device.mobile);
	const tablet = useMediaQuery(device.tablet);

	const redirect = (link) => {
		if (link.target === '_self') {
			Browser.navigate(link.redirectTo);
		} else {
			window.open(link.redirectTo, link.target);
		}
	};

	return (
		<CustomFooter data-testid="footer">
			<div className="flex-row-center">
				<Copyright fontSize="small" className="mr-8"/>
				<p className="text-14 text-regular mr-16">{copyrightText}</p>
			</div>
			<FooterLinksElem>
				{FOOTER_LINKS.map((link, index) => (
					<span key={link.label}
						  data-testid={`${link.label}-footer-link`} className="text-14" onClick={() => redirect(link)}>
                        {link.label}
                    </span>
				))}
			</FooterLinksElem>
			{fi(tablet,
				<div className="footer-lower-section">
					<div className="flex-1">
						<RelatedSites/>
						<UsefulLinks/>
					</div>
					{fi(mobile, null, <SocialMediaComponent/>)}
				</div>)}
			{fi(mobile, <SocialMediaComponent/>)}
		</CustomFooter>
	);
};

export default Footer;