import {CMSObject} from './__CMSObject';
import {Strings} from '../../utils/strings';
import {Lists} from '../../utils/lists';

export class HelpGuide extends CMSObject {
	public title: string;
	public description: string;
	public allPages: boolean;
	public selector?: string;
	public page?: string[];

	constructor(item: any = {}) {
		super(item);
		this.title = Strings.default(item.title);
		this.description = Strings.default(item.description);
		this.allPages = Boolean(item.all_pages);
		if (item.selector) {
			this.selector = Strings.default(item.selector);
		}
		if (item.page) {
			this.page = Lists.default(item.page);
		}
	}
}