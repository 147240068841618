import React, {useEffect, useMemo} from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {registrationDataAtom, registrationStepAtom, showIntroAtom} from '../../pages/Registration';
import {setRecoil} from '../../state/recoilNexus';
import RadioButtonsGroup from '../FormComponents/Radio/RadioButtonsGroup';
import {RegistrationMessages} from './messages';
import {JobsOptions, saveUserPreferences} from './utils';
import {Messages} from '../../utils/messages';
import {Strings} from '../../utils/strings';
import {sessionAtom} from '../../state/session';

const validationSchema = yup.object({
	job: yup
		.string()
		.nullable()
		.required(Messages.MandatoryField),
});

const AboutYouStep = () => {
	const setShowIntro = useSetRecoilState(showIntroAtom);
	const setActiveStep = useSetRecoilState(registrationStepAtom);
	const user = useRecoilValue(sessionAtom);
	const storedInfo = useRecoilValue(registrationDataAtom('job'));
	const emptyProperties = {
		job: null,
	};

	const formik = useFormik({
		initialValues: emptyProperties,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			setRecoil(registrationDataAtom('job'), values.job);
			if (user?.shouldUpdatePreferences()) {
				await saveUserPreferences();
			} else {
				setActiveStep(old => old + 1);
			}
		},
	});
	useEffect(() => {
		formik.setFieldValue('job', storedInfo);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storedInfo]);

	const onJobChange = (obj, field) => {
		formik.setFieldValue(field, obj);
	};

	const goBack = () => {
		setShowIntro(true);
	};

	const value = useMemo(() => {
		if (!user || formik.values.job) return formik.values.job;
		if (user.preferences && user.shouldUpdatePreferences()) {
			formik.setFieldValue('job', user.preferences.jobTitle);
		}
		return formik.values.job;
	}, [formik.values.job, user]);

	const btnText = useMemo(() => {
		if (user?.shouldUpdatePreferences()) {
			return 'Complete';
		}
		return 'Continue';
	}, [user]);

	let options = JobsOptions.slice(0, 3);
	return (
		<form noValidate
			  data-testid="about-you-form"
			  autoComplete="off"
			  onSubmit={(e) => {
				  e.preventDefault();
				  formik.handleSubmit();
				  formik.validateForm().then(r => {
					  if (!Object.keys(r).length) return;
				  });
			  }}>
			<div className="ml-32 mr-32 mb-16">
				<p className="text-24 text-regular mb-16"> {RegistrationMessages.AboutYouStepHeader}</p>
				<RadioButtonsGroup field={{options: options, name: 'job', label: ''}} onChange={onJobChange}
								   value={value}
								   styling="flex-column-start"
								   errorMessage={Strings.default(formik.errors.job)}
								   showError={!!(formik.touched.job && Boolean(formik.errors.job))}/>
			</div>

			<Divider/>
			<div className="flex-row-end pt-16 pb-16 pl-16 mr-32">
				<Button variant="text" data-testid="back-btn" size="small" onClick={goBack}>
					Back
				</Button>
				<Button variant="contained" size="small" data-testid="continue-btn" type="submit">
					{btnText}
				</Button>
			</div>
		</form>
	);
};
export default AboutYouStep;