import {UserSession} from '../../../tw/models/Session';
import {Messages} from '../../../utils/messages';
import React from 'react';

const OrgModalInfoNote = ({user}: { user: UserSession | null }) => {
	if (!user) return null;
	if (user.isFirstTimeUser()) {
		return (
			<div className="text-16">
				<span className="text-semiBold"> {Messages.ChangeOrgRegistrationLine1}</span>
				<span className="text-regular"> {Messages.ChangeOrgRegistrationLine2}</span>
			</div>
		);
	}
	return (
		<span className="text-18 text-semiBold">{Messages.ChangeOrgMessage}</span>
	);
};

export default OrgModalInfoNote