import React, {useContext, useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {documentSelector} from '../../../state/documents';
import {Lists} from '../../../utils/lists';
import {CMSObject} from '../../../tw/models/__CMSObject';
import {fi} from '../../../utils/helpers';
import {Types} from '../../../tw/types';
import {Link} from '../../../tw/models/Link';
import Group from '../../Card/Group';
import styled from '@emotion/styled';
import {WidgetContext} from '../widget';
import {viewAll} from './utils';
import NoContentComponent from './NoContentComponent';
import WidgetHeader from '../commons/WidgetHeader';
import ViewAllBtn from '../commons/ViewAllBtn';
import {HomepageMessages} from './messages';
import {shouldHideButton} from './Homepage';
import {sortCourseItems} from '../helpers';
import {DocumentsWrapper} from '../../../tw/models/DocumentsWrapper';

const NoContentElem = styled.div`
    background-color: var(--color-white);
    border: solid 1px var(--color-border-light);
    border-radius: 6px;
`;
const HomepageOnlineCourses = () => {
	const context = useContext(WidgetContext);
	const documents = useRecoilValue(documentSelector);

	const onlineCourses = useMemo(() => {
		let list = Lists.default<CMSObject>(documents).filter(doc => doc.getType() === Types.LINK && (doc as Link).isOnlineCourse);

		const sortedByTitle = Lists.sort(list, 'title') as DocumentsWrapper;

		// sorted by title first and then by 'essential' label
		return sortCourseItems(sortedByTitle);
	}, [documents]);

	return (
		<div data-testid='online-courses-widget'>
			<WidgetHeader>
				<h2>{HomepageMessages.OnlineCourseWidgetLabel}</h2>
			</WidgetHeader>
			{fi(onlineCourses.length, <Group
					items={fi(context.widget.numberOfItems !== '-1', onlineCourses.slice(0, context.widget.numberOfItems), onlineCourses)}/>,
				<NoContentElem>
					<NoContentComponent message={HomepageMessages.NoOnlineCourses} extra={true}/>
				</NoContentElem>)}
			{fi(shouldHideButton(context.widget), null, <div data-testid="online-course-view-all"
															 className="flex-row-end mr-16">
				<ViewAllBtn viewAll={() => viewAll(context.widget)} noIcon={true} nrOfItems={onlineCourses.length}/>
			</div>)}
		</div>
	);
};

export default HomepageOnlineCourses;