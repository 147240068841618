import React from 'react';
import {FeedbackMessages} from '../messages';
import {sessionAtom} from '../../../state/session';
import {useRecoilValue} from 'recoil';

const FinalStep = () => {
	const userSession = useRecoilValue(sessionAtom);
	if (!userSession) return null;

	return (
		<div className="text-16 text-semiBold flex-row-center align-center" data-testid='feedback-final-step'>
			{FeedbackMessages.FinalMessageStart}{userSession.givenName}{FeedbackMessages.FinalMessageEnd}
		</div>
	);
};

export default FinalStep;