import FourthStep from './steps/FourthStep';
import FirstStep from './steps/FirstStep';
import SecondStep from './steps/SecondStep';
import ThirdStep from './steps/ThirdStep';
import React from 'react';
import {FeedbackMessages} from './messages';
import FinalStep from './steps/FinalStep';

export const FeedbackConfig = [
	{
		component: () => <FirstStep/>,
		question: FeedbackMessages.FirstStepQuestion,
		sliderLabels: ['Very hard', 'Very easy'],
		valuesOption: ['1', '2', '3', '4', '5'],
	},
	{
		component: () => <SecondStep/>,
		question: FeedbackMessages.SecondStepQuestion,
		sliderLabels: ['Not likely at all', 'Extremely likely'],
		valuesOption: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
	},
	{
		component: () => <ThirdStep/>,
	},
	{
		component: () => <FourthStep/>,
		question: FeedbackMessages.FourthStepQuestion,
		valuesOption: [
			{label: 'Yes', value: 'Yes'},
			{label: 'No', value: 'No'}],
	},
	{
		component: () => <FinalStep/>,
	},
];