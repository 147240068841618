import {Strings} from '../../utils/strings';
import {UUID} from '../types';
import {ProductMapped} from './__ProductMapped';
import {getRecoil} from '../../state/recoilNexus';
import {references} from '../../state/state';
import {CMSFile} from './File';
import {Dates} from '../../utils/dates';

export class LibraryItem extends ProductMapped {
	public title: string;
	public description: string;
	public public_from?: Date;

	constructor(item: any = {}) {
		super(item);
		this.title = Strings.default(item.title);
		this.description = Strings.default(item.description);

		if (item.public_from) {
			this.public_from = new Date(item.public_from);
		}

	}

	public isPubliclyAvailable(): boolean {
		if (!this.public_from) {
			return false;
		}
		const now = Dates.getUTC(new Date());
		const itemDate = Dates.getUTC(this.public_from);
		return itemDate.getTime() < now.getTime();
	}
}

export class MediaItemFile extends LibraryItem {
	public file: UUID;

	constructor(item: any = {}) {
		super(item);
		this.file = Strings.default(item.file);

	}

	public fileInfo(): CMSFile | null {
		return getRecoil(references(this.file)) as any as CMSFile;
	}
}