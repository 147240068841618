import Button from '@mui/material/Button';
import ArrowCircleUp from '@mui/icons-material/ArrowCircleUp';
import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {scrollAppToTop} from '../../utils/helpers';

const BtnElem = styled(Button)<{ visible: string }>`
    position: fixed;
    bottom: 62px;
    right: 0;
    z-index: 100;
    border-radius: 6px 0 0 6px;
    border: 1px solid var(--color-border-light);
    border-right: 0;
    background-color: #fff;
    flex-direction: column;
    max-height: unset;
    padding: 4px;
    display: ${props => props.visible};

    &:hover {
        background-color: var(--color-hover)
    }

    svg {
        height: 28px;
        width: 28px;
    }

    span {
        font-family: var(--font-regular);
        font-size: 14px;
    }
`
const BackToTopButton = () => {
    const [visible, setVisible] = useState<string>('none')

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible('visible')
        } else if (scrolled <= 300) {
            setVisible('none')
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            toggleVisible()
        }, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <BtnElem data-testid="scroll-button" onClick={scrollAppToTop} visible={visible}>
            <ArrowCircleUp/>
            <span> Back to top </span>
        </BtnElem>
    );
}

export default BackToTopButton;