import React from 'react';
import {TrialMessages} from '../messages';
import {RegistrationLinks} from '../../Registration/utils';
import Button from '@mui/material/Button';
import {useSetRecoilState} from 'recoil';
import {trialSurveyStart, trialSurveyStep} from '../../../state/trial';
import styled from '@emotion/styled';


const SurveyIntroElem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        margin-bottom: 16px;
    }

    div {
        margin-bottom: 16px;
        text-align: center;
    }

    a {
        color: var(--color-blue);
        text-decoration: underline;
        cursor: pointer
    }
`;
const SurveyIntro = () => {
	const setStartSurveyAtom = useSetRecoilState(trialSurveyStart);
	const setSurveyStep = useSetRecoilState(trialSurveyStep);
	const takeSurvey = () => {
		setStartSurveyAtom(true);
		setSurveyStep((val) => val + 1);
	};

	return (
		<SurveyIntroElem data-testid='trial-survey-intro-wrapper'>
			<p className="text-16 text-regular">{TrialMessages.SurveyFirstParagraph}</p>
			<div className="text-16 text-regular">{TrialMessages.SurveySecondParagraph}</div>
			<p className="text-16 text-regular">{TrialMessages.SurveyThirdParagraph}
				<a href={RegistrationLinks.customerSupportCenter} target="_blank">{TrialMessages.SurveyCustomerLink}. </a>
			</p>
			<Button data-testid='take-survey-btn' size="small" color="primary" variant="contained" onClick={takeSurvey}>
				Take survey
			</Button>
		</SurveyIntroElem>
	);
};

export default SurveyIntro;