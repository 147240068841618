import React, {useMemo} from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import styled from '@emotion/styled';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {
	skippedQuestions,
	trialSurveyData,
	trialSurveyExtraStepData,
	trialSurveyStep,
	trialSurveyStepData,
	trialSurveySupport,
} from '../../../state/trial';
import {getRecoil} from '../../../state/recoilNexus';
import {SurveyConfig} from '../surveyConfig';
import {Objects} from '../../../utils/objects';
import {TrialMessages} from '../messages';
import Client from '../../../tw/client';
import {Browser} from '../../../utils/browser';
import {Strings} from '../../../utils/strings';
import GA from '../../../tw/models/GA';

const FooterElem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;


    hr {
        width: 100%;
    }

    .actions {
        padding: 16px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .flex-end {
        justify-content: flex-end !important;
    }
`;
const SurveyFooter = () => {
	const [surveyStep, setSurveyStep] = useRecoilState(trialSurveyStep);
	const [stepData, setStepData] = useRecoilState(trialSurveyStepData);
	const [extraData, setExtraStepData] = useRecoilState(trialSurveyExtraStepData);
	const [currentStoredData, storeData] = useRecoilState(trialSurveyData(surveyStep));
	const [skipped, skipStep] = useRecoilState(skippedQuestions);
	const setNeedSupport = useSetRecoilState(trialSurveySupport);
	const submitTrialFeedback = async () => {
		const atomKeys = [1, 2, 3, 4, 5, 6, 7, 8, 9];
		let payload: any[] = [];
		for (let i in atomKeys) {
			let idx = atomKeys[+i];
			let answer = getRecoil(trialSurveyData(idx));
			let extraQuestion: any = null;
			if (answer === 'skip' || !answer) continue;
			if (answer?.extra) {
				extraQuestion = {
					question: Objects.default(SurveyConfig[idx].extra).question,
					answer: answer.extra,
				};
				answer = answer.stepData;
			}
			let object = {
				question: SurveyConfig[idx].question,
				answer: answer,
			};
			//to know which final screen to display
			if (object.question === TrialMessages.SurveyQuestion9) {
				setNeedSupport(object.answer);
			}
			payload.push(object);
			if (extraQuestion) payload.push(extraQuestion);
		}

		await Client.addTrialFeedback(Strings.default(Browser.queryParam('code')), payload).then(() => {
			GA.TrialSurveyEvent();
		});
	};

	const skipQuestion = () => {
		storeData('skip');
		skipStep(Array.from(new Set([...getRecoil(skippedQuestions), surveyStep])));
		setSurveyStep((val) => val + 1);
		resetStoredData();
	};

	const resetStoredData = () => {
		setStepData(null);
		setExtraStepData(null);
	};
	const performAction = () => {
		if (extraData) {
			let data = currentStoredData?.stepData || ''; //data stored for main question
			if (stepData) {
				data = stepData;
			}
			storeData({stepData: data, extra: extraData});
		}
		if (stepData && !extraData) {
			storeData(stepData);
		}
		setSurveyStep((val) => val + 1);
		resetStoredData();
		if (isFinalStep) {
			submitTrialFeedback();
		}
	};

	const goBack = () => {
		setSurveyStep((val) => val - 1);
		resetStoredData();
	};
	const disableSkip = useMemo(() => {
		return Objects.default(SurveyConfig[surveyStep]).extra && stepData === 'Yes';
	}, [surveyStep, stepData]);

	const disableNext = useMemo(() => {
		//no data for current step
		const noData = !stepData && !currentStoredData;
		//main question answered yes but no extra question answer
		const withExtra = Objects.default(SurveyConfig[surveyStep]).extra && stepData === 'Yes' && !extraData;
		//question was previously skipped - when reaching question again disable next button
		const skippedQuestion = skipped.filter(s => s === surveyStep).length > 0;
		//question skipped and no user data
		const noDataAfterSkip = !stepData && currentStoredData && !Objects.default(SurveyConfig[surveyStep]).extra
		return noData || withExtra || skippedQuestion || noDataAfterSkip;
	}, [surveyStep, stepData, extraData, currentStoredData, skipped]);

	const isFinalStep = useMemo(() => {
		return surveyStep === SurveyConfig.length - 2;
	}, [surveyStep]);

	const nextText = useMemo(() => {
		return isFinalStep ? 'Submit' : 'Next';
	}, [isFinalStep]);

	return (
		<FooterElem data-testid='survey-footer'>
			<Divider/>
			<div className={`${isFinalStep ? 'flex-end' : ''} actions`}>
				{!isFinalStep &&
					<Button data-testid='survey-skip-btn' size="small" variant="text" color="primary" onClick={skipQuestion} disabled={disableSkip}>
						Skip
					</Button>}
				<div>
					{surveyStep > 1 && <Button data-testid='survey-back-btn' size="small" variant="text" color="primary" onClick={goBack}>
						Back
					</Button>}
					<Button data-testid='survey-next-btn' size="small" variant="contained" color="primary" onClick={performAction}
							disabled={disableNext}>
						{nextText}
					</Button>
				</div>
			</div>
		</FooterElem>
	);
};

export default SurveyFooter;