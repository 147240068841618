import {CMSObject} from '../../../../tw/models/__CMSObject';
import Button from '@mui/material/Button';
import React from 'react';
import {ReactComponent as PreviewIcon} from '../../../../assets/images/card/actions/view-icon.svg';

const ViewButton = ({item}: { item: CMSObject }) => {
	return (
		<Button data-testid='view-btn' color="primary" variant="text" size="large" disableRipple
				onClick={() => item.defaultAction().actionHandler()}>
			<PreviewIcon title="View"/>
		</Button>
	);
};

export default ViewButton;