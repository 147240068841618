type Sorter = (a: any, b: any) => number;

export const Lists = {
    default: <T>(value: any, def: T[] = []): T[] => {
        if (!value) {
            return [];
        }
        if (Array.isArray(value)) {
            return value;
        }
        if (value) {
            return [ value ];
        }
        return def;
    },

    sort: (list: any[], property: string | Sorter, reverse: boolean = false): any[] => {
        if (typeof property === 'function') {
            list.sort(property);
        } else {
            list.sort((a: any, b: any): number => {
                let aValue = a[property];
                let bValue = b[property];
                if ((typeof aValue).toLowerCase() === 'string') {
                    aValue = aValue.toLowerCase()
                }
                if ((typeof bValue).toLowerCase() === 'string') {
                    bValue = bValue.toLowerCase()
                }
                if (aValue < bValue) {
                    return -1;
                }
                if (aValue > bValue) {
                    return 1;
                }
                return 0;
            });
        }
        if (reverse) {
            list.reverse();
        }
        return list;
    },
};
