import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Group from '../../Card/Group';
import React, {useState} from 'react';
import styled from '@emotion/styled';
import {fi} from '../../../utils/helpers';
import ViewAllBtn from './ViewAllBtn';

const AccordionWrapper = styled(Accordion)`
    border: none;
    box-shadow: 2px 0 4px 0 var(--color-box-shadow);
    background-color: white;

    &.Mui-expanded {
        margin: 0;

        :not(:first-of-type) {
            border-top: 1px solid var(--color-grey);
        }
    }

    h3 {
        font-family: var(--font-bold);
        font-size: 17px;
        line-height: 22px;
        color: black;
    }
`;

const AccordionComponent = ({list, showViewAllButton}: {list: any, showViewAllButton: boolean}) => {
	const [expanded, setExpanded] = useState<number | false>(0);
	const [viewAll, setViewAll] = useState(!showViewAllButton);

	const handleChange = (panel: number) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};
	return (
		<>
			{list.map((l: any, idx: number) => (
				<AccordionWrapper key={l.title}
								  data-testid={'accordion-wrapper'}
								  expanded={expanded === idx}
								  onChange={handleChange(idx)}>
					<AccordionSummary id={`${idx}-header`} aria-controls={`${idx}-content`}
									  expandIcon={<ExpandMoreIcon />} data-testid='accordion-summary'>
						<h3>{l.title}</h3>
					</AccordionSummary>
					<AccordionDetails>
						<Group items={fi(viewAll, l.items, l.items.slice(0, 4))}
							   allItems={l.items}
							   label={l.title} itemsOriginalLength={l.items.length}/>
						<ViewAllBtn viewAll={() => setViewAll(!viewAll)}
									expanded={viewAll}
									nrOfItems={l.items.length}
						/>
					</AccordionDetails>
				</AccordionWrapper>

			))}
		</>
	);
};

export default AccordionComponent;