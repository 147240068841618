import {RegistrationMessages} from '../messages';
import {Button, Divider} from '@mui/material';
import React from 'react';

const TeacherMessage = ({goBack}: { goBack: () => void }) => {
	return (
		<div>
			<div className="pl-24 pr-24 pb-16">
				<p className="text-24 text-regular mb-16"> {RegistrationMessages.TrialTeacherHeader}</p>
				<p className="text-16">{RegistrationMessages.TrialTeacherLineOne}</p>
			</div>
			<Divider/>
			<div className="pt-8 pl-24 pr-24 flex-row-end">
				<Button color="primary" variant="text" size="small"
						onClick={goBack} data-testid="back-btn">Back</Button>
			</div>
		</div>
	);
};
export default TeacherMessage;