import * as yup from 'yup';
import {Messages} from '../../utils/messages';
import {useFormik} from 'formik';
import {Lists} from '../../utils/lists';
import {Objects} from '../../utils/objects';
import {Strings} from '../../utils/strings';
import {Button, Divider, useMediaQuery} from '@mui/material';
import RadioButtonsGroup from '../FormComponents/Radio/RadioButtonsGroup';
import SelectComponent from '../FormComponents/Select/SelectComponent';
import {productTreeSelector} from '../../state/product';
import {useRecoilState, useRecoilValue} from 'recoil';
import InputField from '../FormComponents/Input/InputField';
import {
	getQualificationDataSource,
	getSubjectDataSource,
	getUnitDataSource,
	hideTeachingSection,
	saveUserPreferences,
} from '../Registration/utils';
import {sessionAtom} from '../../state/session';
import {fi} from '../../utils/helpers';
import {CTUnit} from '../../tw/models/CTUnit';
import {ChildAssessment} from '../../tw/models/ChildAssessment';
import {Qualification} from '../../tw/models/Qualification';
import React, {Suspense, useEffect, useMemo} from 'react';
import {registrationDataAtom} from '../../pages/Registration';
import {getRecoil, setRecoil} from '../../state/recoilNexus';
import {references} from '../../state/state';
import {Subject} from '../../tw/models/Subject';
import {DisplayMode} from '../../tw/models/__CMSObject';
import {selectedSubjectAtom} from './utils';
import Loader from '../Loader/Loader';
import {AlertSeverity, snackbarStateAtom} from '../Snackbar/SnackbarComponent';
import {device} from '../../utils/constants';

enum FieldType {
	Input = 'text',
	Number = 'number',
	Radio = 'radio',
	SimpleList = 'simple_list',
	Checkbox = 'checkbox'
}

const getConfig = () => {
	let qualificationName = 'qualification';
	let subjectName = 'subject';
	let unitsName = 'units';
	let allUnitsName = 'allUnits';
	let studentsNrName = 'studentsNumber';
	let teaching = 'teaching';

	return [
		{
			name: qualificationName,
			label: 'Qualification',
			mandatory: true,
			multiple: false,
			type: FieldType.SimpleList,
			default: '',
			dataSource: (): any[] => {
				return [];
			},
			validations: yup
				.string()
				.nullable()
				.required(Messages.MandatoryField),
		},
		{
			name: subjectName,
			label: 'Subject',
			mandatory: true,
			multiple: false,
			type: FieldType.SimpleList,
			default: '',
			dataSource: (): any[] => {
				return [];
			},
			validations: yup
				.string()
				.nullable()
				.required(Messages.MandatoryField),
		},
		{
			name: unitsName,
			label: 'Units',
			mandatory: true,
			multiple: true,
			selectAllOption: true,
			type: FieldType.SimpleList,
			default: [],
			dataSource: (): any[] => {
				return [];
			},
			validations: yup
				.array()
				.nullable()
				.when('allUnits', {
					is: (val) => val === true,
					then: (schema) => schema.nullable(),
					otherwise: (schema) => schema.nullable()
						.min(1, Messages.MandatoryField)
						.required(Messages.MandatoryField),
				}),
		},
		{
			name: allUnitsName,
			label: '',
			type: FieldType.Checkbox,
			default: false,
			validations: yup.boolean(),
		},
		{
			name: studentsNrName,
			label: 'Please estimate how many students will certificate in this subject in this current academic year?',
			mandatory: false,
			type: FieldType.Number,
			default: 0,
			validations: yup
				.number()
				.integer('No. of students must be a whole number.')
				.nullable()
				.moreThan(-1, 'No. of students must be positive.'),
		},
		{
			name: teaching,
			label: 'Have you started teaching the course?',
			mandatory: false,
			type: FieldType.Radio,
			options: [
				{value: true, label: 'Yes'},
				{value: false, label: 'No'},
			],
			default: true,
			validations: yup
				.boolean(),
		}];
};

const showInfoSnackbar = () => {
	let showInfo = false;
	const productData = getRecoil(productTreeSelector);
	const selectedSubjectToEdit = getRecoil(selectedSubjectAtom);
	if (!productData) return;
	productData.forEach(p => {
		if (p.disabled) {
			showInfo = true;
		}
		showInfo = showInfo || p.getSubjects().filter(s => s.disabled).length !== 0;
	});

	if (showInfo && selectedSubjectToEdit !== null) {
		setRecoil(snackbarStateAtom, {
			message: `${Messages.QualificationNotAvailable}`,
			severity: AlertSeverity.Info,
		});
	} else {
		setRecoil(snackbarStateAtom, null);
	}
};
const SubjectEdit = () => {
	const [selectedSubject, setSelectedSubject] = useRecoilState(selectedSubjectAtom);
	const qualifications = useRecoilValue(productTreeSelector);
	const userSession = useRecoilValue(sessionAtom);
	const user = Objects.default(userSession);
	const userData = useRecoilValue(registrationDataAtom('qualifications'));
	const mobile = useMediaQuery(device.mobile);

	useEffect(() => {
		//close edit form
		return () => {
			setSelectedSubject(null);
		};
	}, []);
	useEffect(() => {
		showInfoSnackbar();
	}, []);

	const userPreferences = useMemo(() => {
		if (!userSession) return [];
		if (userSession.getQualifications().length && !userSession.shouldUpdatePreferences()) {
			return userSession.getQualifications();
		}
		return Lists.default(userData);
	}, [userData, userSession]);

	/** initial config for formik **/
	let config = {
		fields: getConfig(),
	};

	const emptyProperties = useMemo(() => {
		let fields: any[] = [];
		config.fields.forEach(field => {
			if (field.name) {
				if (Array.isArray(field.default)) {
					fields[field.name] = [...field.default];
				} else {
					fields[field.name] = field.default;
				}
			}
		});
		return fields;
	}, []);

	const validations = useMemo(() => {
		const obj = {};
		Lists.default<any>(Objects.default(config).fields).forEach(field => {
			if (!field.name || !field.validations) {
				return;
			}
			obj[field.name] = field.validations;
		});
		return obj;
	}, [config]);

	const formik = useFormik({
		initialValues: {...emptyProperties},
		validationSchema: yup.object(validations),
		onSubmit: async (values) => {
			await savePreferences(values);
		},
	});

	useEffect(() => {
		let q = Objects.default(selectedSubject);
		if (!Object.keys(q).length || q.qualification === '') return;
		formik.setValues({...getDefaultFormConfig()});
	}, []);

	if (!userSession) return null;
	const onChange = (obj, field) => {
		if (field.includes('teaching')) {
			formik.setFieldValue(field, obj === 'true');
		} else if (field.includes('units')) {
			formik.setFieldValue(field, obj);
		} else {
			let formikObj: any = {};
			if (obj === null || obj === undefined) {
				if (field.includes('qualification')) {
					formikObj = {
						...formik.values,
						qualification: null,
						units: [] as any,
						subject: '',
						allUnits: false,
						teaching: true,
						studentsNumber: 0,
					};
					formik.setValues(formikObj);
				} else if (field.includes('subject')) {
					formikObj = {
						...formik.values,
						subject: null,
						units: [],
						allUnits: false,
						teaching: true,
						studentsNumber: 0,
					};
					formik.setValues(formikObj);
				} else {
					formik.setFieldValue(field, null);
				}
			} else {
				if (field.includes('subject')) {
					formikObj = {
						...formik.values,
						allUnits: true,
						subject: obj.value,
						units: [],
					};
					let unitsList = mobile ? getUnitDataSource(formikObj, config.fields) : [];
					if (unitsList.length) {
						formikObj.units = unitsList;
					}
					formik.setValues(formikObj);
				} else if (field.includes('qualification')) {
					formikObj = {
						...formik.values,
						allUnits: false,
						subject: '',
						qualification: obj.value,
					};
					formik.setValues(formikObj);
				} else {
					formik.setFieldValue(field, obj.value);
				}
			}
		}
	};
	const onChangeSelectAll = (value, _field) => {
		formik.setFieldValue('allUnits', value);
		if (value) {
			if (mobile) {
				formik.setFieldValue('units', getUnitDataSource(formik.values, config.fields));
			} else {
				formik.setFieldValue('units', []);
			}
			formik.setFieldTouched('units', false);
		} else {
			formik.setFieldValue('units', []);
		}
	};

	const getValuesForSelectComponent = (field) => {
		if (field.name.includes('qualification') && !field.dataSource().length) {
			return getQualificationDataSource();
		}

		if (field.name.includes('subject') && !field.dataSource().length) {
			const userSubjectIds = userPreferences.map((u: any) => u.subject);
			if (selectedSubject?.subject) {
				const idxOf = userSubjectIds.indexOf(selectedSubject?.subject);
				userSubjectIds.splice(idxOf, 1);
			}
			return getSubjectDataSource(formik.values['qualification'], config.fields).filter(s => !userSubjectIds.includes(s.value));
		}

		if (field.name.includes('unit') && !field.dataSource().length) {
			return getUnitDataSource(formik.values, config.fields);
		}

		return field.dataSource(field);
	};

	const renderFormComponent = (field) => {
		if (field.type === FieldType.Input || field.type === FieldType.Number) {
			let help = '';
			let fieldLabel = field.label;
			if (field.name.startsWith('studentsNumber')) {
				let subjects: any = getSubjectDataSource(formik.values, config.fields);
				const subjectId = Strings.default(formik.values['subject']);
				const subject = subjects.find((s: any) => s.value === subjectId);
				if (Strings.default(Objects.default(subject).label).trim().endsWith('(2022+)')) {
					fieldLabel = 'Please estimate how many students will certificate in this subject in 2024 in your entire school?';
				}
				help = Messages.StudentsNumberNote;
			}
			return (
				<InputField
					dataTestId={field.name}
					ariaLabel={field.label}
					id={field.name}
					name={field.name}
					field={{...field, label: fieldLabel}}
					label={fieldLabel}
					type={field.type}
					value={formik.values[field.name]}
					onChange={formik.handleChange}
					required={field.mandatory}
					errorMessage={Strings.default(Objects.default(formik.errors[field.name]))}
					showError={Boolean(formik.errors[field.name])}
					touched={!!formik.touched[field.name]}
					min={0}
					helpText={help}
				/>

			);
		} else if (field.type === FieldType.Radio) {
			return (
				<RadioButtonsGroup field={field} onChange={onChange} value={Boolean(formik.values[field.name])}
								   styling="flex-row-start"/>
			);
		} else if (field.type === FieldType.SimpleList) {
			let values: any = getValuesForSelectComponent(field);
			return (
				<SelectComponent
					ariaLabel={field.label}
					id={field.name}
					label={field.label}
					name={field.name}
					value={formik.values[field.name]}
					onChange={onChange}
					checkbox={field.multiple}
					values={values}
					required={field.mandatory}
					render={(option) => {
						if (field.name.includes('units')) {
							return Lists.default(Objects.default(option).label).toString().split(' - ')[0];
						} else {
							return option.label;
						}
					}}
					isClearable={true}
					errorMessage={Strings.default(formik.errors[field.name])}
					showError={!!(formik.touched[field.name] && Boolean(formik.errors[field.name]))}
					showSelectAll={field.selectAllOption ? field.selectAllOption : false}
					onChangeSelectAll={onChangeSelectAll}
					selectAllValue={field.selectAllOption ? formik.values['allUnits'] : false}
				/>
			);
		}
	};

	/** populate formik based on user preferences **/
	const getDefaultFormConfig = () => {
		let data: any = {};
		let q = Objects.default(selectedSubject);
		const subject = getRecoil(references(q.subject)) as Subject;
		let units = subject.getUnits();

		const allUnits = units.length === Lists.default(q.units).length;
		data[`qualification`] = q.qualification;
		data[`subject`] = q.subject;
		data[`studentsNumber`] = q.class_size;
		data[`allUnits`] = allUnits;
		data[`teaching`] = q.teaching;
		data[`units`] = fi(allUnits, [],
			Lists.default<string>(q.units)
				.map(u => units.find(item => item.getId() === u))
				.filter(u => u)
				.map((i: any) => ({
					label: i.displayLabel(DisplayMode.FULL),
					value: i.getId(),
				})));

		return data;
	};

	/** save preferences **/
	const buildNewObject = (values) => {
		const qualification = Lists.default<Qualification>(qualifications).find(q => q.getId() === values.qualification);
		let qualificationObj = Objects.default(qualification);
		const subject = qualificationObj.getSubjects().find(s => s.getId() === values.subject);
		let newObject: any = {
			qualification: values.qualification,
			subject: values.subject,
			class_size: fi(values.studentsNumber !== '' && values.studentsNumber, values.studentsNumber, 0),
		};
		if (Lists.default(subject.specificationGroup).length > 0) {
			newObject.specification_group = Lists.default<any>(subject.specificationGroup).map(s => typeof s === 'string' ? s : s.getId());
		}
		if (values.allUnits) {
			if (qualificationObj.isForTechnicals) {
				newObject.units = Lists.default<CTUnit>(subject.getUnits()).map(u => u.getId());
			} else {
				newObject.units = Lists.default<ChildAssessment>(subject.getUnits()).map(u => u.getId());
			}
		} else {
			newObject.units = values.units.map(u => u.value);
		}
		if (Lists.default(subject.assessmentIDs).length) {
			newObject.assessments = subject.assessmentIDs;
		}
		if (qualificationObj.technicals) {
			newObject.assessments = Lists.default(subject.qualificationSizeIDs);
		}
		if (user.isTrial()) {
			newObject.teaching = false;
		} else {
			newObject.teaching = fi(values.teaching !== '' && values.teaching, values.teaching, false);
		}
		newObject.technicals = qualificationObj.isForTechnicals;
		return newObject;
	};
	const removeUnnecessaryAttributes = (array) => {
		if (userSession.isFirstTimeUser()) return Lists.default<any>(array);
		return Lists.default<any>(array).map(({created, qualificationid, __data, ...item}) => item);
	};

	const updateSubjects = (values, subj) => {
		let newPrefs: any[] = [];
		let newObj = buildNewObject(values);
		const objectAlreadyExists = userPreferences.find((p: any) => p.subject === newObj.subject) as any;
		userPreferences.forEach((p: any) => {
			if (p.subject === subj.subject) {
				if (!objectAlreadyExists) {
					newPrefs.push(buildNewObject(values));
				}
			} else {
				newPrefs.push(p);
			}
		});
		if (objectAlreadyExists) {
			//remove existing object from array and replace with new values
			newPrefs = newPrefs.filter(p => p.subject !== objectAlreadyExists.subject);
			newPrefs.push(newObj);
		}
		return newPrefs;
	};

	const savePreferences = async (values) => {
		let newPreferences: any[];
		let duplicate = userPreferences.find((p: any) => p.subject === values.subject);
		if (selectedSubject?.qualification) {
			//edit mode - update in userPreferences
			let tmp = updateSubjects(values, selectedSubject);
			newPreferences = removeUnnecessaryAttributes(tmp);
		} else {
			//check for duplicates
			if (duplicate) {
				setRecoil(snackbarStateAtom, {
					message: `${Messages.DuplicateSubject}`,
					severity: AlertSeverity.Error,
				});
				return;
			} else {
				newPreferences = [...removeUnnecessaryAttributes(userPreferences), buildNewObject(values)];
			}
		}
		setRecoil(registrationDataAtom('qualifications'), newPreferences);
		if (!user.isFirstTimeUser() && !user.shouldUpdatePreferences()) {
			await saveUserPreferences();
		} else {
			cancelEditMode();
		}

	};

	const isSubjectModular = () => {
		if (formik.values[config.fields[1].name]) {
			let subj = getRecoil(references(formik.values[config.fields[1].name])) as Subject;
			if (subj) return subj.isModular();
		}
		return false;
	};

	const cancelEditMode = () => {
		setSelectedSubject(null);
	};

	return (
		<Suspense fallback={<Loader/>}>
			<form
				autoComplete="off"
				onSubmit={(e: any) => {
					e.preventDefault();
					formik.handleSubmit();
					formik.validateForm().then(resp => {
						if (!Object.keys(resp).length) return;
					});
				}}
				noValidate
				data-testid="subject-form"
				className="mt-16"
			>
				<div className="mb-16 flex-column-center">
					{/*Qualification*/}
					{renderFormComponent(config.fields[0])}

					{/*Subject*/}
					{renderFormComponent(config.fields[1])}

					{/*Units or components*/}
					{isSubjectModular() && renderFormComponent(config.fields[2])}

					{fi(!hideTeachingSection(),
						<>
							{/*Started teaching the course*/}
							{renderFormComponent(config.fields[5])}

							{/*Number of students*/}
							{renderFormComponent(config.fields[4])}
						</>,
					)}
					<div className="mb-16 mt-16">
						<div className="flex-row-end">
							<Button color="primary" variant="text" size="small"
									onClick={cancelEditMode}
									data-testid="cancel-button"
									disabled={Lists.default(userPreferences).length === 0}>Cancel</Button>
							<Button color="primary" variant="outlined" size="small"
									type="submit" data-testid="save-button">Save</Button>
						</div>
					</div>
					{fi(selectedSubject && selectedSubject.qualification === '', <Divider/>)}
				</div>
			</form>
		</Suspense>
	);
};

export default SubjectEdit;