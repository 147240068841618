import {DefaultAction, Types} from '../../tw/types';
import {WidgetContextType} from '../widgets/widget';
import {useRecoilState, useRecoilValue} from 'recoil';
import {selectedItems} from '../widgets/helpers';
import {SearchFilterAtom} from '../widgets/ResourceFinder/utils';
import {cacheBuster} from '../../state/state';
import React, {useEffect, useMemo} from 'react';
import {WidgetType} from '../../tw/models/Page';
import {Lists} from '../../utils/lists';
import {fi, highlightSearchTerm} from '../../utils/helpers';
import {EventsAir} from '../../tw/models/EventsAir';
import {Dates} from '../../utils/dates';
import {CMSObject} from '../../tw/models/__CMSObject';
import Footer from './Footer';
import {disableButton} from './CardActions/Buttons/utils';
import {Messages} from '../../utils/messages';
import Flag from './Flag';
import {Description, Icon, Title} from './Card';
import styled from '@emotion/styled';
import Checkbox from '@mui/material/Checkbox';
import {device} from '../../utils/constants';

const Container = styled.div`
    padding: 16px;
    display: grid;
    position: relative;
    width: 49%;
    min-width: 415px;

    border: 1px solid;
    border-color: var(--color-grey);
    border-radius: 6px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .04);
    background-color: white;

    grid-template-areas: "icon info"
                       "footer footer";
    grid-template-columns: auto 1fr;
    grid-template-rows: 134px 40px;

    div.footer {
        border-top: 1px solid var(--color-grey);
    }

    &.skinny {
        grid-template-areas: "icon info footer";
        grid-template-columns: 0 1fr auto;
        grid-template-rows: 28px;
        width: 100%;
        align-items: center;
		
		.checkbox {
			h5 {
				margin-left: 30px;
			}
		}
        div.flag {
            top: 0;
            margin-left: 16px;
            right: unset;
        }

        div.footer {
            border-top: none;
            padding: 0 16px 0 0;

            & > div > div {
                height: 60px;
            }

            > div:last-of-type {
                padding-left: 16px;
            }

            .no-player {
                width: 0;
                height: 0;
                visibility: hidden;
            }
        }

        .icon {
            overflow: hidden;
        }

        .title {
            justify-content: center;
            padding-top: 0;

            p {
                display: none;
            }
        }
    }

    &.selected {
        border: 2px solid var(--color-lighter-blue-border);
    }

    .highlighted-word {
        background-color: #cce3f0;
        padding: 0 1px;
    }

    @media ${device.tablet} {
        width: 100%;
        min-width: 0;
		
		&.skinny{
			div.footer{
				.user-actions {
					div {
						width: 25px;
					}
				}
			}
		}
    }
`;

const PriceElem = styled.span`
    color: var(--color-green)
`;

const EventDescriptionElem = styled.div`
    font-size: 14px;
    color: var(--color-monochrome);
    font-family: var(--font-regular);
`;

const PastEventInfoElem = styled.div`
    span {
        color: var(--color-light-violet)
    }
`;

const StyledCheckbox = styled(Checkbox)`
    position: absolute;
    top: 16px;
    right: 16px;

    &.right-align {
        left: 5px;
        top: 10px;
        right: unset;
    }
`;


const Updated = styled.p`
    font-size: 14px;
    line-height: 8px;
    margin: 16px 0;
    color: var(--color-backdrop);
`;

const ItemCard = ({item, defaultAction, widgetContext, showCheckbox, style, inline}: {
	item: any,
	defaultAction: DefaultAction,
	widgetContext: WidgetContextType,
	showCheckbox: boolean,
	style?: any,
	inline?: boolean
}) => {
	const [selectedCards, setSelectedCards] = useRecoilState(selectedItems);
	const searchFilter = useRecoilValue(SearchFilterAtom);
	const forceResetCardState = useRecoilValue(cacheBuster('resetSelectCardState'));

	const hasPathwayWidget = useMemo(() => {
		return widgetContext.filteredWidgets.findIndex(widget => widget.type === WidgetType.SizePathway) !== -1;
	}, [widgetContext]);

	const isSkinnyCard = useMemo(() => {
		const config = Lists.default<any>(widgetContext.page.config);
		if (config.length) {
			return config.findIndex(c => c.type === WidgetType.FilterContent) !== -1;
		}
		return false;
	}, [widgetContext]);

	useEffect(() => {
		if (!showCheckbox) {
			setSelectedCards([]);
		}
	}, [forceResetCardState, showCheckbox]);

	const renderDescription = () => {
		if (item.matcher && item.matcher.matchesNumber > 0) {
			return (
				<span
					dangerouslySetInnerHTML={{__html: highlightSearchTerm(item.matcher.matched, searchFilter.query)}} />);
		}
		if (searchFilter?.query) {
			return (
				<span dangerouslySetInnerHTML={{__html: highlightSearchTerm(item.description, searchFilter.query)}} />);
		}
		return item.description;
	};

	const renderTitle = () => {
		if (searchFilter?.query) {
			return (<span dangerouslySetInnerHTML={{__html: highlightSearchTerm(item.title, searchFilter.query)}} />);
		}
		return item.title;
	};

	const UpdatedInfo = useMemo(() => {
		if (!item) {
			return null;
		}
		if (item instanceof EventsAir) {
			if (item.isPastEvent()) return null;
			return <Updated> {Dates.format(item.startDate, true)}</Updated>;
		}

		if (item.getType() === Types.LINK && item.isOnlineCourse) {
			return null;
		}

		return <Updated>{item.getStatus()} - {Dates.format(item.getPublishedDate())}</Updated>;
	}, [item]);

	const FooterElem = useMemo(() => {
		return fi((item instanceof CMSObject), <Footer item={item} />);
	}, [item]);

	const DescriptionElem = useMemo(() => {
		if (item instanceof EventsAir) {
			return (
				<EventDescriptionElem>
					<div> {item.format} {fi(!item.isPastEvent(), <PriceElem>{item.getPrice()}</PriceElem>)}</div>
					{fi(item.isPastEvent(),
						<>
							<br />
							<PastEventInfoElem>
								Originally hosted - <span>{Dates.format(item.startDate, false)}</span>
							</PastEventInfoElem>
						</>,
					)}
				</EventDescriptionElem>
			);
		}
		return <Description>{renderDescription()}</Description>;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item, searchFilter.query]);

	const getClasses = useMemo(() => {
		let classes = '';
		if (isSkinnyCard) {
			classes += 'skinny';
		}
		if (item instanceof CMSObject && item.downloadable() && selectedCards.includes(item.getId())) {
			classes += ' selected';
		}
		return classes;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCards, item]);

	const onSelectCard = (evt) => {
		if (!selectedCards.includes(evt.currentTarget.value)) {
			setSelectedCards([...selectedCards, evt.currentTarget.value]);
		} else {
			setSelectedCards([...selectedCards.filter(id => id !== evt.currentTarget.value)]);
		}
	};

	const showCheckboxSelector = () => {
		return showCheckbox && item instanceof CMSObject && item.downloadable() && !disableButton(item);
	};

	const renderCard = () => {
		if (inline) {
			return (
				<div data-testid='inline-card'>
					<Icon>{item.getIcon(true)}</Icon>
					<Title onClick={defaultAction.actionHandler}>{renderTitle()}</Title>
				</div>
			);
		}
		return (
			<Container style={style} className={getClasses} data-cardid={item.getId()}>
				<Icon className='icon'>
					{showCheckboxSelector() &&
						<StyledCheckbox disableRipple size='small' value={item.getId()}
										className={fi(isSkinnyCard, 'right-align', '')}
										checked={Lists.default(selectedCards).includes(item.getId())}
										onChange={onSelectCard} />
					}
					{item.getIcon()}
				</Icon>
				<Title className={'title ' + fi(showCheckboxSelector(), 'checkbox', '')}>
					<h5 title={fi(disableButton(item), Messages.FeatureNotAvailable, defaultAction.label)}
						onClick={defaultAction.actionHandler}>
						{renderTitle()}
						{hasPathwayWidget && item instanceof CMSObject && item.isTechnicals() &&
							<span className='extra-label'>{item.getExtraLabel()}</span>}
					</h5>
					{UpdatedInfo}
					{DescriptionElem}
				</Title>
				<Flag key={item.getId()} item={item} />
				{FooterElem}
			</Container>
		);
	};

	return (renderCard());
};

export default ItemCard