import {Lists} from '../../utils/lists';
import {Strings} from '../../utils/strings';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import styled from '@emotion/styled';
import {device} from '../../utils/constants';

const RadioGroupElem = styled(RadioGroup)`
    position: relative;
    padding-bottom: 50px;

    #left {
        position: absolute;
        top: 50px;
        left: 0;
		font-size: 12px;
    }

    #right {
        position: absolute;
        top: 50px;
        right: 0;
        font-size: 12px;
    }
`;
const FormControlLabelElem = styled(FormControlLabel)`
    align-items: center;
    background-color: var(--color-lighter-blue-background);
    border: 1px solid var(--color-blue);
    border-radius: 100px;
    display: flex;
    font-family: var(--font-semi-bold);
    font-size: 16px;
    height: 32px;
    justify-content: center;
    line-height: 22px;
    margin: 0 20px !important;
    padding: 0;
    text-align: center;
    transition: all .15s;
    width: 32px;

    .MuiSvgIcon-root {
        display: none;
    }

    .MuiFormControlLabel-label {
        user-select: none;
        transition: all 0.15s;
        color: var(--color-blue);
        z-index: 1;
        font-size: 16px;
        font-family: var(--font-semi-bold);
        margin-bottom: 2px;
    }

    &.checked {
        background-color: #0074bd;

        .MuiFormControlLabel-label {
            color: white;
        }
    }

    @media ${device.tablet} {
        margin: 0 5px !important;
        width: 25px;
        height: 25px;
    }
`;
const CustomRadioElem = styled(Radio)`
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    visibility: hidden;
`;

const FeedbackRadioGroup = ({value, onChange, options, labels, formName}: {
	value: string | any, onChange: (...params) => void,
	options: any[], labels: string[], formName: string
}) => {

	return (
		<RadioGroupElem aria-label={formName} name={formName}
						value={value}
						onChange={onChange}>
			{Lists.default<any>(options).map((mark, index) => (
				<FormControlLabelElem data-testid={`${mark}-test-id`} key={mark} value={mark}
									  control={<CustomRadioElem
										  disableRipple
										  disableFocusRipple
										  disableTouchRipple
										  color="primary"
									  />
									  }
									  className={`${Strings.default(value) === mark ? 'checked' : ''}`}
									  label={mark}/>
			))}
			{labels.length > 0 ?
				<>
					<span id="left">{Lists.default<string>(labels)[0]}</span>
					<span id="right">{Lists.default<string>(labels)[1]}</span>
				</> : null}
		</RadioGroupElem>
	);
};

export default FeedbackRadioGroup;