import {useMediaQuery} from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import styled from '@emotion/styled';
import React from 'react';
import TreeComponent from '../components/TreeMenu/TreeComponent';
import {fi} from '../utils/helpers';
import {atom, useRecoilState} from 'recoil';
import {device} from '../utils/constants';
import ClearIcon from '@mui/icons-material/Clear';

const MenuElem = styled.div`
    grid-area: nav;
    background-color: var(--color-white);
    border-right: 1px solid var(--color-border-light);
    height: 100%;
    position: relative;
`;

const DrawerElem = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    top: 0;
    overflow-y: auto;

    .MuiPaper-root {
        position: unset;
        border: unset;

        @media ${device.tablet} {
            width: 60vw !important;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            padding: 24px 0;
        }
    }
`;

const CustomDrawer = styled(Drawer)`
    @media ${device.tablet} {
        .MuiDrawer-paper {
            width: 90%;
        }
    }
`;

export const mobileMenuState = atom<boolean>({
	key: 'mobileMenuOpenAtom',
	default: false,
});
const Menu = () => {
	const [mobileMenuIsOpen, setMobileState] = useRecoilState(mobileMenuState);
	const mobileOrTablet = useMediaQuery(device.tablet);

	return (
		<MenuElem data-testid="submenu">
			<DrawerElem data-testid="drawer-elem">
				<CustomDrawer data-testid="drawer" anchor={'left'}
							  variant={fi(mobileOrTablet, 'temporary', 'permanent')}
							  open={mobileMenuIsOpen}>
					{fi(mobileOrTablet,
						<div className="pb-16">
							<div className="flex-row-start text-16 text-semiBold pall-16">
								<IconButton data-testid="close-menu" size="small" onClick={() => setMobileState(false)}>
									<ClearIcon fontSize="small"/>
								</IconButton>
								<span className="pl-8">Teach Cambridge</span>
							</div>
							<Divider/>
						</div>)}
					<TreeComponent/>
				</CustomDrawer>
			</DrawerElem>
		</MenuElem>
	);
};

export default Menu;