import React from 'react';
import RadioStep from './RadioStep';

// first step in feedback form
const FirstStep = () => {
	return (
		<RadioStep/>
	);
};

export default FirstStep;