import Button from '@mui/material/Button';
import CheckboxComp from '@mui/material/Checkbox';
import React, {useMemo, useState} from 'react';
import {Download} from '@mui/icons-material';
import {fi, suspensePromise} from '../../../../utils/helpers';
import styled from '@emotion/styled';
import {Objects} from '../../../../utils/objects';
import GA from '../../../../tw/models/GA';
import Client from '../../../../tw/client';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {SubjectSelector} from '../../../../state/product';
import {AlertSeverity, snackbarStateAtom} from '../../../Snackbar/SnackbarComponent';
import {DocumentsWrapper} from '../../../../tw/models/DocumentsWrapper';

const DwdButtonElem = styled(Button)`
    padding: 0 !important;
`;

const DownloadResources = ({selectAll, fileSize, checkbox, indeterminate, selected, dwdItems, reset}: {
	selectAll: (...params) => void,
	fileSize: string | 0,
	checkbox: boolean,
	indeterminate: boolean,
	selected: string[],
	dwdItems: DocumentsWrapper,
	reset: () => void
}) => {

	const selectedSubject = useRecoilValue(SubjectSelector);
	const [promise, setPromise] = useState<any>(null);
	const setSnackbarState = useSetRecoilState(snackbarStateAtom);
	const handleDownload = async () => {
		const fileName = Objects.default(selectedSubject).displayLabel();
		GA.ItemActionEvent(fi(checkbox, 'Download All', 'Download selected items'), {event_label: fileName});

		let toDwd = dwdItems.filter(i => selected.includes(i.getId()));

		const pr = Client.downloadZip(selected, fileName).then(() => {
			toDwd.forEach(i => i.resetFlag());
		});
		setPromise(suspensePromise(pr));
	};

	if (promise) {
		promise?.read();
		setPromise(null);
		reset();
		setSnackbarState({
			message: 'Item/s successfully downloaded',
			severity: AlertSeverity.Success,
		});
	}

	const isDisabled = useMemo(() => {
		return !dwdItems.length;
	}, [dwdItems]);

	return (
		<div title={fi(isDisabled, 'Select at least 1 downloadable item', '')} data-testid="download-resources">
			<CheckboxComp
				aria-label={'select-items'}
				id={'select-items'}
				name={'select-items'}
				checked={checkbox}
				value={checkbox}
				onChange={(event) => {
					selectAll(event);
				}}
				color={'primary'}
				size="medium"
				disableRipple
				indeterminate={indeterminate}
				disabled={isDisabled}
			/>

			<DwdButtonElem variant="text" onClick={handleDownload} disabled={!selected.length}
						   endIcon={<Download/>} color="secondary"
						   title={`Download ${selected.length} files as ZIP (${fileSize})`}
						   data-testid="download-resources-btn">
				{fi(selected.length, `Download items (${selected.length})`, 'Download items')}
			</DwdButtonElem>
		</div>
	);
};

export default DownloadResources;