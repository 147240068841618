import React from "react";
import Divider from "@mui/material/Divider";
import styled from "@emotion/styled";
import LowerSection from '../components/LowerSection/LowerSection';

const IPNoticeElem = styled.div`
  grid-area: pageContent;
  background-color: var(--color-background);
  margin-bottom: 24px;
  padding: 32px;
`
const IPNoticePage = () => {
    return (
        <IPNoticeElem data-testid="ip-notice-page">
            <div className="text-32 text-latoBold mb-24">
                IP notice: teaching resources
            </div>
            <div className="text-16 text-regular mt-8">
                <div>
                    We are the owner or licensee of all intellectual property rights in our Teach Cambridge site, and in
                    the
                    material published on it. Those works are protected by copyright laws and treaties around the world.
                    All
                    such
                    rights are reserved.
                </div>
                <br/>
                <div>
                    You may print off or download copies of the teaching resources posted on the site for use within
                    your
                    centre for
                    non-commercial, educational purposes. This material is intended for use by teaching staff.
                </div>

                <br/>
                <div>
                    The teaching resources posted on the site must not be used in any way other than for non-commercial,
                    educational
                    purposes, without the express written consent of OCR.
                </div>

                <br/>
                <div className="mb-24">
                    Our status (and that of any identified contributors) as the authors of any content on our site must
                    always be
                    acknowledged (except where the content is user-generated).
                </div>

            </div>
            <Divider/>
            <div className="mt-24">
                <LowerSection/>
            </div>
        </IPNoticeElem>
    )
}
export default IPNoticePage;