import {hasRestrictedAccess} from '../../../../state/session';
import {getRecoil} from '../../../../state/recoilNexus';

export const disableButton = (item) => {
	const isUserWithRestrictedAccess = getRecoil(hasRestrictedAccess);
	//item not publicly available
	const notAvailable = isUserWithRestrictedAccess && !item.isPubliclyAvailable();
	//restricted users are not allowed to access online courses
	const onlineCourseRestrict = isUserWithRestrictedAccess && item.getType() === 'link' && item.isOnlineCourse;
	return notAvailable || onlineCourseRestrict || false;
};