import React, { useEffect, useMemo, useRef, useState } from 'react';
import { fi } from '../../../utils/helpers';
import { Objects } from '../../../utils/objects';
import { Lists } from '../../../utils/lists';
import { references } from '../../../state/state';
import { getRecoil, setRecoil } from '../../../state/recoilNexus';
import { UUID } from '../../../tw/types';
import Client from '../../../tw/client';
import { createRoot } from 'react-dom/client';
import Card from '../../Card/Card';
import { RecoilBridge, useRecoilBridgeAcrossReactRoots_UNSTABLE, useRecoilValue } from 'recoil';
import { searchForLink } from './utils';
import { Strings } from '../../../utils/strings';
import { SubjectUpdate } from '../../../tw/models/SubjectUpdate';
import { Link } from '../../../tw/models/Link';
import { Notification } from '../../../tw/models/Notification';
import { Text } from '../../../tw/models/Text';
import { CMSObject } from '../../../tw/models/__CMSObject';
import { MathJaxHtml, MathJaxProvider } from 'mathjax3-react';
import { EventMaterial } from '../../../tw/models/EventMaterial';

type WysiwygType = SubjectUpdate | Link | Notification | Text;

const WrapperCard =  ({item,inline}:{item?:CMSObject,inline:boolean})=>{
	const localRef = useRecoilValue(references(item!.getId()));
	return (
		<Card item={localRef||item} showCheckbox={false} inline={inline} />
	)
}

// renders wysiwyg field
const Wysiwyg = ({item, fieldId, id}: {item: WysiwygType, fieldId: string, id: string}) => {
		const [itemIds, setItemIds] = useState<UUID[]>([]);
		const ref = useRef(null);
		const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();

		const text = useMemo<string>(() => {
			if (!item || !fieldId) {
				return '';
			}
			return Strings.default(item[fieldId]);
		}, [item, fieldId]);

		const hasMath = useMemo(() => {
			return text.includes('</math>');
		}, [text]);

		const handleClick = () => {
			return
		};

		useEffect(() => {
			if (!ref || !ref.current) {
				return;
			}
			checkContent();
		}, [ref]);

		useEffect(() => {
			if (!itemIds.length || !ref) {
				return;
			}
			const elem = ref.current as any;
			const cards = [...Array.from<any>(elem.querySelectorAll('card')), ...Array.from<any>(elem.querySelectorAll('inline'))];
			renderCard(cards);
		}, [ref, itemIds]);

		const renderCard = (cards) => {
			cards.forEach(cardElem => {
				const cardId = cardElem.getAttribute('data-uid');
				let cardItem = getRecoil(references(cardId));
				let inline: boolean = cardElem.tagName.toLowerCase() === 'inline';


				if (!cardItem) {
					return
				}
				if (cardItem instanceof EventMaterial){
					cardItem = cardItem.getObject()
					setRecoil(references(cardItem.getId()), cardItem)
				}
				const root = createRoot(cardElem!);
				root.render(
					<RecoilBridge>
							<WrapperCard item={cardItem!} inline={inline} />
					</RecoilBridge>,
				);
			});
		};

		const checkContent = () => {
			if (!Objects.default(Objects.default(ref).current).innerHTML) {
				setTimeout(checkContent, 500);
			} else {
				searchForLink(id, item);
				fetchItems().catch();
			}
		};

		const fetchItems = async () => {
			const elem = ref.current as any;
			const cards = [...Array.from<any>(elem.querySelectorAll('card')), ...Array.from<any>(elem.querySelectorAll('inline'))];
			const ids = Lists.default<UUID>(Array.from(new Set(cards.map(c => c.dataset.uid))));

			let missingItemIds: UUID[] = [];
			ids.forEach(idd => {
				const element = getRecoil(references(idd));
				if (!element && missingItemIds.indexOf(idd) === -1) {
					missingItemIds.push(idd);
				}
			});
			if (missingItemIds.length > 0) {
				// fetch missing items
				await Client.getDocumentsByUUIDS(missingItemIds)
			}
			setItemIds(Lists.default<UUID>(Array.from(cards.map(c => c.dataset.uid))));
		};

		return (
			<div data-testid='wysiwyg' id={id} onClick={handleClick} className='wysiwyig-content'>
				{fi(hasMath,
					<MathJaxProvider>
						<div data-testid='math-text' ref={ref}>
							<MathJaxHtml html={text} />
						</div>
					</MathJaxProvider>,
					<div data-testid='text' ref={ref} dangerouslySetInnerHTML={{__html: fi(!hasMath, text)}}></div>,
				)}
			</div>
		);
	}
;

export default Wysiwyg;