import React from 'react';
import InputField from '../../../FormComponents/Input/InputField';
import {Strings} from '../../../../utils/strings';
import FormGroup from '@mui/material/FormGroup';

const CentreInfo = ({formik, onChange}: { formik: any, onChange: (...props) => void }) => {
	return (
		<div className="mt-16" data-testid="centreInfo-section">
			<InputField
				required={true}
				id={'centre_address'}
				value={formik.values.centre_address}
				onChange={(event) => onChange(event.target.value, 'centre_address')}
				showError={Boolean(formik.touched.centre_address) && Boolean(formik.errors.centre_address)}
				errorMessage={Strings.default(formik.errors.centre_address)}
				touched={Strings.default(!!formik.touched.centre_address)}
				field={{name: 'centre_address', label: 'Centre Address'}}
			/>
			<FormGroup>
				<InputField
					value={formik.values.centre_name}
					onChange={(event) => onChange(event.target.value, 'centre_name')}
					showError={Boolean(formik.touched.centre_name) && Boolean(formik.errors.centre_name)}
					errorMessage={Strings.default(formik.errors.centre_name)}
					touched={Strings.default(!!formik.touched.centre_name)}
					disabled={true}
					field={{name: 'centre_name', label: 'Centre name'}}
				/>
				<InputField
					value={formik.values.centre_number}
					onChange={(event) => onChange(event.target.value, 'centre_number')}
					showError={Boolean(formik.touched.centre_number) && Boolean(formik.errors.centre_number)}
					errorMessage={Strings.default(formik.errors.centre_number)}
					touched={Strings.default(!!formik.touched.centre_number)}
					disabled={true}
					field={{name: 'centre_number', label: 'Centre number'}}
				/>
			</FormGroup>
		</div>
	);
};

export default CentreInfo;