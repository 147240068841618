import React, { useEffect, useMemo } from 'react';
import Group from '../../Card/Group';
import { documentSelector } from '../../../state/documents';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from '@emotion/styled';
import { homepageDetailsAtom } from './HomepageResources';
import { Page } from '../../../tw/models/Page';
import { findTreeNode, ITreeItem } from '../../TreeMenu/utils';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { Objects } from '../../../utils/objects';
import { getTabContent } from './utils';
import { fi } from '../../../utils/helpers';
import { enableSubjectFilter, UnitSelector } from '../../../state/product';
import LowerSection from '../../LowerSection/LowerSection';
import { useMenu } from '../../../state/menu';

const WrapperElem = styled.div`
    grid-area: pageContent;
    padding: 16px;
    background-color: var(--color-background);
`;
const HomepageDetailsPage = () => {
	const documents = useRecoilValue(documentSelector);
	const setEnableSubjectFilter = useSetRecoilState(enableSubjectFilter);
	const [homepageDetails, setHomepageDetails] = useRecoilState(homepageDetailsAtom);
	const {setPage, state: {subTree}} = useMenu();
	const unit = useRecoilValue(UnitSelector);

	useEffect(() => {
		const locationPath = document.location.pathname;
		let tab = fi(locationPath.includes('/keyDocs'), 0, fi(locationPath.includes('/newAndUpdated'), 1, 2));
		if (!homepageDetails && tab > -1) {
			const details = getTabContent(tab);
			if (!details) return;
			setHomepageDetails({title: details.title, items: details.items, dwdFileName: details.dwdFileName});
		}
	}, []);

	const items = useMemo(() => {
		if (!homepageDetails) return null;
		const unitIds = unit.map(u => u.value)
		return homepageDetails.items().byUnitList(unitIds);
	}, [homepageDetails, documents, unit]);

	useEffect(() => {
		if (!homepageDetails) return;
		let homepage = subTree.find(p => p.object.landingPage());
		if (!homepage) return;
		let selectedPageObject: ITreeItem = {
			object: new Page({
				title: homepageDetails.title,
				description: '',
				isHomePage: false,
				config: [],
				order: -1,
				parent: null,
			}),
			index: -1,
			id: homepageDetails.title,
			order: -1,
			children: [],
			parent: homepage,
		};
		setPage(selectedPageObject);
		return () => {
			const node = findTreeNode(subTree, (i) => i.id === Objects.default(homepage).id);
			setPage(node);
		};
	}, [homepageDetails, subTree]);

	useEffect(() => {
		setEnableSubjectFilter(true);
		return () => {
			setEnableSubjectFilter(false);
		};
	}, []);

	if (!homepageDetails) return null;

	return (
		<WrapperElem data-testid='homepage-details'>
			<Breadcrumbs/>
			<div className="mb-24 text-32 text-latoBold">{homepageDetails.title}</div>
			<Group items={items} label={homepageDetails.dwdFileName}/>
			<div className="mt-56">
				<LowerSection/>
			</div>
		</WrapperElem>
	);
};

export default HomepageDetailsPage;