import React, {useContext, useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {examDateSelector} from '../../../state/keyDates';
import Card from '../KeyDates/Card';
import styled from '@emotion/styled';
import WidgetHeader from '../commons/WidgetHeader';
import {WidgetContext} from '../widget';
import ViewAllBtn from '../commons/ViewAllBtn';
import {viewAll} from './utils';
import {fi} from '../../../utils/helpers';
import NoContentComponent from './NoContentComponent';
import {HomepageMessages} from './messages';
import {shouldHideButton} from './Homepage';
import {Lists} from '../../../utils/lists';

const Group = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

const NoContentElem = styled.div`
    background-color: var(--color-white);
    border: solid 1px var(--color-border-light);
    border-radius: 6px;
`;

const HomepageKeyDates = () => {
	const context = useContext(WidgetContext);
	const keyDates = useRecoilValue(examDateSelector);
	// display first key dates that are in the near future
	const filtered = useMemo(() => {
		const now = new Date();
		return Lists.sort(keyDates.filter(keyDate => keyDate.date > now), 'date');
	}, [keyDates]);

	return (
		<div data-testid='key-dates-widget'>
			<WidgetHeader>
				<h2>Key dates</h2>
			</WidgetHeader>
			<Group>
				{filtered.slice(0, context.widget.numberOfItems).map((keyDate, i) => (
					<Card key={keyDate.__uuid} keyDate={keyDate}></Card>
				))}
			</Group>
			{fi(!filtered.length,
				<NoContentElem>
					<NoContentComponent message={HomepageMessages.NoKeyDocuments} extra={true} />
				</NoContentElem>,
			)}
			{fi(shouldHideButton(context.widget), null,
				<div data-testid='key-dates-view-all' className='flex-row-end mr-16'>
					<ViewAllBtn viewAll={() => viewAll(context.widget)} noIcon={true} nrOfItems={keyDates.length}
								hideNrOfItems={true} />
				</div>)}
		</div>
	);
};

export default HomepageKeyDates;