import {getRecoil} from '../../../state/recoilNexus';
import {pageSelector} from '../../TreeMenu/utils';
import {Browser} from '../../../utils/browser';
import {Lists} from '../../../utils/lists';
import {CMSObject} from '../../../tw/models/__CMSObject';
import {documentSelector} from '../../../state/documents';
import {HomepageResourcesConfig, HomepageResourcesLabels} from './config';
import {HomepageMessages} from './messages';
import {ROUTE_FAVOURITES, ROUTE_KEY_DOCS, ROUTE_NEW_UPDATED} from '../../../utils/constants';
import {Types} from '../../../tw/types';
import {DocumentsWrapper} from '../../../tw/models/DocumentsWrapper';
import {Page} from '../../../tw/models/Page';
import {references} from '../../../state/state';
import {Strings} from '../../../utils/strings';

export const displayShowAllButton = (widget, listItems) => {
	// check if page link exists in references (otherwise it might be unpublished)
	const page = getRecoil(references(Strings.default(widget.pageLink))) as Page;
	return widget.numberOfItems !== -1 && listItems > +widget.numberOfItems && page ;
};

export const viewAll = (widget) => {
	let pageTree = getRecoil(pageSelector);
	if (!pageTree || (pageTree && !pageTree.length)) return;
	let page = Lists.default<Page>(pageTree).find(p => p.getId() === widget.pageLink);
	if (!page) return;
	Browser.navigate(`${page.getURL()}${document.location.search}`);
};

export const getRecentlyUpdatedItems = () => {
	const docs = getRecoil(documentSelector);
	let list = Lists.default<CMSObject>(docs).filter(doc => doc.getType() !== Types.TEXT && doc.isNewOrUpdated()) as DocumentsWrapper;
	let sortedList = Lists.sort(list, 'title') as DocumentsWrapper;
	let localStorageStoredItems = localStorage.getItem('new_updated') as string;
	let tmp: CMSObject[] = [];
	let final: DocumentsWrapper = sortedList;
	if (localStorageStoredItems) {
		let time = 3 * 60 * 60 * 24 * 1000;
		let today = new Date().getTime();
		JSON.parse(localStorageStoredItems).forEach((d, index, object) => {
			if (today - new Date(d.accessed).getTime() > time) {
				//3 days passed, should remove it
				object.splice(index, 1);
			} else {
				//should still be displayed
				if (!list.filter(document => document.getId() === d.id).length) {
					let foundDocument = docs.find(document => document.getId() === d.id) ;
					if (foundDocument) tmp.push(foundDocument);
				}
			}
		});
	}
	if (tmp.length) {
		final = final.concat(Lists.sort(tmp, 'title')) as DocumentsWrapper;
	}
	return final;
};

export const getFavouriteItems = () => {
	const docs = getRecoil(documentSelector);
	let list = Lists.default<CMSObject>(docs).filter(doc => doc.isFavorite());
	return Lists.sort(list, 'title') as DocumentsWrapper;
};

export const getKeyDocuments = () => {
	const docs = getRecoil(documentSelector);
	let list = Lists.default<CMSObject>(docs).filter(doc => doc.isKeyDocument());
	return Lists.sort(list, 'title') as DocumentsWrapper;
};


export type DetailsProps = {
	dwdFileName: string,
	title: string,
	items: () => DocumentsWrapper
}

export type ResourceProps = {
	message: string, //message displayed when there are no items
	items: () => DocumentsWrapper,
	route: string,
	title: string,
	dwdFileName: string
}

export const getTabContent = (value) => {
	let tabObject: ResourceProps | null = null;
	switch (HomepageResourcesConfig[value].label) {
		case HomepageResourcesLabels.Favourites:
			tabObject = {
				message: HomepageMessages.NoFavourites,
				items: getFavouriteItems,
				route: ROUTE_FAVOURITES,
				title: HomepageMessages.FavouritesPageTitle,
				dwdFileName: 'Favourites'
			};
			return tabObject;
		case HomepageResourcesLabels.KeyDocuments:
			tabObject = {
				message: HomepageMessages.NoKeyDocuments,
				items: getKeyDocuments,
				route: ROUTE_KEY_DOCS,
				title: HomepageMessages.KeyDocumentsPageTitle,
				dwdFileName: 'KeyDocuments'
			};
			return tabObject;
		case HomepageResourcesLabels.RecentlyUpdated:
			tabObject = {
				message: HomepageMessages.NoRecentlyUpdated,
				items: getRecentlyUpdatedItems,
				route: ROUTE_NEW_UPDATED,
				title: HomepageMessages.NewAndUpdatedPageTitle,
				dwdFileName: 'NewOrUpdated'
			};
			return tabObject;
		default:
			return tabObject;
	}
};