import React, {useMemo} from 'react';
import Group from '../../Card/Group';
import {useRecoilValue} from 'recoil';
import {subjectUpdateSelector} from '../../../state/subjectUpdate';
import {Lists} from '../../../utils/lists';

const GlobalAnnouncements = () => {
	const subjectsList = useRecoilValue(subjectUpdateSelector);

	const list = useMemo(() => {
		let slist=  subjectsList.filter(s => s.isAnnouncement());
		return Lists.sort(slist, 'date', true);

	}, [subjectsList])

	if (!list || list.length === 0) return null;

	return (
		<Group items={list} data-testid='global-announcements-group'></Group>
	)
}

export default GlobalAnnouncements