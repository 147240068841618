import {UUID} from '../../../tw/types';
import {LibraryItem, MediaItemFile} from '../../../tw/models/__MediaLibrary';
import {getRecoil} from '../../../state/recoilNexus';
import {references} from '../../../state/state';
import {CMSFile} from '../../../tw/models/File';
import {Numbers} from '../../../utils/numbers';
import {DocumentsWrapper} from '../../../tw/models/DocumentsWrapper';

//compute download file size
export const computeFilesSize = (ids: UUID[], items: LibraryItem[] | DocumentsWrapper) => {
	const list = items.filter(i => ids.includes(i.getId()));
	if (!list.length) return 0;
	let totalSize = 0;
	list.forEach(i => {
		if (i instanceof MediaItemFile) {
			const ref = getRecoil(references(i.file)) as any as CMSFile;
			if (ref) {
				totalSize += ref.size;
			}
		}
	});
	return Numbers.asFileSize(totalSize);
};