import {UserSession} from '../../../tw/models/Session';
import SubjectsComponent from '../../SubjectsComponents/SubjectsComponent';
import React from 'react';

const Subjects = ({user}: { user: UserSession }) => {
	return (
		<div>
			<div className="text-20 text-semiBold">Subjects</div>
			<SubjectsComponent userData={user.getQualifications()} hideActions={true}/>
		</div>
	);
};
export default Subjects;