import {InfoOutlined} from '@mui/icons-material';
import React from 'react';
import styled from '@emotion/styled';

const InfoElem = styled.div`
    width: 100%;
    background-color: var(--color-lighter-blue-background);
    font-size: 16px;
    font-family: var(--font-semi-bold);
    color: var(--color-blue);
    border: solid 1px var(--color-blue);
    border-radius: 6px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 16px 0;

    svg {
        margin-right: 8px;
    }
`;

const EventInfoMessage = () => {
	// component rendered if event is ongoing
	return (
		<InfoElem data-testid='event-info-message'>
			<InfoOutlined/> To book on the day please contact us by telephone on 024 7649 6398
		</InfoElem>
	);
};

export default EventInfoMessage;