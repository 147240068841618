import React from "react"
import styled from "@emotion/styled";
import TrialIntro from "../components/Registration/TrialIntro";

const TrialWrapperElem = styled.div`
  height: 100%;
  background-color: var(--color-background);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  #header {
    width: 670px;
  }
`

const TrialAccessPage = () => {
	const renderTrialHeader = () => {
		return (
			<div className="flex-column-center mb-24" id="header">
				<div className="text-24 text-latoBold mb-8">
					Sign up for trial access
				</div>
				<div className="text-16 text-regular">
					In order to sign you up for trial access we need some information.
				</div>
			</div>
		)
	}
	return (
		<TrialWrapperElem className="pt-56" data-testid='trial-access-wrapper'>
			{renderTrialHeader()}
			<TrialIntro/>
		</TrialWrapperElem>
	)
}

export default TrialAccessPage