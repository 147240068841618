import React from 'react';
import {helpInfoSelector, helpPanelToggledAtom} from '../../state/helpGuide';
import {useRecoilState, useRecoilValue} from 'recoil';
import {Lists} from '../../utils/lists';
import {HelpOutline} from '@mui/icons-material';
import styled from '@emotion/styled';
import {useMediaQuery} from '@mui/material';
import {device} from '../../utils/constants';

const HelpGuideElem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-blue);
    border: solid 1px var(--color-blue);
    border-radius: 3px;
    position: fixed;
    bottom: 230px;
    right: 0;
    cursor: pointer;
    padding: 8px;
    z-index: 100;
    max-width: fit-content;
    min-height: 50px;

    svg {
        color: var(--color-white)
    }
`

const HelpGuideLabelElem = styled.div`
	color: var(--color-white);
	font-family: var(--font-semi-bold);
	margin-top:6px;
	text-orientation: mixed;
	transform: rotate(180deg);
	writing-mode: vertical-rl;
`
const HelpGuidePanel = () => {
	const [toggled, togglePanel] = useRecoilState(helpPanelToggledAtom);
	const helpInfoItems = useRecoilValue(helpInfoSelector);
	const tabletOrMobile = useMediaQuery(device.tablet);

	if (toggled || Lists.default(helpInfoItems).length === 0 || tabletOrMobile) {
		return null;
	}

	const handleOnClick = () => {
		togglePanel(true);
	}

	return (
		<HelpGuideElem data-testid='help-info-panel' onClick={handleOnClick}>
			<HelpOutline/>
			<HelpGuideLabelElem>Help guide</HelpGuideLabelElem>
		</HelpGuideElem>
	)
}

export default HelpGuidePanel