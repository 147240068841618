import styled from "@emotion/styled";
import React from "react";
import {device} from '../../../utils/constants';

export const Header = styled.div`
    margin-bottom: 8px;

    h2 {
        font-family: var(--font-bolder);
        font-size: 20px;
        line-height: 24px;
    }

    > div {
        font-family: var(--font-regular);
        font-size: 16px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        margin-bottom: 16px;
        h2 {
            margin-bottom: 8px;
        }
    }
`

const WidgetHeader = ({children}: any) => {
	return (
		<Header>
			{children}
		</Header>
	)
}

export default WidgetHeader