import {fi} from './helpers';
import {useLocalStorage} from './hooks';

export const dateOptions: Intl.DateTimeFormatOptions = {
	day: '2-digit',
	month: '2-digit',
	year: 'numeric',
};

export enum timeOptions {
	AM = 'Morning',
	PM = 'Afternoon',
}

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const Dates = {
	TimeZoneOption: 'TimeZoneOption',
	TimeZoneDefault: 'Europe/London',
	DefaultDateFormat: 'en-GB',

	setTimeZone: (value: string) => {
		if (!value) {
			localStorage.removeItem(Dates.TimeZoneOption);
		} else {
			localStorage.setItem(Dates.TimeZoneOption, value);
		}
	},

	getTimeZone: () => {
		return (localStorage.getItem(Dates.TimeZoneOption) || Dates.TimeZoneDefault).replace(/"/g, '');
	},

	useTimeZone: () => {
		return useLocalStorage(Dates.TimeZoneOption, Dates.TimeZoneDefault);
	},

	local: (value: string | Date, month: 'short' | 'long' = 'short'): string => {
		if (!value) {
			return '';
		}

		return fi(typeof value === 'string', new Date(value), value)
			.toLocaleDateString(Dates.DefaultDateFormat, {
				...dateOptions,
				timeZone: Dates.getTimeZone(),
				month,
			});
	},


	timeAgo: (val: string | Date | number): string => {
		const now = +(new Date());
		let time: number = 0;
		switch (typeof val) {
			case 'number':
				break;
			case 'string':
				time = +new Date(val);
				break;
			case 'object':
				if (val.constructor === Date) {
					time = val.getTime();
				}
				break;
			default:
				time = +new Date();
		}
		if ((new Date(time)).getFullYear() === 1) {
			return '';
		}

		const timeFormats = [
			[60, 'seconds', 1], // 60
			[120, '1 minute ago', '1 minute from now'], // 60*2
			[3600, 'minutes', 60], // 60*60, 60
			[7200, '1 hour ago', '1 hour from now'], // 60*60*2
			[86400, 'hours', 3600], // 60*60*24, 60*60
			[172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
			[604800, 'days', 86400], // 60*60*24*7, 60*60*24
			[1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
			[2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
			[4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
			[29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
			[58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
			[2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
			[5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
			[58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
		];
		let seconds = (now - (time as any)) / 1000;
		let token = 'ago';
		let listChoice = 1;

		if (Math.floor(seconds) === 0) {
			return 'Just now';
		}
		if (seconds < 0) {
			seconds = Math.abs(seconds);
			token = 'from now';
			listChoice = 2;
		}
		let i = 0;
		let format = timeFormats[i++];
		while (format) {
			if (seconds < format[0]) {
				if (typeof format[2] === 'string') {
					return format[listChoice].toString();
				} else {
					return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
				}
			}
			format = timeFormats[i++];
		}
		return time.toString() as any;
	},

	format(value: string | Date, weekday: boolean = false, hideYear: boolean = false): string {
		if (!value) {
			return '';
		}
		const date = new Date(value);
		return `${fi(weekday, weekdays[date.getDay()] + ' ', '')} 
                ${date.getDate()} ${date.toLocaleString('default', {month: 'long'})} 
                ${fi(hideYear, '', date.getFullYear())}`;
	},

	getMonth(date: Date): string {
		return months[date.getMonth()];
	},

	getMidnight(UTC?: boolean): Date {
		let midnight = new Date();
		if (UTC) {
			midnight.setUTCHours(0, 0, 0, 0);
		} else {
			midnight.setHours(0, 0, 0, 0);
		}
		return midnight;
	},

	getMonthDate(date): string {
		return fi(!date, '', `${date.getDate().toString().padStart(2, '0')} ${months[date.getMonth()]} ${date.getFullYear()}`);
	},

	getUTC(date: Date): Date{
		return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes())
	}
};