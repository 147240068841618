import {CMSObject} from './__CMSObject';
import {DefaultAction, Types, UUID} from '../types';
import {Strings} from '../../utils/strings';
import {Lists} from '../../utils/lists';
import {Link} from './Link';
import {Document} from './Document';
import {fi} from '../../utils/helpers';
import GA from './GA';
import React, {ReactElement} from "react";
import {disableButton} from "../../components/Card/CardActions/Buttons/utils";
import {Messages} from "../../utils/messages";
import {ReactComponent as LinkIcon} from '../../assets/images/card/link-normal.svg';
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";
import {CMSFile} from "./File";

import {ReactComponent as DocIconSmall} from '../../assets/images/card/doc-small.svg';
import {ReactComponent as PDFIconSmall} from '../../assets/images/card/pdf-small.svg';
import {ReactComponent as CSVIconSmall} from '../../assets/images/card/csv-small.svg';
import {ReactComponent as XLSIconSmall} from '../../assets/images/card/xls-small.svg';
import {ReactComponent as XLSMIconSmall} from '../../assets/images/card/xlsm-small.svg';
import {ReactComponent as PPTIconSmall} from '../../assets/images/card/ppt-small.svg';
import {ReactComponent as ZIPIconSmall} from '../../assets/images/card/zip-small.svg';


export class EventMaterial extends CMSObject {
    public static searchable: boolean = true;
    public title: string;
    public description: string;
    public folder: UUID;
    public file: UUID;
    public link: string;
    public display_text: string;
    public event_id: UUID[];

    private static icons: any = {
        PDF: PDFIconSmall,
        CSV: CSVIconSmall,
        EXCEL: XLSIconSmall,
        MATHEXCEL: XLSMIconSmall,
        DOC: DocIconSmall,
        DOCX: DocIconSmall,
        PPT: PPTIconSmall,
        ZIP:ZIPIconSmall,
    };

    constructor(item: any = {}) {
        super(item);

        this.file = item.file;
        this.folder = Strings.default(item.folder);
        this.title = Strings.default(item.title);
        this.description = Strings.default(item.description);
        this.link = Strings.default(item.link);
        this.display_text = Strings.default(item.display_text);
        this.event_id = Lists.default(item.event_id);
    }

    public getObject(shareAction:boolean = true): Link | Document {
        if (this.link) {
            return new Link({...this.__data, __type: Types.LINK, onlyShareAction: shareAction})
        }
        return new Document({...this.__data, __type: Types.DOCUMENT, onlyShareAction: shareAction})
    }

    public defaultAction(): DefaultAction {
        return {
            label: fi(this.link, 'View', 'Download'),
            actionHandler: () => {
                if (this.link) {
                    GA.LinkEvent({
                        event_category: 'Outbound Link',
                        event_label: this.link,
                        event_description: Strings.default(this.description),
                        link_name: this.display_text,
                        uuid: this.getId(),
                    })
                    window.open(this.link, '_blank');
                } else if(this.file) {
                    super.defaultAction().actionHandler();
                }
            },
        }
    }

    public getIcon(_small: boolean = false): ReactElement | null {
        const defaultAction = this.defaultAction();
        if (this.link?.length) {
            return <LinkIcon onClick={defaultAction.actionHandler} title={fi(disableButton(this), Messages.FeatureNotAvailable, defaultAction.label)} />;
        }
        const fileInfo = getRecoil(references(this.file))  as any as CMSFile;
        if (!fileInfo) {
            return null
        }
        if(fileInfo.extension&&EventMaterial.icons[fileInfo.extension.toUpperCase()]) {
            const Icon = EventMaterial.icons[fileInfo.extension.toUpperCase()];
            return <Icon onClick={defaultAction.actionHandler}
                         title={fi(disableButton(this), Messages.FeatureNotAvailable, defaultAction.label)}/>;
        }
        return null;
    }
}