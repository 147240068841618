import styled from '@emotion/styled';
import React from 'react';
import {Messages} from '../../utils/messages';
import {fi} from '../../utils/helpers';

const NoContentElem = styled.div`
    background-color: var(--color-white);
    font-family: var(--font-regular);
    font-size: 16px;
    border: solid 1px var(--color-border-light);
    box-shadow: 0 0 0 1px var(--color-box-shadow);
    border-radius: 6px;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;

    a {
        color: var(--color-blue)
    }
`;

const NoContent = ({label, extra}: { label?: string | any, extra?: boolean }) => {
	return (
		<div className="flex-column-center align-center text-16 pall-24" data-testid='no-content'>
			<div>{fi(label, label, Messages.NoContent)}</div>
			<br/>
			{extra && <div> {Messages.CheckAgainLater}</div>}
		</div>
	);
};

export default NoContent;