import {MediaItemFile} from './__MediaLibrary';
import {UUID} from '../types';
import {Strings} from '../../utils/strings';
import React, {ReactElement} from 'react';
import {fi} from '../../utils/helpers';
import {ReactComponent as AudioIconSmall} from '../../assets/images/card/audio-small.svg';
import {ReactComponent as AudioIcon} from '../../assets/images/card/audio-normal.svg';
import {ReactComponent as PlayIcon} from '../../assets/images/card/actions/play-icon.svg';
import {ReactComponent as DownloadIcon} from '../../assets/images/card/actions/download-icon.svg';
import {disableButton} from '../../components/Card/CardActions/Buttons/utils';
import {Messages} from '../../utils/messages';

export class Audio extends MediaItemFile {
	public static searchable: boolean = true;
	public static markAsSeen = true;
	public content_type: UUID;

	constructor(item: any = {}) {
		super(item);
		this.content_type = Strings.default(item.content_type);
	}

	public getIcon(small: boolean = false): ReactElement | null {
		const defaultAction = this.defaultAction();
		const Icon = fi(small, AudioIconSmall, AudioIcon);
		return <Icon onClick={defaultAction.actionHandler}
					 title={fi(disableButton(this), Messages.FeatureNotAvailable, defaultAction.label)}/>
	}

	public getActionIcon(): React.ReactElement | null {
		return (
			<>
				<DownloadIcon title='Download'/>
				<PlayIcon title='Play'/>
			</>
		)
	}
	public downloadable(): boolean {
		return true;
	}


}