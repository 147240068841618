import {fi} from '../../utils/helpers';
import {UserSession} from '../../tw/models/Session';
import {pageSelector} from '../TreeMenu/utils';
import {getRecoil, setRecoil} from '../../state/recoilNexus';
import {Browser} from '../../utils/browser';
import {Lists} from '../../utils/lists';
import {Page} from '../../tw/models/Page';

export enum PlacementOptions {
	TopStart = 'top-start',
	Top = 'top',
	TopEnd = 'top-end',
	RightStart = 'right-start',
	Right = 'right',
	RightEnd = 'right-end',
	BottomStart = 'bottom-start',
	Bottom = 'bottom',
	BottomEnd = 'bottom-end',
	LeftStart = 'left-start',
	Left = 'left',
	LeftEnd = 'left-end'
}

export enum BackdropDiv {
	Top = 'top-div',
	Right = 'right-div',
	Bottom = 'bottom-div',
	Left = 'left-div',
}

interface BackdropOffset {
	top: number;
	right: number;
	bottom: number;
	left: number;
}

export interface ITour {
	// unique id for the step, they will be saved in user preferences in the database
	id: string;
	// title and content for the card
	title: string;
	content: string;
	// List of widgets that must be found on the page to match the step. Empty list '[]' will match everything
	// If many widgets are defined in the list then the filter will match the first one from the list (or)
	widget: string[];
	// desktop placement of the widget relative to the element
	placement: PlacementOptions;
	// mobile placement of the widget on mobile phones
	mobilePlacement: PlacementOptions;
	// tablet placement of the widget on tablets
	tabletPlacement: PlacementOptions;
	// css query selector for the element where the widget should show
	targetElement: string | string[];
	// url slug for the page. The tour will navigate to that page if it's not the active page.
	// The name 'home' will identify any page marked as home page
	targetPage: string;
	// optional callback to do a certain action before the step is shown
	callback?: (history?: any, state?: any) => Promise<void>;
	// custom filter function for this specific step
	filter?: (history?: any, state?: any, page?: any) => boolean;
	// optional function for displaying the right content and title based on role
	setContent?: (state: any, content: string, title: string) => any;
	// optional function to set up the step
	setup?: (user?: UserSession | undefined, isMobileOrTablet?: boolean, enabled?: boolean) => any;
	// desktop popper modifiers
	popperModifiers?: any[];
	// tablet popper modifiers
	tabletPopperModifiers?: any[];
	// mobile popper modifiers
	mobilePopperModifiers?: any[];
	// scroll to position
	scrollTo?: number;
	logicalPosition?: 'center' | 'end' | 'nearest' | 'start';
	// true if the element is part of a header
	isHeader?: boolean;
	// backdrop effect offset relative to the highlighted element
	backdropOffset?: BackdropOffset;
	backdropElement?: string;
	onScroll?: (params: any[]) => any[];
	padding?: number;
}

export const introMessages: Partial<ITour>[] = [
	{
		id: 'feature_tour',
		title: 'We have new features!',
		content: 'We will help you get familiarised with these new features that will improve your work! ' +
			'You can take this tour, continue from where you left it, or skip it if you want by clicking on “x”.',
	},
];

export const checkpointMessage: Partial<ITour>[] = [
	{
		id: 'checkpoint',
		title: 'We have new features on subject page',
		content: 'If you’d like to familiarise yourself with\n' +
			'the new features on the subject pages\n' +
			'you can continue with the tour.\n' +
			'Alternatively, you can close the tour now\n' +
			'and come back when you’re ready.',
	},
];

export const redirectToTourPage = (tag: string) => {
	const pages = getRecoil(pageSelector);
	const nodes = Lists.default<Page>(pages).filter(i => i.userTourPage());
	if (nodes.length) {
		const page = nodes.find(p => p.getTags().includes(tag));
		if (page && !document.location.pathname.includes(page.getId())) {
			Browser.navigate(page.getURL());
		}
	}
};
export const tourConfig: ITour[] = [
	{
		id: 'new_home_page',
		title: 'Welcome to Teach Cambridge. This is your new home page.',
		content: 'This new home page allows for non-\n' +
			'subject specific information to be\n' +
			'displayed easily and you can now\n' +
			'manage your subjects here instead of the\n' +
			'account information screen.',
		placement: PlacementOptions.Top,
		popperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-100, 10],
					},
				},
			],
		tabletPlacement: PlacementOptions.Top,
		tabletPopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-50, -36],
					},
				},
			],
		mobilePlacement: PlacementOptions.Top,
		mobilePopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-16, -36],
					},
				},
			],
		targetElement: 'div[data-testid*=page-body]',
		targetPage: 'dashboard',
		widget: [],
		isHeader: true,
		backdropOffset: {
			top: -100,
			right: 0,
			bottom: 0,
			left: 0,
		},
		padding: 16,
		setup: function (_user, isMobileOrTablet) {
			redirectToTourPage('#user_tour_1');
		},
	},
	{
		id: 'main_navigation',
		title: 'Navigation Change',
		content: 'The new navigation now provides access\n' +
			'to non-subject specific areas, whilst still\n' +
			'enabling you to navigate your subject\n' +
			'pages in the same way you previously\n' +
			'did.',
		placement: PlacementOptions.RightStart,
		popperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [50, 300],
					},
				},
			],
		tabletPlacement: PlacementOptions.RightStart,
		tabletPopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [150, 20],
					},
				},
			],
		mobilePlacement: PlacementOptions.BottomEnd,
		mobilePopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [50, 0],
					},
				},
			],
		targetElement: ['div[data-testid*=main-menu]', 'div[data-testid=submenu]'],
		targetPage: 'dashboard',
		widget: [],
		isHeader: true,
		backdropOffset: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		setup: function (_user, isMobileOrTablet) {
			redirectToTourPage('#user_tour_1');

			if (isMobileOrTablet) {
				this.targetElement = 'div[data-testid*=main-menu]';
			}
		},
	},
	{
		id: 'announcements',
		title: 'Announcements',
		content: 'Non-subject specific notifications and\n' +
			'announcements are now displayed on\n' +
			'the new home page. Subject specific\n' +
			'updates and announcements will\n' +
			'continue to be shown on subject pages.',
		placement: PlacementOptions.BottomEnd,
		popperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-248, -36],
					},
				},
			],
		tabletPlacement: PlacementOptions.BottomEnd,
		tabletPopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-320, -36],
					},
				},
			],
		mobilePlacement: PlacementOptions.BottomEnd,
		mobilePopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-16, -100],
					},
				},
			],
		targetElement: 'div[data-testid*=global-announcements-widget]',
		targetPage: 'dashboard',
		widget: ['home_page'],
		isHeader: true,
		backdropOffset: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		padding: 16,
		setup: function (_user, isMobileOrTablet) {
			redirectToTourPage('#user_tour_1');
		},
	},
	{
		id: 'manage_subjects',
		title: 'Managing your subjects',
		content: 'You now manage your subjects from the\n' +
			'new home page. From here you can link\n' +
			'to the subject home pages and add or\n' +
			'remove subjects from your subject list.',
		placement: PlacementOptions.Top,
		popperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-248, -36],
					},
				},
			],
		tabletPlacement: PlacementOptions.Top,
		tabletPopperModifiers: [],
		mobilePlacement: PlacementOptions.Top,
		mobilePopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [25, -20],
					},
				},
			],
		targetElement: 'div[data-testid*=my-subjects]',
		targetPage: 'dashboard',
		widget: ['my_subjects'],
		isHeader: true,
		backdropOffset: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		padding: 16,
		setup: function (_user, isMobileOrTablet) {
			redirectToTourPage('#user_tour_1');
		},
	},
	{
		id: 'subject_filter_new',
		title: 'Subject filter',
		content: 'This is your subject filter. Any content\n' +
			'you are viewing on the page is related to\n' +
			'the subject selected.',
		placement: PlacementOptions.BottomEnd,
		popperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-248, -36],
					},
				},
			],
		tabletPlacement: PlacementOptions.BottomEnd,
		tabletPopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-320, -36],
					},
				},
			],
		mobilePlacement: PlacementOptions.BottomEnd,
		mobilePopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-16, -36],
					},
				},
			],
		targetElement: 'div[data-testid*=subject-filter]',
		targetPage: 'subject-home',
		widget: ['qualifications'],
		isHeader: true,
		backdropOffset: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		setup: function (_user, isMobileOrTablet) {
			if (isMobileOrTablet) {
				if (this.backdropOffset) {
					this.backdropOffset.top = -16;
				}
				this.isHeader = false;
			}
			redirectToTourPage('#user_tour_2');
		},
		onScroll: (modifiers): any[] => {
			const tmp = [...modifiers].map(m => ({...m, options: {...m.options, offset: [...m.options.offset]}}));
			tmp![0].options.offset[1] = window.scrollY - 36 - fi(window.scrollY > 0, 44, 0);
			return tmp;
		},
	},
	{
		id: 'select_subjects',
		title: 'Select your subjects',
		content: 'To change your subject, and the content available on the page, click the drop down and select a ' +
			'subject. You can also add new subjects to your filter from here.',
		placement: PlacementOptions.BottomStart,
		popperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [120, -36],
					},
				},
			],
		tabletPlacement: PlacementOptions.BottomEnd,
		tabletPopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-320, -36],
					},
				},
			],
		mobilePlacement: PlacementOptions.BottomEnd,
		mobilePopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-16, -36],
					},
				},
			],
		targetElement: 'div[data-testid*=subject-filter]',
		targetPage: 'subject-home',
		widget: ['qualifications'],
		setup: function (user: UserSession | undefined, isMobileOrTablet: boolean | undefined) {
			if (Boolean(isMobileOrTablet)) {
				if (this.backdropOffset) {
					this.backdropOffset.top = -16;
				}
				this.isHeader = false;
			}
			redirectToTourPage('#user_tour_2');
		},
		onScroll: (modifiers): any[] => {
			const tmp = [...modifiers].map(m => ({...m, options: {...m.options, offset: [...m.options.offset]}}));
			const old = tmp![0].options.original;
			tmp![0].options.offset[1] = window.scrollY + old - fi(window.scrollY > 0, 44, 0);
			return tmp;
		},
		isHeader: true,
		backdropOffset: {
			top: 0,
			right: -16,
			bottom: 0,
			left: 100,
		},
	},
	{
		id: 'left_hand_menu',
		title: 'Search for Resources here',
		content: 'Use the Resource Finder tool to find a specific resource or browse through the menu to see all the ' +
			'resources available to you, relevant to the subject in your subject filter.',
		placement: PlacementOptions.RightStart,
		popperModifiers:
			[
				{name: 'arrow', enabled: true, options: {element: 'div[data-testid*=submenu]'}},
				{
					name: 'offset',
					options: {
						offset: [0, 24],
					},
				},
				{
					name: 'preventOverflow',
					enabled: true,
					options: {
						altAxis: true,
						boundary: null,
						padding: {top: 102},
					},
				},
			],
		tabletPlacement: PlacementOptions.RightStart,
		tabletPopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-16, 24],
					},
				},
			],
		mobilePlacement: PlacementOptions.BottomStart,
		mobilePopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [-16, 0],
					},
				},
			],
		targetElement: 'div[data-testid*=submenu]',
		targetPage: 'subject-home',
		widget: [],
		scrollTo: 0,
		setup: function (_user, isMobileOrTablet, enabled) {
			if (!isMobileOrTablet) {
				const filter = document.querySelector('div[data-testid*=subject-filter]');
				if (this.popperModifiers && filter) {
					// set the qualification filter as boundary for left menu popper
					let preventOverflow = this.popperModifiers.find(obj => obj.name === 'preventOverflow');
					if (preventOverflow?.options) {
						preventOverflow.options.boundary = filter;
					}
				}
			} else {
				this.targetElement = 'button[data-testid=mobile-menu-btn]';
				this.isHeader = true;
			}
			if (!enabled) {
				this.content = 'You can browse through the menu to see all the ' +
					'resources available to you, relevant to the subject in your subject filter.';
			}
			redirectToTourPage('#user_tour_2');
		},
	},
	{
		id: 'resources',
		title: 'Resources',
		content: 'This is where you will see key documents, new or updated resources, as well as a list of your ' +
			'favourite resources, all relevant to the subject in your subject filter. Click on the heart to add them ' +
			'to your favourites list.',
		placement: PlacementOptions.TopStart,
		popperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 30],
					},
				},
			],
		tabletPlacement: PlacementOptions.TopStart,
		tabletPopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 70],
					},
				},
			],
		mobilePlacement: PlacementOptions.TopStart,
		mobilePopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 70],
					},
				},
			],
		targetElement: 'div[data-testid*=resources-widget]',
		targetPage: 'subject-home',
		widget: ['home_page_resources'],
		logicalPosition: 'end',
		setContent: (user, content, title) => {
			if (user.isTrial()) {
				return {
					content: `This is where you will see key documents, new or updated resources, as well as a list of
					your favourite resources, all relevant to the subject in your subject filter. Click on the heart
					to add them to your favourites list. Only certain resources are available to you in the trial access.
					If you wish to start teaching the specification and get full access then please contact
					the Exams Officer at your centre. If your centre is approved to teach OCR qualifications,
					they will be able to set up your full access.`,
					title,
				};
			}
			return {content, title};
		},
		backdropOffset: {
			top: -44,
			right: 0,
			bottom: 0,
			left: 0,
		},
		padding: 16,
		setup: function (_user, isMobileOrTablet, enabled) {
			redirectToTourPage('#user_tour_2');
		},
	},
	{
		id: 'subject_updates',
		title: 'Subject updates',
		content: 'This is where you can find key information that you need to know, relating to the subject in your ' +
			'subject filter.',
		placement: PlacementOptions.TopStart,
		popperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 24],
					},
				},
			],
		tabletPlacement: PlacementOptions.TopStart,
		tabletPopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 24],
					},
				},
			],
		mobilePlacement: PlacementOptions.TopStart,
		mobilePopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 24],
					},
				},
			],
		targetElement: 'div[data-testid*=subject-updates-widget]',
		targetPage: 'subject-home',
		widget: ['home_page'],
		filter: (_user, page) => {
			return !!page.config.find(p => p.type === 'home_page' && p.item === 'subject_updates');
		},
		backdropOffset: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		padding: 16,
		setup: function (_user, isMobileOrTablet, enabled) {
			redirectToTourPage('#user_tour_2');
		},
	},
	{
		id: 'key_dates',
		title: 'Key dates',
		content: 'This is where you can check key dates or deadlines, related to the subject in your subject filter.',
		placement: PlacementOptions.TopStart,
		popperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 24],
					},
				},
			],
		tabletPlacement: PlacementOptions.TopStart,
		tabletPopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 24],
					},
				},
			],
		mobilePlacement: PlacementOptions.TopStart,
		mobilePopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 24],
					},
				},
			],
		targetElement: 'div[data-testid*=key-dates-widget]',
		targetPage: 'subject-home',
		widget: ['home_page'],
		filter: (_user, page) => {
			return !!page.config.find(p => p.type === 'home_page' && p.item === 'key_dates');
		},
		backdropOffset: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		padding: 16,
		setup: function (_user, isMobileOrTablet, enabled) {
			redirectToTourPage('#user_tour_2');
		},
	},
	{
		id: 'events',
		title: 'Upcoming events',
		content: 'This is where you can get all the details you need for training and CPD, relevant to the subject ' +
			'in your subject filter. Just click on the details to book your place.',
		placement: PlacementOptions.TopStart,
		popperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 24],
					},
				},
			],
		tabletPlacement: PlacementOptions.TopStart,
		tabletPopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 24],
					},
				},
			],
		mobilePlacement: PlacementOptions.TopStart,
		mobilePopperModifiers:
			[
				{
					name: 'offset',
					options: {
						offset: [0, 24],
					},
				},
			],
		targetElement: 'div[data-testid*=events-widget]',
		targetPage: 'subject-home',
		widget: ['home_page'],
		filter: (_user, page) => {
			return !!page.config.find(p => p.type === 'home_page' && p.item === 'upcoming_events');
		},
		backdropOffset: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		padding: 16,
		setup: function (_user, isMobileOrTablet, enabled) {
			redirectToTourPage('#user_tour_2');
		},
	},
];
