import { RecoilState, RecoilValue, TransactionInterface_UNSTABLE, useRecoilCallback } from 'recoil';

interface Nexus {
    get?: <T>(atom: RecoilValue<T>) => T;
    getPromise?: <T>(atom: RecoilValue<T>) => Promise<T>;
    set?: <T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void;
    atomicSet?: <T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) => void;
    reset?: (atom: RecoilState<any>) => void;
    refresh?: (atom: RecoilState<any>) => void;
    transaction?: (cb: (state: TransactionInterface_UNSTABLE) => void) => void;
}


const nexus: Nexus = {
    // @ts-ignore
    get: () => {},
    // @ts-ignore
    getPromise: () => {},
    // @ts-ignore
    set: () => {},
    // @ts-ignore
    atomicSet: () => {},
    // @ts-ignore
    reset: () => {},
    // @ts-ignore
    transaction: () => {},
    // @ts-ignore
    refresh: () => {}
};

export default function RecoilNexus() {
    nexus.get = useRecoilCallback<[ atom: RecoilValue<any> ], any>(({snapshot}) =>
        function <T>(atom: RecoilValue<T>) {
            return snapshot.getLoadable(atom).contents;
        }, []);
    nexus.getPromise = useRecoilCallback<[ atom: RecoilValue<any> ], Promise<any>>(({snapshot}) =>
        function <T>(atom: RecoilValue<T>) {
            return snapshot.getPromise(atom);
        }, []);
    nexus.set = useRecoilCallback<any, any>(({set}) => set, []);
    // @ts-ignore
    nexus.atomicSet = useRecoilCallback(({transact_UNSTABLE}) => {
        return function <T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) {
            transact_UNSTABLE(({set}) => {
                set(atom, valOrUpdater);
            });
        };
    }, []);
    nexus.reset = useRecoilCallback(({reset}) => reset, []);

    nexus.transaction = useRecoilCallback(({transact_UNSTABLE}) => {
        return function (cb: (state: TransactionInterface_UNSTABLE) => void) {
            transact_UNSTABLE(state => {
                cb(state);
            });
        };
    }, []);
    return null;
}

export function getRecoil<T>(atom: RecoilValue<T>): T {
    return nexus.get!(atom);
}

export function getRecoilPromise<T>(atom: RecoilValue<T>): Promise<T> {
    return nexus.getPromise!(atom);
}

export function setRecoil<T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) {
    nexus.set!(atom, valOrUpdater);
}

// Does not work on selectors or atoms where their default value is a selector
export function atomicSetRecoil<T>(atom: RecoilState<T>, valOrUpdater: T | ((currVal: T) => T)) {
    nexus.set!(atom, valOrUpdater);
}

export function resetRecoil(atom: RecoilState<any>) {
    nexus.reset!(atom);
}

export function transaction(cb: (state: TransactionInterface_UNSTABLE) => void) {
    nexus.transaction!(cb);
}

export function refreshRecoil(atom: RecoilState<any>) {
    nexus.refresh!(atom);
}