import {UUID} from '../types';
import {Strings} from '../../utils/strings';
import {CMSObject, DisplayMode} from './__CMSObject';
import {Numbers} from '../../utils/numbers';
import {CTUnit} from './CTUnit';
import {Pathway} from './Pathway';
import {Lists} from '../../utils/lists';
import {references} from '../../state/state';
import {getRecoil} from '../../state/recoilNexus';

export type UnitTypes = {
	optionalUnits: UUID[];
	mandatoryUnits: UUID[];
}

export class QualificationSize extends CMSObject {
	public status: boolean;
	public code: string;
	public glh: number;
	public name: string;
	public level: number;
	public qca_code: string;
	public qualification: UUID;

	public units: CTUnit[] = [];
	public pathways: Pathway[] = [];

	constructor(item: any = {}) {
		super(item);

		this.status = Boolean(item.status);
		this.level = item.level;
		this.code = Strings.default(item.code);
		this.name = Strings.default(item.name);
		this.qca_code = Strings.default(item.qca_code);
		this.qualification = Strings.default(item.qualification);
		this.glh = Numbers.default(item.glh);

		const existing = getRecoil(references(this.getId()));
		if (existing) {
			return existing as QualificationSize;
		}
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		if (options === DisplayMode.SHORT) {
			return this.name;
		} else {
			return `${this.code} - ${this.name}`;
		}
	}

	public hasPathways(): boolean {
		return this.pathways.length > 0;
	}

	public resolveUnits(units: CTUnit[], pathways: Pathway[]): void {
		pathways.forEach((pathway) => {
			if (pathway.qualification_size.includes(this.getId())) {
				this.pathways.push(pathway);
			}
		});
		this.pathways = Lists.sort(Array.from(new Set(this.pathways)), 'name');
		const pathwayIDs: UUID[] = this.pathways.map((pathway) => pathway.getId());
		units.forEach((unit) => {
			if (unit.mandatory_size.includes(this.getId()) || unit.optional_size.includes(this.getId()) ||
				unit.optional_pathway.filter(id => pathwayIDs.includes(id)).length ||
				unit.pathway.filter(id => pathwayIDs.includes(id)).length
			) {
				let tmp = {...unit};
				tmp.alias = unit.displayLabel();
				if (!this.units.filter(u => u.getId() === unit.getId()).length) {
					this.units.push(unit);
				}
			}
		});

		this.units = Array.from(new Set(this.units));
	}

	public unitsNeeded(pathway: Pathway | null): number {
		let mandatoryGLH: number = 0, unitNeeded: number = 0;
		const optionalGLH: number[] = [];
		let pathwayId: UUID = '';
		if (pathway) {
			pathwayId = pathway.getId();
		}
		this.units.forEach((unit) => {
			if (unit.mandatory_size.includes(this.getId()) || (pathwayId !== '' && unit.pathway.includes(pathwayId))) {
				unitNeeded++;
				mandatoryGLH += unit.glh;
			} else if (unit.optional_size.includes(this.getId()) || (pathwayId !== '' && unit.optional_pathway.includes(pathwayId))) {
				optionalGLH.push(Numbers.default(unit.glh));
			}
		});
		optionalGLH.sort((a, b) => (a - b));
		let left = this.glh - mandatoryGLH;
		for (let glh of optionalGLH) {
			if (left <= 0) {
				break;
			}
			unitNeeded++;
			left -= glh;
		}
		return unitNeeded;
	}

	public unitsType(pathway: Pathway | null): UnitTypes {
		const result: UnitTypes = {optionalUnits: [], mandatoryUnits: []};
		if (pathway) {
			this.units.forEach((unit) => {
				if (unit.pathway.includes(pathway.getId())) {
					result.mandatoryUnits.push(unit.getId());
				}
				if (unit.optional_pathway.includes(pathway.getId())) {
					result.optionalUnits.push(unit.getId());
				}
			});
		} else {
			this.units.forEach((unit) => {
				if (unit.mandatory_size.includes(this.getId())) {
					result.mandatoryUnits.push(unit.getId());
				}
				if (unit.optional_size.includes(this.getId())) {
					result.optionalUnits.push(unit.getId());
				}
			});
		}
		result.mandatoryUnits = Lists.sort(result.mandatoryUnits, 'alias');
		result.optionalUnits = Lists.sort(result.optionalUnits, 'alias');
		return result;
	}
}