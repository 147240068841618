import React from 'react';
import {TrialMessages} from './messages';
import styled from '@emotion/styled';

const SurveyCompletedElem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;

    p {
        margin-bottom: 8px;
        text-align: center
    }
`;
const SurveyCompleted = () => {
	return (
		<SurveyCompletedElem className="flex-column-center text-16 text-regular" data-testid='trial-survey-completed'>
			<p data-testid='survey-completed-line1'>{TrialMessages.SurveyCompletedLine1}</p>
			<p data-testid='survey-completed-line2'>{TrialMessages.SurveyCompletedLine2}</p>
			<p data-testid='survey-completed-line3'>{TrialMessages.SurveyCompletedLine3}&nbsp; <span
				className="text-semiBold">{TrialMessages.TrialEmail}</span>.</p>
		</SurveyCompletedElem>
	);
};

export default SurveyCompleted;