import React from "react"
import styled from "@emotion/styled";
import {CMSObject} from "../../tw/models/__CMSObject";

const Wrapper = styled.div`
    position: absolute;
    right: 0;
    font-family: var(--font-semi-bold);
    cursor: default;
    font-size: 12px;
    margin-top: -1px;
    line-height: 17px;

    > span {
        border-radius: 0 0 4px 4px;
        color: white;
        padding: 0 8px;
        height: 18px;
        display: inline-block;

        :first-of-type {
            background-color: var(--color-darker-red);
            margin-right: 8px;
        }

        :last-of-type {
            background-color: var(--color-green);
            margin-right: 16px;

            &.essential {
                background-color: var(--color-darker-violet);
            }
		
            &.urgent {
                background-color: var(--color-darker-red);
            }
        }

        &.pastEvent {
            background-color: var(--color-grey);
            color: var(--color-monochrome)
        }
		
		&.fullyBooked {
            background-color: var(--color-green);
		}
    }
`

const Flag = ({item}: {item: CMSObject}) => {
	return (
		<Wrapper data-testid='label' className='flag' >
			{item.getFlag()}
		</Wrapper>
	)
}

export default Flag