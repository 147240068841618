import React from 'react';
import TreeItem from './TreeItem';
import styled from '@emotion/styled';
import { useMenu } from '../../state/menu';

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--color-white);
    width: 100%;

    & > div > div > div > div > p {
        font-family: var(--font-semi-bold);
    }
`;

const TreeComponent = () => {
	const {state:{subTree}} = useMenu();

	return (
		<ListWrapper data-depth={0} data-parentid={null} data-testid="tree-component-wrapper">
			{subTree.map((item) => (
				<TreeItem key={item.id} item={item} depth={0}/>
			))}
		</ListWrapper>
	);
};

export default TreeComponent;