import {atom, selector} from 'recoil';
import {sessionAtom} from './session';
import {HelpGuide} from '../tw/models/HelpGuide';
import Client from '../tw/client';
import {Types} from '../tw/types';

export const helpInfoSelector = selector<HelpGuide[]>({
		key: 'helpInfoSelector',
		get: async ({get}) => {
			const userSession = get(sessionAtom);
			let helpInfoItems: HelpGuide[] = [];
			if (!userSession) return helpInfoItems;
			if (!userSession.getQualifications().length) {
				return helpInfoItems;
			}
			try {
				const resp = await Client.query<HelpGuide>({
					types: [Types.HELP_INFO],
				});
				if (resp?.results) {
					helpInfoItems = [...resp.results];
				}
			} catch (e) {
			}
			return helpInfoItems;
		}
	},
);

export const helpPanelToggledAtom = atom<boolean>({
	key: 'helpPanelToggledAtom',
	default: false,
});

export const infoPanelState = atom<HelpGuide | null>({
	key: 'infoPanelState',
	default: null,
});