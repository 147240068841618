import {ITour} from './types';
import {findTreeNode, ITreeItem, pageSelector} from '../TreeMenu/utils';
import {UserSession} from '../../tw/models/Session';
import {Objects} from '../../utils/objects';
import {getRecoil} from '../../state/recoilNexus';

const pagesForTour = ['home', 'dashboard', 'subject-home'];
export const filterSteps = (list: ITour[], session: UserSession, pageTree: ITreeItem[]): ITour[] => {
	const pages = getRecoil(pageSelector);
	const tourPages = pages.filter(i => i.userTourPage());

	return list.filter((step) => {
		if (!tourPages.length) {
			return false;
		}
		const page = findTreeNode(pageTree, (p) =>
			p.object.landingPage() && pagesForTour.includes(step.targetPage) && p.object.getURL().endsWith(`/${step.targetPage}`)
		);

		if (!page) {
			return false;
		}

		if (step.widget.length) {
			const matchedWidgets = page.object.config.filter((widget) => step.widget.includes(widget.type));
			if (matchedWidgets.length === 0) {
				return false;
			}
		}

		if (step.setContent) {
			const res = Objects.default(step.setContent(session, step.content, step.title));
			step.title = res.title;
			step.content = res.content;
		}

		if (step.filter) {
			return step.filter(session, page.object);
		}
		return true;
	});
};