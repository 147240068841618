import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from '@emotion/styled';
import SelectComponent, {ISelectValue} from '../../FormComponents/Select/SelectComponent';
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil';
import {
	enableSubjectFilter,
	enableUnitFilter,
	getUnitCodesForURL,
	SubjectSelector,
	UnitSelector,
} from '../../../state/product';
import {defaultSubjectAtom, sessionAtom} from '../../../state/session';
import {getRecoil, setRecoil} from '../../../state/recoilNexus';
import {references, scrollTopPosition, showTourAtom} from '../../../state/state';
import {Lists} from '../../../utils/lists';
import {Subject, Unit} from '../../../tw/models/Subject';
import {fi} from '../../../utils/helpers';
import {components} from 'react-select';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import {Strings} from '../../../utils/strings';
import Divider from '@mui/material/Divider';
import {useMediaQuery} from '@mui/material';
import {Messages} from '../../../utils/messages';
import {Browser} from '../../../utils/browser';
import {device} from '../../../utils/constants';
import AddSubjectButton from '../../commons/AddSubjectButton';
import {AlertSeverity, snackbarStateAtom} from '../../Snackbar/SnackbarComponent';
import {CTUnit} from '../../../tw/models/CTUnit';
import {SearchFilterAtom, showSearchResults} from '../ResourceFinder/utils';

const SubjectElem = styled.div`
    grid-area: subject;
    height: 68px;

    .MuiFormControl-root {
        margin-bottom: 8px !important;
    }

    @media ${device.tablet} {
        height: 235px;
    }
`;

const TitleElem = styled.div`
    @media ${device.tablet} {
        margin-bottom: 8px;
    }
`;
const StickyContainer = styled.div`
    height: 68px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    background-color: var(--color-blue-background-darker);

    &.sticky-position {
        z-index: 1;
        top: 69px;
        width: calc(100% - 75px);
        position: fixed;
    }

    @media ${device.tablet} {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 235px;
        padding: 16px;
    }
	
	@media ${device.mobile} {
        &.sticky-position {
            width: 100%;
        }
	}
`;

const UnitElem = styled.div`
    width: 260px;
    max-width: 260px;
    min-width: 260px;
    margin-left: 24px;
    margin-right: 30px;

    @media ${device.tablet} {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        max-width: 100%;
    }
`;

const NewSubjectBtn = styled.div`
    padding: 16px;

    button {
        font: var(--font-regular);
    }

`;

const customStyles = {
	option: (provided, state) => ({
		...provided,
		height: 42,
		backgroundColor: fi(state.isSelected, 'var(--color-grey)', 'transparent'),
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		lineHeight: 'normal',
		color: 'var(--color-black)',
		'&:hover': {
			backgroundColor: 'var(--color-blue-background)',
			'.hidden': {
				display: 'inline',
			},
		},
		'&.subject-option': {
			justifyContent: 'space-between !important',
		},
	}),
	control: (provided, state) => ({
		...provided,
		cursor: 'pointer',
		boxShadow: 'none',
		color: state.isDisabled ? '#9e9e9e' : '#212121',
		whiteSpace: 'nowrap',
		borderColor: fi(state.isFocused, 'var(--color-monochrome)', 'var(--color-grey)'),
		'.subject': {
			fontWeight: 'bold',
			color: 'var(--color-blue)',
		},
		height: 42,
		'&:hover': {
			borderColor: 'var(--color-monochrome)',
		},
		'&:active': {
			borderColor: 'var(--color-monochrome)',
		},
	}),
	valueContainer: (provided) => ({
		...provided,
		display: 'flex',
		textOverflow: 'ellipsis',
		maxWidth: '90%',
		whiteSpace: 'nowrap',
		overflowX: 'hidden',
		flexWrap: 'nowrap !important',
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		color: fi(state.isFocused, 'var(--color-monochrome)', 'var(--color-grey)'),
		'&:hover': {
			color: 'var(--color-monochrome)',
		},
		'&:active': {
			color: 'var(--color-monochrome)',
		},
	}),
	menuList: (provided) => ({
		...provided,
		paddingBottom: 0,
	}),
};

const SubjectStatus = styled.span<{ default: boolean }>`
    color: var(--color-backdrop);
    font-size: 14px;
    font-weight: ${props => fi(props.default, 'bold', 'normal')};

    &:hover {
        cursor: ${props => fi(props.default, 'auto', 'pointer')};
    }

    .hidden {
        display: none;
    }

    & > span {
        color: var(--color-blue);
        font-weight: bold;
    }
`;

const CustomOption = (optionProps) => {
	const defaultSubject = getRecoil(defaultSubjectAtom);
	const session = getRecoil(sessionAtom);
	const [qualification, ...subject] = Strings.default(optionProps.children).split(':');

	if (!session) {
		return null;
	}

	const subjectStatus = () => {
		return (
			<SubjectStatus default={defaultSubject === optionProps.data.value}>
				{fi(defaultSubject === optionProps.data.value, ' - default',
					<span className="hidden" title={Messages.DefaultSubjectTooltip}
						  onClick={() => session.setDefaultSubject(optionProps.data.value)}> - mark as default</span>)}
			</SubjectStatus>
		);
	};

	return (
		<>
			<components.Option {...optionProps} className="subject-option">
				<span>{qualification}: <strong>{subject.join(":")}</strong>{subjectStatus()}</span>
				{fi(optionProps.isSelected, <CheckCircleOutlined color="primary"/>)}
			</components.Option>
			<Divider className="option-divider"/>
		</>
	);
};

const SingleSubjectValue = (optionProps) => {
	const [qualification, ...subject] = Strings.default(optionProps.children).split(':');
	const defaultSubject = getRecoil(defaultSubjectAtom);
	return (
		<components.SingleValue {...optionProps}>
			<span>{qualification}: <span
				className="subject">{subject.join(":")}</span>{fi(defaultSubject === optionProps.data.value, <span
				style={{fontSize: '14px', color: 'var(--color-blue)'}}> - default</span>)}</span>
		</components.SingleValue>
	);
};

const CustomMenu = (menuProps) => {
	const mobileOrTablet = useMediaQuery(device.tablet);
	const session = useRecoilValue(sessionAtom);
	if (!session) {
		return null;
	}
	return (
		<components.Menu {...menuProps}>
			{menuProps.children}
			{fi(!mobileOrTablet,
				<>
					<Divider/>
					<NewSubjectBtn title={fi(session.preview, Messages.DisabledInPreview)}>
						<AddSubjectButton disabled={!!session.preview} shouldNavigate={true}/>
					</NewSubjectBtn>
				</>)}
		</components.Menu>
	);
};

const SubjectFilter = () => {
	const positionToTop = useRecoilValue(scrollTopPosition);
	const [subject, setSubject] = useRecoilState(SubjectSelector);
	const [unit, setUnit] = useRecoilState(UnitSelector);
	const session = useRecoilValue(sessionAtom);
	const enabledSubjectFilter = useRecoilValue(enableSubjectFilter);
	const resetSearchResults = useResetRecoilState(showSearchResults);
	const resetSearchFilter = useResetRecoilState(SearchFilterAtom);
	const mobileOrTable = useMediaQuery(device.tablet);
	const firstMounted = useRef(true);
	const enabledUnitFilter = useRecoilValue(enableUnitFilter);
	const tourActive = useRecoilValue(showTourAtom)

	const [selectedUnits, setSelectedUnits] = useState<any[]>([]);
	const setActiveUnit = useSetRecoilState(UnitSelector);
	const unitURL = Browser.queryParam('unit');

	const getUnitsByLabel = () => {
		if (!unitURL || !subject || unitURL === 'all') return [{label: 'All units', value: 'all'}];
		const unitArray = unitURL.split(',');

		const sbjUnits = subject.getUnits();
		let unitObjects = unitArray.map(u => {
			return sbjUnits.find(unitt => {
				let check = fi(subject.isTechnicals(), (unitt as CTUnit).alias, unitt.code);
				return check == u;
			});
		});
		return Lists.default<Unit>(unitObjects).map(u => ({
			value: u.getId(),
			label: u.displayLabel(),
			object: u,
		}));
	};
	useEffect(() => {
		if (firstMounted.current && subject) {
			firstMounted.current = false;
			setSubject(subject.getId() as any);
			setActiveUnit(getUnitsByLabel());
			return;
		}
	}, [subject]);

	const userSubjects = useMemo(() => {
		const subjects: ISelectValue[] = [];

		if (!session) {
			return [];
		}

		session.getQualifications().forEach(q => {
			const qObj = getRecoil(references(q.qualification));
			const sObj = getRecoil(references(q.subject)) as Subject;
			if (qObj && sObj) {
				subjects.push({
					value: q.subject,
					label: `${qObj.displayLabel()}: ${sObj.displayLabel()}`,
					object: q,
				});
			}
		});
		Lists.sort(subjects, 'label');
		return subjects;
	}, [session]);

	const subjectUnits = useMemo(() => {
		if (!subject) {
			return [];
		}

		let units: ISelectValue[] = subject.userUnits().map(userUnit => ({
			value: userUnit.getId(),
			label: userUnit.displayLabel(),
			object: userUnit,
		}));

		if (units.length === 0) {
			return units;
		}
		units = Lists.sort(units, 'label');
		units.unshift({value: 'all', label: 'All Units', object: null});
		return units;
	}, [subject]);

	useEffect(() => {
		if (enabledSubjectFilter) {
			if (!document.location.search) {
				if (subject && unit) {
					let codes = getUnitCodesForURL(unit)
					Browser.setQueryParam({subject: subject.getId(), unit: codes});
				}
			}
		}
	}, [document.location.search, subject, unit, enabledSubjectFilter]);

	const updateSelectedUnits = () => {
		setUnit(selectedUnits);
	};

	useEffect(() => {
		updateSelectedUnits();
	}, [selectedUnits]);

	if (!subject || !session || !enabledSubjectFilter) {
		return null;
	}

	const onChangeSubject = (selection) => {
		if (selection.value === subject.getId() as any) {
			return;
		}
		resetSearchResults();
		resetSearchFilter();
		setSubject(selection.value);
		setRecoil(snackbarStateAtom, {
			message: `${Messages.SubjectChanged}`,
			severity: AlertSeverity.Success,
		});
		setUnit([{label: 'All units', value: 'all'}]);
	};

	const onChangeUnit = (selection) => {
		//if all units selected and another unit is selected -> deselect all units
		let unitsSelected = selectedUnits.length ? selectedUnits : unit;
		if (unitsSelected.find(u => u.value === 'all') && selection.filter(s => s.value !== 'all').length) {
			setSelectedUnits(selection.filter(s => s.value !== 'all'));
		} else if (selection.find(s => s.value === 'all') || !selection.length) {
			//if any units are selected and new selection is all units -> deselect other units
			setSelectedUnits([{label: 'All units', value: 'all'}]);
		} else {
			setSelectedUnits(selection);
		}
	};

	return (
		<SubjectElem data-testid="subject-filter" >
			<StickyContainer className={positionToTop ? 'sticky-position' : ''}>
				<TitleElem className="mr-24 text-semiBold">Subjects</TitleElem>
				<SelectComponent label="" id="subjectFilterSelector"
								 data-testid="subject-selector"
								 values={userSubjects}
								 value={subject.getId()}
								 onChange={onChangeSubject}
								 component={{SingleValue: SingleSubjectValue, Option: CustomOption, Menu: CustomMenu}}
								 hideSelectedOptions={false}
								 styles={customStyles}
								 notNative={true}
				/>

				{fi(subject.isModular() || subject.isTechnicals(),
					<UnitElem data-testid="unit-filter">
						<SelectComponent label=""
										 hideSelectedOptions={false}
										 id="unitFilter"
										 data-testid="unit-selector"
										 values={subjectUnits}
										 value={unit}
										 onChange={onChangeUnit}
										 styles={customStyles}
										 readonly={!enabledUnitFilter}
										 checkbox={true}
										 isClearable={false}
										 notNative={true}
						/>
					</UnitElem>)}

				{fi(mobileOrTable, <AddSubjectButton disabled={!!session.preview}
													 shouldNavigate={true}/>)}
			</StickyContainer>
		</SubjectElem>
	);
};

export default SubjectFilter;