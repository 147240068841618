import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { fi } from '../../../../utils/helpers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DateRangePicker, DateRange as DateRangeReact} from 'react-date-range';

// main css file
import 'react-date-range/dist/styles.css'; // theme css file
import 'react-date-range/dist/theme/default.css';
import { useRecoilState } from 'recoil';
import { examDateFilter } from '../../../../state/keyDates';
import { Strings } from '../../../../utils/strings';
import { Lists } from '../../../../utils/lists';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Dates } from '../../../../utils/dates';
import { useMediaQuery } from '@mui/material';
import { device } from '../../../../utils/constants';
import styled from '@emotion/styled';

const DateRangeWrapper = styled(DateRangeReact)`
	z-index: 9999;
`
const StyledBtn = styled(Button)`
	width: 100%;
	
	@media ${device.tablet} {
		padding: 0;
		margin-top: 4px;
	}
`


const defaultPickerState = () => ({
	startDate: Dates.getMidnight(true),
	endDate: Dates.getMidnight(true),
	key: 'selection',
});

const DateRange = () => {
	const [pickerState, setPickerState] = useState([defaultPickerState()]);
	const [filter, setFilter] = useRecoilState(examDateFilter);
	const popupState = usePopupState({variant: 'popover', popupId: 'datePicker'});
	const tablet = useMediaQuery(device.tablet);

	const setDateRange = (selection) => {
		setPickerState([selection]);
		const dateRange: string[] = [Strings.default(selection.startDate), Strings.default(selection.endDate)];
		setFilter({...filter, dateRange: dateRange});
	};

	const [from, to] = useMemo<string[]>(() => {
		return Lists.default(filter.dateRange);
	}, [filter]);

	const onChange = (item) => {
		setDateRange(item.selection);
		popupState.close();
	};

	const firstMounted = useRef(true);
	useEffect(() => {
		if (firstMounted.current && !tablet) {
			const now = new Date();
			const selection = {
				startDate: Dates.getMidnight(true),
				endDate: new Date(now.setFullYear(now.getFullYear() + 1)),
				key: 'selection',
			};
			setPickerState([selection]);
			setFilter({...filter, dateRange: [selection.startDate.toString(), selection.endDate.toString()]});
		}
		firstMounted.current = false;
	}, [setDateRange]);

	return (
		<div className="filter-group" data-testid="date-range-filter">
			<p>Date range:</p>
			<StyledBtn data-testid="date-picker-trigger" {...bindTrigger(popupState)} disableRipple>
				{fi(from, Dates.getMonthDate(new Date(from)), 'From')} - {fi(from, Dates.getMonthDate(new Date(to)), 'To')}
				{fi(popupState.isOpen, <ExpandLessIcon fontSize="small"/>, <ExpandMoreIcon fontSize="small"/>)}
			</StyledBtn>
			<div className="date-range-picker">
				<Popover {...bindPopover(popupState)} anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}>
					{!tablet ?
						<DateRangePicker
							data-testid="date-range-picker"
							onChange={item => onChange(item)}
							ranges={pickerState}
							direction={'horizontal'}
						/>
						: <DateRangeWrapper
							data-testid='date-range-picker-mobile'
							onChange={item => onChange(item)}
							ranges={pickerState}

						/>
					}
				</Popover>
			</div>
		</div>
	);
};

export default DateRange;