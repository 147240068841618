import {EventsAir} from '../../../tw/models/EventsAir';

export const sortByDate = (array: EventsAir[], reverse: boolean = false) => {
	let asc = reverse ? -1 : 1;
	return array.sort((a, b) => {
		let dateA = new Date(a.startDate).getTime();
		let dateB = new Date(b.startDate).getTime();
		if (dateA === dateB) {
			return a.startTime < b.startTime ? (-1) * asc : asc;
		} else {
			return a.startDate < b.startDate ? (-1) * asc : asc;
		}
	});
};