import React, {useEffect} from 'react';
import {trialSurveyCompleted, trialSurveyStart, trialSurveyStep} from '../../state/trial';
import {useRecoilState, useRecoilValue} from 'recoil';
import styled from '@emotion/styled';
import {SurveyConfig} from './surveyConfig';
import {fi} from '../../utils/helpers';
import {TrialMessages} from './messages';
import SurveyFooter from './commons/SurveyFooter';
import {Browser} from '../../utils/browser';
import Client from '../../tw/client';
import SurveyCompleted from './SurveyCompleted';

const SurveyWrapperElem = styled.div`
    height: 100%;
    width: 100%;
    background-color: var(--color-background);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .survey-step {
        width: 900px;
        text-align: left;
        color: var(--color-blue);
        font-family: var(--font-semi-bold);
        margin-bottom: 16px;
    }
`;

const SurveyStepElem = styled.div`
    width: 900px;
    background-color: var(--color-white);
    border: solid 1px var(--color-border-light);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    .survey-content {
        padding: 16px 24px;
        width: 100%;
    }
`;

const Survey = () => {
	const surveyStarted = useRecoilValue(trialSurveyStart);
	const surveyStep = useRecoilValue(trialSurveyStep);
	const [surveyCompleted, setSurveyCompleted] = useRecoilState(trialSurveyCompleted);

	const code = Browser.queryParam('code');
	useEffect(() => {
		if (code) {
			Client.checkTrialSurvey(code).then(res => {
				let status = false;
				try {
					status = JSON.parse(res);
				} catch (e) {
				}
				setSurveyCompleted(status);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code]);

	return (
		<SurveyWrapperElem data-testid='trial-survey-wrapper'>
			{fi(surveyStep > 0 && surveyStep < SurveyConfig.length -1, <div data-testid='trial-survey-step-no' className="survey-step">{surveyStep} out of {SurveyConfig.length - 2}</div>)}
			<SurveyStepElem>
				<p className="text-18 text-semiBold mb-16 mt-16">{TrialMessages.SurveyTitle}</p>
				{fi(surveyCompleted,
					<SurveyCompleted/>,
					<>
						<div className="survey-content">
							{fi(!surveyStarted, SurveyConfig[0].component(), SurveyConfig[surveyStep].component())}
						</div>
						{SurveyConfig[surveyStep].footer && <SurveyFooter/>}
					</>)}
			</SurveyStepElem>
		</SurveyWrapperElem>
	);
};

export default Survey;