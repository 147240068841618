import React, { useMemo, useState } from 'react';
import Filter from './Filters/Filter';
import { useRecoilState, useRecoilValue } from 'recoil';
import { examDateFilter, examDateSelector, ExamDateType } from '../../../state/keyDates';
import { Dates } from '../../../utils/dates';
import styled from '@emotion/styled';
import Card from './Card';
import { SubjectSelector } from '../../../state/product';
import Sorter from '../../commons/Sorter';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { CMSObject } from '../../../tw/models/__CMSObject';
import { UUID } from '../../../tw/types';
import Client from '../../../tw/client';
import { sessionAtom } from '../../../state/session';
import NoContent from '../../commons/NoContent';
import { Backdrop, useMediaQuery } from '@mui/material';
import { device } from '../../../utils/constants';
import TuneIcon from '@mui/icons-material/Tune';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import DateRange from './Filters/DateRange';
import ActivityType from './Filters/ActivityType';
import Series from './Filters/Series';
import Divider from '@mui/material/Divider';
import { Objects } from '../../../utils/objects';
import { Strings } from '../../../utils/strings';
import { Lists } from '../../../utils/lists';

const GroupLabel = styled.div`
    font-family: var(--font-bolder);
    font-size: 20px;
    line-height: 24px;
`;

const Group = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    white-space: nowrap;

    button {
        background: none;
        padding: 0;
        justify-content: flex-start;

        & > svg {
            width: 20px;
            height: 20px;
            margin-left: 8px;
        }
    }

    p {
        font-weight: bold;
    }

    & > div:last-of-type {
        margin: 0 16px;
    }
`;

const DownloadButton = styled.div`
    position: absolute;
    right: 16px;
    top: 92px;

    @media ${device.tablet} {
        margin-top: 217px;
        margin-right: 45px;
    }

    @media ${device.mobile} {
        margin-top: 215px;
        margin-right: 45px;
    }
`;

const MobileWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const FilterIcon = styled.div`
    margin-top: -155px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.mobile} {
        margin-top: -200px;
    }

    > div {
        font-family: var(--font-bolder);
        color: var(--color-blue);
    }

    > svg {
        &.filters {
            color: var(--color-blue);
        }
    }
`;

const DwdBtn = styled(Button)`
    padding: 0;
    color: black;

    & > span {
        margin: 0;
    }

    & > svg {
        color: black;
    }
`;

const Filters = styled.div`
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90%;
    z-index: 999;
    padding: 16px;
    border-radius: 5px;

    & > div {
        &.header {
            font-family: var(--font-bolder);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
        }

        margin-bottom: 16px;
    }

    & > hr {
        margin: 0 -16px 16px -16px;
    }
`;

const ClearBtn = styled(Button)`
    width: 100%;
`;

type downloadProps = {
	label: string,
	path: string,
}

const dwdOptions: downloadProps[] = [
	{label: 'Download key dates CSV', path: 'downloadKeyDatesCSV'},
	{label: 'Download timetables CSV', path: 'downloadTimetablesCSV'},
	{label: 'Download iCalendar', path: 'downloadKeyDates'},
];

const Sort = () => {
	const [filter, setFilter] = useRecoilState(examDateFilter);

	return (
		<Sorter label="Sort by deadline" callbackFn={(sortBy) => setFilter({...filter, sort: sortBy})}/>
	);
};

const Download = ({list, token, mobile}: {list: CMSObject[], token: UUID, mobile: boolean}) => {
	const popupState = usePopupState({variant: 'popover', popupId: 'download'});

	const onClick = (_event: any, path: string) => {
		popupState.close();
		if (list.length === 0) {
			return;
		}
		Client.downloadKeyDates(list.map(l => l.getId()), token, path).catch();
	};

	return (
		<DownloadButton data-testid="download-key-dates">
			{!mobile ?
				<Button {...bindTrigger(popupState)} size={'small'} variant={'contained'} color={'primary'}
						disableRipple disabled={list.length === 0} data-testid="dwd-btn">Download key dates</Button>
				:
				<DwdBtn {...bindTrigger(popupState)}
						data-testid="dwd-mobile-btn"
						disabled={list.length === 0} disableRipple><DownloadIcon fontSize={'small'}/></DwdBtn>
			}
			<Menu {...bindMenu(popupState)} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
				  transformOrigin={{vertical: 'top', horizontal: 'left'}}>
				{dwdOptions.map((option, idx) => (
					<MenuItem key={option.label} onClick={(evt) => onClick(evt, option.path)}>{option.label}</MenuItem>
				))}

			</Menu>

		</DownloadButton>
	);
};

const KeyDates = () => {
	const keyDates = useRecoilValue(examDateSelector);
	const selectedSubject = useRecoilValue(SubjectSelector);
	const session = useRecoilValue(sessionAtom);
	const tablet = useMediaQuery(device.tablet);
	const [toggleFilter, setToggleFilter] = useState<boolean>(false);
	const [filter, setFilter] = useRecoilState(examDateFilter);

	const keyDatesList = useMemo(() => {
		const groups: any[] = [];
		let group: any = {
			label: '',
			items: [],
		};
		keyDates.forEach((keyDate: ExamDateType) => {
			const date = `${Dates.getMonth(keyDate.date)} ${keyDate.date.getFullYear()}`;
			if (date !== group.label) {
				group = {
					label: date,
					items: [],
				};
				groups.push(group);
			}
			group.items.push(keyDate);
		});
		return groups;
	}, [keyDates, selectedSubject]);

	const filtersNo = useMemo(() => {
		let activeFilters: number = 0;
		const filters = Objects.default(filter);
		activeFilters = Number(Lists.default(filters.dateRange).length > 0)
			+ Number(Strings.default(filters.type) !== '')
			+ Number(Strings.default(filters.series) !== '');
		return activeFilters;
	}, [filter]);

	if (!keyDates || !session) {
		return null;
	}

	const removeClass = () => {
		const appLayout = document.getElementById('app-layout');
		if (appLayout) {
			appLayout.classList.remove('block-scroll');
		}
	}

	const onClearFilters = () => {
		removeClass();
		setFilter({sort: 'asc'});
		setToggleFilter(false);
	};

	 const onToggleFilter = () => {
		 const appLayout = document.getElementById('app-layout');
		 if (appLayout) {
			 appLayout.classList.add('block-scroll');
		 }
		 setToggleFilter(!toggleFilter)
	 }



	return (
		<React.Fragment>
			{!tablet ?
				<Header data-testid="keyDate-header">
					<Filter/>
					<Sort/>
				</Header>
				:
				<MobileWrapper>
					<FilterIcon>
						<Download list={keyDatesList.map(k => k.items).flat()} token={session.downloadToken}
								  mobile={true}/>
						<TuneIcon onClick={onToggleFilter}
								  className={filtersNo > 0 ? 'filters' : ''}/>
						{filtersNo > 0 && <div>({filtersNo})</div>}
					</FilterIcon>
					{toggleFilter &&
                        <>
                            <Backdrop sx={{color: '#fff', zIndex: 999}}
                                      open={toggleFilter}/>
                            <Filters>
                                <div className="header" data-testid="filters-header">
                                    <span>Sort & Filter</span>
                                    <ClearIcon fontSize={'small'} onClick={() => setToggleFilter(false)}/>
                                </div>
                                <Divider/>
                                <DateRange/>
                                <Divider/>
                                <ActivityType/>
                                <Divider/>
                                <Series/>
                                <Divider/>
                                <Sort/>
                                <Divider/>
                                <ClearBtn data-testid="clear-filter-btn" size="small" disableRipple
                                          disabled={filtersNo === 0}
                                          onClick={onClearFilters}>Clear
                                    filters
                                </ClearBtn>
                            </Filters>
                        </>
					}
				</MobileWrapper>
			}
			{keyDatesList.length === 0 && <NoContent
                label="There are no key dates or timetable dates that match the selected criteria. Please check back again later."/>}
			{keyDatesList.map((group, idx) =>
				<React.Fragment key={group.label}>
					<GroupLabel data-testid="keyDate-group-label">{group.label}</GroupLabel>
					<Group>
						{group.items.map((keyDate, i) => (
							<Card key={keyDate.__uuid} keyDate={keyDate}></Card>
						))}
					</Group>
				</React.Fragment>,
			)}
			{!tablet &&
                <Download list={keyDatesList.map(k => k.items).flat()} token={session.downloadToken} mobile={false}/>}
		</React.Fragment>
	);
};

export default KeyDates;