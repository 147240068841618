import React from 'react';
import {UUID} from '../../../tw/types';
import HomepageKeyDates from './HomepageKeyDates';
import HomepageEvents from './HomepageEvents';
import HomepageSubjectUpdates from './HomepageSubjectUpdates';
import HomepageOnlineCourses from './HomepageOnlineCourses';
import {HomepageWidgets} from '../widget';
import HomepageGlobalAnnouncements from './HomepageGlobalAnnouncements';

type HomepageProps = {
	item: UUID,
	numberOfItems: string,
	pageLink: string
}

export const shouldHideButton = (widget) => {
	return widget.numberOfItems == -1;
};
const Homepage = (props: HomepageProps) => {

	const renderHomepageWidget = () => {
		switch (props.item) {
			case HomepageWidgets.KeyDates:
				return <HomepageKeyDates key={props.item}/>;
			case HomepageWidgets.Events:
				return <HomepageEvents key={props.item}/>;
			case HomepageWidgets.SubjectUpdates:
				return <HomepageSubjectUpdates key={props.item}/>;
			case HomepageWidgets.OnlineCourse:
				return <HomepageOnlineCourses key={props.item}/>;
			case HomepageWidgets.GlobalAnnouncements:
				return <HomepageGlobalAnnouncements key={props.item}/>;
			default:
				return null;
		}
	};

	return (
		renderHomepageWidget()
	);
};

export default Homepage;