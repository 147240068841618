import {EventsAir} from '../../../../tw/models/EventsAir';
import {fi} from '../../../../utils/helpers';
import React from 'react';
import EventInfoMessage from './EventInfoMessage';
import styled from '@emotion/styled';

const HeadingElem = styled.div`
	.subheading{
		color: var(--color-grey-text)
	}
`
const EventHeading = ({event, showInfo}: { event: EventsAir, showInfo: boolean }) => {
	return (
		<HeadingElem>
			<div className="text-24 text-latoBold mb-8">{event.displayLabel()}</div>
			<div className="subheading text-16 text-regular">{event.eventDetailsSubheading()}</div>
			{fi(showInfo, <EventInfoMessage/>)}
		</HeadingElem>
	);
};

export default EventHeading;