import {RegistrationMessages} from '../messages';
import {RegistrationLinks} from '../utils';
import {SIGN_UP_URL} from '../../../utils/constants';
import RadioButtonsGroup from '../../FormComponents/Radio/RadioButtonsGroup';
import React from 'react';

const SUPPORT_OPTIONS = [
	{label: 'Yes', value: 'yes'},
	{label: 'No', value: 'no'},
];


const TrialMessage = ({value, onChange}: { value: any, onChange: (...params) => void }) => {

	return (
		<>
			<p className="text-24 text-regular mb-16"> {RegistrationMessages.CommunicationStepHeader}
			</p>
			<div className="mb-16 text-16 text-regular">
				By completing your profile you are agreeing to our <a
				href={RegistrationLinks.termsAndConditions} target="_blank" rel="noreferrer">terms and conditions</a>.
			</div>
			<div className="mb-8 text-16 text-regular">
				We recommend you <a href={SIGN_UP_URL} target="_blank" rel="noreferrer">sign up</a> to our email
				updates to stay up to date with all the latest subject-specific news, resources, CPD and support
				available.
			</div>
			<RadioButtonsGroup field={{
				options: SUPPORT_OPTIONS,
				name: 'allowContact',
				label: RegistrationMessages.TrialCommunicationLabel,
			}}
							   onChange={onChange}
							   value={value}
							   styling="flex-row-start"/>
		</>
	);
};

export default TrialMessage;