import React from 'react';
import {useRecoilState} from 'recoil';
import {selectedTraining} from '../TrainingForm';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';

const FormSubmitted = () => {
	const [selected, setSelected] = useRecoilState(selectedTraining);
	if (!selected) return null;

	return (
		<div className="text-16" data-testid="form-submitted">
			<div>Your request for <span className="text-semiBold">"{selected}"</span> has been registered.</div>
			<div>Thank you for showing interest on one of our trainings. Someone from the team will get in contact with
				you.
			</div>
			<br/>
			<div className="mb-16">If you are interested in following another training, click below and fill in the
				form.
			</div>
			<Button size="small" variant="text" onClick={() => setSelected(null)} startIcon={<Add/>}
					data-testid="add-training-btn">
				Add another training
			</Button>
		</div>
	);
};

export default FormSubmitted;