import React, { Suspense, useMemo } from 'react';
import styled from '@emotion/styled';
import Logo from '../assets/images/OCR-Logo.svg';
import AccountMenu from '../components/Account/AccountMenu';
import Notifications from '../components/Notifications/Notifications';
import Loader from '../components/Loader/Loader';
import TrialCountdown from '../components/Trial/TrialCountdown';
import { sessionAtom } from '../state/session';
import { useRecoilState, useRecoilValue } from 'recoil';
import { fi } from '../utils/helpers';
import { device } from '../utils/constants';
import IconButton from '@mui/material/IconButton';
import { useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { mobileMenuState } from './Menu';
import { useMenu } from '../state/menu';

const WEBSITE_HEADER = 'Teach Cambridge';

const HeaderElem = styled.div`
    grid-area: header;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-grey);
    position: sticky;
    width: 100%;
    padding: 0 24px 0 96px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    z-index: 100;
    top: 0;
    @media ${device.tablet} {
        padding: 0 16px;
        border-bottom: 0;
    }
`;

const LogoContainerElem = styled.a`
    height: 100%;
    border-right: 1px solid var(--color-grey);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 24px;
    width: 226px;

    img {
        width: 105px;
        height: 45px;
    }

    @media ${device.tablet} {
        display: none;
    }
`;
const HeaderContentElem = styled.div`
    width: 100%;
    padding-left: 24px;

    .mobile-icon {
        margin-right: 16px;
    }

    @media ${device.tablet} {
        padding-left: 0
    }
`;


const Header = () => {
	const mobileOrTablet = useMediaQuery(device.tablet);
	const [mobileMenu, setMobileMenu] = useRecoilState(mobileMenuState);
	const user = useRecoilValue(sessionAtom);
	const {state: {subTree, menuVisible}} = useMenu();

	const showCountdown = useMemo(() => {
		if (!user) return false;
		return user.isTrial() && !user.isFirstTimeUser();
	}, [user]);

	const mobileMenuAction = () => {
		const currentState = mobileMenu;
		setMobileMenu(!currentState);
	};
	const hideNotifications = useMemo(() => {
		if (!user) return true;
		return user.getQualifications().length === 0;
	}, [user]);

	return (
		<HeaderElem data-testid="header" id="content-header">
			<LogoContainerElem href={'/'} data-testid="logo-link" className="logo-container">
				<img src={Logo} alt="Cambridge Assessment"/>
			</LogoContainerElem>
			<HeaderContentElem className="flex-row-space-between">
				<div className="flex-row-center">
					{fi(mobileOrTablet && subTree.length > 0 && menuVisible,
						<IconButton onClick={mobileMenuAction} className="mobile-icon"
									data-testid="mobile-menu-btn"> <MenuIcon/>
						</IconButton>)}
					<div className="text-18 text-latoBold"> {WEBSITE_HEADER}</div>
					{fi(showCountdown, <TrialCountdown/>)}
				</div>
				<div className="flex-row-center">
					{fi(!hideNotifications,
						<Suspense fallback={<Loader/>}>
							<Notifications/>
						</Suspense>,
					)}
					<div className="flex-row-center">
						<AccountMenu/>
					</div>
				</div>
			</HeaderContentElem>
		</HeaderElem>
	);
};

export default Header;