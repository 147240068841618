import React, {Suspense} from "react";
import PageLoader from "../components/Loader/PageLoader";
import styled from "@emotion/styled";

const ContentElem = styled.div`
    grid-area: main;
    position: relative;
`
const Content = ({children}: any) => {
    return (
        <ContentElem data-testid='content' id="content">
            <Suspense fallback={<PageLoader/>}>
                {children}
            </Suspense>
        </ContentElem>
    )
}

export default Content;