import {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {sessionAtom} from '../../state/session';
import {GASelector} from '../../state/analytics';
import {productTreeSelector} from '../../state/product';
import {consentCookieAtom} from '../Cookies/CookieComponent';

const GoogleAnalytics = () => {
	const session = useRecoilValue(sessionAtom);
	const setGA = useSetRecoilState(GASelector);
	const productTree = useRecoilValue(productTreeSelector);
	const hasConsentCookie = useRecoilValue(consentCookieAtom);

	useEffect(() => {
		if (!session || !session.preferences || !productTree) {
			return;
		}

		// default stuff about the user
		const preferences = session.preferences;

		let gaDimensions: any = {
			school: preferences.orgName,
			schoolid: preferences.org,
			userrole: preferences.jobTitle,
			accessType: session.getAccessType(),
			businessStream: session.selectedBusinessStream,
		};

		let qualificationName, subjectName = '';

		// user's qualification and subject (first from the list) name from the product tree
		if (preferences?.qualification.length && productTree) {
			const userQualification = preferences.qualification[0];
			const qualificationData = productTree.find(pt => pt.getId() === userQualification.qualification);
			if (qualificationData) {
				qualificationName = qualificationData.displayLabel();
				qualificationData.subjects.forEach(s => {
					if (userQualification.subject.includes(s.getId())) {
						subjectName = s.displayLabel();
						return;
					}
				});
			}
		}

		if (qualificationName && subjectName) {
			gaDimensions = {...gaDimensions, qualificationgroup: qualificationName, qualification: subjectName};
		}

		if (hasConsentCookie) {
			setGA(gaDimensions);
		}
	}, [session, productTree, hasConsentCookie]);

	return null;
};

export default GoogleAnalytics;