import {Types} from "../types";
import {fi} from "../../utils/helpers";
import {CMSObject} from "./__CMSObject";
import {Document} from "./Document";
import {Qualification} from "./Qualification";
import {Subject} from "./Subject";
import {Page} from "./Page";
import {Notification} from "./Notification";
import {Assessment} from "./Assessment";
import {ChildAssessment} from "./ChildAssessment";
import {QualificationSize} from "./QualificationSize";
import {CTUnit} from "./CTUnit";
import {Pathway} from "./Pathway";
import {Link} from "./Link";
import {Image} from "./Image";
import {Audio} from "./Audio";
import {Video} from "./Video";
import {EventMaterial} from "./EventMaterial";
import {Text} from "./Text";
import {CMSFile} from "./File";
import {ContentType} from "./ContentType";
import {SubjectUpdate} from "./SubjectUpdate";
import {UsefulLink} from "./UsefulLink";
import {ContentGroup} from "./ContentGroup";
import {Component} from "./Component";
import {EventsAir} from "./EventsAir";
import {Topic} from './Topic';
import {TimeTable} from './TimeTable';
import {KeyDate} from './KeyDate';
import {HelpGuide} from './HelpGuide';

// Holds a mapping of what CMS type is an instance of what class
const classRegistry = {
	[Types.DOCUMENT]: Document,
	[Types.QUALIFICATION_GROUP_MAPPING]: Qualification,
	[Types.SUBJECT_GROUP_MAPPING]: Subject,
	[Types.PAGE]: Page,
	[Types.NOTIFICATIONS]: Notification,
	[Types.ASSESSMENT]: Assessment,
	[Types.CHILD_ASSESSMENT]: ChildAssessment,
	[Types.QUALIFICATION_SIZE]: QualificationSize,
	[Types.CT_UNIT]: CTUnit,
	[Types.PATHWAY]: Pathway,
	[Types.LINK]: Link,
	[Types.IMAGE]: Image,
	[Types.AUDIO]: Audio,
	[Types.VIDEO]: Video,
	[Types.TEXT]: Text,
	[Types.EVENT_MATERIAL]: EventMaterial,
	[Types.FILE]: CMSFile,
	[Types.LINK]: Link,
	[Types.CONTENT_TYPE]: ContentType,
	[Types.SUBJECT_UPDATES]: SubjectUpdate,
	[Types.USEFUL_LINK]: UsefulLink,
	[Types.CONTENT_GROUP]: ContentGroup,
	[Types.COMPONENT]: Component,
	[Types.EVENT]: EventsAir,
	[Types.TOPIC]: Topic,
	[Types.HELP_INFO]: HelpGuide,
	[Types.TIMETABLE]: TimeTable,
	[Types.KEY_DATE]: KeyDate,
	[Types.SUBJECT]: Subject,
	[Types.QUALIFICATION]: Qualification
};


// Class of returns a Class from a Type that is registered in the classRegistry. If the class is not registered,
// or doesn't exist, it will return the CMSObject class if useDefault is true, otherwise it will return null
export const ClassOf = (type: Types | string, useDefault: boolean = true): any => {
	return classRegistry[type] || (fi(useDefault, CMSObject, null));
};

// InstanceOf returns an instance of a given class type or attempts to instantiate it from an object returned
// from the server that has the __type property indicating the class type.
// If the class type is not registered in the classRegistry then it will instantiate
// a CMSObject class if useDefault is true or return null if useDefault is false
export const InstanceOf = (type: Types | any, useDefault: boolean = true): any => {
	if (typeof type === 'string') {
		const cls: any = ClassOf(type as Types, useDefault);
		if (cls) {
			return new cls({__type: type});
		}
	} else if (typeof type === 'object') {
		if (type.__type) {
			const cls: any = ClassOf(type.__type, useDefault);
			if (cls) {
				return new cls(type);
			}
		} else if (type.fileuid) {
			return new CMSFile(type);
		}
	}

	if (useDefault) {
		return new CMSObject({__type: type});
	}

	return null;
};