import {CMSObject} from './__CMSObject';
import {Types, UUID} from '../types';
import {Strings} from '../../utils/strings';
import {Numbers} from '../../utils/numbers';
import {Subject} from './Subject';
import {getRecoil} from '../../state/recoilNexus';
import {references} from '../../state/state';
import React, {ReactElement} from 'react';

export class ExamDates extends CMSObject {
	public file: UUID;
	public date: Date;
	public exam_year: number;
	public series: string;
	public created: string;
	public updated: string;

	constructor(data: any = {}) {
		super(data);
		this.file = Strings.default(data.key_dates_file);
		this.date = new Date(data.date);
		this.exam_year = Numbers.default(data.exam_year);
		this.series = Strings.default(data.series);
		this.created = Strings.default(data.created);
		this.updated = Strings.default(data.updated);
	}

	public getSeriesYear(): string {
		return Strings.capitalize(`${this.series.substring(0,3)} ${this.exam_year.toString()}`);
	}

	public matchesSubject(subject: Subject): boolean {
		const qualification = getRecoil(references(subject.qualificationMappingID));
		if (!qualification) { return false;}
		const qualifications = this.getQualifications();
		if(qualifications.includes(qualification.displayLabel())) {
			const subjects = this.getSubjects();
			if (this.getType() === Types.KEY_DATE  && !subjects.length) { // if keydate has no subject but matches qualification
				return true;
			}
			if(subjects.includes(subject.displayLabel())) {
				return true;
			}
		}
		return false;
	}

	public getQualifications(): string[] {
		return [];
	}

	public getSubjects(): string[] {
		return [];
	}

	public getFlag(): ReactElement[] {
		const result: ReactElement[] = [];
		let date = this.created;
		let label: string = 'New';
		if (this.updated) {
			label = 'Updated';
			date = this.updated;
		}

		const keyDate = new Date(date);
		if (keyDate.toString() === 'Invalid Date') {
			return result;
		}

		const now = new Date();
		const elapsed = (now.getTime() - keyDate.getTime()) / 1000 / 60 / 60 / 24;
		if (elapsed <= 30) {
			result.push(<span key={this.getId()}>{label}</span>) ;
		}
		return result;
	}
}


