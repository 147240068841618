import { atom, selector } from 'recoil';
import { SubjectSelector } from './product';
import { CMSObject } from '../tw/models/__CMSObject';
import Client from '../tw/client';
import { references } from './state';
import { UUID } from '../tw/types';
import { DocumentsWrapper } from '../tw/models/DocumentsWrapper';

// holds a list of updated (rate/favorite/etc) item ids
const updatedDocuments = atom<UUID[]>({
	key: 'updatedDocuments',
	default: [],
});

// in order to reflect document changes in documentSelector, you need to call refreshDocument.
// it updates the references atom family for the given item when set, and records the id to updatedDocuments atom.
// when read from, it returns the items from the updatedDocuments from references.
export const refreshItem = selector<CMSObject | CMSObject[] | null>({
	key: 'refreshItem',
	get: ({get}) => {
		const ids = get(updatedDocuments);
		const res: CMSObject[] = [];
		for (const id of ids) {
			const ref = get(references(id));
			if (ref) {
				res.push(ref);
			}
		}
		return res;
	},
	set: ({set}, item) => {
		if (item && item instanceof CMSObject) {
			set(references(item.getId()), item);
			set(updatedDocuments, (existing) => Array.from(new Set([...existing, item.getId()])));
		}
	},
});

// returns documents from the server. only refreshes when subject selector changes.
const loadDocumentSelector = selector<DocumentsWrapper>({
	key: 'loadDocumentSelector',
	get: async ({get}) => {
		const subject = get(SubjectSelector);
		if (!subject) {
			return DocumentsWrapper.from([]);
		}

		const tmp = await Client.getDocuments(subject.getId());
		return DocumentsWrapper.from(tmp) as any;
	},
});

// returns the list of documents for the selected subject from loadDocumentSelector and amends it with
// updated documents we have in updatedDocuments atom.
export const documentSelector = selector<DocumentsWrapper>({
	key: 'documentSelector',
	get: async ({get}) => {
		const documents = get(loadDocumentSelector);
		const refreshed = get(refreshItem);

		if (refreshed && Array.isArray(refreshed) && refreshed.length) {
			const tmp = DocumentsWrapper.from([...documents]) as any;
			refreshed.forEach((doc) => {
				for (let i = 0; i < tmp.length; i++) {
					if (tmp[i].getId() === doc.getId()) {
						tmp[i] = doc;
						break;
					}
				}
			});
			return tmp;
		}

		return documents;
	}
});