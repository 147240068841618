import React, {useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {feedbackData, feedbackStepAtom} from '../../../state/feedback';
import {Strings} from '../../../utils/strings';
import {getRecoil, setRecoil} from '../../../state/recoilNexus';
import {FeedbackMessages} from '../messages';
import InputField from '../../FormComponents/Input/InputField';

//third step in feedback form
const ThirdStep = () => {
	const [localValue, setLocalValue] = useState<string>('');
	const feedbackStep = useRecoilValue(feedbackStepAtom);

	const onChange = (e) => {
		setLocalValue(Strings.default(e.target.value));
		setRecoil(feedbackData(feedbackStep), Strings.default(e.target.value));
	};

	const value = useMemo(() => {
		const storedData = getRecoil(feedbackData(feedbackStep));
		if (storedData !== null) return storedData;
		return localValue;
	}, [getRecoil(feedbackData(feedbackStep)), feedbackStep, localValue]);

	const question = useMemo(() => {
		return getRecoil(feedbackData(feedbackStep - 1)) > 8 ? FeedbackMessages.ThirdStepPositiveQuestion : FeedbackMessages.ThirdStepNegativeQuestion;
	}, [getRecoil(feedbackData(feedbackStep - 1)), feedbackStep]);

	return (
		<form noValidate
			  autoComplete="off" className="flex-column-center align-center">
			<span className="text-16 text-semiBold mb-16">{question}</span>
			<InputField
				id="feedback"
				name="feedback"
				isTextArea={true}
				value={value}
				onChange={onChange}
				type={'text'}
				field={{name: 'feedback', label: 'Answer'}}
			/>
		</form>
	);
};

export default ThirdStep;