import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { QualificationSize } from '../../../tw/models/QualificationSize';
import { fi } from '../../../utils/helpers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PathwayMenu, { pathwayAtom } from './PathwayComponent';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { SubjectSelector } from '../../../state/product';
import Button from '@mui/material/Button';

export const qualificationSizeAtom = atom<QualificationSize | null>({
	key: 'qualificationSizeAtom',
	default: null,
});

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const WrapperFilter = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: var(--font-regular);

    .filter {
        display: flex;
        align-items: center;
        width: 50%;

        button {
            padding: 0 8px;
        }
    }
`;

const SizeContainer = styled.div`
    box-shadow: 0 4px 6px 0 var(--color-box-shadow);
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    cursor: pointer;
    background: white;
    width: 100%;
    padding: 14px 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &.selected {
        border-color: var(--color-blue);
        color: var(--color-blue);
    }
`;

const Title = styled.div`
    margin-bottom: 16px;
    font-family: var(--font-semi-bold);
    display: flex;
    align-items: center;

    > svg {
        width: 20px;
        height: 20px;
        margin-left: 8px;
    }
`;

const UnitInfo = styled.div`
    box-shadow: 0 4px 6px 0 var(--color-box-shadow);
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    background: white;
    width: fit-content;
    padding: 8px;
    margin-top: 16px;
`;


type Size = {
	qualification: QualificationSize,
	opened: boolean
}

const SizeComponent = ({sizes}: {sizes: QualificationSize[]}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [qualificationSize, setQualificationSize] = useState<QualificationSize | null>(null);

	const [sizeState, setSizeState] = useRecoilState(qualificationSizeAtom);
	const [pathwayState, setPathwayState] = useRecoilState(pathwayAtom);
	const selectedSubject = useRecoilValue(SubjectSelector);

	const open = Boolean(anchorEl);

	const unitsNeeded: number = useMemo(() => {
		if (!sizeState) {
			return 0;
		}
		return sizeState.unitsNeeded(pathwayState);
	}, [sizeState, pathwayState]);

	const qualificationSizes: Size[] = useMemo(() => {
		const tmp: Size[] = [];
		sizes.forEach(size => {
			tmp.push({
				qualification: new QualificationSize(size),
				opened: false,
			});
		});
		return tmp;
	}, [sizes]);

	useEffect(() => {
		resetFilter();
	}, [selectedSubject]);

	useEffect(() => {
		return () => {
			resetFilter();
		};
	}, []);

	const resetFilter = () => {
		setSizeState(null);
		setPathwayState(null);
	};

	const handleClick = (evt: any, size: Size) => {
		evt.stopPropagation();

		setQualificationSize(size.qualification);
		if (size.qualification.hasPathways()) {
			setAnchorEl(evt.currentTarget);
			size.opened = !size.opened;
		} else {
			// set size atom from here only for the sizes without pathways
			setSizeState(size.qualification);
			setPathwayState(null);
			size.opened = false;
		}
	};

	const onClose = () => {
		setAnchorEl(null);
		if(qualificationSize) {
			const q = qualificationSizes.find(size => size.qualification.getId() === qualificationSize.getId());
			if (q) {
				q.opened = false;
			}
		}
	};

	if (!qualificationSizes.length) {
		return null;
	}

	return (
		<Wrapper data-testid='size-pathway-widget'>
			<WrapperFilter>
				{
					qualificationSizes.map((size: Size, index: number) => {
						return (
							<SizeContainer
								className={fi(sizeState && sizeState.getId() === size.qualification.getId(), 'selected')}
								data-testid='size-container' key={size.qualification.displayLabel()} onClick={(evt) => {
								handleClick(evt, size);
							}}>
								<Title>
									{size.qualification.displayLabel()}
									{fi(size.qualification.hasPathways(), fi(size.opened, <ExpandLessIcon />, <ExpandMoreIcon />))}
								</Title>
								<div data-testid={`glh-${index}`}>{size.qualification.glh} GLH</div>
							</SizeContainer>

						);
					})
				}
				<div className='filter'>
					<Button size='small' onClick={resetFilter} data-testid='reset-filter-btn'>Clear filters</Button>
				</div>
				{fi(qualificationSize?.hasPathways(),
					<PathwayMenu anchorEl={anchorEl} qualificationSize={qualificationSize} open={open}
								 onClose={onClose} />
				)}
			</WrapperFilter>
			{fi(sizeState, <UnitInfo data-testid='units-needed'>Units needed: <strong>{unitsNeeded}</strong></UnitInfo>)}
		</Wrapper>
	);
};

export default SizeComponent;