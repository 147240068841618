import React, {useEffect, useMemo} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {eventsSelector, EventsType, eventsTypeAtom} from '../../../state/events';
import Group from '../../Card/Group';
import {selectedMonthAtom, selectedYearAtom} from '../commons/CarouselItems';
import {fi} from '../../../utils/helpers';
import NoContent from '../../commons/NoContent';
import {Messages} from '../../../utils/messages';
import {enableUnitFilter, SubjectSelector} from '../../../state/product';
import {sortByDate} from './utils';

const Events = () => {
	const events = useRecoilValue(eventsSelector);
	const selectedYear = useRecoilValue(selectedYearAtom);
	const selectedMonth = useRecoilValue(selectedMonthAtom);
	const type = useRecoilValue(eventsTypeAtom);
	const subject = useRecoilValue(SubjectSelector);
	const unitFilterEnabled = useSetRecoilState(enableUnitFilter);

	useEffect(() => {
		unitFilterEnabled(false);
		return () => {
			unitFilterEnabled(true);
		};
	}, []);

	const filteredEvents = useMemo(() => {
		if (!events) return [];
		let filtered = events.filter(event => {
			let [eventYear, eventMonth, day] = event.startDate.split('-');

			if (selectedYear === 'All' || eventYear === selectedYear) {
				if (selectedMonth === 'All') {
					return true;
				}
				const date = new Date(+eventYear, +eventMonth - 1, +day);
				const monthString = date.toLocaleString('default', {month: 'short'});

				if (monthString === selectedMonth) {
					return true;
				}
			}
		});
		return fi(type === EventsType.Active, sortByDate([...filtered]), sortByDate([...filtered], true));
	}, [selectedMonth, selectedYear, events]);

	const noContentMessage = useMemo(() => {
		let qualification = subject?.getQualificationAndSubject();
		return (
			<div className="flex-column-center align-center">
				<div>{fi(type === EventsType.Active, Messages.NoPlannedEvents, Messages.NoPastEvents)} <span
					className="text-semiBold"> {qualification}</span></div>
				<br/>
				{fi(type === EventsType.Active, Messages.CheckAgainLater,
					<div className="flex-row-center">
						Please contact&nbsp;<a href={Messages.CustomerSupportCenter} target="_blank">OCR</a>&nbsp;with
						any questions you might have.
					</div>)}
			</div>
		);
	}, [events]);

	return (
		<>
			<div className="text-20 text-latoBold">
				{fi(filteredEvents.length, `${filteredEvents.length} event(s)`, 'Events')}
			</div>

			{fi(filteredEvents.length,
				<Group items={filteredEvents}/>,
				<NoContent label={noContentMessage}/>,
			)}
		</>

	);
};

export default Events;