import {atom} from "recoil";

export type ISubject = {
    qualification: string,
    subject: string,
    units: string[],
    class_size: any;
    teaching: any
}

export const selectedSubjectAtom = atom<ISubject | null>({
    key: "selectedSubjectAtomStep",
    default: null
})