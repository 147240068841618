import React from "react";
import SubjectsStep from "./SubjectsStep";
import CommunicationStep from "./CommunicationStep";
import CenterInfoStep from "./CenterInfoStep";
import {Roles} from "../../tw/models/Session";
import AboutYouStep from "./AboutYouStep";

const CentreInfoLabel = "Centre info."
const AboutYouLabel = "About you"
const SubjectsLabel = "Your subjects";
const CommunicationsLabel = "Communications";
export const RegistrationSteps = {
    [Roles.Trial]: [
        {
            label: CentreInfoLabel,
            component: () => <CenterInfoStep/>
        },
        {
            label: SubjectsLabel,
            component: () =>  <SubjectsStep/>
        },
        {
            label: CommunicationsLabel,
            component: () =>  <CommunicationStep/>
        }
    ],
    [Roles.ExamsOfficer]: [ //same for ASSESSOR & TEACH_CAMBRIDGE_RESTRICTED_ACCESS
        {
            label: SubjectsLabel,
            component: () => <SubjectsStep/>
        },
        {
            label: CommunicationsLabel,
            component:  () => <CommunicationStep/>
        }
    ],
    [Roles.Teacher]: [
        {
            label: AboutYouLabel,
            component: () =>  <AboutYouStep/>
        },
        {
            label: SubjectsLabel,
            component:  () => <SubjectsStep/>
        },
        {
            label: CommunicationsLabel,
            component: () =>  <CommunicationStep/>
        }
    ],
    ['RECONFIRM']:[
        {
            label: AboutYouLabel,
            component: () =>  <AboutYouStep/>
        },
    ]
}