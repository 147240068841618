import React from 'react';
import {trialSurveySupport} from '../../../state/trial';
import {useRecoilValue} from 'recoil';
import {fi} from '../../../utils/helpers';
import {TrialMessages} from '../messages';

const FinalStep = () => {
	const needSupport = useRecoilValue(trialSurveySupport);

	return (
		<div className="flex-row-center">
			{fi(needSupport === 'Yes',
				<div className="text-16 text-regular" data-testid='final-step-support'>
					<span className="text-semiBold">
						{TrialMessages.SurveyFinalSupport1} &nbsp;
					</span>
					{TrialMessages.SurveyFinalSupport2}
				</div>,
				<div className="text-16 text-semiBold"  data-testid='final-step-no-support'>
					{TrialMessages.SurveyFinalNoSupport}
				</div>)}
		</div>
	);
};

export default FinalStep;