import {UserSession} from '../../../tw/models/Session';
import React from 'react';

const JobComponent = ({user}: { user: UserSession }) => {
	if (!user.preferences) return null;
	return (
		<div className="mb-32">
			<div className="text-20 text-semiBold pb-8">Job title</div>
			<div className="text-16 text-regular" data-testid="job-title">{user.preferences.jobTitle}</div>
		</div>
	);
};

export default JobComponent;