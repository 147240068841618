import {EventsAir} from '../../../../tw/models/EventsAir';
import Divider from '@mui/material/Divider';
import Group from '../../../Card/Group';
import React from 'react';
import styled from '@emotion/styled';

const TrainingElem = styled.div`
	.subheading{
		color: var(--color-grey-text)
	}
`
const TrainingMaterialsSection = ({event}: { event: EventsAir }) => {
	return (
		<TrainingElem className="mb-32">
			<Divider/>
			<div className="mb-16 mt-24 heading-section">
				<div className="text-20 text-semiBold mb-8">Training materials</div>
				<div className="subheading text-16 text-regular">The documents and materials used during this course</div>
			</div>
			<Group items={event.eventMaterials.map(e => e.getObject())} hideActions={true}/>
		</TrainingElem>
	);
};

export default TrainingMaterialsSection