import React from 'react';
import {ExamTypeFilter} from '../../../../state/keyDates';
import {fi} from '../../../../utils/helpers';
import KeyTypeFilterComponent from './KeyTypeFilterComponent';

const Series = () => {
	const set = (filter: ExamTypeFilter, value: string) => {
		return {...filter, series: fi(value !== 'All', value)}
	}

	const sort = (a, b) => {
		const aYear = a.substring(a.length - 5);
		const bYear = b.substring(b.length - 5);
		if (aYear < bYear) return -1;
		if (aYear > bYear) return 1;

		const aSeries = a.substring(0, a.length - 5);
		const bSeries = b.substring(0, b.length - 5);
		if (aSeries < bSeries) return -1;
		if (aSeries > bSeries) return 1;
		return 0;
	};

	return (
		<KeyTypeFilterComponent keyFilter={'series'} title='Series' filter={item => item.getSeriesYear()} set={set} sort={sort}/>
	);
};

export default Series;