import { Lists } from '../../utils/lists';
import { fi } from '../../utils/helpers';
import { Subject } from '../../tw/models/Subject';
import { productTreeSelector } from '../../state/product';
import { getRecoil, setRecoil } from '../../state/recoilNexus';
import { CMSObject, DisplayMode } from '../../tw/models/__CMSObject';
import { defaultSubjectAtom, sessionAtom, sessionSelector } from '../../state/session';
import { registrationDataAtom } from '../../pages/Registration';
import Client from '../../tw/client';
import { selectedSubjectAtom } from '../SubjectsComponents/utils';
import { ISelectValue } from '../FormComponents/Select/SelectComponent';
import { UserSession } from '../../tw/models/Session';
import { AlertSeverity, snackbarStateAtom } from '../Snackbar/SnackbarComponent';
import { Messages } from '../../utils/messages';
import { showTrialWelcome } from '../../utils/constants';
import { Strings } from '../../utils/strings';
import GA from '../../tw/models/GA';
import { Browser } from '../../utils/browser';

/** registration links **/
export enum RegistrationLinks {
	customerSupportCenter = 'https://www.ocr.org.uk/contact-us/',
	termsAndConditions = 'https://www.cambridgeassessment.org.uk/footer/terms-and-conditions/index.aspx'
}

/** options **/
enum OptionsLabels {
	YesOption = 'Yes',
	NoOption = 'No',
}

export enum RolesLabels {
	Teacher = 'Teacher',
	HeadOfDepartment = 'Head of department',
	Headteacher = 'Headteacher',
	ExamOfficer = 'Exam officer'
}

/** registration options **/
export const TrueOrFalseOptions = [
	{label: OptionsLabels.YesOption, value: true},
	{label: OptionsLabels.NoOption, value: false},
];

export const JobsOptions: ISelectValue[] = [
	{
		label: RolesLabels.Teacher,
		value: RolesLabels.Teacher,
		object: null,
	},
	{
		label: RolesLabels.HeadOfDepartment,
		value: RolesLabels.HeadOfDepartment,
		object: null,
	},
	{
		label: RolesLabels.Headteacher,
		value: RolesLabels.Headteacher,
		object: null,
	},
	{
		label: RolesLabels.ExamOfficer,
		value: RolesLabels.ExamOfficer,
		object: null,
	},
];
/** functions **/
export const getQualificationDataSource = () => {
	let qualifications = getRecoil(productTreeSelector);
	if (!qualifications) return [];
	let list = qualifications.map((qualification) => ({
			label: qualification.getTitle(),
			value: qualification.getId(),
			isDisabled: qualification.isDisabled(),
		}),
	);
	return Lists.sort(list, 'label');
};

export const getSubjectDataSource = (values, config) => {
	let qualifications = getRecoil(productTreeSelector);
	if (!qualifications) return [];
	let qualification: any = null;
	if (typeof values === 'string') {
		qualification = qualifications.find(q => q.getId() === values);
	} else {
		qualification = qualifications.find(q => q.getId() === values[config[0].name]);
	}
	if (!qualification) return [];

	let list = Lists.default<Subject>(qualification.getSubjects()).map((s) => ({
			label: s.getTitle(),
			value: s.getId(),
			isDisabled: s.isDisabled(),
		}),
	);
	return Lists.sort(list, 'label');
};
export const getUnitDataSource = (values, config, extra?) => {
	let qualifications = getRecoil(productTreeSelector);
	if (!qualifications) return [];

	let qualification = qualifications.find(q => q.getId() === values[config[0].name]);
	if (!qualification) return [];
	let subject = qualification.getSubjects().find(s => s.getId() === values[config[1].name]);


	if (!subject) return [];

	let list = Lists.default<CMSObject>(subject.getUnits()).map((u) => ({
			label: u.displayLabel(DisplayMode.FULL),
			value: u.getId(),
			object: u,
		}),
	);

	list = Lists.sort(list, 'label');

	if (extra) {
		list.unshift({label: 'All units', value: 'all', object: null as any as CMSObject});
	}

	return list;
};

export const getSubjectByQualification = (qualificationId: string, subjectId: string): Subject | null => {
	let qualifications = getRecoil(productTreeSelector);
	if (!qualifications) return null;

	let qualification = qualifications.find(q => q.getId() === qualificationId);
	if (!qualification) return null;

	const subject = qualification.getSubjects().find(s => s.getId() === subjectId);
	if (!subject) return null;
	return subject;
};

export const hideTeachingSection = () => {
	let userSession = getRecoil(sessionAtom);
	if (!userSession || (userSession?.getQualifications().length)) return true;
	return userSession.isTrial() || userSession.isAssessor() || userSession.isExamsOfficer() || userSession.isRestrictedAccess();
};

export const disableActionButtons = () => {
	let selectedSubject = getRecoil(selectedSubjectAtom);
	return !!(selectedSubject && (selectedSubject.qualification === '' || selectedSubject.qualification));
};

export const saveUserPreferences = async () => {
	let aboutData = getRecoil(registrationDataAtom('job'));
	let qualificationsData = getRecoil(registrationDataAtom('qualifications'));
	let trialInfo = getRecoil(registrationDataAtom('trial'));
	let allowContact = getRecoil(registrationDataAtom('allowContact'));
	let user = getRecoil(sessionAtom);
	if (!user) return null;
	let jobTitle = user.getJobTitle();

	if (user.isTrial()) {
		const sessionStoredValue = sessionStorage.getItem('trialAccessSetup');
		jobTitle = sessionStoredValue && JSON.parse(sessionStoredValue).jobTitle || 'Teacher';
	} else if (user.isFirstTimeUser() || user.shouldUpdatePreferences()) {
		if (user.isRestrictedAccess()) {
			jobTitle = 'Restricted access';
		} else if (user.isExamsOfficer()) {
			jobTitle = 'Exams officer';
		} else if (user.isAssessor()) {
			jobTitle = 'Assessor';
		} else {
			jobTitle = aboutData;
		}
	}
	let qualifications = user.getQualifications().map(q => q.__data);
	if (qualificationsData) qualifications = qualificationsData;
	const payload: any = {
		job_title: jobTitle,
		qualification: qualifications,
		registrationinfo: fi(user, {...trialInfo, allowContact}, {}),
		preferences: {...user.getPreferences(), defaultSubject: Strings.default(getRecoil(defaultSubjectAtom))},
	};
	await Client.saveUserPreferences(payload).then((res) => {
		if (!user) return;
		if (user.isTrial() && user.isFirstTimeUser()) {
			sessionStorage.setItem(showTrialWelcome, 'true');
		}
		if (user.isFirstTimeUser() || user.shouldUpdatePreferences()) {
			Browser.navigate('/');
		}
		setRecoil(sessionSelector, new UserSession(res));
		if (!user.isFirstTimeUser()) {
			setRecoil(snackbarStateAtom, {
				message: `${Messages.SubjectsUpdateSuccess}`,
				severity: AlertSeverity.Success,
			});
			//to cancel edit mode
			setRecoil(selectedSubjectAtom, null);
		}
	}).catch(() => {
		setRecoil(snackbarStateAtom, {
			message: `${Messages.SubjectsUpdateError}`,
			severity: AlertSeverity.Error,
		});
	}).finally(() => {
		GA.UpdatePreferencesEvent();
	});
};