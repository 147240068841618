import React, {useMemo} from 'react';
import {fi} from '../../utils/helpers';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import {device} from '../../utils/constants';
import {bindPopover, bindTrigger, usePopupState} from 'material-ui-popup-state/hooks';
import Popover from '@mui/material/Popover';
import {useMediaQuery} from '@mui/material';

const RELATED_SITES = [
	{
		label: 'Active Results',
		redirectTo: 'https://activeresults-online.ocr.org.uk',
	},
	{
		label: 'Exam Builder',
		redirectTo: 'https://exambuilder.ocr.org.uk/',
	},
	{
		label: 'Interchange',
		redirectTo: 'https://interchange.ocr.org.uk/',
	},
];

const ButtonElem = styled(Button)`
    .header-buttons {
        color: var(--color-white);
        font-size: 14px;
        padding: 0 !important;
        margin-left: 24px;
        font-family: var(--font-semi-bold) !important;
    }

    &:hover {
        background-color: unset !important;
    }

    @media ${device.tablet} {
        padding: 0 !important;
        color: var(--color-white)
    }
`;

const DropdownElem = styled.div`
    background-color: var(--color-black);
    display: block;
    min-height: 103px;
    position: absolute;
    top: 43px;
    flex-direction: column;
    width: 200px;
    z-index: 9999;

    a {
        &:first-of-type {
            margin-top: 24px;
        }

        &:last-of-type {
            margin-bottom: 24px;
        }

        margin-top: 8px;
        display: flex;
        align-items: center;
        margin-left: 24px;
        font-size: 14px;
        font-family: var(--font-semi-bold)
    }

    @media ${device.tablet} {
        display: flex;
        position: unset;
    }
`;

const PopoverContent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--color-black);
    min-height: 103px;
    width: 200px;
    padding: 12px;

    a {
        color: var(--color-white);
        margin-top: 8px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: var(--font-semi-bold)
    }

    @media ${device.tablet} {
        width: 100%;
    }
`
const RelatedSites = () => {
	const popupState = usePopupState({variant: 'popover', popupId: 'related-sites'});
	const mobile = useMediaQuery(device.mobile);

	const popoverPosition = useMemo(() => {
		if (mobile) {
			return {
				anchor: {vertical: 'top', horizontal: 'center'},
				transform: {vertical: 'bottom', horizontal: 'center'},
			};
		}
		return {
			anchor: {vertical: 'bottom', horizontal: 'left'},
			transform: {vertical: 'top', horizontal: 'left'},
		};
	}, [mobile]);

	return (
		<div data-testid="related-sites-section">
			<ButtonElem data-testid="related-sites-button" disableRipple
						className="header-buttons" {...bindTrigger(popupState)}>
				Teach Cambridge
				{fi(!popupState.isOpen,
					<ExpandMore fontSize="small"/>,
					<ExpandLess fontSize="small"/>)}
			</ButtonElem>

			<Popover {...bindPopover(popupState)}
					 anchorOrigin={popoverPosition.anchor as any}
					 transformOrigin={popoverPosition.transform as any}
					 data-testid="related-sites-dropdown"
			>
				<PopoverContent>
					{RELATED_SITES.map((item, index) => (
						<a href={item.redirectTo} target="_blank" key={item.label}
						   data-testid={`${item.label}-test-id`}>
							{item.label}
						</a>
					))}
				</PopoverContent>
			</Popover>
		</div>
	);
};

export default RelatedSites;