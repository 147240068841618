import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {articlesSelector} from '../../../state/articles';
import Card from '../../Card/Card';
import styled from '@emotion/styled';
import NoContent from '../../commons/NoContent';
import {fi} from '../../../utils/helpers';
import {Messages} from '../../../utils/messages';
import {enableUnitFilter, SubjectSelector} from '../../../state/product';
import WidgetHeader from '../commons/WidgetHeader';
import {WidgetTitles} from '../commons/widgetTitles';
import {WidgetContext} from '../widget';
import ViewAllBtn from '../commons/ViewAllBtn';

const FAQWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
`;

type FAQWidget = {
	showTitle?: boolean,
	showAll?: boolean,
}

const FAQ = () => {
	const context = useContext(WidgetContext);
	const widget = context.widget as FAQWidget;

	const articles = useRecoilValue(articlesSelector);
	const subject = useRecoilValue(SubjectSelector);
	const [expanded, setExpanded] = useState(false);

	const unitFilterEnabled = useSetRecoilState(enableUnitFilter);

	useEffect(() => {
		unitFilterEnabled(false);
		return () => {
			unitFilterEnabled(true);
		};
	}, []);

	const message = useMemo(() => {
		if (!articles) return '';
		if (!articles.length) {
			let qualification = subject?.getQualificationAndSubject();
			return (<div className="flex-column-center align-center">
				<div>{Messages.NoFAQ} <span className="text-semiBold"> {qualification}</span></div>
				<br/>
				{Messages.ChangeFilterMessage}
			</div>);
		}
		return '';
	}, [articles]);

	const viewAll = () => {
		setExpanded(!expanded)
	}

	if (!articles) return null;

	return (
		<FAQWrapper data-testid='faq-wrapper'>
			{fi(widget.showTitle, <WidgetHeader>
				<h2> {WidgetTitles.FAQ}</h2>
			</WidgetHeader>)}
			{fi(articles.length, fi(expanded || widget.showAll, articles, articles.slice(0, 5)).map((item: any, idx: number) => (
				<Card item={item} key={item.id} showCheckbox={false} />
			)), <NoContent label={message} />)}
			{fi(articles.length > 5, <div className="flex-row-end mr-16">
				<ViewAllBtn viewAll={viewAll} expanded={expanded} nrOfItems={articles.length} />
			</div>)}
		</FAQWrapper>
	);
};

export default FAQ;