import {CMSObject} from "./__CMSObject";
import {Subject} from "./Subject";
import {Strings} from "../../utils/strings";
import {Lists} from "../../utils/lists";

export class Qualification extends CMSObject {
    public title: string;
    public description: string;
    public isForTechnicals: boolean;
    public disabled: boolean;
    public subjects: Subject[] = [];
    public qualificationid: number;

    constructor(item: any = {}) {
        super(item);
        this.title = Strings.default(item.title);
        this.description = Strings.default(item.description);
        this.isForTechnicals = Boolean(item.isForTechnicals);
        this.disabled = Boolean(item.disabled);
        this.qualificationid = item.qualificationid;
        Lists.default(item.subjects).forEach(s => {
            this.subjects.push(new Subject(s));
        });
    }

    public getQualId(): number {
        return this.qualificationid;
    }

    public getTitle(): string {
        return this.title;
    }

    public isDisabled(): boolean {
        return this.disabled;
    }

    public getSubjects(): Subject[] {
        return this.subjects
    }
}
