import {CMSObject, DisplayMode} from './__CMSObject';
import {Strings} from '../../utils/strings';
import {fi} from '../../utils/helpers';

export class ContentType extends CMSObject {
	public name: string;
	public description: string;
	public rateable_content: boolean;

	constructor(obj: any = {}) {
		super(obj);

		this.name = Strings.default(obj.name);
		this.description = Strings.default(obj.description);
		this.rateable_content = fi(typeof obj.rateable_content === 'undefined', true, Boolean(obj.rateable_content));
	}

	public displayLabel(_options: DisplayMode = DisplayMode.SHORT): string {
		return this.name;
	}

	public isRateable(): boolean {
		return this.rateable_content;
	}

	// retrieves first occurrence of a #
	public getNameTag(): string {
		const parts = this.name.split(' ');
		return Strings.default(parts.find(part => part.includes('#')));
	}
}