import React from 'react';
import styled from '@emotion/styled';
import {ExamDateType} from '../../../state/keyDates';
import {Dates} from '../../../utils/dates';
import Flag from '../../Card/Flag';

const Container = styled.div`
    display: grid;
    width: 49%;
	position: relative;

    border: 1px solid var(--color-border);
    border-radius: 6px;
    background-color: white;

    grid-template-areas: "date info"
						"series info";
    grid-template-columns: auto 1fr;
    grid-template-rows: 90px 45px;


    @media (max-width: 1200px) {
        width: 100%;
    }
	
	&.key_date > div:not(:last-of-type):not(.flag) {
        background: var(--color-lighter-blue-background);
    }
	
	&.timetable_date > div:not(:last-of-type):not(.flag) {
		background: var(--color-light-orange);
	}
`;

const CardDate = styled.div`
    grid-area: date;
    padding: 16px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    border-right: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    border-radius: 6px 0 0 0;

    font-family: var(--font-regular);
	font-weight: bold;

    &> span:last-of-type {
        font-family: var(--font-bolder);
        font-size: 24px;
        line-height: 28px;
		font-weight: 700;
    }
`;

const Series = styled.div`
    grid-area: series;
    padding: 16px;
	
    display: flex;
	align-items: center;
	
    border-right: 1px solid var(--color-border);
    border-radius: 0 0 0 6px;

    font-family: var(--font-regular);
	font-size: 12px;
	font-weight: 400;

    > span {
        font-family: var(--font-bold);
    }
`;

const Info = styled.div`
    grid-area: info;
    padding: 16px;
	color: var(--color-monochrome);
	
    display: flex;
    align-items: flex-start;
	flex-direction: column;
	justify-content: space-between; 
	
	font-size: 14px;
    font-family: var(--font-regular);
	
	&>div>div:first-of-type {
		font-size: 16px;
		font-family: var(--font-bold);
		
		&:first-of-type {
			margin-bottom: 6px;
		}
	}
	
	&>div:last-of-type {
        
		font-weight: bold;
    }
`;

const Card = ({keyDate}: {keyDate: ExamDateType}) => {
	return (
		<Container className={keyDate.getType()} data-testid='key-date-card-container'>
			<Flag item={keyDate} data-testid='key-date-card-flag'/>
			<CardDate>
				<span className='text-14' data-testid='key-date-month'>{Dates.getMonth(keyDate.date)}</span>
				<span data-testid='key-date-date'>{keyDate.date.getDate().toString().padStart(2, '0')}</span>
			</CardDate>
			<Series data-testid='key-date-series'>Series: &nbsp; <span>{keyDate.getSeriesYear()}</span></Series>
			<Info>
				<div data-testid='title-section'>
					{keyDate.getTitle()}
					{keyDate.getDate()}
					<div className='text-14 mb-8' data-testid='key-date-description'>{keyDate.description}</div>
				</div>
				{keyDate.renderQualification()}
			</Info>
		</Container>
	);
};

export default Card;