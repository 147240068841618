import {InfoOutlined} from '@mui/icons-material';
import React from 'react';
import {bindPopover, usePopupState, bindHover} from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import styled from '@emotion/styled';

const HelpTextElem = styled.div`
    width: 250px;
    font-family: var(--font-regular);
    color: var(--color-monochrome);
    font-size: 14px;
    line-height: 18px;
    padding: 8px;
`;

const InfoPopover = ({helpText, popupState}) => {
	return (
		<HoverPopover {...bindPopover(popupState)}
					  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
					  transformOrigin={{vertical: 'top', horizontal: 'right'}}
					  data-testid="info-popover"
		>
			<HelpTextElem>
				{helpText}
			</HelpTextElem>
		</HoverPopover>
	);
};
const InfoElement = (props) => {
	const popupState = usePopupState({variant: 'popover', popupId: 'account-dropdown'});

	return (
		<div data-testid="info-element">
			<InfoOutlined className="mb-8 info-comp" color="primary" {...bindHover(popupState)}/>
			<InfoPopover helpText={props.helpText} popupState={popupState} />
		</div>
	);
};

export default InfoElement;