import {createTheme} from '@mui/material';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#0074bd',
		},
		secondary: {
			main: '#424242',
		},
	},
	typography: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fontSize: 16,
		h1: {
			fontFamily: 'var(--font-bolder)',
			fontWeight: 400,
			fontSize: 18,
			color: 'var(--color-monochrome)',
		},
		h2: {
			fontFamily: 'var(--font-bold)',
			fontWeight: 400,
			fontSize: 18,
			color: 'var(--color-monochrome)',
		},
		h3: {
			fontFamily: 'var(--font-semibold)',
			fontSize: 24,
		},
		h4: {
			fontFamily: 'var(--font-semibold)',
			fontSize: 16,
			fontWeight: 600,
		},
	},
	components: {
		MuiInputBase: {
			defaultProps: {},
			styleOverrides: {
				root: {
					padding: '2px',
					fontSize: 16,
					fontFamily: 'var(--font-regular)',
					border: '1px solid var(--color-border)',
					borderRadius: 4,
				},

			},
		},
		MuiInput: {
			defaultProps: {
				disableUnderline: true,
			},
			styleOverrides: {
				root: {
					padding: '4px 8px',
					'&.Mui-error': {
						borderColor: 'var(--color-red) !important',
					},
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					width: '100%',
					marginBottom: 8,
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontFamily: 'var(--font-semi-bold)',
					fontSize: 16,
					marginBottom: 8,
					color: 'var(--color-monochrome)',
					lineHeight: '22px',
					'&.Mui-focused': {
						color: 'var(--color-monochrome)',
					},
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					color: 'var(--color-monochrome)',
					fontFamily: 'var(--font-semi-bold)',
					fontSize: 16,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: 16,
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					borderRadius: 4,
					padding: '8px 22px',
					color: 'var(--color-blue)',
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				root: {
					borderRadius: 4,
					padding: '8px 22px',
					textTransform: 'none',
					boxShadow: 'none',
					fontSize: 16,
					fontFamily: 'var(--font-semi-bold)',
					fontWeight: 500,
					userSelect: 'none',
					transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
					'&:hover': {
						boxShadow: 'none',
					},
					'&:active': {
						boxShadow: '0 0 0 1px var(--color-blue);',
					},
					'&:focus-visible': {
						boxShadow: '0 0 0 1px var(--color-blue);',
					},
				},
				containedPrimary: {
					backgroundColor: 'var(--color-blue)',
					borderColor: 'var(--color-blue)',
					color: 'var(--color-white)',
					minWidth: 100,
					margin: '0 8px',
					':disabled': {
						backgroundColor: 'var(--color-blue)',
						color: 'var(--color-white)',
						opacity: 0.5,
					},
					'&:last-of-type': {
						marginRight: 0,
					},
					'&:first-of-type': {
						marginLeft: 0,
					},
					'&:hover': {
						backgroundColor: 'var(--color-darker-blue)',
						borderColor: 'var(--color-blue)',
						color: 'var(--color-white)',
					},
					'&:active': {
						backgroundColor: 'var(--color-blue)',
						borderColor: 'var(--color-blue)',
						color: 'var(--color-white)',
					},
					'&:focus-visible': {
						backgroundColor: 'var(--color-blue)',
						borderColor: 'var(--color-blue)',
						color: 'var(--color-white)',
					},
				},
				containedSecondary: {
					backgroundColor: 'var(--color-background)',
					borderColor: 'var(--color-border)',
					color: 'var(--color-black)',
					minWidth: 100,
					margin: '0 8px',
					'&:last-of-type': {
						marginRight: 0,
					},
					'&:first-of-type': {
						marginLeft: 0,
					},
					'&:hover': {
						backgroundColor: 'var(--color-grey)',
						borderColor: 'var(--color-border)',
						color: 'var(--color-black)',
					},
					'&:active': {
						backgroundColor: 'var(--color-grey)',
						borderColor: 'var(--color-border)',
						color: 'var(--color-black)',
					},
					'&:focus-visible': {
						backgroundColor: 'var(--color-grey)',
						borderColor: 'var(--color-border)',
						color: 'var(--color-black)',
					},
				},
				containedError: {
					backgroundColor: 'var(--color-red)',
					borderColor: 'var(--color-red)',
					color: 'var(--color-white)',
					minWidth: 100,
					margin: '0 8px',
					'&:last-of-type': {
						marginRight: 0,
					},
					'&:first-of-type': {
						marginLeft: 0,
					},
					'&:hover': {
						backgroundColor: 'var(--color-red)',
						borderColor: 'var(--color-red)',
						color: 'var(--color-white)',
					},
					'&:active': {
						backgroundColor: 'var(--color-red)',
						borderColor: 'var(--color-red)',
						color: 'var(--color-white)',
					},
					'&:focus-visible': {
						backgroundColor: 'var(--color-red)',
						borderColor: 'var(--color-red)',
						color: 'var(--color-white)',
					},
				},
				textPrimary: {
					borderRadius: 4,
					border: '1px solid transparent',
					':disabled': {
						color: 'var(--color-blue)',
						opacity: 0.5,
					},
					'&:hover': {
						backgroundColor: 'var(--color-hover)',
					},
					'&:focus-visible': {
						boxShadow: '0 0 0 1px var(--color-blue);',
					},
				},
				textSecondary: {
					borderRadius: 4,
					border: '1px solid transparent',
					':disabled': {
						color: 'var(--color-monochrome)',
						opacity: 0.5,
					},
					'&:hover': {
						backgroundColor: 'var(--color-hover)',
					},
					'&:focus-visible': {
						boxShadow: '0 0 0 1px var(--color-monochrome);',
					},
				},
				textError: {
					borderRadius: 4,
					border: '1px solid transparent',
					color: 'var(--color-red)',
					'&:hover': {
						backgroundColor: 'var(--color-box-shadow)',
					},
					'&:focus-visible': {
						boxShadow: '0 0 0 1px var(--color-blue);',
					},
					':disabled': {
						color: 'var(--color-red)',
						opacity: 0.5,
					},
				},
				outlined: {
					borderRadius: 4,
					borderColor: 'var(--color-blue)',
					'&:hover': {
						backgroundColor: 'var(--color-hover)',
					},
				},
				sizeSmall: {
					height: '36px',
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					borderRadius: 2,
					padding: 0,
				},
			},
		},
		MuiLink: {
			defaultProps: {
				color: 'inherit',
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					padding: '9px',
					'&:hover': {
						backgroundColor: 'transparent',
					},
					icon: {
						borderRadius: '50%',
						width: 20,
						height: 20,
						backgroundColor: 'white',
						border: 'solid 1px',
						backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
						'$root.Mui-focusVisible &': {
							outline: '2px auto rgba(19,124,189,.6)',
							outlineOffset: 2,
						},
						'input:hover ~ &': {
							backgroundColor: '#ebf1f5',
						},
						'input:disabled ~ &': {
							boxShadow: 'none',
							background: 'rgba(206,217,224,.5)',
						},
					},
					checkedIcon: {
						border: 'unset',
						backgroundColor: 'var(--color-blue)',
						backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
						'&:before': {
							display: 'block',
							width: 20,
							height: 20,
							backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
							content: '""',
						},
						'input:hover ~ &': {
							backgroundColor: 'var(--color-blue)',
						},
					},
				},

			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: 'var(--color-red)',
				},
			},
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					'&.MuiLinearProgress-determinate': {
						backgroundColor: 'var(--color-grey)',
					},
					'&.MuiLinearProgress-bar1': {
						backgroundColor: 'var(--color-blue)',
					},
				},
			},
		},
		MuiStep: {
			styleOverrides: {
				root: {
					horizontal: {
						padding: 0,
					},
				},
			},
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					height: '36px',
					width: '36px',
					color: 'var(--color-grey)',
					zIndex: 1,
					'&.Mui-active': {
						color: 'var(--color-blue)',
					},
				},
			},
		},
		MuiStepConnector: {
			styleOverrides: {
				root: {
					top: '18px',
					height: '4px',
					'&.Mui-active, &.Mui-completed': {
						'.MuiStepConnector-line': {
							borderColor: 'var(--color-blue)',
							zIndex: '-1',
						},
					},
				},
				line: {
					borderTopWidth: '4px',
					borderRadius: '2px',
					borderColor: 'var(--color-grey)',
					zIndex: '-1',
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				label: {
					fontFamily: 'var(--font-semi-bold)',
					fontSize: '16px',
					lineHeight: '22px',
					color: 'var(--color-black)',
					marginTop: '4px !important',
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: 'unset',
					fontFamily: 'var(--font-semi-bold)',
					backgroundColor: 'var(--color-background)',
					border: 'solid 1px var(--color-border-light)',
					borderRadius: 0,
					'&:first-of-type': {
						borderLeft: 'unset',
					},
					'&.Mui-selected': {
						backgroundColor: 'var(--color-white)',
						borderBottom: 'unset',
						'&:last-of-type': {
							borderRight: 'unset',
						},
					},
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					bottom: 'unset',
					top: 0,
				},
			},
		},
		MuiFormGroup: {
			styleOverrides: {
				root: {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					'.MuiFormControl-root': {
						width: '48%',
					},
				},
			},
		},
		MuiSnackbar: {
			styleOverrides: {
				anchorOriginTopRight: {
					top: '80px !important',
					right: '24px !important',
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
				},
				message: {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
				},
				standardError: {
					color: 'var(--color-red)',
					border: 'solid 1px var(--color-darker-red)',
				},
				standardSuccess: {
					color: 'var(--color-green)',
					border: 'solid 1px var(--color-green)',
				},
				standardInfo: {
					color: 'var(--color-blue)',
					border: 'solid 1px var(--color-blue)',
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				root: {
					'.MuiMenuItem-root': {
						color: 'var(--color-black)',
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: 'var(--color-white)',
					color: 'var(--color-monochrome)',
					fontSize: '12px',
					border: 'solid 1px var(--color-black)',
					borderRadius: 0,
				},
			},
		},
	},
});