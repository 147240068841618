import {ZendeskArticle} from '../../../../tw/models/ZendeskArticle';
import React, {useEffect} from 'react';
import {scrollAppToTop} from '../../../../utils/helpers';
import styled from '@emotion/styled';

const FAQContentElem = styled.div`
    .target-elem {
        scroll-margin-top: 80px;
    }
`;
const FAQDetailsContent = ({article}: { article: ZendeskArticle }) => {

	useEffect(() => {
		scrollAppToTop();
	}, []);

	useEffect(() => {
		if (!article) return;
		parseOverview();
	}, [article]);

	const parseOverview = () => {
		let overviewContent = document.getElementById('article-overview-content');
		overviewContent!.innerHTML = article.body;
		if (overviewContent) {
			// get all anchor elements
			let allAnchors = Array.from(overviewContent.getElementsByTagName('a'));
			// anchors to same page sections
			allAnchors.forEach(a => {
				// check if anchor has a hash and no pathname => same page anchor
				if (a.hash && a.pathname === '/') {
					// change anchor's path to current page
					a.pathname = document.location.pathname;
					let target: HTMLElement | null = document.querySelector(a.hash);
					if (target) {
						target.classList.add('target-elem');
					}
				}
			});
		}
	};
	return (
		<FAQContentElem data-testid='faq-details' id="article-overview-content" className="mb-24 wysiwyig-content"/>
	);
};

export default FAQDetailsContent;