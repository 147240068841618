import {SubjectUpdate} from '../../../../tw/models/SubjectUpdate';
import React, {useMemo} from 'react';
import {Dates} from '../../../../utils/dates';

const DetailsHeading = ({subjectUpdate}: { subjectUpdate: SubjectUpdate }) => {
	const updatedInfo = useMemo(() => {
		if (!subjectUpdate) return '';
		let label = 'Added';
		if (subjectUpdate.getVersion() > 1) label = 'Updated';
		return `${label}-${Dates.format(subjectUpdate.getPublishedDate())}`;
	}, [subjectUpdate]);


	return (
		<>
			<div className="text-24 text-latoBold mb-8" data-testid='subject-update-title'>{subjectUpdate.title}</div>
			<div data-testid='subject-update-label'> {updatedInfo}</div>
		</>
	);
};

export default DetailsHeading