import React, {useEffect, useMemo} from 'react';
import CarouselItems, {CarouselItemsType, selectedMonthAtom, selectedYearAtom} from '../commons/CarouselItems';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {eventsCodesAtom, eventsSelector, eventsTypeAtom} from '../../../state/events';
import {SubjectSelector} from '../../../state/product';
import {selectedPageAtom} from '../../../state/state';
import {WidgetType} from '../../../tw/models/Page';
import styled from '@emotion/styled';
import {fi} from '../../../utils/helpers';
import {device} from '../../../utils/constants';

const MONTHS = {
	'Jan': 1,
	'Feb': 2,
	'Mar': 3,
	'Apr': 4,
	'May': 5,
	'Jun': 6,
	'Jul': 7,
	'Aug': 8,
	'Sept': 9,
	'Oct': 10,
	'Nov': 11,
	'Dec': 12,
};


const EventsFilterElem = styled.div`
    .label {
        width: 65px;
    }

    .carousel-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .year-carousel {
        margin-bottom: 16px;
    }

    @media ${device.mobile} {
        .carousel-item {
            flex-direction: column;
            justify-content: center;
			align-items: flex-start;
        }
    }
`;
const EventsFilter = () => {
	const setEventsCodes = useSetRecoilState(eventsCodesAtom);
	const events = useRecoilValue(eventsSelector);
	const selectedSubject = useRecoilValue(SubjectSelector);
	const selectedPage = useRecoilValue(selectedPageAtom);
	const setEventsType = useSetRecoilState(eventsTypeAtom);
	const [year, setSelectedYear] = useRecoilState(selectedYearAtom);
	const [month, setSelectedMonth] = useRecoilState(selectedMonthAtom);

	useEffect(() => {
		setSelectedYear('All');
		setSelectedMonth('All');
	}, []);

	useEffect(() => {
		if (!selectedSubject) return;
		if(selectedSubject.isTechnicals()){
			setEventsCodes(selectedSubject.getQualificationSizes())
		} else {
			setEventsCodes(selectedSubject.getAssessmentCodes());
		}
	}, [selectedSubject]);

	useEffect(() => {
		if (!selectedPage) {
			return;
		}
		const eventsWidget = selectedPage.object.config.find(c => c.type === WidgetType.Events);
		if (!eventsWidget) {
			return;
		}
		setEventsType(eventsWidget.eventType);
	}, [selectedPage]);

	const years = useMemo(() => {
		if (!events) return [];
		let list = events.map(e => {
			let d = new Date(e.startDate);
			return d.getFullYear().toString();
		});
		return ['All'].concat(Array.from(new Set(list)).sort((a, b) => a.localeCompare(b)));
	}, [events]);

	const months = useMemo(() => {
		if (!events) return [];
		let listOfMonths: string[] = [];
		events.forEach(e => {
			let d = new Date(e.startDate);
			if (year === 'All' || d.getFullYear().toString() === year) {
				listOfMonths.push(d.toLocaleString('en-GB', {month: 'short'}));
			}
		});
		listOfMonths.sort((a, b) => MONTHS[a] - MONTHS[b]);
		return ['All'].concat(Array.from(new Set(listOfMonths)));
	}, [events, year]);

	useEffect(() => {
		//reset month to all if year changed and selected month is no longer available
		if (!months.includes(month) || year === 'All') {
			setSelectedMonth('All');
		}
	}, [year]);

	return (
		<EventsFilterElem>
			{fi(years.length > 1,
				<div className="carousel-item year-carousel">
					<span className="text-16 text-regular label"> Year: </span>
					<CarouselItems items={years} type={CarouselItemsType.Year}/>
				</div>)}
			{fi(months.length > 1,
				<div className="carousel-item">
					<span className="text-16 text-regular label"> Month: </span>
					<CarouselItems items={months} type={CarouselItemsType.Month}/>
				</div>)}
		</EventsFilterElem>
	);
};

export default EventsFilter;