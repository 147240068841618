import { ContentType } from '../../tw/models/ContentType';
import { Lists } from '../../utils/lists';
import { Strings } from '../../utils/strings';
import { DocumentsWrapper } from '../../tw/models/DocumentsWrapper';
import { atom } from 'recoil';
import { UUID } from '../../tw/types';
import { HomepageWidgets } from './widget';
import { Objects } from '../../utils/objects';
import { findTreeNode } from '../TreeMenu/utils';
import { WidgetType } from '../../tw/models/Page';
import { Browser } from '../../utils/browser';

export type NamedGroup = {
	title: string,
	description: string,
	items: DocumentsWrapper,
	subgroups?: Array<NamedGroup>,
	tag: string,
	order: number,
}

export const selectedItems = atom<UUID[]>({
	key: 'selectedItems',
	default: [],
});

export const groupByTag = (contentType: ContentType, items: DocumentsWrapper, subgroups: string[] = []): NamedGroup[] => {
	const tag = contentType.getNameTag();
	let map: {[key: string]: NamedGroup} = {};

	if (!tag) {
		map[contentType.name] = {
			title: contentType.displayLabel(),
			description: contentType.description,
			items: items,
			tag: '',
			order: -2,
		};
	} else {
		items.forEach(item => {
			const itemTag = Strings.default(item.getTags().find(t => t.trim().startsWith(tag))).substring(tag.length + 1);
			const parts = itemTag.split('/');
			let titleTag = itemTag.trim();
			let descriptionTag = contentType.description.trim();

			if (parts.length === 2) {
				titleTag = parts[0].trim();
				descriptionTag = parts[1].trim();
			}

			const newTitle = contentType.displayLabel().replace(tag, titleTag);
			if (!map[newTitle]) {
				map[newTitle] = {
					title: newTitle,
					description: descriptionTag,
					items: new DocumentsWrapper(),
					tag: titleTag,
					order: subgroups.findIndex(s => s.includes(titleTag)),
				};
			} else {
				if (map[newTitle].description === contentType.description && descriptionTag !== contentType.description) {
					map[newTitle].description = descriptionTag;
				}
			}

			map[newTitle].items.push(item);
		});
	}

	let result: NamedGroup[] = [];
	Object.keys(map).forEach(key => {
		result.push(map[key]);
	});

	if (subgroups.length) {
		return Lists.sort(result, 'order');
	}

	return Lists.sort(result, 'tag');
};

export const sortCourseItems = (list: DocumentsWrapper) => {
	const onlineCourseWithLabel = '#online_course_';
	const essentialLabel = 'essential';

	return list.sort((a, b) => {
		const aLabel = Strings.default(a.getTags().find(t => t.toLowerCase().startsWith(onlineCourseWithLabel))).replace(onlineCourseWithLabel, '').toLowerCase() === essentialLabel;
		const bLabel = Strings.default(b.getTags().find(t => t.toLowerCase().startsWith(onlineCourseWithLabel))).replace(onlineCourseWithLabel, '').toLowerCase() === essentialLabel;
		if (aLabel && !bLabel) {
			return -1;
		} else if (!aLabel && bLabel) {
			return 1;
		}
		return 0;
	});
};


//if details page of an event/subject update/global announcement is rendered by clicking on homepage card
//the "Find more .." button should redirect the user to the page configured on that homepage widget
export const redirectFromDetailsPage = (type: HomepageWidgets, pages, setPage, selectedPage) => {
	//if clicked from homepage, should go to the page configured with widget
	const homepage = findTreeNode(pages, p => p.object.isLandingPage);
	if (homepage && document.location.pathname.includes(homepage.id)) {
		//find page configured with widget
		const homepageWidgets = homepage.object.config;
		const mainPageId = Objects.default(homepageWidgets.find(w => w.type === WidgetType.Homepage && w.item === type)).pageLink;
		const mainPage = findTreeNode(pages, p => p.object.getId() === mainPageId);
		if (mainPage) {
			Browser.navigate(mainPage.object.getURL());
		}
	} else {
		Browser.navigate(selectedPage.object.getURL());
	}
};