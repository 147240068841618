import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { useRecoilState } from 'recoil';
import { SearchFilterAtom } from '../utils';
import { fi } from '../../../../utils/helpers';
import { ReactComponent as AscendingIcon } from '../../../../assets/images/ascending.svg';
import { ReactComponent as DescendingIcon } from '../../../../assets/images/descending.svg';
import styled from '@emotion/styled';
import { device } from '../../../../utils/constants';

const IconBtn = styled(IconButton)`
	@media ${device.mobile} {
        margin-top: 20px;
        margin-left: 8px;
	}
`

const SortingButton = () => {
	//true - ascending
	//false - descending
	const [value, setValue] = useState<boolean>(true);
	const [filter, setFilter] = useRecoilState(SearchFilterAtom);

	useEffect(() => {
		setValue(filter.order === 'asc');
	}, [filter.order]);

	useEffect(() => {
		const order = fi(value, 'asc', 'desc');
		setFilter({...filter, order: order});
	}, [value]);
	const setSorting = () => {
		setValue(!value);
	};
	return (
		<IconBtn onClick={setSorting} data-testid="sorting-icon-btn">
			{fi(value, <AscendingIcon title="Switch to descending order" data-testid="ascending-order"/>,
				<DescendingIcon
					title="Switch to ascending order" data-testid="descending-order"/>)}
		</IconBtn>
	);
};

export default SortingButton;