import {Strings} from '../../utils/strings';
import {ExamDates} from './__ExamDates';
import {Dates, timeOptions} from '../../utils/dates';
import React, {ReactElement} from 'react';
import {Types} from '../types';

export class TimeTable extends ExamDates {
	public board: string;
	public qualification_mapping: string;
	public qualification: string;
	public code: string;
	public subject_mapping: string;
	public subject: string;
	public title: string;
	public time: string;
	public duration: string;
	public type: string;

	constructor(data: any = {}) {
		super(data);
		this.board = Strings.default(data.board);
		this.qualification_mapping = Strings.default(data.qualification_mapping);
		this.qualification = Strings.default(data.qualification);
		this.code = Strings.default(data.code);
		this.subject_mapping = Strings.default(data.subject_mapping);
		this.subject = Strings.default(data.subject);
		this.title = Strings.default(data.title);
		this.time = Strings.default(data.time);
		this.duration = Strings.default(data.duration);

		this.type = 'Exam Date';
	}

	public getType(): Types {
		return Types.TIMETABLE;
	}

	public getQualifications(): string[] {
		return this.qualification_mapping.replace(/\s*,\s*/g, ",").split(',');
	}

	public getSubjects(): string[] {
		return this.subject_mapping.replace(/\s*,\s*/g, ",").split(',');
	}

	public getTitle(): ReactElement {
		return (
			<div>
				<p>{this.type}</p>
				<p>{this.qualification}, {this.subject}</p>
			</div>
		);
	}

	public getDate(): ReactElement {
		return (
			<div data-testid='date-info' className='mt-8'>
				<div data-testid='date'>
					{Dates.format(this.date, true, true)} | <span
					className='text-semiBold'>{timeOptions[this.time]}</span>
				</div>
				<div data-testid='duration' className='mt-8'>
					Duration: {this.duration}
				</div>
			</div>
		);
	}

	public renderQualification(): ReactElement {
		return (
            <div data-testid='qualification-info'>
				{this.code}, {this.title}
            </div>
        );
	}
}