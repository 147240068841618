import {Notification} from '../../tw/models/Notification';
import React from 'react';
import {fi} from '../../utils/helpers';
import styled from '@emotion/styled';
import Wysiwyg from '../widgets/wysiwyg/Wysiwyg';

const NotificationElem = styled('div')<{ unread: boolean }>`
    display: flex;
    padding: 8px 16px;

    ${props => props.unread && `background-color: var(--color-blue-background);`}
    .notification {
        width: 100%;
    }
`;

const GlobalNotification = ({notification, index}: { notification: Notification, index: number }) => {
	let notificationMappings = notification.getNotificationMapping();
	return (
		<NotificationElem data-notificationid={notification.getId()}
						  data-testid="notification" key={index}
						  unread={!notification.wasSeen()}
						  className={`${fi(!notification.wasSeen(), 'unread')} notification-item`}>
			<div data-testid="notification-icon" className="pr-8">
				{notification.getIcon()}
			</div>
			<div data-testid="notification-content" className="flex-column-center notification">
				<Wysiwyg item={notification} id={notification.getId()} fieldId='description'/>
				<div id={notification.getId()} className="text-16 text-regular mb-8 wysiwyig-content"/>
				<div data-testid="notification-footer" className="flex-row-space-between text-13">
					<div> {fi(notificationMappings.length > 1,
						<div title={notificationMappings.slice(1).join(' / ')}>
							<span> {notificationMappings[0]}</span>
							<span> &nbsp; (+ {notificationMappings.length - 1} more)</span>
						</div>,
						<span>{notificationMappings[0]}</span>)}
					</div>
					<span> {notification.getPublishedDate()}</span>
				</div>
			</div>
		</NotificationElem>
	);
};

export default GlobalNotification;
