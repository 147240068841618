import React from 'react';
import DropdownSelection from './components/DropdownSelection';
import { SortingAndGroupingTypes } from './utils';
import SortingButton from './components/SortingButton';
import InfoElement from '../../commons/InfoElement';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import { device } from '../../../utils/constants';

const Wrapper = styled.div`
    .info-comp {
        margin-bottom: 0;
        margin-right: 16px;
        font-size: 25px;
    }

    @media ${device.mobile} {
        flex-direction: column;
        align-items: normal;
    }
`;

const MobileWrapper = styled.div`
    @media ${device.mobile} {
		display: flex;
		flex-direction: row;
    }
`;

const SortingAndGrouping = () => {
	const mobileOrTablet = useMediaQuery(device.mobile);
	const text = `Sort by Relevance will return items in the order that they match your search keywords.`;
	return (
		<Wrapper className="flex-row-end" data-testid="sorting-grouping">
			{!mobileOrTablet && <InfoElement helpText={text}/>}
			{mobileOrTablet ?
				<MobileWrapper>
					<DropdownSelection type={SortingAndGroupingTypes.Sorting}/>
					<SortingButton/>
				</MobileWrapper>
				:
				<>
					<SortingButton/>
					<DropdownSelection type={SortingAndGroupingTypes.Sorting}/>
				</>
			}
			<DropdownSelection type={SortingAndGroupingTypes.Grouping}/>
		</Wrapper>
	);
};

export default SortingAndGrouping;