import React from 'react';
import {RecoilRoot} from "recoil";
import {createRoot} from "react-dom/client";
import RecoilNexus from "./state/recoilNexus";
import App from "./App";
import "./assets/scss/index.scss"

const container: any = document.getElementById('root');
const root = createRoot(container);
root.render(
    <RecoilRoot>
        <App/>
        <RecoilNexus/>
    </RecoilRoot>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
