import {FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel} from '@mui/material';
import React from 'react';
import {Lists} from '../../../utils/lists';
import RadioButton from './RadioButton';

interface Option {
    label: string,
    value: any
}

interface FieldType {
    name: string,
    label: string,
    options: Option[] | any[]
}

const RadioButtonsGroup = ({field, onChange, value, styling, showError, errorMessage}: {
    field: FieldType,
    onChange: (...params) => void,
    value: any,
    styling: string,
    showError?: boolean;
    errorMessage?: string;
}) => {
    return (
        <FormControl variant="standard" style={{zIndex:0}} >
            <FormLabel htmlFor={field.name} focused={false}>
                {field.label}
            </FormLabel>
                <FormGroup className={styling}>
                    {Lists.default<Option>(field.options).map((option, optionIdx) => (
                        <FormControlLabel data-testid={`${field.name}-${option.label}`} key={option.label} value={option.value}
                                              control={<RadioButton data-testid={`${option.label}-test-id`} checked={option.value === value} name={field.name} onChange={(evt) => onChange(evt.target.value, field.name)}/>}
                                              label={option.label}/>
                    ))}
                </FormGroup>
            {showError && <FormHelperText data-testid="error-message">{errorMessage}</FormHelperText>}
        </FormControl>
    )
}

export default RadioButtonsGroup;