import React, {useEffect, useMemo} from 'react';
import {FormMessages} from './messages';
import SelectComponent from '../../../FormComponents/Select/SelectComponent';
import {Strings} from '../../../../utils/strings';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {eventsCodesAtom, eventsSelector, EventsType, eventsTypeAtom} from '../../../../state/events';
import {sessionAtom} from '../../../../state/session';
import {references} from '../../../../state/state';
import {getRecoil, setRecoil} from '../../../../state/recoilNexus';
import {Subject} from '../../../../tw/models/Subject';


const TrainingCourse = ({formik, onChange}: { formik: any, onChange: (...props) => void }) => {
	const setEventsCodes = useSetRecoilState(eventsCodesAtom);
	const events = useRecoilValue(eventsSelector);
	const userSession = useRecoilValue(sessionAtom);

	useEffect(() => {
		if (!userSession) return;
		let assessmentCodes: string[] = [];
		userSession.getQualifications().forEach((q) => {
			let subjectObject = getRecoil(references(q.subject)) as Subject;
			if (subjectObject) {
				assessmentCodes = [...assessmentCodes, ...subjectObject.getAssessmentCodes()];
			}
		});
		setEventsCodes(assessmentCodes);
		setRecoil(eventsTypeAtom, EventsType.All);
	}, []);

	const eventsOptions = useMemo(() => {
		if (!events || !events.length) return [];
		const now = new Date();
		let currentYear = now.getFullYear();
		let startDate;
		let endDate;
		//only events of current academic year should be displayed
		//23 sept -> 24 july
		if (now.getUTCMonth() >= 8) {
			//if it's sep or later
			startDate = new Date(currentYear, 8, 23); //sept
			endDate = new Date(currentYear + 1, 6, 24); //july
		} else {
			startDate = new Date(currentYear - 1, 8, 23); //sept
			endDate = new Date(currentYear, 6, 24); //july
		}
		return events.filter((value, index, array) => {
			return array.findIndex(v => v.masterCode === value.masterCode) === index;
		}).filter(e => {
			let eventStartDate = new Date(e.startDate);
			let eventEndDate = new Date(e.endDate);
			return eventStartDate >= startDate && eventEndDate <= endDate;
		}).map(e => {
			return {
				label: `${e.title} - ${e.getEventType()} (${e.masterCode})`,
				value: `${e.title}-${e.getEventType()}`,
				object: e,
			};
		});
	}, [events]);

	const handleChange = (e) => {
		onChange(e.value, 'training');
		onChange(e.object.masterCode, 'code');
	}
	return (
		<div className="mt-16" data-testid="trainingCourse-section">
			<div className="text-16 text-regular">{FormMessages.TrainingCourseLineOne}</div>
			<br/>
			<div className="text-16 text-regular">{FormMessages.TrainingCourseLineTwo}</div>
			<div className="mt-16">
				<SelectComponent
					id={'training'}
					label={'Training'}
					name={'training'}
					value={formik.values.training}
					onChange={handleChange}
					checkbox={false}
					values={eventsOptions}
					required={true}
					errorMessage={Strings.default(formik.errors.training)}
					showError={(formik.touched.training && Boolean(formik.errors.training))}
				/>
			</div>
		</div>
	);
};

export default TrainingCourse;