import Button from '@mui/material/Button';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {ReactComponent as PlayIcon} from '../../../../assets/images/card/actions/play-icon.svg';
import {ReactComponent as PauseIcon} from '../../../../assets/images/card/actions/pause-icon.svg';
import {Audio} from '../../../../tw/models/Audio';
import {fi} from '../../../../utils/helpers';
import styled from '@emotion/styled';
import Client from '../../../../tw/client';
import {useRecoilValue} from 'recoil';
import {SubjectSelector} from '../../../../state/product';
import {Messages} from '../../../../utils/messages';
import GA from '../../../../tw/models/GA';
import {disableButton} from './utils';

const Wrapper = styled.div`

    position: absolute;
    top: -17px;
    left: 0;
    /* bottom: 0; */
    right: 0;

    height: 16px;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    transition: all 0.1s linear;

    .text {
        position: absolute;
        pointer-events: none;
        left: 3px;
        top: 1px;
        display: block;
        font-size: 12px;
        z-index: 100;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.5);
    }

    .progress-bar {
        position: absolute;
        pointer-events: none;
        background: #37c5f7;
        width: 0;
        top: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;
        padding: 0 2px;
    }

`;

const AudioPlayer = styled.audio`
    width: 0 !important;
    height: 0 !important;
    visibility: hidden !important;
`;

let currentPlayer;

const AudioButton = ({item}: {item: Audio}) => {
	const [id,] = useState(((new Date()).getTime() + Math.ceil(Math.random() * 1e10)).toString());
	const [playing, setPlaying] = useState<boolean>(false);
	const [ref, setRef] = useState<HTMLAudioElement | null>(null);
	const [src, setSrc] = useState<string>('');
	const selectedSubject = useRecoilValue(SubjectSelector);
	const firstMounted = useRef(true);

	const disableAction = useMemo(() => {
		return disableButton(item);
	}, [item]);

	const setBasePlayer = () => {
		if (!ref) {
			return;
		}
		if (currentPlayer) {
			if (currentPlayer.id !== ref.id) {
				currentPlayer.pause();
				currentPlayer = ref;
			}
		} else {
			currentPlayer = ref;
		}
	};

	const onClick = () => {
		Client.getSignedURL(item.getId(), true).then(url => {
			setSrc(url);
		});
		item.markAsSeen();
		// call GA event
		GA.ItemActionEvent('Play', item.formGAParams());

		// uncomment for testing
		//setSrc('https://file-examples.com/storage/fef33ff2be64c78c39bbd81/2017/11/file_example_MP3_700KB.mp3');

		if (ref == null || !ref) {
			return;
		}

		setBasePlayer();

		if (ref.paused) {
			ref.play().then(() => {
				setPlaying(true);
			}).catch((e) => {
				console.log('can\'t play', e);
			});
		} else {
			ref.pause();
			setPlaying(false);
		}
	};

	useEffect(() => {
		if (firstMounted.current && ref) {
			setBasePlayer();
			firstMounted.current = false;
			ref.play().then(() => {
				setPlaying(true);
			}).catch();
		}
	}, [ref, src]);

	// stop player when changing subject
	useEffect(() => {
		if (ref && !ref.paused && playing) {
			ref.pause();
			ref.currentTime = 0;
			setPlaying(false);
		}
		setSrc('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSubject]);

	const onTimeUpdate = () => {
		if (!ref) {
			return;
		}
		const c = document.getElementById(id);
		const txt = document.getElementById(`${id}-txt`);

		if (c && txt) {
			const currentTime = ref.currentTime,
				maxDuration = ref.duration;
			c.style.width = ((currentTime * 100) / maxDuration) + '%';

			const getFormattedTime = (time) => {
				const minutes = Math.floor(time / 60);
				const seconds = Math.floor(time - minutes * 60);
				return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
			};
			txt.innerText = `${getFormattedTime(currentTime)} / ${getFormattedTime(maxDuration)}`;
		}
	};

	const seekAudio = (evt) => {
		if (!ref || !playing) {
			return;
		}
		const c = evt.target.getBoundingClientRect();
		const spot = evt.clientX - c.x;
		ref.currentTime = ref.duration * spot / c.width;
		evt.preventDefault();
		evt.stopPropagation();
	};

	return (
		<div data-testid='audio-player-wrapper'>
			<span title={fi(disableAction, Messages.FeatureNotAvailable, 'Play')}>
				<Button color='primary' variant='text' size='large' onClick={onClick}
						data-testid='play-audio-btn' disabled={disableAction}>
					{fi(playing, <PauseIcon title='Pause' />, <PlayIcon title='Play' />)}
				</Button>
			</span>
			{fi(src,
				<Wrapper onClick={seekAudio} data-testid='audio-player' className='no-player'>
					<div id={`${id}-txt`} className='text'>Loading...</div>
					<div className='progress-bar' id={id}></div>
					<AudioPlayer ref={(val) => setRef(val)}
								 autoPlay={false}
								 controls
								 onPlaying={() => setPlaying(true)}
								 onPause={() => setPlaying(false)}
								 preload={'true'}
								 onTimeUpdate={onTimeUpdate}
								 id={`${id}-audio-player`}
					>
						<source src={src} type='audio/mpeg' />
					</AudioPlayer>
				</Wrapper>)}
		</div>
	);
};

export default AudioButton;