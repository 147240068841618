import {UsefulLink} from "../tw/models/UsefulLink";
import {selector} from "recoil";
import Client from "../tw/client";
import {Types} from "../tw/types";
import {Objects} from "../utils/objects";
import {Lists} from "../utils/lists";

export const usefulLinkSelector = selector<UsefulLink[]>({
	key: "usefulLinkSelector",
    get: async () => {
		try {
			const res = await Client.query<UsefulLink>({
				types: [Types.USEFUL_LINK],
			});
			return Lists.default(Objects.default(res).results);
		} catch (e) {
            return [];
		}
	},
})