import {atom, atomFamily} from 'recoil';

export const feedbackAtom = atom<boolean>({
	key: 'feedbackAtom',
	default: false,
});


export const feedbackStepAtom = atom<number>({
	key: 'feedbackStepAtom',
	default: 0,
});

export const feedbackData = atomFamily<any, any>({
	key: 'feedbackData',
	default: null,
});

export const feedbackTrigger = atom<string>({
	key: 'feedbackTrigger',
    default: 'auto',
})