import React from 'react';
import styled from '@emotion/styled';
import {sessionAtom} from '../../state/session';
import {useRecoilValue} from 'recoil';
import {Objects} from '../../utils/objects';

const CountdownElem = styled.div`
    padding: 4px 8px;
    margin-left: 16px;

    &.info-blue {
        background-color: var(--color-info-background);
        border-radius: 4px;
        font-size: 12px;
        font-family: var(--font-semi-bold);
        line-height: 17px;
        color: var(--color-info);
    }

    &.warning-yellow {
        background-color: var(--color-warning-background);
        border-radius: 4px;
        font-size: 12px;
        font-family: var(--font-semi-bold);
        line-height: 17px;
        color: var(--color-warning);
    }

    &.critical-red {
        background-color: var(--color-critical-background);
        border-radius: 4px;
        font-size: 12px;
        font-family: var(--font-semi-bold);;
        line-height: 17px;
        color: var(--color-critical);
    }
`;
const TrialCountdown = () => {
	const user = useRecoilValue(sessionAtom);
	if (!user) return null;
	const formatDate = (date) => {
		let stringDate = date.toString();
		if (stringDate.length < 13) {
			return Number(stringDate.padEnd(13, '0'));
		}
		return date;
	};
	const getDaysLeft = () => {
		let finalDate = new Date().getTime();
		const formattedDate = formatDate(Objects.default(user.trial).date)
		let distance = 15 * (1000 * 60 * 60 * 24) - (finalDate - formattedDate);
		let daysLeft = Math.floor(distance / (1000 * 60 * 60 * 24));
		let message = 'Trial ends in ' + daysLeft + ' days';
		let styling = 'info-blue';
		if (daysLeft <= 1) {
			styling = 'critical-red';
			message = 'Trial ends today';
		} else if (daysLeft > 1 && daysLeft < 6) {
			styling = 'warning-yellow';
		}
		if (distance < 0) {
			styling = 'critical-red';
			message = 'Trial expired';
		}
		return {styling, message};
	};

	return (
		<CountdownElem className={`${getDaysLeft().styling} trial-countdown`} data-testid='trial-countdown'>
			{getDaysLeft().message}
		</CountdownElem>
	);
};

export default TrialCountdown;