import {atom, atomFamily, selector} from 'recoil';
import {CMSObject} from '../tw/models/__CMSObject';
import Client from '../tw/client';
import {ITreeItem} from '../components/TreeMenu/utils';
import {EventsAir} from '../tw/models/EventsAir';
import {ZendeskArticle} from '../tw/models/ZendeskArticle';

// What page from the tree is selected
export const selectedPageAtom = atom<ITreeItem | null>({
	key: 'selectedPage',
	default: null,
});
export const selectedRootPageAtom = atom<ITreeItem | null>({
	key: 'selectedRootPage',
	default: null,
});

export const references = atomFamily<CMSObject | null, string>({
	key: 'references',
	default: null,
});

export const featureFlagsSelector = selector({
	key: 'featureFlagSelector',
	get: async () => {
		let resp: string[] = [];
		try {
			resp = await Client.getFeatureFlags();
		} catch (e) {

		}
		return resp;
	}
});

export const cacheBuster = atomFamily<number, string>({
	key: 'cacheBuster',
	default: 0,
});

type ObjectType = CMSObject | EventsAir | ZendeskArticle
export const selectedObject = atom<ObjectType | null>({
	key: 'selectedObject',
	default: null,
});

export const showTourAtom = atom<number>({
	key: 'showTour',
	default: 0,
});

export const scrollTopPosition = atom<boolean>({
	key: 'scrollTopPosition',
	default: false,
});

export const sharedItem = atom<CMSObject | null>({
	key: 'sharedItem',
	default: null,
});

export const enableRootMenu = atom<boolean>({
	key: 'enableRootMenu',
	default: false,
});
