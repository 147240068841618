import {ITreeItem} from '../components/TreeMenu/utils';
import {setRecoil} from '../state/recoilNexus';
import {RecoilState} from 'recoil';
import {Strings} from './strings';

export const fi = (condition: any, a: any, b?: any): any => {
	return Boolean(condition) ? a : b;
};

// Handle action is used where there are click events on elements that not usually have those (divs, spans, etc)
// and you want them both on click and on enter when element is selected. Make sure you add tabindex="0" to the element.
export const handleAction = (callback: (e) => void): any => {
	return {
		onClick: (e) => {
			cancelEvent(e);
			callback(e);
		},
		onKeyPress: (e) => {
			if (e.key === 'Enter') {
				callback(e);
			}
		},
	};
};

export const cancelEvent = (e: Event): void => {
	e.preventDefault();
	e.stopPropagation();
};

export const fireEvent = (element: any, eventType: string) => {
	if (element) {
		if (element.fireEvent) {
			element.fireEvent(`on${eventType}`);
		} else {
			const event = new Event(eventType, {bubbles: true, cancelable: false});
			element.dispatchEvent(event);
		}
	}
};

export const walkTreeUp = (node: ITreeItem, cb: (node: ITreeItem) => void) => {
	if (!node) return;

	cb(node);
	if (node.parent) {
		walkTreeUp(node.parent, cb);
	}
};

// used for async calls that doesn't return recoil suspense promise
export const suspensePromise = (promise) => {
	let status = 'pending';
	let result;
	let suspender = promise.then((r) => {
		status = 'success';
		result = r;
	}).catch((e) => {
		status = 'error';
		result = e;
	});

	return {
		read() {
			if (status === 'pending') {
				throw suspender;
			} else if (status === 'error') {
				throw result;
			} else if (status === 'success') {
				return result;
			}
		},
	};
};

export const resetAtomFamily = (atom: (param: any) => RecoilState<any>, keys: any[]) => {
	keys.forEach(k => {
		setRecoil(atom(k), null);
	});
};
export const highlightSearchTerm = (text, searchTerm, bold: boolean = false) => {
	if (!searchTerm) return text;
	let escapedSearchTerm = searchTerm.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
	const searchRegex = new RegExp(escapedSearchTerm, 'gi');
	text = Strings.default(text);
	if (bold && text.length > 50) {
		const match = text.match(searchRegex);
		if (match) {
			text = text.replace(/(<([^>]+)>)/gi, '');
			const index = text.indexOf(match[0]);
			const after = index + escapedSearchTerm.length;
			const dot = text.indexOf('.', after);
			const nl = text.indexOf('\n', after);
			text = `...${text.substr(Math.max(0, index - 10),
				Math.max(20 + escapedSearchTerm.length,
					Math.min(30, 10 + escapedSearchTerm.length + Math.min(dot - after, nl - after)))
			)}...`;
		}
	}
	if (text.match(searchRegex) && text.match(searchRegex).length) {
		return text.replace(searchRegex, fi(bold, `<strong>$&</strong>`, `<span class='highlighted-word'>$&</span>`));
	}
	return text;
};

export const setCookie = (name, value, days, path) => {
	let date = new Date();
	date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * days);
	let expires = '; expires=' + date.toUTCString();
	let cookiePath = '; path=' + path;
	document.cookie = name + '=' + value + expires + cookiePath;
};

export const scrollAppToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth'
	});
}

export const capitalize = (val: string): string => {
	return val.substring(0, 1).toUpperCase() + val.substring(1).toLowerCase();
};

export const goToMyCambridge = () => {
	let domain = 'https://mycambridge.sso.cambridge.org/';
	if (['localhost', 'dev.'].find(d => window.location.hostname.includes(d))) {
		domain = domain.replace('.sso', '.dev.internal.sso');
	} else if (['test.', 'pp1.'].find(d => window.location.hostname.includes(d))) {
		domain = domain.replace('.sso', '.qa.internal.sso');
	}
	window.open(domain, '_blank');
};