import React from 'react';
import styled from '@emotion/styled';
import {sessionAtom} from '../../state/session';
import {useRecoilState, useRecoilValue} from 'recoil';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {fi, resetAtomFamily} from '../../utils/helpers';
import {registrationDataAtom, showIntroAtom} from '../../pages/Registration';
import {saveUserPreferences} from '../Registration/utils';
import {setRecoil} from '../../state/recoilNexus';
import JobComponent from './components/JobComponent';
import Subjects from './components/Subjects';
import RegistrationStepper from '../Registration/RegistrationStepper';

const InnerPreferenceWrapper = styled.div`
	max-width: 900px;
`
const PreferencesWrapperElem = styled.div`
    background-color: var(--color-background);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 0;

    .registration {
        background-color: var(--color-white);
        width: 670px;
        border: 1px solid var(--color-grey);
        padding-top: 24px;
        box-shadow: 0 0 0 1px var(--color-box-shadow);
        border-radius: 6px;
    }

    .page-title {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 16px;
        font-family: var(--font-semi-bold);
        font-size: 24px;
    }
`;
const ContentElem = styled.div`
    background-color: var(--color-white);
    border: solid 1px var(--color-border-light);
    padding: 16px;
`;


const Preferences = () => {
	const [showIntro, setShowIntro] = useRecoilState(showIntroAtom);

	const user = useRecoilValue(sessionAtom);

	if (!user) return null;

	const editPreferences = () => {
		resetAtomFamily(registrationDataAtom, ['job']);
		setRecoil(registrationDataAtom('qualifications'), user.getQualifications());
		setShowIntro(false);
	};

	const confirmPreferences = async () => {
		setRecoil(registrationDataAtom('job'), user.getJobTitle());
		await saveUserPreferences();
	};
	return (
		<PreferencesWrapperElem>
			{fi(!showIntro,
				<div data-testid="update-profile-screen">
					<div className="page-title">Update your profile</div>
					<div className="registration">
						<RegistrationStepper/>
					</div>
				</div>,
				<InnerPreferenceWrapper data-testid="check-preferences-screen">
					<div className="mb-24">
						<p className="text-24 text-latoBold">Check preferences</p>
						<p className="text-16 text-regular">Please update your profile. In order to keep the information
							on
							the website relevant we need to check if your preferences are up to date.
						</p>
					</div>
					<ContentElem>
						<JobComponent user={user}/>
						<Subjects user={user}/>
						<Divider/>
						<div className="flex-row-end mt-16">
							<Button size="small" variant="text" color="primary" onClick={editPreferences}
									data-testid="edit-preferences-btn">
								Edit
							</Button>
							{!user.reconfirmPreferences() &&
								<Button size="small" variant="contained" color="primary" onClick={confirmPreferences}
										data-testid="confirm-preferences-btn">
									Confirm
								</Button>}
						</div>
					</ContentElem>
				</InnerPreferenceWrapper>)}
		</PreferencesWrapperElem>
	);
};

export default Preferences;