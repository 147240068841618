import React, {useEffect, useMemo, useState} from 'react';
import {Lists} from '../../../utils/lists';
import FeedbackRadioGroup from '../../commons/FeedbackRadioGroup';
import {Strings} from '../../../utils/strings';
import {
	skippedQuestions,
	trialSurveyData,
	trialSurveyExtraStepData,
	trialSurveyStep,
	trialSurveyStepData,
} from '../../../state/trial';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {FormType, SurveyConfig} from '../surveyConfig';
import {Objects} from '../../../utils/objects';
import RadioButtonsGroup from '../../FormComponents/Radio/RadioButtonsGroup';
import {fi} from '../../../utils/helpers';

const TrialRadioField = () => {
	const [localValue, setLocalValue] = useState<string | null>(null);
	const setStepData = useSetRecoilState(trialSurveyStepData);
	const [extraData, setExtraStepData] = useRecoilState(trialSurveyExtraStepData);
	const surveyStep = useRecoilValue(trialSurveyStep);
	const currentStoredData = useRecoilValue(trialSurveyData(surveyStep));
	const [skipped, setSkipped] = useRecoilState(skippedQuestions);

	useEffect(() => {
		setLocalValue(null);
	}, [surveyStep]);

	const onChange = (e) => {
		if (skipped.length) {
			let copy = [...skipped];
			setSkipped(copy.filter(c => c !== surveyStep));
		}
		const val = e.hasOwnProperty('target') ? e.target.value : e;
		setLocalValue(Strings.default(val).toString());
		setStepData(Strings.default(val).toString());
		if (val === 'No' && extraData) {
			setExtraStepData(null);
		}
	};

	const value = useMemo(() => {
		if (localValue !== null) {
			return localValue;
		}
		if (currentStoredData) {
			const data = fi(currentStoredData.stepData, currentStoredData.stepData, currentStoredData);
			return fi(currentStoredData === 'skip', '', data);
		}
		return '';
	}, [currentStoredData, surveyStep, localValue]);

	useEffect(() => {
		if(!currentStoredData) return;
		const data = fi(currentStoredData.stepData, currentStoredData.stepData, currentStoredData);
		setStepData(data);
	},[currentStoredData])

	const options = useMemo(() => {
		return Lists.default<string>(Objects.default(SurveyConfig[surveyStep].form).valuesOption);
	}, [surveyStep]);

	return (
		<div className="flex-row-center align-center">
			{fi(Objects.default(SurveyConfig[surveyStep].form).type === FormType.FeedbackRadio,
				<FeedbackRadioGroup formName={'trialSurvey'}
									labels={Lists.default<string>(Objects.default(SurveyConfig[surveyStep].form).sliderLabels)}
									onChange={onChange}
									options={options}
									value={value}/>,
				<RadioButtonsGroup field={{name: 'trialSurvey', label: '', options: options}} onChange={onChange}
								   value={value} styling={'flex-row-center'}/>)}
		</div>
	);
};

export default TrialRadioField;