import {EventMaterial} from './EventMaterial';
import {Strings} from '../../utils/strings';
import {Numbers} from '../../utils/numbers';
import {Objects} from '../../utils/objects';
import {Lists} from '../../utils/lists';
import {DefaultAction} from '../types';
import React, {ReactElement} from 'react';
import {CURRENCY_SYMBOLS} from '../../utils/constants';
import {selectedEvent} from '../../state/events';
import {setRecoil} from '../../state/recoilNexus';
import {Browser} from '../../utils/browser';
import {BaseClass} from './__base';
import {InstanceOf} from './index';
import {selectedObject} from '../../state/state';
import GA from './GA';
import {fi} from '../../utils/helpers';

type EventPrice = {
	currencyCode: string;
	amount: number
}

export class EventsAir extends BaseClass {
	public code: string;
	public masterCode: string;

	public title: string;
	public overview: string;
	public availableSeats: number;
	public format: string;
	public location: string;
	public price: EventPrice;
	public status: string;

	public eventMaterials: EventMaterial[];

	public startDate: string;
	public startTime: string;
	public endDate: string;
	public endTime: string;

	constructor(item: any = {}) {
		super(item);
		this.code = Strings.default(item.code);
		this.masterCode = Strings.default(item.masterCode);
		this.title = Strings.default(item.name);
		this.overview = Strings.default(item.overview);
		this.availableSeats = Numbers.default(item.availableSeats);
		this.location = Strings.default(item.location);
		this.price = Objects.default(item.price);
		this.format = Strings.default(item.format);
		this.status = Strings.default(item.status);
		this.eventMaterials = Lists.default(item.eventMaterials).map((m: any) => {
			return InstanceOf(m);
		});
		this.startDate = Strings.default(item.startDate);
		this.startTime = Strings.default(item.startTime);
		this.endDate = Strings.default(item.endDate);
		this.endTime = Strings.default(item.endTime);
	}

	public getId(): string {
		return this.code;
	}

	public getType(): string {
		return 'event';
	}

	selectEvent() {
		if (this.isFullyBooked()) {
			return;
		}
		setRecoil(selectedEvent, this);
		setRecoil(selectedObject, this);
		Browser.navigate(`${document.location.pathname}/event-details/${this.code}`);
	}

	public defaultAction(): DefaultAction {
		return {
			label: 'View',
			actionHandler: () => {
				GA.EventActionEvent(fi(this.isPastEvent(), 'Past event view', 'Active event view'), this.displayLabel());
				this.selectEvent();
			},
		};
	}

	public getPrice(): string {
		if (this.price && this.price.amount > 0) {
			if (CURRENCY_SYMBOLS[this.price.currencyCode]) {
				if (CURRENCY_SYMBOLS[this.price.currencyCode].position === 'left') {
					return `- ${CURRENCY_SYMBOLS[this.price.currencyCode].symbol}${this.price.amount}`;
				} else {
					return `- ${this.price.amount}${CURRENCY_SYMBOLS[this.price.currencyCode].symbol}`;
				}
			}
			return `- ${this.price.amount}`;
		}
		return `- Free`;
	}

	public getIcon(): ReactElement | null {
		let [year, month, day] = this.startDate.split('-');
		const date = new Date(+year, +month - 1, +day);
		const monthString = date.toLocaleString('default', {month: 'short'});
		const defaultAction = this.defaultAction();
		return (
			<div className='eventContainer' onClick={defaultAction.actionHandler} title={defaultAction.label}>
				<div className='halo'>
					<div className={`${this.isPastEvent() ? 'pastEvent' : ''} eventIcon`}>
						<div className='dots' />
						<div className='text-18 text-regular'>
							{day}
						</div>
						<div className='text-8 text-regular'>
							{monthString}
						</div>
					</div>
				</div>
			</div>
		);
	}

	public isPastEvent(): boolean {
		const eventStartDate = new Date(this.startDate).getTime();
		let now = new Date();
		now.setHours(0, 0, 0);
		const today = now.getTime();
		return eventStartDate < today;
	}

	public getEventDuration(): string {
		let startHour = this.startTime.slice(0, 2);
		let endHour = this.endTime.slice(0, 2);
		let hourDuration = Number(endHour) - Number(startHour);
		if (hourDuration === 1) return `Duration: <span>${hourDuration} hour</span>`;
		if (hourDuration > 1) return `Duration: <span>${hourDuration} hours</span>`;

		let startMin = this.startTime.slice(2);
		let endMin = this.endTime.slice(2);
		let MinDuration = Number(endMin) - Number(startMin);
		return `Duration: <span>${MinDuration} Minutes</span>`;
	}

	public isFullyBooked(): boolean {
		return !this.isPastEvent() && this.availableSeats === 0;
	}

	public getFlag(): ReactElement[] {
		if (this.isPastEvent()) {
			return [<span className='pastEvent' key={this.code}>Finished</span>];
		}
		if (this.isFullyBooked()) {
			return [<span className='fullyBooked' key={this.code}>Fully booked</span>];
		}
		return [];
	}

	public displayLabel(): string {
		let count = (this.title.match(/:/g) || []).length;
		switch (count) {
			case 1:
				return this.title.split(':')[0];
			case 0:
				return this.title;
			default:
				return this.title.split(':')[1];
		}
	}

	public eventDetailsSubheading(): string {
		let count = (this.title.match(/:/g) || []).length;
		switch (count) {
			case 1:
				return this.title.split(':')[1];
			case 0:
				return '';
			default:
				let tmp = this.title.split(':')[1] + ':';
				let tmp2 = this.title.split(tmp)[1];
				let subheading = tmp2.split(':');
				if (subheading.length >= 3) {
					return subheading[1] + ': ' + subheading[2];
				} else {
					return this.title.substring(this.title.indexOf(tmp) + tmp.length);
				}
		}
	}

	public getTimeFormatted(time): string {
		let H = +time.substring(0, 2);
		let h = H % 24;
		let minutes = time.substring(2, 5);
		if (minutes === '00') {
			return (h % 12 || 12) + (h < 12 ? 'am' : 'pm');
		} else {
			return (h % 12 || 12) + ':' + minutes + (h < 12 ? 'am' : 'pm');
		}
	}

	public getEventType(): string {
		const startTime = new Date(`1970-01-01T${this.startTime.substring(0, 2)}:${this.startTime.substring(2)}Z`);
		const endTime = new Date(`1970-01-01T${this.endTime.substring(0, 2)}:${this.endTime.substring(2)}Z`);
		const durationH = (endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60;

		if (durationH > 4) {
			// longer than 4 h
			return 'Full day course';
		} else if (durationH > 1) {
			// 1-4 h
			return 'Half day course';
		} else {
			// 1 h
			return 'Webinar course';
		}
	}

	public downloadable(): boolean {
		return false;
	}

	public isPubliclyAvailable(): boolean {
		return true
	}
}