import React, { useEffect, useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ConfirmationModalProps, modalAtom } from './ModalDialog';
import { sessionAtom, sessionSelector } from '../../state/session';
import Client from '../../tw/client';
import { setRecoil } from '../../state/recoilNexus';
import GA from '../../tw/models/GA';
import { Browser } from '../../utils/browser';
import OrganizationModalContent from './components/OrganizationModalContent';


export const organizationChangeAtom = atom<boolean>({
	key: 'organizationChangeAtom',
	default: false,
});

export const organizationChangePathAtom = atom<string>({
	key: 'organizationChangePathAtom',
	default: '',
});

export const selectedOrgAtom = atom<string>({
	key: 'selectedOrgAtom',
	default: '',
});

const ChangeOrgModal = () => {
	const setValue = useSetRecoilState(modalAtom);
	const userSession = useRecoilValue(sessionAtom);
	const [selectedOrg, setSelectedOrg] = useRecoilState(selectedOrgAtom);
	const [showOrgModal, setShowOrgModal] = useRecoilState(organizationChangeAtom);
	const organizationPath = useRecoilValue(organizationChangePathAtom);

	useEffect(() => {
		if (!userSession) return;
		setSelectedOrg(`${userSession.lastSelectedOrg}~${userSession.selectedBusinessStream}`);
	}, [userSession]);

	const hideModal = () => {
		setShowOrgModal(false);
		setValue(null);
	};
	const cancelModal = () => {
		if (!userSession) return;
		if (userSession.isFirstTimeUser() && !userSession.lastSelectedOrg) {
			return;
		}
		setSelectedOrg('');
		hideModal();
	};

	const disableConfirmAction = useMemo(() => {
		return !selectedOrg || selectedOrg === '~';
	}, [selectedOrg]);

	useEffect(() => {
		if (!userSession || !showOrgModal) return;
		const modalObject: ConfirmationModalProps = {
			title: `Select your centre`,
			message: <OrganizationModalContent user={userSession}/>,
			onConfirm: async () => {
				if (!selectedOrg) return;
				await Client.switchOrganization(selectedOrg).then((res) => {
					let userClone = userSession.clone({
						lastSelectedOrg: res.lastSelectedOrg,
						selectedBusinessStream: res.selectedBusinessStream,
						preferences: res.preferences,
					});
					setRecoil(sessionSelector, userClone);
					Browser.navigate('/');
					hideModal();
				}).finally(() => {
					if (organizationPath) {
						GA.ChangeOrganizationEvent(organizationPath);
					}
				});
			},
			onCancel: () => cancelModal(),
			confirmText: 'Confirm',
			hasCancel: !userSession.isFirstTimeUser(),
			disableAction: disableConfirmAction,
		};
		setValue(modalObject);
	}, [showOrgModal, selectedOrg]);

	if (!userSession) return null;

	return (
		<></>
	);
};

export default ChangeOrgModal;