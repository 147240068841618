import React from 'react';
import SurveyIntro from './surveySteps/SurveyIntro';
import {TrialMessages} from './messages';
import {YesOrNoOptions} from '../../utils/constants';
import FinalStep from './surveySteps/FinalStep';
import GenericStep from './surveySteps/GenericStep';

export enum FormType {
	FeedbackRadio = 'feedbackRadio',
	Text = 'text',
	Radio = 'radio'
}

export const SurveyConfig = [
	{
		component:  () => <SurveyIntro/>,
		footer: false,
	},
	{
		component:  () => <GenericStep/>,
		footer: true,
		question: TrialMessages.SurveyQuestion1,
		form: {
			type: FormType.Text,
		},
	},
	{
		component: () => <GenericStep/>,
		footer: true,
		question: TrialMessages.SurveyQuestion2,
		form: {
			type: FormType.FeedbackRadio,
			valuesOption: ['1', '2', '3', '4', '5'],
			sliderLabels: ['Very hard', 'Very easy'],
		},
	},
	{
		component:  () => <GenericStep/>,
		footer: true,
		question: TrialMessages.SurveyQuestion3,
		form: {
			type: FormType.Text,
		},
	},
	{
		component: () =>  <GenericStep/>,
		footer: true,
		question: TrialMessages.SurveyQuestion4,
		form: {
			type: FormType.Text,
		},
	},
	{
		component: () =>  <GenericStep/>,
		footer: true,
		question: TrialMessages.SurveyQuestion5,
		form: {
			type: FormType.Text,
		},
	},
	{
		component:  () => <GenericStep/>,
		footer: true,
		question: TrialMessages.SurveyQuestion6,
		form: {
			type: FormType.Radio,
			valuesOption: YesOrNoOptions,
		},
	},
	{
		component:  () => <GenericStep/>,
		footer: true,
		question: TrialMessages.SurveyQuestion7,
		form: {
			type: FormType.Radio,
			valuesOption: YesOrNoOptions,
		},
		extra: {
			question: TrialMessages.SurveyQuestionExtra7,
			type: FormType.Text,
		},
	},
	{
		component:  () => <GenericStep/>,
		footer: true,
		question: TrialMessages.SurveyQuestion8,
		form: {
			type: FormType.Radio,
			valuesOption: YesOrNoOptions,
		},
	},
	{
		component:  () => <GenericStep/>,
		footer: true,
		question: TrialMessages.SurveyQuestion9,
		form: {
			type: FormType.Radio,
			valuesOption: YesOrNoOptions,
		},
		extra: {
			question: TrialMessages.SurveyQuestionExtra9,
			type: FormType.Text,
		},
	},
	{
		component:  () => <FinalStep/>,
		footer: false,
	},
];