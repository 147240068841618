import React, {Suspense, useMemo} from "react";
import {AppRoute, ROUTES} from "./utils/routes";
import {Lists} from "./utils/lists";
import {Route, Routes} from "react-router-dom";
import PageLoader from "./components/Loader/PageLoader";
import {useRecoilValue} from 'recoil';
import {sessionSelector} from './state/session';

const PageRouter = () => {
    const session = useRecoilValue(sessionSelector);

    const renderRoute = useMemo(() => (route: AppRoute, index: number) => {
        if (route.private && !session) {
            return null;
        }
        const children = Lists.default<AppRoute>(route.children);
        if (children.length) {
            return (
                <Route key={index} path={route.path} element={<route.component>
                    <Routes>
                        {children.map((child, idx) => renderRoute(child, idx))}
                    </Routes>
                </route.component>}/>
            )
        }

        if (route.component) {
            return <Route key={index} path={route.path} element={
                <Suspense fallback={<PageLoader/>}>
                    <route.component/>
                </Suspense>
            }/>
        }
    }, [session])
    return <Routes> {ROUTES.map(renderRoute)}</Routes>
}

export default PageRouter;