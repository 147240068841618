import {UUID} from '../types';
import {Strings} from '../../utils/strings';
import {Numbers} from '../../utils/numbers';
import {Lists} from '../../utils/lists';
import {CMSObject, DisplayMode} from './__CMSObject';

export class CTUnit extends CMSObject {
	public static title = 'Unit';
	public static autoPublish = true;
	public alias: string;
	public code: string;
	public glh: number;
	public name: string;

	public status: boolean;

	public mandatory_size: UUID[];
	public optional_size: UUID[];
	public pathway: UUID[];
	public optional_pathway: UUID[];
	public type: string;

	constructor(item: any = {}) {
		super(item);

		this.code = Strings.default(item.code);
		this.name = Strings.default(item.name);
		this.alias = Strings.default(item.alias);
		this.glh = Numbers.default(item.glh);
		this.status = Boolean(item.status);
		this.type = Strings.default(item.type);

		this.mandatory_size = Lists.default(item.mandatory_size);
		this.optional_size = Lists.default(item.optional_size);
		this.pathway = Lists.default(item.pathway);
		this.optional_pathway = Lists.default(item.optional_pathway);
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		if (options === DisplayMode.SHORT) {
			let id = this.alias.split(' ')[1];
			if (id) {
				id = id.toString().padStart(2, '0');
				return `Unit ${id}`;
			} else {
				return this.alias;
			}
		} else {
			return `${this.alias} - ${this.name}`;
		}
	}

	public getGLH(): string {
		return `${this.glh} GLH`;
	}
}
