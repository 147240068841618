import React, {useContext, useMemo} from 'react';
import {UUID} from '../../../tw/types';
import {WidgetContext} from '../widget';
import {useRecoilValue} from 'recoil';
import {documentSelector} from '../../../state/documents';
import {Lists} from '../../../utils/lists';
import {DocumentsWrapper} from '../../../tw/models/DocumentsWrapper';
import WidgetHeader from '../commons/WidgetHeader';
import {getRecoil} from '../../../state/recoilNexus';
import {references} from '../../../state/state';
import {Topic} from '../../../tw/models/Topic';
import {fi} from '../../../utils/helpers';
import styled from '@emotion/styled';
import {Text} from '../../../tw/models/Text';
import RatingComponent from '../../Card/CardActions/RatingComponent';
import {sessionAtom} from '../../../state/session';
import Wysiwyg from '../wysiwyg/Wysiwyg';

interface TextWidget {
	topic: UUID;
	topicTitle: boolean;
	topicDescription: boolean;

	itemTitle: boolean;
	itemDescription: boolean;

	metadata: boolean;
}

const ContentWrapper = styled.div`
    margin-bottom: 40px;
`;

const MetadataWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    margin-top: 8px;
    font-family: var(--font-regular);
    font-size: 14px;
    color: var(--color-monochrome);

    svg {
        margin-left: 8px;
        margin-right: 8px;
        height: 18px;
        width: 18px;
        transition: all 0.2s linear;
        color: var(--color-border);

        &:hover {
            cursor: pointer;
        }
    }

    .rating-feedback {
        background: var(--color-background);
        display: flex;
        top: -8px;
        left: 160px;
        justify-content: flex-start;
        height: 100%;
        padding: 8px;

        button {
            padding-right: 8px;
        }
    }
`;

const PageContentWidget = () => {
	const context = useContext(WidgetContext);
	const widget = context.widget as TextWidget;
	const topic = getRecoil(references(widget.topic)) as Topic;
	const user = useRecoilValue(sessionAtom);

	const documents = useRecoilValue(documentSelector);

	const filteredDocuments = useMemo(() => {
		return Lists.sort(documents.byTopic(widget.topic), 'title') as DocumentsWrapper;
	}, [documents, context, widget]);

	const ratingDisabled = useMemo(() => {
		if (!user) return true;
		return user.isTrial() || user.isRestrictedAccess();
	}, [user]);

	if (!user || !topic) return null;
	return (
		<>
			<WidgetHeader data-testid='topic-header'>
				{fi(widget.topicTitle, <h2 data-testid='topic-title'>{topic.displayLabel()}</h2>)}
				{fi(widget.topicDescription, <div>{topic.description}</div>)}
			</WidgetHeader>
			{filteredDocuments.map((doc, idx) =>
				<ContentWrapper className="wysiwyig-content" key={doc.displayLabel()} data-testid="free-text">
					{fi(widget.itemTitle, <h3>{doc.displayLabel()}</h3>)}
					{fi(widget.itemDescription, <div>{(doc as Text).description}</div>)}
					{fi(widget.metadata,
						<MetadataWrapper>
							<span>Published on {doc.getPublishedDate()}</span>
							{fi(topic.isRateable(),
								<RatingComponent item={doc} disabled={ratingDisabled}/>,
							)}
						</MetadataWrapper>,
					)}
					<Wysiwyg item={doc as Text} fieldId='text' id={`page-content-${doc.getId()}`}/>
				</ContentWrapper>,
			)}
		</>
	);
};

export default PageContentWidget;