import React from 'react';
import {TrialMessages} from './messages';
import styled from '@emotion/styled';

const TrialMessageElem = styled.div`
    p {
        font-size: 16px;
        font-family: var(--font-regular);
        line-height: 20px;
    }

    a {
        color: var(--color-blue);
        text-decoration: underline;
    }
`
const InfoMessage = () => {

	return (
		<TrialMessageElem>
			<p data-testid='info-first-p'>{TrialMessages.FirstParagraph}</p>
			<br/>
			<p data-testid='info-second-p'>
				{TrialMessages.SecondParagraph} &nbsp;<a href={'mailto:' + TrialMessages.TrialEmail}> {TrialMessages.TrialEmail}</a>
			</p>
			<br/>
			<p data-testid='info-third-p'>
				{TrialMessages.ThirdParagraph}
			</p>
			<p data-testid='info-fourth-p'>
				{TrialMessages.FourthParagraph}
			</p>
		</TrialMessageElem>
	);
};

export default InfoMessage;