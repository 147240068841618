import React, {useEffect} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {feedbackAtom, feedbackData, feedbackStepAtom} from '../../state/feedback';
import {ConfirmationModalProps, modalAtom} from './ModalDialog';
import Feedback from '../Feedback/Feedback';
import {resetAtomFamily, setCookie} from '../../utils/helpers';
import {FEEDBACK_COOKIE_NAME, FEEDBACK_COOKIE_VERSION} from '../../utils/constants';

const FeedbackModal = () => {
	const setValue = useSetRecoilState(modalAtom);
	const [showFeedback, setShowFeedback] = useRecoilState(feedbackAtom);
	const setFeedbackStep = useSetRecoilState(feedbackStepAtom);
	const cancelModal = () => {
		setFeedbackStep(0);
		resetAtomFamily(feedbackData, [0, 1, 2, 3]);
		setShowFeedback(false);
		setCookie(FEEDBACK_COOKIE_NAME, FEEDBACK_COOKIE_VERSION, 90, '/');
	};

	const doNothing = () => {
		return;
	};
	useEffect(() => {
		if (!showFeedback) {
			return;
		}
		const modalObject: ConfirmationModalProps = {
			title: `Feedback form`,
			message: <Feedback/>,
			onConfirm: () => doNothing(),
			onCancel: () => cancelModal(),
			confirmText: 'Next',
			hasCancel: true,
			noFooter: true,
		};
		setValue(modalObject);
	}, [showFeedback]);

	if (!showFeedback) return null;

	return (
		<></>
	);
};
export default FeedbackModal;