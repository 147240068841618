import React from 'react';
import { atom, useRecoilState } from 'recoil';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Clear from '@mui/icons-material/Clear';

type SeverityType = 'success' | 'error' | 'warning' | 'info'

export interface SnackbarState {
	message: string;
	severity: SeverityType;
}

export enum AlertSeverity {
	Error = 'error',
	Success = 'success',
	Warning = 'warning',
	Info = 'info'
}

export const snackbarStateAtom = atom<SnackbarState | null>({
	key: 'snackbarState',
	default: null,
});
const SnackbarComponent = () => {
	const [snackbarState, setSnackbarState] = useRecoilState(snackbarStateAtom);

	if (!snackbarState) return null;
	const handleClose = () => {
		setSnackbarState(null);
	};

	return (
		<Snackbar data-testid="snackbar-component" anchorOrigin={{vertical: 'top', horizontal: 'right'}}
				  open={true} autoHideDuration={6000} onClose={handleClose}>
			<Alert severity={snackbarState.severity} sx={{width: '100%'}} data-testid="snackbar-alert">
				{snackbarState.message}
				<Clear data-testid="close-btn" onClick={handleClose} fontSize="small" className="ml-16"/>
			</Alert>
		</Snackbar>
	);
};

export default SnackbarComponent;