import FormSubmitted from './TrainingComponents/FormSubmitted';
import React from 'react';
import TrainingForm from './TrainingForm';

const TrainingFormWrapper = () => {
	return (
		<>
			<TrainingForm/>
			<FormSubmitted/>
		</>
	);
};

export default TrainingFormWrapper;