import React, {useMemo} from "react"
import Group from "../../Card/Group";
import {useRecoilValue} from "recoil";
import {subjectUpdateSelector} from "../../../state/subjectUpdate";
import {Lists} from '../../../utils/lists';

const SubjectUpdates = () => {
	const subjectsList = useRecoilValue(subjectUpdateSelector);

	const list = useMemo(() => {
		let ulist =  subjectsList.filter(s => s.isUpdate());
		return Lists.sort(ulist, 'date', true);
	}, [subjectsList])

	if (!list || list.length === 0) return null;

	return (
		<Group items={list} data-testid='subject-updates-group'></Group>
	)
}

export default SubjectUpdates