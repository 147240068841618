import React from 'react';
import {ExamTypeFilter} from '../../../../state/keyDates';
import {fi} from '../../../../utils/helpers';
import KeyTypeFilterComponent from './KeyTypeFilterComponent';

const ActivityType = () => {
	const set = (filter: ExamTypeFilter, value: string) => {
		return {...filter, type: fi(value !== 'All', value)};
	};

	return (
		<KeyTypeFilterComponent keyFilter={'type'} title='Activity type' filter={item => item.type} set={set} />
	);
};

export default ActivityType;