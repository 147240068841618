export type UUID = string;

export enum Types {
    UNDEFINED = '',
    FOLDER = 'folder',
    CONTENT_TYPE = 'content_type',
    DOCUMENT = 'document',
    IMAGE = 'image',
    TEXT = 'text',
    VIDEO = 'video',
    LINK = 'link',
    AUDIO = 'audio',
    EVENT_MATERIAL = 'event_material',
    BUSINESS_STREAM = 'business_stream',
    QUALIFICATION_GROUP = 'qualification_group',
    QUALIFICATION = 'qualification',
    QUALIFICATION_SIZE = 'qualification_size',
    PATHWAY = 'pathway',
    CT_UNIT = 'ct_unit',
    SPECIFICATION_GROUP = 'specification_group',
    SUBJECT = 'subject',
    EVENT = 'events',
    // CERTIFICATION = 'certification',
    COMPONENT = 'component',
    // TIER = 'tier',
    CHILD_ASSESSMENT = 'child_assessment',
    ASSESSMENT = 'assessment',
    FILE = 'file',
    USEFUL_LINK = 'useful_link',
    CONTENT_GROUP = 'content_group',
    // PAGE_TEMPLATE = 'page_template',
    PAGE = 'page',
    QUALIFICATION_GROUP_MAPPING = 'qualification_group_mapping',
    SUBJECT_GROUP_MAPPING = 'qualification_mapping',
    SUBJECT_UPDATES = 'subject_information',
    NOTIFICATIONS = 'notification',
    KEY_DATE = 'key_date',
    TIMETABLE = 'timetable_date',
    KEY_DATES_FILE = 'key_dates_file',
    ITEM_NOTE = 'note',
    EMAIL_UPDATES = 'subject_email',
    TOPIC = 'topic',
    HELP_INFO = 'help_info',
    ZENDESK_ARTICLE = 'zendesk_article', //for testing

    LIBRARY = 'library',
    PRODUCT_MAPPING = 'product',
}

export type SubjectUpdateViewState = {
    subject_update: UUID;
    version: string;
    last_seen: string;
}

export type Filter = number

// Content filter
export enum Filters {
    // NONE = 0 as Filter,
    // DELETED = 1 as Filter,
    ARCHIVED = 2 as Filter,
    PRIVATE = 4 as Filter,
    UNPUBLISHED = 8 as Filter,
    // ALL = Filters.DELETED | Filters.ARCHIVED | Filters.PRIVATE | Filters.UNPUBLISHED,
}

export enum SeenOptions {
    New = 'New',
    Updated = 'Updated',
}

export type LocalizedText = {
    lang: string;
    text: string;
}
export type LocalizedString = LocalizedText[];

export type IQueryResult<T> = {
    results: T[];
    references?: { [key: UUID]: any }
    offset?: number;
    total?: number;
    error?: string;
    status?: number;
    etag?: string;
}

export type IDistinctResult = {
    results: any[];
    references: { [key: UUID]: any }
}

export type Sort = 'asc' | 'desc';

// The structure used for querying CMS objects
export type QueryContentRequest = {
    // Optional. If sent, the server will only compare if your request will return the same
    // result in which case it will return a 304 Not Modified response.
    // ETags and the request associated to it are stored in the 'queryETags' atomFamily.
    // The response matching a certain ETag is stored in 'cachedQueryResults' atomFamily.
    etag?: string;
    // Offset to start result set (page) from
    start?: number;
    // Number of items to return for page. -1 means no limit and will return all items starting from 'start'.
    limit?: number;
    // Field uid to be used for sorting
    order?: string;
    // Sort method. ASC or DESC
    sort?: Sort;
    // If uuids is provided, the result will only include items from this list.
    // Usually used if you have a bunch of references, and you want the CMS objects for them.
    uuids?: string[];
    // Model types to consider
    types: string[];
    // Do not retrieve metadata
    nometa?: boolean;
    // NoIndex will disable index lookup for the matching criteria
    noindex?: boolean;
    // Content filter
    filter?: Filter;
    // Include and resolve object references
    references?: boolean;
    // search query
    query?: string;
    // If set to true, the CMS will only return cached metadata about the objects and no other fields
    metadataonly?: boolean;
    // Objects whose fields match this values
    matching?: { [key: string]: any };
    withindex?: { [key: string]: any };
}

// used for clickable card features,
// stores the action name and the action handler
export type DefaultAction = {
    label: string;
    actionHandler: () => void;
}

