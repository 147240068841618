import React from 'react';
import styled from '@emotion/styled';
import {InfoOutlined} from '@mui/icons-material';
import {Messages} from '../../utils/messages';
import {sessionAtom} from '../../state/session';
import {useRecoilValue} from 'recoil';

const PreviewElem = styled.div`
    width: 100%;
    background-color: var(--color-lighter-blue-background);
    border-bottom: solid 1px var(--color-blue);
    position: fixed;
    top: 0;
    font-size: 16px;
    color: var(--color-blue);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 16px;
    z-index: 9999;

    svg {
        margin-right: 16px;
    }
`;
const Preview = () => {
	const user = useRecoilValue(sessionAtom);
	if (!user || !user.preview) return null;
	return (
		<PreviewElem>
			<InfoOutlined/> <strong> Preview mode.</strong> &nbsp; {Messages.PreviewMode}
		</PreviewElem>
	);
};

export default Preview;