import {BaseClass} from "./__base";
import {Strings} from "../../utils/strings";
import {UUID} from "../types";

export class CMSFile extends BaseClass {
	public static readonly Extensions: any = {
		PDF: ['pdf'],
		CSV: ['csv'],
		Excel: ['xls', 'xlsx'],
		MathExcel: ['xlsm', 'xlsmx'],
		Doc: ['doc', 'docx'],
		Image: ['jpeg', 'jpg', 'png', 'gif', 'jfif', 'jpe'],
		PPT: ['ppt', 'pptx'],
		ZIP: ['zip'],
		Audio: ['mp3', 'mp4', 'wav']
	}

	public fileuid: UUID;
	public size: number;
	public filename: string;
	public contenttype: string;
	public hash: string;
	public created: Date; // Date string
	public author: string;

	constructor(data: any = {}) {
		super(data);

		this.fileuid = data.fileuid;
		this.size = data.size;
		this.filename = Strings.default(data.filename, '-');
		this.contenttype = Strings.default(data.contenttype);
		this.hash = Strings.default(data.hash);
		this.created = new Date(data.created);
		this.author = Strings.default(data.author, '-');
	}

	public get extension(): string {
		return Strings.default(this.filename.split('.').pop())
	}

	public isDoc(): boolean {
		return CMSFile.Extensions.Doc.includes(this.extension);
	}

	public isExcel(): boolean {
		return CMSFile.Extensions.Excel.includes(this.extension);
	}

	public isMathExcel(): boolean {
		return CMSFile.Extensions.MathExcel.includes(this.extension);
	}

	public isPDF(): boolean {
		return CMSFile.Extensions.PDF.includes(this.extension);
	}

	public isCSV(): boolean {
		return CMSFile.Extensions.CSV.includes(this.extension);
	}

	public isImage(): boolean {
		return CMSFile.Extensions.Image.includes(this.extension);
	}

	public isAudio(): boolean {
		return CMSFile.Extensions.Audio.includes(this.extension);
	}

	public isZIP(): boolean {
		return CMSFile.Extensions.ZIP.includes(this.extension);
	}

	public isPPT(): boolean {
		return CMSFile.Extensions.PPT.includes(this.extension);
	}

	public isOffice(): boolean {
		return this.isPPT() || this.isDoc() || this.isExcel() || this.isMathExcel() || this.isCSV();
	}
}