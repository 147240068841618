import {UUID} from '../types';
import {MediaItemFile} from './__MediaLibrary';
import React, {ReactElement} from 'react';
import {ReactComponent as DocIconSmall} from '../../assets/images/card/doc-small.svg';
import {ReactComponent as DOCIcon} from '../../assets/images/card/doc-normal.svg';
import {ReactComponent as PDFIconSmall} from '../../assets/images/card/pdf-small.svg';
import {ReactComponent as PDFIcon} from '../../assets/images/card/pdf-normal.svg';
import {ReactComponent as CSVIconSmall} from '../../assets/images/card/csv-small.svg';
import {ReactComponent as CSVIcon} from '../../assets/images/card/csv-normal.svg';
import {ReactComponent as XLSIconSmall} from '../../assets/images/card/xls-small.svg';
import {ReactComponent as XLSIcon} from '../../assets/images/card/xls-normal.svg';
import {ReactComponent as XLSMIconSmall} from '../../assets/images/card/xlsm-small.svg';
import {ReactComponent as XLSMIcon} from '../../assets/images/card/xlsm-normal.svg';
import {ReactComponent as PPTIconSmall} from '../../assets/images/card/ppt-small.svg';
import {ReactComponent as PPTIcon} from '../../assets/images/card/ppt-normal.svg';
import {ReactComponent as ZIPIconSmall} from '../../assets/images/card/zip-small.svg';
import {ReactComponent as ZIPIcon} from '../../assets/images/card/zip-normal.svg';

import {CMSFile} from './File';
import {fi} from '../../utils/helpers';
import {disableButton} from '../../components/Card/CardActions/Buttons/utils';
import {Messages} from '../../utils/messages';

export class Document extends MediaItemFile {
	public static searchable: boolean = true;
	public static markAsSeen = true;
	public content_type: UUID;

	private static icons: any = {
		PDF: [PDFIcon, PDFIconSmall],
		CSV: [CSVIcon, CSVIconSmall],
		Excel: [XLSIcon, XLSIconSmall],
		MathExcel: [XLSMIcon, XLSMIconSmall],
		Doc: [DOCIcon, DocIconSmall],
		PPT: [PPTIcon, PPTIconSmall],
		ZIP: [ZIPIcon, ZIPIconSmall],
	};

	constructor(item: any = {}) {
		super(item);
		this.content_type = item.content_type;
	}

	public getIcon(small: boolean = false): ReactElement | null {
		const fileInfo = this.fileInfo();
		if (!fileInfo) {
			return null;
		}

		for (let key in Document.icons) {
			if (CMSFile.Extensions[key].includes(fileInfo.extension)) {
				const defaultAction = this.defaultAction();
				const Icon = Document.icons[key][+small];
				return <Icon onClick={defaultAction.actionHandler}
							 title={fi(disableButton(this), Messages.FeatureNotAvailable, defaultAction.label)}/>;
			}
		}
		return null;
	}

	public hasPreview(): boolean {
		const fileInfo = this.fileInfo();
		if (!fileInfo) {
			return false;
		}
		return fileInfo.isPDF();
	}

	public downloadable(): boolean {
		return true;
	}


}