import {LibraryItem} from './__MediaLibrary';
import {UUID} from '../types';
import {Strings} from '../../utils/strings';
import {Dates} from '../../utils/dates';

export class Text extends LibraryItem {
	public text: string;
	public topic: UUID;

	constructor(item: any ={}) {
		super(item);

		this.text = Strings.default(item.text);
		this.topic = Strings.default(item.topic);
	}


	public getTopic() {
		return this.topic;
	}

	public getPublishedDate() {
		return Dates.local(this.__meta.published, 'long');
	}

}