import React from 'react';
import RadioStep from './RadioStep';

//second step in feedback form
const SecondStep = () => {
	return (
		<RadioStep/>
	);
};

export default SecondStep;