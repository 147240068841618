import {EventsAir} from '../../../../tw/models/EventsAir';
import {Dates} from '../../../../utils/dates';
import React from 'react';

const ActiveEventDetails = ({event}: { event: EventsAir }) => {
	return (
		<>
			<div className="text-16 text-semiBold" data-testid='event-info'>
				{event.location} - {event.format} <span className="greenText">{event.getPrice()}</span>
			</div>
			<div className="text-14 text-regular"> {Dates.format(event.startDate, true)}</div>
			<div className="text-14 text-regular">
				{event.getTimeFormatted(event.startTime)}-{event.getTimeFormatted(event.endTime)}
			</div>
		</>
	);
};

export default ActiveEventDetails;