import React from "react";

export const HomepageResourcesLabels = {
    KeyDocuments: "Key documents",
    RecentlyUpdated: "Recently updated",
    Favourites: "My favourites"
}

type Config = {
    label: string,
    component?: React.ReactNode
}

export const HomepageResourcesConfig: Config[] = [
    {
        label: HomepageResourcesLabels.KeyDocuments,
    },
    {
        label: HomepageResourcesLabels.RecentlyUpdated,
    },
    {
        label: HomepageResourcesLabels.Favourites,
    },

]
