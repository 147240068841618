import Button from '@mui/material/Button';
import React, {useMemo} from 'react';
import {atom, useRecoilState} from 'recoil';
import {cancelEvent, fi} from '../../utils/helpers';
import GenericModal from './GenericModal';

export type ConfirmationModalProps = {
	title: string,
	message: React.ReactNode,
	onConfirm: () => void,
	onCancel?: () => void,
	confirmText: string,
	hasCancel: boolean;
	dangerColor?: boolean
	noFooter?: boolean
	disableAction?: boolean;
}

export const modalAtom = atom<ConfirmationModalProps | null>({
	key: 'modalAtom',
	default: null,
});

const ModalDialog = () => {
	const [value, setValue] = useRecoilState(modalAtom);

	const onCancel = (evt) => {
		cancelEvent(evt);
		if (!value) return;
		if (value.onCancel) {
			value.onCancel();
		}
		setValue(null);
	};

	const onConfirm = async (evt) => {
		cancelEvent(evt);
		if (!value) return;
		await value.onConfirm();
		onCancel(evt);
	};

	const footer = useMemo(() => {
		if (!value || value.noFooter) return null;
		return (
			<>
				{fi(value.hasCancel, <Button variant="text" color="primary" onClick={onCancel} title={'Cancel'}
											 size="small">Cancel</Button>)}
				<Button variant="contained" color={`${value.dangerColor ? 'error' : 'primary'}`} onClick={onConfirm}
						title={value.confirmText}
						disabled={value.disableAction || false}
						size="small" data-testid='modal-confirm-btn'>{value.confirmText}</Button>
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	if (!value) return null;
	const doNothing = () => {
		return
	}
	return (
		<GenericModal title={value.title} footer={footer} onClose={fi(value.hasCancel, onCancel, doNothing)} hasCancel={value.hasCancel}>
			{value.message}
		</GenericModal>
	);
};

export default ModalDialog;
