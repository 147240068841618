import {FormMessages} from './messages';
import PersonalInfo from './PersonalInfo';
import CentreInfo from './CentreInfo';
import TrainingCourse from './TrainingCourse';
import DeliveryDate from './DeliveryDate';
import PriceList from './PriceList';
import React from 'react';

export const TrainingFormComponents = [
	{
		label: FormMessages.PersonalInformation,
		component: (props) => <PersonalInfo {...props}/>,
	},
	{
		label: FormMessages.CentreInformation,
		component: (props) => <CentreInfo {...props}/>,
	},
	{
		label: FormMessages.TrainingCourse,
		component: (props) => <TrainingCourse {...props}/>,
	},
	{
		label: FormMessages.DeliveryDate,
		component: (props) => <DeliveryDate {...props}/>,
	},
	{
		label: FormMessages.PriceList,
		component: (props) => <PriceList {...props}/>,
	},
];

