import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	ContentTypeSelector,
	SearchCurrentPage,
	SearchFilterAtom,
	searchHits,
	SeriesSelector,
	showSearchResults, toggleFilterAtom,
	YearSelector,
} from '../../utils';
import Button from '@mui/material/Button';

import InputField from '../../../../FormComponents/Input/InputField';
import SelectComponent, { ISelectValue } from '../../../../FormComponents/Select/SelectComponent';
import { SubjectSelector } from '../../../../../state/product';
import { fi } from '../../../../../utils/helpers';
import { Lists } from '../../../../../utils/lists';
import { cacheBuster } from '../../../../../state/state';
import { setRecoil } from '../../../../../state/recoilNexus';
import Client from '../../../../../tw/client';
import { Strings } from '../../../../../utils/strings';
import GA from '../../../../../tw/models/GA';
import { useMediaQuery } from '@mui/material';
import { device } from '../../../../../utils/constants';
import ClearFilters from './ClearFilters';

const Wrapper = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;

    input {
        background-color: white;
        border-radius: 4px;
    }

    .search-term {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 16px;
    }
	
	@media ${device.mobile} {
        margin-top: 16px;
    }
`;

const StyledSelect = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;


    @media ${device.mobile} {
        flex-direction: column;
    }
`;

const Form = () => {
	const [filter, setFilter] = useRecoilState(SearchFilterAtom);

	const contentTypes = useRecoilValue(ContentTypeSelector);
	const years = useRecoilValue(YearSelector);
	const series = useRecoilValue(SeriesSelector);
	const setShowResults = useSetRecoilState(showSearchResults);
	const subject = useRecoilValue(SubjectSelector);
	const setSearchHits = useSetRecoilState(searchHits);
	const setPage = useSetRecoilState(SearchCurrentPage);
	const mobileOrTablet = useMediaQuery(device.mobile);
	const [toggleFilter, setToggleFilter] = useRecoilState(toggleFilterAtom);

	const contentTypesList: ISelectValue[] = useMemo(() => {
		const ctList: ISelectValue[] = [];
		contentTypes.forEach(ct => {
			const label = ct.displayLabel();
			ctList.push({
				label: fi(label.includes('#'), label.substring(0, label.indexOf('#')), label),
				value: ct.getId(),
				object: ct,
			});
		});
		return Lists.sort(ctList, 'label');
	}, [contentTypes]);

	const yearsList: ISelectValue[] = useMemo(() => {
		const yearList: ISelectValue[] = [];
		years.forEach(year => yearList.push({label: year, value: year, object: year}));
		return Lists.sort(yearList, 'label', true);
	}, [years]);

	const seriesList: ISelectValue[] = useMemo(() => {
		const listSeries: ISelectValue[] = [];
		series.forEach(s => listSeries.push({label: s, value: s, object: s}));
		return Lists.sort(listSeries, 'label');
	}, [series]);

	useEffect(() => {
		updateYearsValue();
	}, [filter.contentTypes]);

	useEffect(() => {
		updateSeriesValue();
	}, [filter.years]);

	const updateYearsValue = () => {
		let copyYears = [...filter.years];
		let updatedYears = copyYears.filter(c => yearsList.map(v => v.value).includes(c.value));
		setFilter({...filter, years: updatedYears});
	};

	const updateSeriesValue = () => {
		let copySeries = [...filter.series];
		let updatedSeries = copySeries.filter(c => seriesList.map(v => v.value).includes(c.value));
		setFilter({...filter, series: updatedSeries});
	};

	const onChange = async (obj, field) => {
		setRecoil(cacheBuster('resetSelectCardState'), (val) => val + 1);
		switch (field) {
			case 'content-type-selector':
				setFilter({...filter, contentTypes: obj});
				break;
			case 'year-selector':
				setFilter({...filter, years: obj});
				break;
			case 'series-selector':
				setFilter({...filter, series: obj});
				break;
		}
	};

	const onChangeQuery = (evt) => {
		setRecoil(cacheBuster('resetSelectCardState'), (val) => val + 1);
		setFilter({...filter, query: evt.target.value});
	};


	useEffect(() => {
		handleSearch();
	}, [filter.contentTypes, filter.series, filter.years, filter.sort, filter.order,subject]);

	const assessments = useMemo(() => {
		if (!subject) return [];
		if (subject.isTechnicals()) {
			return subject.getQualificationSizes();
		}
		return subject.getAssessmentCodes();
	}, [subject]);

	const handleSearch = async (fromAction?: boolean) => {
		setRecoil(cacheBuster('resetSelectCardState'), (val) => val + 1);
		setPage(0);
		let units: string[] = [];
		let components: string[] = [];
		let searchContentTypes = filter.contentTypes.map(c => c.value);
		let searchYears = filter.years.map(c => c.value);
		let searchSeries = filter.series.map(c => c.value);
		const query = Strings.default(filter.query).replace(/[^a-zA-Z0-9 ]/g, '');

		if (fromAction && toggleFilter) {
			setToggleFilter(false);
		}

		Client.search(query, searchContentTypes, searchYears, searchSeries, assessments, units, components, filter.sort, filter.order).then(r => {
			setSearchHits(r.hits.hits);
			setShowResults(true);
		}).finally(() => {
			GA.SearchEvent({
				event_label: Strings.default(filter.query, 'null'),
				content_type: Lists.default<ISelectValue>(filter.contentTypes).map(ct => ct.label).join(', '),
				examyear: Lists.default<ISelectValue>(filter.years).map(y => y.label).join(', '),
				series: Lists.default<ISelectValue>(filter.series).map(s => Strings.capitalize(s.label)).join(', '),
				sortBy: filter.sort,
			});
		});
	};

	const handleInputKeyPress = async (e) => {
		if (e.charCode === 13 && filter.query) {
			await handleSearch(true);
		}
	};

	return (
		<Wrapper>
			<form>
				<div data-testid="search-bar-section" className="search-term">
					<InputField placeholder="Search a resource"
								id="search-input"
								value={filter.query}
								field={{name: 'search', label: ''}}
								onChange={onChangeQuery}
								onKeyPress={handleInputKeyPress}/>
					<Button size="small" onClick={() => handleSearch(true)} variant={'contained'}>Search</Button>
				</div>
				<StyledSelect data-testid="filter-content">
					<SelectComponent
						label="Content type"
						id="content-type-selector"
						placeholder="All"
						value={filter.contentTypes}
						onChange={onChange}
						values={contentTypesList}
						checkbox={true}
					/>
					<SelectComponent
						label="Year"
						id="year-selector"
						placeholder="All"
						value={filter.years}
						onChange={onChange}
						values={yearsList}
						checkbox={true}
					/>
					<SelectComponent
						label="Series"
						id="series-selector"
						placeholder="All"
						value={filter.series}
						onChange={onChange}
						values={seriesList}
						checkbox={true}
					/>

					{!mobileOrTablet && <ClearFilters/>}
				</StyledSelect>
			</form>
		</Wrapper>
	);
};

export default Form;