import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { fi } from '../../../../utils/helpers';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { groupingAtom, GroupingRule, SearchFilterAtom, SortingAndGroupingTypes } from '../utils';
import { useRecoilState } from 'recoil';
import { useMediaQuery } from '@mui/material';
import { device } from '../../../../utils/constants';
import SelectComponent from '../../../FormComponents/Select/SelectComponent';

const BtnElem = styled(Button)`
    span {
        color: var(--color-monochrome) !important;
    }
`;

const SortingOptionsElem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const sortingOptions = [
	{label: 'Relevance', value: 'default', object: null},
	{label: 'Title', value: 'title', object: null},
	{label: 'Description', value: 'description', object: null},
	{label: 'Content type', value: 'content_type_name', object: null},
	{label: 'Date', value: 'modified', object: null},
];

const groupingOptions = [
	{label: 'None', value: GroupingRule.None, object: null},
	{label: 'Content type', value: GroupingRule.ContentType, object: null},
	{label: 'Exam year', value: GroupingRule.Year, object: null},
	{label: 'Series', value: GroupingRule.Series, object: null},
];

const sortingAndGrouping = {
	[SortingAndGroupingTypes.Sorting]: {
		label: 'Sort by:',
		options: sortingOptions,
	},
	[SortingAndGroupingTypes.Grouping]: {
		label: 'Group by:',
		options: groupingOptions,
	},
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
	width: 100%;
`

const DropdownSelection = (props) => {
	const [filter, setFilter] = useRecoilState(SearchFilterAtom);
	const [grouping, setGrouping] = useRecoilState(groupingAtom);
	const mobileOrTablet = useMediaQuery(device.mobile);

	const popupState = usePopupState({variant: 'popover', popupId: 'sort-dropdown'});

	const options = useMemo(() => {
		return sortingAndGrouping[props.type].options;
	}, [props]);

	const [value, setValue] = useState<string>(options[0].label);

	useEffect(() => {
		if (props.type === SortingAndGroupingTypes.Sorting) {
			const defaultOption = options.find(o => o.value === filter.sort);
			setValue(defaultOption.label);
		}
	}, [options, props.type, filter.sort]);

	const label = useMemo(() => {
		return sortingAndGrouping[props.type].label;
	}, [props]);

	useEffect(() => {
		if (filter.sort === '') {
			setFilter({...filter, sort: 'title', order: 'asc'});
		}
	}, []);
	const handleChange = (e) => {
		setValue(e.label);
		if (props.type === SortingAndGroupingTypes.Sorting) {
			setFilter({...filter, sort: e.value});
		} else {
			setGrouping(e.value);
		}
		popupState.close();
	};

	return (
		<>
			{mobileOrTablet ?
				<Wrapper>
					<SelectComponent
						label={label}
						id={`${label}-selector`}
						value={props.type === SortingAndGroupingTypes.Sorting ? filter.sort : grouping}
						onChange={(s) => handleChange(s)}
						values={options}
					/>
				</Wrapper>
				:
				<>
					<BtnElem size="small" {...bindTrigger(popupState)}
							 endIcon={fi(popupState.isOpen, <ExpandLess fontSize="small"/>, <ExpandMore
								 fontSize="small"/>)} data-testid={`${props.type}-btn`}>
						<span>{label}</span>&nbsp; {value}
					</BtnElem>
					<Popover {...bindPopover(popupState)}
							 anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
							 transformOrigin={{vertical: 'top', horizontal: 'left'}}
							 data-testid={`${props.type}-popover`}
					>
						<SortingOptionsElem data-testid={`${props.type}-options`}>
							{options.map(s => {
									return <Button size="small" onClick={() => handleChange(s)} color="secondary"
												   data-testid={`${s.label}-id`} key={s.label}>
										{s.label}
									</Button>
								},
							)}
						</SortingOptionsElem>
					</Popover>
				</>
			}
		</>
	);
};

export default DropdownSelection;