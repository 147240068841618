import {fi} from '../../../utils/helpers';
import {Favorite, FavoriteBorder} from '@mui/icons-material';
import React from 'react';
import {CMSObject} from '../../../tw/models/__CMSObject';
import styled from '@emotion/styled';
import {Messages} from '../../../utils/messages';

const Wrapper = styled.div`
    svg[data-testid="FavoriteIcon"] {
        color: var(--color-blue);
    }
`;

const AddToFavorite = ({item, disabled}: { item: CMSObject, disabled: boolean }) => {
	const onClick = () => {
		if (disabled) return;
		else item.toggleFavorite();
	};
	return (
		<Wrapper className={`${disabled ? 'low-opacity' : ''} no-padding`} data-testid={'add-to-favourites-btn'}
				 title={fi(disabled, Messages.FeatureNotAvailable, 'Add to favourites')}
				 onClick={onClick}>
			{fi(item.isFavorite(), <Favorite/>, <FavoriteBorder/>)}
		</Wrapper>
	);
};

export default AddToFavorite;
