import InputField from '../../../FormComponents/Input/InputField';
import {Strings} from '../../../../utils/strings';
import React from 'react';

const AdditionalInfo = ({formik, onChange}: { formik: any, onChange: (...props) => void }) => {
	return (
		<div className="mt-16">
			<InputField
				id="other"
				isTextArea={true}
				value={formik.values.other}
				onChange={(event) => onChange(event.target.value, 'other')}
				showError={Boolean(formik.touched.other) && Boolean(formik.errors.other)}
				errorMessage={Strings.default(formik.errors.other)}
				touched={Strings.default(!!formik.touched.other)}
				field={{name: 'other', label: 'Any other information you would like us to know:'}}
			/>
		</div>
	);
};

export default AdditionalInfo;