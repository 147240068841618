import React, {useEffect, useMemo} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import {Pathway} from '../../../tw/models/Pathway';
import styled from '@emotion/styled';
import {Objects} from '../../../utils/objects';
import {atom, useRecoilState} from 'recoil';
import {qualificationSizeAtom} from './SizeComponent';
import {QualificationSize} from '../../../tw/models/QualificationSize';
import {fi} from '../../../utils/helpers';

export const pathwayAtom = atom<Pathway | null>({
		key: 'pathwayAtom',
		default: null,
	},
);

const StyledMenu = styled(Menu)<{ width: string }>`
    font-size: 16px;
    margin-top: 8px;

    ul {
        ${props => `min-width: ${props.width}px;`};
        padding: 0;

        li {
            font-size: 14px;
        }
    }

    .title {
        font-family: var(--font-semi-bold);
        text-align: center;
        border-bottom: 1px solid var(--color-box-shadow);
        padding: 16px;
        margin-bottom: 8px;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    color: var(--color-black);

    &:last-of-type {
        margin-bottom: 8px;
    }

    &.selected {
        color: var(--color-blue);
        background-color: var(--color-selected);
    }
`;

const PathwayMenu = ({anchorEl, open, qualificationSize, onClose}: {
	anchorEl: any,
	open: boolean,
	qualificationSize: QualificationSize | null,
	onClose: () => void
}) => {
	const [selectedIndex, setSelectedIndex] = React.useState(-1);
	const [pathwayState, setPathwayState] = useRecoilState(pathwayAtom);
	const [sizeState, setSizeState] = useRecoilState(qualificationSizeAtom);

	const pathways = useMemo(() => {
		if (!qualificationSize) {
			return [];
		}
		return qualificationSize.pathways;
	}, [qualificationSize]);

	const handleMenuItemClick = (
		_event: React.MouseEvent<HTMLElement>,
		index: number,
	) => {
		setSelectedIndex(index);
		setPathwayState(pathways[index]);
		setSizeState(qualificationSize);
		onClose();
	};

	useEffect(() => {
		if (!sizeState || !qualificationSize) return;
		if (sizeState.getId() !== qualificationSize.getId()) {
			setSelectedIndex(-1);
		} else {
			if (!pathwayState) return;
			const idx = pathways.findIndex(p => p.getId() === pathwayState.getId());
			setSelectedIndex(idx);
		}
	}, [sizeState, qualificationSize]);
	return (
		<StyledMenu id="pathways-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={onClose}
					MenuListProps={{
						role: 'list',
					}}
					TransitionComponent={Fade}
					width={Objects.default(anchorEl).offsetWidth}>
			<div className="title">Pathways</div>
			{pathways.map((pathway: Pathway, index) => (
				<StyledMenuItem data-testid={`pathway-${index}`}
								key={`pathway-${pathway.__uuid}`}
								selected={index === selectedIndex}
								className={fi(index === selectedIndex, 'selected')}
								onClick={(event) => handleMenuItemClick(event, index)}>
					{pathway.displayLabel()}
				</StyledMenuItem>
			))}
		</StyledMenu>
	);
};

export default PathwayMenu;