import {CMSObject} from '../../../../tw/models/__CMSObject';
import React, {useMemo} from 'react';
import Button from '@mui/material/Button';
import {ReactComponent as DownloadIcon} from '../../../../assets/images/card/actions/download-icon.svg';
import {disableButton} from './utils';
import {Messages} from '../../../../utils/messages';
import {fi} from '../../../../utils/helpers';

const DownloadButton = ({item}: { item: CMSObject }) => {
	const disableAction = useMemo(() => {
		return disableButton(item);
	}, [item]);
	return (
		<span title={fi(disableAction, Messages.FeatureNotAvailable, 'Download')}>
			<Button data-testid='dwd-btn' color="primary" variant="text" size="large" onClick={() => item.download()}
					disabled={disableAction}>
				<DownloadIcon/>
			</Button>
		</span>
	);
};

export default DownloadButton;