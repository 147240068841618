import {atom, useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {ConfirmationModalProps, modalAtom} from './ModalDialog';
import React, {useEffect} from 'react';
import {sessionAtom} from '../../state/session';
import InfoMessage from '../Trial/InfoMessage';
import {showTrialWelcome, USER_TOUR} from '../../utils/constants';

export const trialModalAtom = atom<boolean>({
	key: 'trialModalAtom',
	default: false,
});

const TrialIntroModal = () => {
	const userSession = useRecoilValue(sessionAtom);
	const setValue = useSetRecoilState(modalAtom);
	const [showModal, setShowModal] = useRecoilState(trialModalAtom);

	useEffect(() => {
		if (sessionStorage.getItem(showTrialWelcome)) {
			setShowModal(true);
		}
	}, [sessionStorage.getItem(showTrialWelcome)])

	const cancelModal = () => {
		setShowModal(false);
		sessionStorage.removeItem(showTrialWelcome);
		localStorage.removeItem(USER_TOUR);
	};

	const doNothing = () => {
		return
	}

	useEffect(() => {
		if (!userSession || !showModal) return;
		const modalObject: ConfirmationModalProps = {
			title: `Trial access`,
			message: <InfoMessage/>,
			onConfirm: doNothing,
			onCancel: cancelModal,
			noFooter: true,
			confirmText: '',
			hasCancel: true,
		};
		setValue(modalObject);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModal]);

	if (!userSession) return null;

	return (
		<></>
	);
};

export default TrialIntroModal;