import React from "react";
import {ReactComponent as TwitterIcon} from '../../assets/images/twitter.svg';
import {ReactComponent as LinkedInIcon} from '../../assets/images/linkedin.svg';
import {ReactComponent as YoutubeIcon} from '../../assets/images/youtube.svg';
import {ReactComponent as FacebookIcon} from '../../assets/images/facebook.svg';
import {ReactComponent as InstagramIcon} from '../../assets/images/instagram.svg';
import {device} from '../../utils/constants';
import styled from '@emotion/styled';

const SOCIAL_MEDIA_LINKS = [
    {
        label: "Twitter",
        icon: true,
        redirectTo: "https://twitter.com/ocrexams/"
    },
    {
        label: "LinkedIn",
        icon: true,
        redirectTo: "https://www.linkedin.com/company/ocr/"
    },
    {
        label: "Youtube",
        icon: true,
        redirectTo: "https://www.youtube.com/user/ocrexams"
    },
    {
        label: "Facebook",
        icon: true,
        redirectTo: "https://www.facebook.com/ocrexams"
    },
    {
        label: "Instagram",
        icon: true,
        redirectTo: "https://www.instagram.com/ocrexaminations"
    },
];

const SocialMediaElem = styled.div`
    @media ${device.tablet} {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        padding: 16px 8px 0 0;
        width: 100%;
        justify-content: flex-end;
        a:first-of-type {
            margin-left: 0 !important;
        }
    }
    @media ${device.mobile} {
        padding-top: 16px;
        justify-content: space-between;
    }
`
const SocialMediaComponent = () => {
    const renderIcon = (item: string) => {
        switch (item) {
            case "Twitter":
                return <TwitterIcon/>
            case "LinkedIn":
                return <LinkedInIcon/>
            case "Youtube":
                return <YoutubeIcon/>
            case "Facebook":
                return <FacebookIcon/>
            default:
                return <InstagramIcon/>
        }
    }

    return (
        <SocialMediaElem>
            {SOCIAL_MEDIA_LINKS.map((item, index) => (
                <a href={item.redirectTo} target="_blank" key={item.label}
                   data-testid={`${item.label}-test-id`} className="ml-16">
                    {renderIcon(item.label)}
                </a>
            ))}
        </SocialMediaElem>
    )
}

export default SocialMediaComponent;