import React, {useCallback, useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {helpInfoSelector, helpPanelToggledAtom, infoPanelState} from '../../state/helpGuide';
import {HelpGuide} from '../../tw/models/HelpGuide';
import {Lists} from '../../utils/lists';
import InfoPanel from './InfoPanel';
import styled from '@emotion/styled';

const ContentElem = styled.div`
    background-color: var(--color-white);
    border: solid 1px var(--color-border-light);
    border-radius: 3px;
    box-shadow: -2px 0 3px 3px var(--color-box-shadow);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    min-height: 220px;
    padding: 8px;
    position: fixed;
    right: 0;
    top: 230px;
    width: 335px
`
let previous: any;
const getIdentifiers = (element: any): { xpath: string, testids: string[], ids: string[] } => {
	let path = '';
	let current = element;
	const testids: string[] = [];
	const ids: string[] = [];

	// go up to the tree nodes until we reach the root and get all the ids and data-testids
	// and create the xpath for the given element
	do {
		const currentName = current.localName;
		const parent = current.parentElement;

		if (current.dataset.testid) {
			testids.push(current.dataset.testid);
		}
		if (current.id) {
			ids.push(current.id);
		}

		if (parent && parent.childElementCount > 1) {
			const children = Array.from(parent.children).filter((child: any) => child.localName === currentName);
			if (children.length === 1) {
				path = `/${currentName}${path}`;
			} else {
				path = `/${currentName}[${children.indexOf(current) + 1}]${path}`;
			}
		} else {
			path = `/${currentName}${path}`;
		}
		current = parent;
	} while (current && current.tagName !== 'BODY');
	return {xpath: `/html/body${path}`, testids, ids};
};
const HelpGuideContent = () => {
	const toggled = useRecoilValue(helpPanelToggledAtom);
	const items = useRecoilValue(helpInfoSelector);
	const setContent = useSetRecoilState(infoPanelState);

	const eventHandler = useCallback((event) => {
		if (previous) {
			previous = null;
		}
		previous = event.target;
		const {xpath, ids, testids} = getIdentifiers(previous);

		let matches: HelpGuide[] = [];
		items.forEach((item: HelpGuide) => {
			if (item.allPages) {
				if (item.selector) {
					if (ids.includes(item.selector) || testids.includes(item.selector) || xpath.startsWith(item.selector)) {
						matches.push(item);
					}
				} else {
					matches.push(item);
				}
			} else if (item.page) {
				if (Lists.default<string>(item.page).filter(id => window.location.pathname.includes(id)).length) {
					if (item.selector) {
						if (ids.includes(item.selector) || testids.includes(item.selector) || xpath.startsWith(item.selector)) {
							matches.push(item);
						}
					} else {
						matches.push(item);
					}
				}
			}
		});

		matches.sort((a: HelpGuide, b: HelpGuide) => {
			if (a.page && b.allPages) {
				return -1;
			} else if (a.allPages && b.page) {
				return 1;
			}

			if (a.selector && !b.selector) {
				return -1;
			} else if (!a.selector && b.selector) {
				return 1;
			}
			return 0;
		});
		if (matches.length) {
			setContent(matches[0]);
		} else {
			setContent(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	useEffect(() => {
		window.removeEventListener('mouseover', eventHandler);
		if (toggled) {
			window.addEventListener('mouseover', eventHandler);
		}
	}, [toggled, eventHandler]);

	if (!toggled) {
		return null;
	}
	return (
		<ContentElem>
			<InfoPanel/>
		</ContentElem>
	);
};

export default HelpGuideContent;