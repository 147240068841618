import styled from '@emotion/styled';
import React from 'react';
import Loader from './Loader';

const LoaderElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const PageLoader = ({size = 40}: {size?: number} ) => {
	return (
		<LoaderElement>
			<Loader size={size}/>
		</LoaderElement>
	);
};

export default PageLoader;
