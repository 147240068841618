import {atom, selector} from 'recoil';
import {documentSelector} from '../../../state/documents';
import {ContentType} from '../../../tw/models/ContentType';
import {references} from '../../../state/state';
import {ISelectValue} from '../../FormComponents/Select/SelectComponent';
import {DocumentsWrapper} from '../../../tw/models/DocumentsWrapper';
import {Link} from '../../../tw/models/Link';
import {Strings} from '../../../utils/strings';
import {Lists} from '../../../utils/lists';
import {UnitSelector} from '../../../state/product';
import {fi} from '../../../utils/helpers';
import {Objects} from '../../../utils/objects';

export enum SortingAndGroupingTypes {
	Sorting = 'sorting',
	Grouping = 'grouping'
}

export type SearchFilter = {
	query: string,
	contentTypes: ISelectValue[],
	years: ISelectValue[],
	series: ISelectValue[],
	sort: string,
	order: string
}

export enum GroupingRule {
	None = 'none',
	ContentType = 'content_type',
	Year = 'exam_year',
	Series = 'series'
}

export const groupingAtom = atom<GroupingRule>({
	key: 'groupingAtom',
	default: GroupingRule.None,
});
export const SearchFilterAtom = atom<SearchFilter>({
	key: 'SearchFilterAtom',
	default: {query: '', contentTypes: [], years: [], series: [], sort: 'title', order: 'asc'},
});

export const SearchCurrentPage = atom<number>({
	key: 'SearchCurrentPage',
	default: 0,
});

export const searchHits = atom<any[]>({
	key: 'SearchHitsAtom',
	default: [],
});
export const showSearchResults = atom<boolean>({
		key: 'showSearchResults',
		default: false,
	},
);

export const toggleFilterAtom = atom<boolean>({
	key: 'toggleFilterAtom',
    default: false,
})

export const LibraryItemSelector = selector<DocumentsWrapper>({
	key: 'LibraryItemSelector',
	get: async ({get}) => {
		const documents = get(documentSelector);
		return documents.filter((item) => item.isSearchable() || (item instanceof Link && !item.isOnlineCourse));
	},
});

export const SearchResultSelector = selector<DocumentsWrapper>({
	key: 'SearchResultSelector',
	get: async ({get}) => {
		const showResults = get(showSearchResults);
		if (!showResults) {
			return new DocumentsWrapper();
		}
		const hits = get(searchHits);
		let filteredItems = get(LibraryItemSelector);
		let unit = get(UnitSelector);
		let filtered = filteredItems.byUnitList(unit.map(u => u.value));
		get(SearchFilterAtom);

		let newList: DocumentsWrapper = new DocumentsWrapper();
		if (hits && Lists.default<any>(hits).length) {
			hits.forEach(match => {
				const doc = filtered.find(d => d.getId() === match._id);
				if (doc) {
					let copyObject = doc;
					if (Objects.default(match.highlight).file_content) {
						const contentMatches = Lists.default<any>(Objects.default(match.highlight).file_content);
						copyObject = doc.clone({
							...doc,
							matcher: {
								matched: Strings.default(contentMatches[0]).replace(/\n/mg, ' ') + '...' +
									fi(contentMatches.length > 1,
										`<br /><strong>+${contentMatches.length - 1} more</strong> matches in document`,
										'',
									),
								matchesNumber: contentMatches.length,
							},
						});
					}
					newList.push(copyObject);
				}
			});
		}

		return newList;
	}
});

export const ContentTypeSelector = selector<ContentType[]>({
	key: 'ContentTypeSelector',
	get: async ({get}) => {
		const contentTypes: ContentType[] = [];
		const filteredItems = get(LibraryItemSelector);

		const filters = get(SearchFilterAtom);
		if (filters) {
			let filtered = filteredItems;
			if (filters.years.length) {
				filtered = filtered.byYears(...filters.years.map(y => y.value));
			}
			if (filters.series.length) {
				filtered = filtered.bySeries(...filters.series.map(y => y.value));
			}
			// @ts-ignore
			const contentTypeIds = [...new Set([...filtered.contentTypeIds(), ...filters.contentTypes.map(y => y.value)])];
			contentTypeIds.forEach(ct => {
				const contentType = get(references(ct));
				if (contentType) {
					contentTypes.push(contentType as ContentType);
				}
			});
		}

		return contentTypes;
	}
});

export const YearSelector = selector<string[]>({
	key: 'YearSelector',
	get: async ({get}) => {
		let years: string[] = [];
		const filteredItems = get(LibraryItemSelector);

		const filters = get(SearchFilterAtom);
		if (filters) {
			let filtered = filteredItems;
			if (filters.contentTypes.length) {
				filtered = filtered.byContentType(...filters.contentTypes.map(ct => ct.value));
			}
			if (filters.series.length) {
				filtered = filtered.bySeries(...filters.series.map(y => y.value));
			}
			filtered.forEach(item => {
				if (item['exam_year'] && !years.includes(item['exam_year'])) {
					years.push(item['exam_year'].toString());
				}
			});
		}

		// @ts-ignore
		return [...new Set([...years, ...filters.years.map(y => y.value)])];
	}
});

export const SeriesSelector = selector<string[]>({
	key: 'SeriesSelector',
	get: async ({get}) => {
		let series: string[] = [];
		const filteredItems = get(LibraryItemSelector);

		const filters = get(SearchFilterAtom);
		if (filters) {
			let filtered = filteredItems;
			if (filters.contentTypes.length) {
				filtered = filtered.byContentType(...filters.contentTypes.map(ct => ct.value));
			}
			if (filters.years.length) {
				filtered = filtered.byYears(...filters.years.map(y => y.value));
			}
			filtered.forEach(item => {
				if (item['series'] && !series.includes(item['series'])) {
					series.push(item['series']);
				}
			});
		}

		// @ts-ignore
		return [...new Set([...series, ...filters.series.map(y => y.value)])];
	}
});
