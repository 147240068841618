import React, {useMemo} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {showIntroAtom} from '../../pages/Registration';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {sessionAtom} from '../../state/session';
import {RegistrationMessages} from './messages';

const CompleteProfile = () => {
	const userSession = useRecoilValue(sessionAtom);
	const setShowIntro = useSetRecoilState(showIntroAtom);
	const goNext = () => {
		setShowIntro(false);
	};

	const profileText = useMemo(() => {
		if (!userSession) return '';
		let userRoles = userSession.getRoles();
		if (userRoles.isExamsOfficer || (userRoles.isAssessor && !userRoles.isTeacher)) {
			return RegistrationMessages.CompleteProfileExamOfficer;
		} else {
			return RegistrationMessages.CompleteProfileTeacher;
		}
	}, [userSession]);

	if (!userSession) return null;

	return (
		<div data-testid="complete-profile-screen">
			<div className="pl-24 pr-24 pb-24" data-testid="complete-profile-text">
				<div className="mb-16 text-16 text-bold">{RegistrationMessages.CompleteProfileInfoNote}</div>
				<p className="text-16 text-regular" data-testid="profile-text">{profileText}</p>
			</div>
			<Divider/>
			<div className="mr-32 pt-16 pb-16 flex-row-end">
				<Button onClick={goNext} variant="contained" size="small" color="primary" data-testid="continue-btn">
					Continue
				</Button>
			</div>
		</div>
	);
};

export default CompleteProfile;