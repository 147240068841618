import React from 'react';
import styled from '@emotion/styled';
import { Types } from '../../../tw/types';
import DownloadButton from './Buttons/DownloadButton';
import PreviewButton from './Buttons/PreviewButton';
import LinkButton from './Buttons/LinkButton';
import VideoButton from './Buttons/VideoButton';
import AudioButton from './Buttons/AudioButton';
import ViewButton from './Buttons/ViewButton';
import { references } from '../../../state/state';
import { getRecoil } from '../../../state/recoilNexus';
import { Objects } from '../../../utils/objects';

const Wrapper = styled.div`
    display: flex;

    button {
        min-width: 38px;
        width: 38px;
        height: 38px;
        padding: 0;

        svg {
            height: 24px;
            width: 24px;
        }
    }
`;

const CardActions = ({item}: { item: any }) => {
	const renderActionButton = () => {
		switch (item.getType()) {
			case Types.DOCUMENT:
			case Types.IMAGE:
				if (item.hasPreview()) {
					return (
						<>
							<DownloadButton item={item}/>
							<PreviewButton item={item}/>
						</>
					);
				}
				return <DownloadButton item={item}/>;
			case Types.LINK:
			case Types.USEFUL_LINK:
				return <LinkButton item={item}/>;
			case Types.VIDEO:
				return <VideoButton item={item} onClick={item.defaultAction().actionHandler}/>;
			case Types.AUDIO:
				if (Objects.default(getRecoil(references(item.file)), {isZIP: () => false}).isZIP()) {
					return <DownloadButton item={item}/>;
				}
				return (
					<>
						<DownloadButton item={item}/>
						<AudioButton item={item}/>
					</>
				);
			case Types.SUBJECT_UPDATES:
				return <ViewButton item={item}/>;
		}
	};

	return (
		<Wrapper data-testid="card-actions">
			{renderActionButton()}
		</Wrapper>
	);
};

export default CardActions;