import React, { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { sessionAtom } from '../../state/session';
import { fi } from '../../utils/helpers';
import { Objects } from '../../utils/objects';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Edit } from '@mui/icons-material';
import { organizationChangeAtom, organizationChangePathAtom } from '../ModalDialogs/ChangeOrgModal';
import { useMenu } from '../../state/menu';

const AccountDetailsElem = styled.div`
    p {
        margin-bottom: 16px;
        font-size: 16px;
    }
`;
const BoldTextElem = styled.span`
    display: inline-block;
    font-family: var(--font-semi-bold);
    max-width: 190px;
    min-width: 160px;
`;

const RegularTextElem = styled.span`
    font-family: var(--font-regular);
    color: var(--color-monochrome)
`;
const AccountDetails = () => {
	const user = useRecoilValue(sessionAtom);
	const setShowOrgModal = useSetRecoilState(organizationChangeAtom);
	const setOrganizationPath = useSetRecoilState(organizationChangePathAtom);
	const {toggleVisible} = useMenu();

	const canChangeOrg = useMemo(() => {
		if (!user) return false;
		return user.availableStreams().length > 1 || user.organizations.length > 1;
	}, [user]);

	useEffect(() => {
		toggleVisible(false);
	}, []);

	if (!user) return null;

	const onChangeOrg = (evt) => {
		if (evt) {
			evt.preventDefault();
		}
		setOrganizationPath('Org from account page');
		setShowOrgModal(true);
	};

	return (
		<AccountDetailsElem>
			<div className="pl-24 pr-24">
				<div className="text-semiBold text-20 mb-24">Details</div>
				<p>
					<BoldTextElem>First name</BoldTextElem>
					<RegularTextElem> {user.givenName}</RegularTextElem>
				</p>
				<p>
					<BoldTextElem>Last name</BoldTextElem>
					<RegularTextElem> {user.familyName}</RegularTextElem>
				</p>
				{fi(user.isAssessor(), '',
					<p data-testid="centre-number">
						<BoldTextElem>Centre no. (NCN)</BoldTextElem>
						<RegularTextElem> {Objects.default(user.preferences).org}</RegularTextElem>
					</p>)}
				{fi(user.isAssessor(), '',
					<p data-testid="school">
						<BoldTextElem>School</BoldTextElem>
						<RegularTextElem> {user.getOrgName('short')}</RegularTextElem>
					</p>)}
				<p>
					<BoldTextElem>Email</BoldTextElem>
					<RegularTextElem> {user.email}</RegularTextElem>
				</p>
			</div>
			{canChangeOrg &&
                <>
                    <Divider/>
                    <div className="flex-row-end pt-16 pr-24">
                        <Button data-testid="change-org-btn" size="small" color="primary" variant="outlined"
                                startIcon={<Edit/>} onClick={onChangeOrg}>
                            Change centre
                        </Button>
                    </div>
                </>
			}
		</AccountDetailsElem>
	);
};

export default AccountDetails;