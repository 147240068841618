import React, {useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {articlesSelector, selectedArticle} from '../../../state/articles';
import {Browser} from '../../../utils/browser';
import {ROUTES} from '../../../utils/routes';
import DetailsPage from '../commons/DetailsPage';
import {setRecoil} from '../../../state/recoilNexus';
import {selectedObject} from '../../../state/state';
import FAQDetailsContent from './components/FAQDetailsContent';
import FAQDetailsFooter from './components/FAQDetailsFooter';
import FAQDetailsHeading from './components/FAQDetailsHeading';

const FAQDetails = () => {
	const articles = useRecoilValue(articlesSelector);
	const [article, setArticle] = useRecoilState(selectedArticle);
	const {pageId, pageSlug, id} = Browser.getParams(ROUTES);

	useEffect(() => {
		if (!articles || (articles && !articles.length)) return;
		let selectedArt = articles.find(s => (s.id).toString() === id);
		if (selectedArt) {
			if (!article && id) {
				setArticle(selectedArt);
				setRecoil(selectedObject, selectedArt);
			}
		} else {
			Browser.navigate(`/${pageId}/${pageSlug}${document.location.search}`);
		}
	}, [id, articles]);

	if (!article) return null;

	return (
		<DetailsPage heading={<FAQDetailsHeading article={article}/>}
					 content={<FAQDetailsContent article={article}/>}
					 footer={<FAQDetailsFooter/>}/>
	);
};
export default FAQDetails;