import {Strings} from '../../utils/strings';
import React, {ReactElement} from 'react';
import {ReactComponent as SubjectInfoIcon} from '../../assets/images/card/subject-info-normal.svg';
import {DefaultAction} from '../types';
import {ProductMapped} from './__ProductMapped';
import {setRecoil} from '../../state/recoilNexus';
import {Browser} from '../../utils/browser';
import {selectedSubjectUpdate} from '../../state/subjectUpdate';
import {selectedObject} from '../../state/state';
import GA from './GA';
import {DisplayMode} from './__CMSObject';
import Client from '../client';

export enum SubjectUpdateTypes {
	Update = 'Subject Update',
	UpdateAnnouncement = 'Subject Update & Global Announcement',
	Announcement = 'Global Announcement',
}

export class SubjectUpdate extends ProductMapped {
	public static markAsSeen = true;

	public title: string;
	public description: string;
	public type: SubjectUpdateTypes;
	public text: string;
	public examYear?: number;
	public series?: string;
	public urgent?: boolean;
	public label?: string;
	public link?: string;
	public date?: Date;

	constructor(item: any = {}) {
		super(item);

		this.title = Strings.default(item.title);
		this.description = Strings.default(item.description);
		this.text = Strings.default(item.text);
		this.type = Strings.default(item.type, SubjectUpdateTypes.Update) as SubjectUpdateTypes;

		if (item.exam_year) {
			this.examYear = Number(item.exam_year);
		}

		if (item.series) {
			this.series = Strings.default(item.series);
		}

		if (item.urgent) {
			this.urgent = Boolean(item.urgent);
		}

		if (item.label) {
			this.label = Strings.default(item.label);
		}

		if (item.link) {
			this.link = Strings.default(item.link);
		}

		if (item.__meta?.modified) {
			this.date = item.__meta.modified;
		}
	}

	public isUpdate() {
		return this.type === SubjectUpdateTypes.Update || this.type === SubjectUpdateTypes.UpdateAnnouncement;
	}

	public isAnnouncement() {
		return this.type === SubjectUpdateTypes.Announcement || this.type === SubjectUpdateTypes.UpdateAnnouncement;
	}

	selectSubjectUpdate() {
		setRecoil(selectedSubjectUpdate, this);
		setRecoil(selectedObject, this);
		Browser.navigate(`${document.location.pathname}/info-details/${this.getId()}`);
	}

	public defaultAction(): DefaultAction {
		return {
			label: 'View',
			actionHandler: () => {
				GA.SubjectUpdateEvent({event_label: this.displayLabel(), event_description: this.description});
				this.markAsSeen();
				this.selectSubjectUpdate();
			},
		};
	}

	public markPageAsSeen() {
		Client.markAsVisitated({
			subject_update: this.getId(),
			version: this.getVersion().toString(),
			last_seen: (new Date()).toISOString(),
		}).catch();
	}

	public getFlag(): ReactElement[] {
		const flags = super.getFlag();
		if (this.urgent) {
			flags.unshift(<span key={`${this.getId()}-urgent`} className="urgent">Urgent</span>);
		}
		return flags;
	}

	public getIcon(_small: boolean = false): ReactElement | null {
		const defaultAction = this.defaultAction();
		return <SubjectInfoIcon onClick={defaultAction.actionHandler} title={defaultAction.label}/>;
	}

	public hideActions() {
		return true;
	}

	public displayLabel(_options: DisplayMode = DisplayMode.SHORT): string {
		return this.title;
	}
}