import {Strings} from '../../utils/strings';
import {CMSObject} from './__CMSObject';

export class Topic extends CMSObject {
	public name: string;
	public description: string;
	public rateable: boolean;

	constructor(obj: any = {}) {
		super(obj);
		this.name = Strings.default(obj.name);
		this.description = Strings.default(obj.description);
		this.rateable = Boolean(obj.rateable_content);
	}

	public isRateable(): boolean {
		return this.rateable;
	}
}