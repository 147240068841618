import {CMSObject} from '../../../tw/models/__CMSObject';
import React, {useState} from 'react';
import {Messages} from '../../../utils/messages';
import InputField from '../../FormComponents/Input/InputField';
import {Button, Divider} from '@mui/material';

const RatingFeedback = ({cancel, item}: { cancel: () => void, item: CMSObject }) => {
	const [value, setValue] = useState('');
	const sendRatingFeedback = () => {
		item.rate(1, value);
		cancel();
	};

	return (
		<div>
			<div className="text-16 text-regular mb-16">
				{Messages.RatingMessage}
			</div>
			<InputField
				value={value}
				onChange={(event) => setValue(event.target.value)}
				field={{name: 'rating', label: 'Message'}}
				placeholder={'Write your feedback here...'}
				isTextArea={true}
				id={'rating-feedback-input'}
			/>
			<Divider/>
			<div className="flex-row-end mt-16">
				<Button variant="text" color="primary" onClick={() => cancel()}
						size="small">Cancel</Button>
				<Button variant="contained" color={'primary'} onClick={sendRatingFeedback}
						size="small" data-testid='send-feedback-btn'>Send feedback</Button>
			</div>
		</div>
	);
};

export default RatingFeedback;