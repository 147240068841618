import React, {useEffect} from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
import {Button, Divider, FormGroup} from '@mui/material';
import InputField from '../FormComponents/Input/InputField';
import {Strings} from '../../utils/strings';
import {Messages} from '../../utils/messages';
import {registrationDataAtom, registrationStepAtom} from '../../pages/Registration';
import {getRecoil, setRecoil} from '../../state/recoilNexus';
import {useRecoilState} from 'recoil';

const CenterInfoStep = () => {
	const [activeStep, setActiveStep] = useRecoilState(registrationStepAtom);
	const validationSchema = yup.object({
		centerName: yup
			.string()
			.nullable()
			.required(Messages.MandatoryField),
		centerNumber: yup
			.string()
			.nullable(),
		postCode: yup
			.string()
			.nullable()
			.required(Messages.MandatoryField),
	});

	const emptyProperties = {
		centerName: '',
		centerNumber: '',
		postCode: '',
	};

	const formik = useFormik({
		initialValues: emptyProperties,
		validationSchema: validationSchema,
		onSubmit: (values) => {
			setRecoil(registrationDataAtom('trial'), values);
			setActiveStep(old => old + 1);
		},
	});
	const onChange = (value, field) => {
		formik.setFieldValue(field, value);
	};

	useEffect(() => {
		const storedData = getRecoil(registrationDataAtom('trial'));
		if (storedData) {
			formik.setValues(storedData);
		}
	}, [activeStep]);
	
	return (
		<div>
			<form
				autoComplete="off"
				onSubmit={(e) => {
					e.preventDefault();
					formik.handleSubmit();
					formik.validateForm().then((resp) => {
						if (!Object.keys(resp).length) return;
					});
				}}
				data-testid="center-info"
				noValidate
			>
				<div className="pl-24 pr-24 pb-16">
					<p className="text-24 mb-24">Centre information</p>
					<InputField
						required={true}
						id={'centerName'}
						isTextArea={false}
						value={formik.values.centerName}
						onChange={(event) => onChange(event.target.value, 'centerName')}
						showError={Boolean(formik.touched.centerName) && Boolean(formik.errors.centerName)}
						errorMessage={Strings.default(formik.errors.centerName)}
						touched={!!formik.touched.centerName}
						dataTestId={`input-centerName`}
						field={{name: 'centerName', label: 'Centre name'}}
					/>
					<FormGroup>
						<InputField
							id='centerNr'
							required={false}
							isTextArea={false}
							value={formik.values.centerNumber}
							onChange={(event) => onChange(event.target.value, 'centerNumber')}
							showError={Boolean(formik.touched.centerNumber) && Boolean(formik.errors.centerNumber)}
							errorMessage={Strings.default(formik.errors.centerNumber)}
							touched={!!formik.touched.centerNumber}
							dataTestId={`input-centre-no`}
							field={{name: 'centerNumber', label: 'Centre no.'}}
						/>
						<InputField
							id='centerPostcode'
							required={true}
							isTextArea={false}
							value={formik.values.postCode}
							onChange={(event) => onChange(event.target.value, 'postCode')}
							showError={Boolean(formik.touched.postCode) && Boolean(formik.errors.postCode)}
							errorMessage={Strings.default(formik.errors.postCode)}
							touched={!!formik.touched.postCode}
							dataTestId={`input-centre-postCode`}
							field={{name: 'postCode', label: 'Centre postcode'}}
						/>
					</FormGroup>
				</div>

				<Divider/>

				<div className="flex-row-end pall-24">
					<Button color="primary" variant="contained" size="small" type="submit"
							disabled={false}
							data-testid="continue-btn">Continue</Button>
				</div>
			</form>
		</div>
	);
};
export default CenterInfoStep;