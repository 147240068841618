import {BaseClass} from './__base';
import {Numbers} from '../../utils/numbers';
import {Strings} from '../../utils/strings';
import {Lists} from '../../utils/lists';
import {setRecoil} from '../../state/recoilNexus';
import {Browser} from '../../utils/browser';
import {DefaultAction} from '../types';
import {selectedArticle} from '../../state/articles';
import React, {ReactElement} from 'react';
import {ReactComponent as ChildPageIcon} from '../../assets/images/card/child-page-normal.svg';
import {selectedObject} from '../../state/state';

export class ZendeskArticle extends BaseClass {
	public id: number;
	public created: string;
	public updated: string;
	public fullTitle: string;
	public title: string;
	public body: string;
	public subjects: string[];

	constructor(item: any = {}) {
		super(item);
		this.id = Numbers.default(item.id);
		this.created = Strings.default(item.created_at);
		this.updated = Strings.default(item.updated_at);
		this.fullTitle = Strings.default(item.title);
		this.title = Strings.default(this.displayLabel());
		this.body = Strings.default(item.body);
		this.subjects = Lists.default(item.label_names);
	}

	public getId(): string {
		return Strings.default(this.id);
	}

	selectArticle() {
		setRecoil(selectedArticle, this);
		setRecoil(selectedObject, this);
		Browser.navigate(`${document.location.pathname}/article/${this.id}`);
	}

	public defaultAction(): DefaultAction {
		return {
			label: 'View',
			actionHandler: () => this.selectArticle(),
		};
	}

	public getIcon(_small: boolean = false): ReactElement | null {
		const defaultAction = this.defaultAction();
		return <ChildPageIcon title={defaultAction.label} onClick={defaultAction.actionHandler}/>;
	}

	public displayLabel(): string {
		let count = (this.fullTitle.match(/:/g) || []).length;
		if (count > 0) {
			return this.fullTitle.split(':')[1];
		}
		return this.fullTitle;
	}
}