import React, {useEffect} from 'react';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {ConfirmationModalProps, modalAtom} from './ModalDialog';
import {ratingFeedbackAtom} from '../../state/modals';
import {CMSObject} from '../../tw/models/__CMSObject';
import RatingFeedback from './components/RatingFeedback';

const RatingFeedbackModal = ({item}:{item: CMSObject}) => {
	const setValue = useSetRecoilState(modalAtom);
	const [showRatingFeedback, setShowRatingFeedback] = useRecoilState(ratingFeedbackAtom);

	const cancelModal = () => {
		setShowRatingFeedback(false);
		setValue(null);
	};

	const doNothing = () => {
		return
	}

	useEffect(() => {
		if (!showRatingFeedback) return;
		const modalObject: ConfirmationModalProps = {
			title: `Feedback`,
			message: <RatingFeedback cancel={cancelModal} item={item}/>,
			onConfirm: doNothing,
			onCancel: () => cancelModal(),
			confirmText: 'Send feedback',
			hasCancel: true,
			noFooter: true,
		};
		setValue(modalObject);
	}, [showRatingFeedback]);


	if (!showRatingFeedback) return null;

	return (
		<></>
	);
};

export default RatingFeedbackModal;