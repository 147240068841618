import styled from '@emotion/styled';
import React, {Suspense, useEffect} from 'react';
import Card from './Card';
import DownloadActions from '../widgets/commons/DownloadActions';
import {Strings} from '../../utils/strings';
import Loader from '../Loader/Loader';
import {fi} from '../../utils/helpers';
import {Lists} from '../../utils/lists';
import {LibraryItem} from '../../tw/models/__MediaLibrary';

const CardList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;
const Group = ({items, allItems, label, itemsOriginalLength, hideActions, selectState}: {
	items: any,
	allItems?: any,
	label?: string,
	itemsOriginalLength?: number,
	hideActions?: boolean,
	selectState?: boolean,
}) => {
	const [selectMode, setSelectMode] = React.useState<boolean>(Boolean(selectState));

	useEffect(() => {
		setSelectMode(Boolean(selectState));
	}, [selectState])

	return (
		<>
			<CardList data-testid='cards-list'>
				{Lists.default(items).map((item, idx) => (
					<Card item={item} key={`${idx}-${label}`} showCheckbox={selectMode} />
				))}
			</CardList>
			{fi(hideActions, null,
				<Suspense
					fallback={<div className='mt-16 mr-16 flex-row-end'><Loader size={16} /> &nbsp; Downloading ...
					</div>}>
					<DownloadActions key={`dwd-${label}`}
									 items={Lists.default<LibraryItem>(items)}
									 allItems={allItems}
									 fileName={Strings.default(label, 'documents')}
									 itemsLength={itemsOriginalLength}
									 cb={setSelectMode}
					/>
				</Suspense>)}
		</>
	);
};

export default Group;