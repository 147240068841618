import React, {useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {references} from '../../../state/state';
import styled from '@emotion/styled';
import {fi} from '../../../utils/helpers';
import PersonOutline from '@mui/icons-material/PersonOutline';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import {bindHover, bindPopover, usePopupState} from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Rating from '@mui/material/Rating';
import { CMSObject } from '../../../tw/models/__CMSObject';
import Feedback from './Feedback';
import {Text} from '../../../tw/models/Text';
import {Messages} from '../../../utils/messages';
import RatingFeedbackModal from '../../ModalDialogs/RatingFeedbackModal';

const Wrapper = styled.div`
    overflow: hidden;
    padding: 16px;

    hr {
        margin: 0 -16px;
    }

    .blue-color {
        color: var(--color-blue);
    }
`;

const Action = styled.div`
    font-family: var(--font-semi-bold);
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
`;

const Details = styled.div`
    padding-top: 16px;

    p {
        color: var(--color-grey);
    }
`;

const Score = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    div {
        display: flex;
        align-items: center;

        span {
            margin-right: 8px;
        }
    }
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;

const Icon = styled.div`
    svg[data-testid="StarIcon"] {
        color: var(--color-blue);
    }

    .rate-average {
        font-family: var(--font-semi-bold);
        padding-left: 3px;
        color: var(--color-lighter-monochrome);
        font-size: 16px;
    }
`;

const computeRating = (rating: number[]) => {
    let avgRating: any = 0;
    const ratings: any[] = [];
    const itemRatings = rating.slice(1);
    const totalNoVotes = itemRatings.reduce((a, b) => a + b, 0); // number of votes
    const totalValueForVotes = itemRatings.reduce((sum, nr, i) => sum + (nr * (i + 1)), 0); // total value

    for (let i = 0; i < itemRatings.length; i++) {
        let percentageValue = (itemRatings[i] * 100) / totalNoVotes;
        ratings.push({
            label: i + 1,
            percentage: percentageValue.toFixed(1),
            rounded: Math.round(percentageValue),
            noVotes: itemRatings[i],
        });
    }

    if (totalNoVotes !== 0) {
        // round avg to nearest .5
        avgRating = (Math.round((totalValueForVotes / totalNoVotes) * 2) / 2).toFixed(1);
    }
    ratings.reverse();

    return {average: avgRating, totalNoVotes, ratings, ownVote: rating[0]};
};

const RatingStats = ({item, popupState}: { item: CMSObject, popupState: any }) => {
    const [newRating, setNewRating] = useState<number>(0);
    const {average, totalNoVotes, ratings, ownVote} = useMemo(() => {
        if (!item.hasRating()) {
            return {
                average: 0,
                totalNoVotes: 0,
                ratings: [],
                ownVote: 0,
            };
        }
        return computeRating(item.getRating());
    }, [item]);

    const rateItem = (ev, score) => {
        ev.stopPropagation();
        ev.preventDefault();
        if (score === 1) {
            popupState.close();
            setNewRating(score);
            return;
        }
        item.rate(score);
    };

    const sendFeedback = (feedback) => {
        item.rate(newRating, feedback);
        setNewRating(0);
    };

    if (newRating) {
        return (
            <Feedback onClose={sendFeedback}/>
        );
    }

    return (
        <HoverPopover data-testid='rating-popover'
                      {...bindPopover(popupState)}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                      transformOrigin={{vertical: 'top', horizontal: 'left'}}
        >
            <Wrapper data-testid="rating-stats-wrapper">
                <Action>
                    <p className="mr-8">Rate this resource</p>
                    <Rating data-testid="rating-action"
                            size="small"
                            precision={1}
                            value={ownVote}
                            onChange={(ev, score) => {
                                rateItem(ev, score);
                            }}
                            classes={{iconHover: 'blue-color', iconFilled: 'blue-color'}}/>
                </Action>
                <Divider/>
                <Details data-testid='rating-details'>
                    {fi(!item.hasRating(), <p>No ratings yet</p>,
                        <>
                            <Score data-testid="rating-score-wrapper">
                                <Rating data-testid="rating-score-average"
                                        size="small"
                                        precision={0.5}
                                        readOnly={true}
                                        value={Number(average)}
                                        classes={{iconFilled: 'blue-color'}}/>
                                <div>
                                    <span data-testid="rating-score-total">{totalNoVotes}</span>
                                    <PersonOutline fontSize="small"></PersonOutline>
                                </div>
                            </Score>
                            <div className="pt-16">
                                {ratings.map((r, idx) => (
                                    <Row key={r.label}>
                                        <span>{r.label}</span>
                                        <LinearProgress variant="determinate"
                                                        style={{width: '100%', margin: '0 8px'}}
                                                        value={fi(r.rounded === 100, 100, r.rounded)}/>
                                        <span>{r.noVotes}</span>
                                    </Row>
                                ))}
                            </div>
                        </>,
                    )}
                </Details>
            </Wrapper>
        </HoverPopover>
    );
};

const RatingComponent = ({item, disabled}: { item: any, disabled: boolean }) => {
    const contentType = useRecoilValue(references(item.content_type));
    const topic = useRecoilValue(references(item.topic));
    const popupState = usePopupState({variant: 'popover', popupId: 'rating-popover'});

    const isRateable = useMemo(() => {
        if (item instanceof Text && topic) {
            return topic.isRateable();
        } else if (contentType) {
            return contentType.isRateable();
        }
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentType, topic]);

    const average = useMemo(() => {
        return computeRating(item.getRating()).average;
    }, [item]);

    if (!isRateable) {
        return null;
    }

    return (
        <div data-testid='rating-wrapper'>
            {fi(disabled,
                <Icon data-testid='star-rate' className="low-opacity" title={Messages.FeatureNotAvailable}>
                    <StarBorder/> </Icon>,
                <Icon {...bindHover(popupState)} data-testid='rating-star'>
                    {fi(item.isRated(), <Star/>, <StarBorder/>)}
                    {fi(average > 0, <span className="rate-average">{average}</span>)}
                </Icon>,
            )}

            <RatingStats item={item} popupState={popupState}/>
            <RatingFeedbackModal item={item}/>
        </div>
    );
};

export default RatingComponent;