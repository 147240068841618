import React, {useEffect, useMemo, useState} from 'react';
import {useRecoilState, useRecoilValue, useResetRecoilState} from 'recoil';
import {featureFlagsSelector, showTourAtom} from '../../state/state';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import {fi, scrollAppToTop} from '../../utils/helpers';
import {tourAtom} from '../../state/session';
import ClearIcon from '@mui/icons-material/Clear';
import {checkpointMessage, introMessages, ITour, tourConfig} from './types';
import {filterSteps} from './helpers';
import {Objects} from '../../utils/objects';
import {Strings} from '../../utils/strings';
import {useLocation} from 'react-router-dom';
import {Lists} from '../../utils/lists';
import client from '../../tw/client';
import {Preferences, UserSession} from '../../tw/models/Session';
import {useMedia} from '../../utils/hooks';
import {device, RESOURCE_FINDER_FLAG, USER_TOUR} from '../../utils/constants';
import GA from '../../tw/models/GA';
import {getRecoil, setRecoil} from '../../state/recoilNexus';
import {backdropElement, backdropSet} from './Backdrop';
import {findTreeNode, ITreeItem} from '../TreeMenu/utils';
import styled from '@emotion/styled';
import Popper from '@mui/material/Popper';
import {useMenu} from '../../state/menu';
import {useMediaQuery} from '@mui/material';

const StyledPopper = styled(Popper)`
    z-index: 9999;
    border: solid 0.5px var(--color-border-light);
    border-radius: 4px;
    box-shadow: 3px -3px 3px var(--color-box-shadow);
    background-color: white;

    width: 450px;
    padding: 16px;

    font-family: var(--font-regular);
    font-size: 16px;
    line-height: 20px;

    & > div:first-of-type {
        box-shadow: none;
    }

    &[data-popper-placement*="bottom"] {
        top: 36px !important;
    }

    &[data-popper-placement*="bottom"] .arrow {
        top: 0;
        left: 0;
        margin-top: -0.9em;
        width: 3em;
        height: 1em;

        &:before {
            transform: rotate(135deg);
            box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
        }
    }

    &[data-popper-placement="bottom"] .arrow {
        transform: translateX(-50%);
        margin-left: 50%
    }

    &[data-popper-placement="bottom-end"] .arrow {
        transform: translateX(-95%);
        margin-left: 95%;
    }

    &[data-popper-placement="bottom-start"] .arrow {
        transform: translateX(-5%);
        margin-left: 5%;
    }

    &[data-popper-placement="top-start"] .arrow {
        bottom: 0;
        left: 8px;
        margin-bottom: -0.9em;
        width: 3em;
        height: 1em;

        &:before {
            transform-origin: 0 0;
            transform: rotate(-45deg);
            box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
        }
    }

    &[data-popper-placement="top"] .arrow {
        bottom: 0;
        left: 0;
        margin-bottom: -0.9em;
        width: 3em;
        height: 1em;

        transform: translateX(-50%);
        margin-left: 50%;

        &:before {
            transform-origin: 0 0;
            transform: rotate(-45deg);
            box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
        }
    }

    &[data-popper-placement="top-end"] .arrow {
        left: unset;
        top: 0;
        right: 0;
        margin-top: -0.9em;
        width: 3em;
        height: 1em;

        &:before {
            border-width: 0 1em 1em 1em;
            border-color: transparent transparent white transparent;
        }
    }

    &[data-popper-placement*="right"] .arrow {
        left: 0;
        margin-left: -0.9em;
        height: 3em;
        width: 1em;

        &:before {
            transform: rotate(45deg);
            box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
        }
    }

    &[data-popper-placement="right-start"] .arrow {
        transform: translateY(-5%);
        margin-top: 5%;
    }

    &[data-popper-placement*="left"] .arrow {
        right: 0;
        margin-right: -0.9em;
        height: 3em;
        width: 1em;

        &:before {

            transform-origin: 0 0;
            transform: rotate(-135deg);
            box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
        }
    }

    &[data-popper-placement="left-start"] .arrow {
        transform: translateY(-10%);
        margin-top: 10%;
    }

    .arrow {
        position: absolute;
        font-size: 10px;
        width: 3em;
        height: 3em;

        &:before {
            content: "";
            margin: auto;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border: 1em solid black;
            border-color: transparent transparent white white;
        }
    }

    &[data-popper-placement="right-start"] {
        box-shadow: -3px 3px 3px var(--color-box-shadow);
    }

    &[data-popper-placement="top-start"] {
        box-shadow: -3px 3px 3px var(--color-box-shadow);
    }

    @media ${device.mobile} {
        width: 350px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: var(--font-semi-bold);

    svg {
        width: 18px;
        height: 18px;
        margin-top: 2px;

        &:hover {
            cursor: pointer;
        }
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 0;
`;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;

    button {
        max-height: 36px;
        padding: 6px 8px;
        margin-bottom: 0;

        :last-of-type {
            background-color: var(--color-blue);
            color: white;
            margin-left: 8px;
        }
    }
`;

const Intro = styled.div`
    z-index: 9999;
    position: fixed;
    bottom: 24px;
    left: 24px;
    width: 400px;
    box-sizing: border-box;
    box-shadow: 3px 3px 3px 0 var(--color-box-shadow);
    background-color: white;

    font-family: var(--font-regular);
    font-size: 16px;
    line-height: 20px;

    & > div:first-of-type {
        padding: 16px;
        box-shadow: none;
    }
`;

const Checkpoint = styled.div`
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--color-backdrop);
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiPaper-root {
        background-color: var(--color-white);
        width: 400px;
        box-sizing: border-box;
        box-shadow: 3px 3px 3px 0 var(--color-box-shadow);

        & > div {
            padding: 16px;
            box-shadow: none;
        }

        font-family: var(--font-regular);
        font-size: 16px;
        line-height: 20px;
    }
`;

const Tour = ({session, state, pageTree}: { session: UserSession, state: number, pageTree: ITreeItem[] }) => {
	const location = useLocation();
	const [stepsLength, setStepsLength] = useState<number>(0);
	const [stepIndex, setStepIndex] = useState<number>(-1);
	const [intro, setIntro] = useState<Partial<ITour> | null>(null);
	const [tourStepsList, setTourStepsList] = useState<ITour[]>([]);
	const [currentStep, setCurrentStep] = useState<ITour | null>(null);
	const [opened, setOpened] = useState<boolean>(false);
	const [scrollTop, setScrollTop] = useState(0);
	const featureFlags = useRecoilValue(featureFlagsSelector);
	const [tourSteps, setTourSteps] = useRecoilState(tourAtom);
	const [checkpoint, setCheckpoint] = useState<Partial<ITour> | null>(null);
	const isBackdropSet = useRecoilValue(backdropSet);
	const {setPage, state: {toggleVisibleMenu, secondaryMenuVisible}} = useMenu();
	const resetTour = useResetRecoilState(showTourAtom);
	const isMobileOrTablet = useMediaQuery(device.tablet);

	const currentDevice = useMedia(['(max-device-width: 767px)', '(max-device-width: 1024px)', '(min-device-width: 1220px)'],
		['mobile', 'tablet', 'web'], 'web');
	let popperModifiers: any [] = [];

	useEffect(() => {
		const scrollFct = (_e) => {
			setScrollTop(window.scrollY);
		};
		window.addEventListener('scroll', scrollFct);
		return () => {
			window.removeEventListener('scroll', scrollFct);
		};
	}, []);

	const resourceFinderEnabled = useMemo(() => {
		return featureFlags.includes(RESOURCE_FINDER_FLAG);
	}, [featureFlags, session]);

	useEffect(() => {
		let filteredSteps: ITour[] = filterSteps(tourConfig, session, pageTree);

		let targetIntro: Partial<ITour> | null = null;
		if (localStorage.getItem(USER_TOUR) || !filteredSteps.length || currentStep) {
			return;
		}
		if (tourSteps.length) {
			tourStepsList.splice(0);
			filteredSteps = filteredSteps.filter((step) => !tourSteps.includes(step.id));
			if (!filteredSteps.length) {
				return;
			}

			targetIntro = introMessages[0]; // new features
			if (targetIntro?.setContent) {
				targetIntro.setContent(session, Strings.default(targetIntro.content), Strings.default(targetIntro.title));
			}
		} else {
			// jump straight to first step
			setIntro(null);
			if (filteredSteps[0].setup) {
				filteredSteps[0].setup(session, isMobileOrTablet, resourceFinderEnabled);
			}
			setCurrentStep(filteredSteps[0]);
			setStepIndex(0);
			//need to set tour steps in case user saw some steps but clicks on take a tour
			setTourStepsList(filteredSteps);
		}

		setStepsLength(filteredSteps.length);
		if (!tourStepsList.length) {
			setTourStepsList(filteredSteps);
			if (!filteredSteps.length) {
				targetIntro = null;
			}
			setIntro(targetIntro);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session, state]);

	useEffect(() => {
		const copyObj: any = {...currentStep};
		if (copyObj.popperModifiers) {
			copyObj.popperModifiers = [...copyObj.popperModifiers].map(m => ({...m, options: {...m.options}}));
		}
		if (copyObj.backdropOffset) {
			copyObj.backdropOffset = {...copyObj.backdropOffset};
		}
		setRecoil(backdropElement, copyObj);
	}, [currentStep]);

	useEffect(() => {
		//expand secondary menu if collapsed
		if (currentStep?.id === 'main_navigation' || currentStep?.id === 'left_hand_menu') {
			if (!secondaryMenuVisible) {
				toggleVisibleMenu(true);
			}
		}
	}, [currentStep]);
	const onLocationChange = () => {
		if (!currentStep) {
			return;
		}
		const node = findTreeNode(pageTree, currentStep.targetPage);
		if (node) {
			setPage(node);
		}
		if (targetElement) {
			setOpened(true);
		} else if (typeof targetElement == 'boolean' && !targetElement) {
			setOpened(false);
		} else if (!targetElement) {
			setOpened(false);
			nextStep();
		}
	};

	useEffect(() => {
		if (isBackdropSet !== '' && currentStep?.id === isBackdropSet) {
			onLocationChange()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isBackdropSet, currentStep]);

	const targetElement: any = useMemo(() => {
		if (!currentStep) { //when checkpoint displayed, currentStep is null
			return false;
		}
		const step = Objects.default(currentStep);
		let elem = document.querySelector(step.targetElement);
		if (!elem) elem = document.getElementById('tour-center');

		if (elem) {
			elem.scrollIntoView({
				block: step.logicalPosition ? step.logicalPosition : 'center',
				behavior: 'smooth',
				inline: 'nearest',
			});
			if (step.scrollTo !== undefined) {
				window.scrollTo({
					top: step.scrollTo,
					behavior: 'smooth',
				});
			}
		}
		return elem;
	}, [isBackdropSet, currentStep]);

	const nextOrCheckpoint = (evt: any = null) => {
		if (evt) {
			evt.preventDefault();
		}
		//check if next step has different targetPage -> display checkpoint
		if (tourStepsList[stepIndex]?.targetPage !== tourStepsList[stepIndex + 1]?.targetPage && stepIndex + 1 !== stepsLength) {
			setCheckpoint(checkpointMessage[0]);
			saveState(false);
			setCurrentStep(null);
		} else {
			nextStep(evt);
		}
	};

	const nextStep = (evt: any = null) => {
		if (evt) {
			evt.preventDefault();
		}
		if (intro) {
			setIntro(null);
		}
		if (checkpoint) {
			setCheckpoint(null);
		}
		let stepIdx = stepIndex + 1;
		if (stepIdx === 0) { // tour started
			GA.TourEvent('Tour started');
		}
		if (stepIndex + 1 === stepsLength) { // end of tour (finish)
			saveState(true);
			GA.TourEvent('Tour completed');
			return;
		}
		setStepIndex(stepIdx);
		if (currentStep) {
			stepIdx = tourStepsList.findIndex(t => t.id === currentStep.id) + 1;
		}
		const s = tourStepsList[stepIdx];
		if (s) {
			if (s.setup) {
				s.setup(session, isMobileOrTablet, resourceFinderEnabled);
			}
			setCurrentStep(s);
			if (s.callback) {
				s.callback(location, session).catch();
			}
			saveState(false);
		} else {
			setCurrentStep(null);
		}
	};

	const saveState = (endTour: boolean, evt: any = null) => {
		if (!session) {
			return;
		}
		if (evt) {
			evt.preventDefault();
		}
		let stepsToAdd: string[] = [...tourSteps];
		if (endTour) {
			setRecoil(backdropElement, null);
			scrollAppToTop();
			setCurrentStep(null);
			setStepIndex(-1);
			setIntro(null);
			resetTour();

			stepsToAdd = [...stepsToAdd, ...tourStepsList.map(s => s.id).filter(id => !tourSteps.includes(id))];

			GA.TourEvent('Tour - never show again');
		} else {
			if (!currentStep) {
				return;
			}
			stepsToAdd.push(currentStep.id);
		}
		stepsToAdd = Array.from(new Set(stepsToAdd));
		setTourSteps(Array.from(new Set([...tourSteps, ...stepsToAdd])));
		const userPrefs = {...Objects.default(session.preferences).preferences} as Preferences;
		userPrefs.tour = [...stepsToAdd];

		client.UpdatePreferences(userPrefs).catch();
	};

	if (!session) {
		return null;
	}

	const closePopup = () => {
		GA.TourEvent('Tour closed');
		localStorage.setItem(USER_TOUR, 'closed');
		setOpened(false);
		setCurrentStep(null);
		setIntro(null);
		setStepIndex(-1);
		setRecoil(backdropElement, null);
		setTourStepsList([]);
		resetTour();
	};

	if (currentStep && !localStorage.getItem(USER_TOUR) && !checkpoint) {
		const element = targetElement as HTMLElement;
		if (!element) {
			return null;
		}

		let modif: any[] = [];
		let placement: any = '';
		document.getElementsByTagName('html')[0].classList.add('tour');
		switch (currentDevice) {
			case 'mobile':
				modif = Lists.default(currentStep.mobilePopperModifiers);
				placement = Strings.default(currentStep.mobilePlacement);
				break;
			case 'tablet':
				modif = Lists.default(currentStep.tabletPopperModifiers);
				placement = Strings.default(currentStep.tabletPlacement);
				break;
			default:
				modif = Lists.default(currentStep.popperModifiers);
				placement = Strings.default(currentStep.placement);
		}

		if (currentStep.onScroll) {
			modif = currentStep.onScroll(modif);
		}
		popperModifiers = modif;
		return (
			<StyledPopper open={opened}
						  modifiers={popperModifiers}
						  anchorEl={targetElement}
						  placement={placement}
						  className="tour-popper"
						  key={scrollTop}
						  data-testid="user-tour">
				{({TransitionProps}) => (
					<Fade {...TransitionProps} timeout={350}>
						<>
							<span className={'arrow'}/>
							<Paper>
								<Header>
									<span>{currentStep.title}</span>
									<span data-testid="close-tour"><ClearIcon fontSize="small"
																			  onClick={closePopup}/></span>
								</Header>
								<Content dangerouslySetInnerHTML={{__html: currentStep.content}}/>
								<Divider style={{margin: '0 -16px'}}/>
								<Footer>
									<div>({stepIndex + 1}/{stepsLength})</div>
									<div>
										<Button variant="text" color="primary" size="small" disableRipple
												data-testid="show-no-more"
												onClick={(evt) => {
													saveState(true, evt);
												}}>Don't show
											again</Button>
										<Button color="primary" size="small"
												disableRipple
												data-testid="next-step"
												onClick={(evt) => nextOrCheckpoint(evt)}>{fi(stepIndex + 1 < stepsLength, 'Next', 'Finish')}</Button>
									</div>
								</Footer>
							</Paper>
						</>
					</Fade>
				)}
			</StyledPopper>
		);
	}

	if (intro) {
		return (
			<Intro data-testid="intro-tour">
				<Paper>
					<Header>
						<span>{intro.title}</span>
						<span data-testid="close-tour"><ClearIcon fontSize="small" onClick={closePopup}/></span>
					</Header>
					<Content>{intro.content}</Content>
					<Divider style={{margin: '0 -16px'}}/>
					<Footer>
						<Button variant="text" color="primary" size="small" disableRipple
								data-testid="intro-hide-tour-forever"
								onClick={(evt) => saveState(true, evt)}>Don't show
							again</Button>
						<Button color="primary" size="small"
								disableRipple
								data-testid="intro-next-step"
								onClick={(evt) => nextStep(evt)}>Try it out</Button>
					</Footer>
				</Paper>
			</Intro>
		);
	}

	if (checkpoint) {
		return (
			<Checkpoint data-testid="checkpoint" className="tour-popper">
				<Paper>
					<Header>
						<span>{checkpoint.title}</span>
						<span data-testid="close-tour"><ClearIcon fontSize="small" onClick={closePopup}/></span>
					</Header>
					<Content>{checkpoint.content}</Content>
					<Divider style={{margin: '0 -16px'}}/>
					<Footer>
						<Button variant="text" color="primary" size="small" disableRipple
								data-testid="intro-hide-tour-forever"
								onClick={closePopup}>Stop</Button>
						<Button color="primary" size="small"
								disableRipple
								data-testid="intro-next-step"
								onClick={(evt) => nextStep(evt)}>Continue</Button>
					</Footer>
				</Paper>
			</Checkpoint>
		);
	}
	return null;
};

export default Tour;