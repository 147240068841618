import React from 'react';
import HelpOutline from '@mui/icons-material/HelpOutline';
import ClearIcon from '@mui/icons-material/Clear';
import {Objects} from '../../utils/objects';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {helpPanelToggledAtom, infoPanelState} from '../../state/helpGuide';
import Divider from '@mui/material/Divider';
import {fi} from '../../utils/helpers';
import styled from '@emotion/styled';

const HeaderElem = styled.div`
	display:flex;
	align-items: center;
	justify-content: space-between;
    margin-bottom: 8px;

    #blue-icon{
        color: var(--color-blue)
    }
`

const BodyElem = styled.div`
    max-height: 335px;
    overflow-y: auto;
    margin-top: 8px;
    height: 160px;
`

const TitleElem = styled.div`
    font-family: var(--font-semi-bold);
    padding: 0 8px;
`

const InfoPanel = () => {
	const content = useRecoilValue(infoPanelState);
	const setToggled = useSetRecoilState(helpPanelToggledAtom);

	const defaultDescription = <div>
		<div className="text-semiBold">Welcome to the Teach Cambridge Help guide!</div>
		<br/>
		<div>Hover your mouse over certain page features to display the name and function here. Click the X button to
			close the Help guide.
		</div>
		<br/>
		<div>
			For a more guided tour of the site, you can {' '}
			<span className="text-semiBold"> Take a tour</span> under <span className="text-semiBold">My account</span>.
		</div>
	</div>;

	return (
		<>
			<HeaderElem data-testid='help-info-content-header'>
				<HelpOutline fontSize="small" id="blue-icon"/>
				<TitleElem data-testid='help-info-content-title'>
					{fi(content, Objects.default(content).title, 'Help info guide')}
				</TitleElem>
				<ClearIcon data-testid='close-content' onClick={() => setToggled(false)} fontSize="small"/>
			</HeaderElem>
			<Divider/>
			<BodyElem data-testid='help-info-content-body'>
				{fi(content, <span dangerouslySetInnerHTML={{__html: Objects.default(content).description}}></span>,
					<div>{defaultDescription}</div>)}
			</BodyElem>
		</>
	);
};

export default InfoPanel;